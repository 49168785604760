import DOMPurify from 'dompurify';
import fileDownload from 'js-file-download';
import _, { trim } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ImportQuestionsDropdown from './components/ImportQuestionsDropdown';
import { browserHistory } from 'react-router';
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import XLSX from 'xlsx';

import AmsAlert from '../../../utils/AmsAlert';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';
import AmsModal from '../../../utils/AmsModal';
import AmsSkipLinks from '../../../utils/AmsSkipLinks';
import AmsTable from '../../../utils/AmsTable';
import {
  QuestionTemplates,
  buildQuestionDataUsingSchema,
  exportColumns,
  modifySchema,
  populateTemplate,
  validateQuestion,
} from './utils';

import { selectTab } from '../../../actions/adminActions';
import { fetchAmsLookup } from '../../../actions/lookupActions';
import { fetchCitations } from '../../../actions/reviewActions';
import {
  addQuestion,
  deleteQuestion,
  fetchContentAreaLookups,
  fetchPerformanceAreaLookups,
  fetchPerformanceMeasureLookups,
  fetchQuestionSchemaJSONs,
  fetchSurveyLookups,
  updateQuestion,
} from '../../../actions/surveyActions';
import SurveyLookupDropdown from '../../../utils/SurveyLookupDropdown';
import ExportQuestionsDropdown from './components/ExportQuestionsDropdown';
import DownloadTemplateDropdown from './components/DownloadTemplateDropdown';
import CreateQuestionButton from './components/CreateQuestionButton';
import { TemplatesRequireCitations } from './utils';

function SurveyQuestionsAdminPage(props) {
  const dispatch = useDispatch();
  const [responseErrors, setResponseErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reviewTypes, setReviewTypes] = useState({});
  const [fiscalYears, setFiscalYears] = useState({});
  const [contentAreas, setContentAreas] = useState([]);
  const [surveyLookups, setSurveyLookups] = useState([]);
  const [performanceAreas, setPerformanceAreas] = useState([]);
  const [performanceMeasures, setPerformanceMeasures] = useState([]);
  const [template, setTemplate] = useState({});
  const [evidenceSources, setEvidenceSources] = useState({});
  const [programOptions, setProgramOptions] = useState({});
  const [programOptionObjects, setProgramOptionObjects] = useState({});
  const [citations, setCitations] = useState({});
  const [questionsToImport, setQuestionsToImport] = useState(null);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showAfterImportModal, setShowAfterImportModal] = useState(false);
  const [data, setData] = useState({});
  const [filters, setFilters] = useState({ reviewType: null });
  const [mapContentAreas, setMapContentAreas] = useState([]);
  const [mapPerformanceAreas, setMapPerformanceAreas] = useState([]);
  const [mapPerformanceMeasures, setMapPerformanceMeasures] = useState([]);
  const [attributes, setAttributes] = useState({});
  const [existingQuestions, setExistingQuestions] = useState({});
  const [paginationOptions, setPaginationOptions] = useState({
    page: 0,
    limit: 10,
  });
  const [findingCategories, setFindingCategories] = useState({});
  const [questionCodeToDelete, setQuestionCodeToDelete] = useState(null);
  const [questionIdToDelete, setQuestionIdToDelete] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const amsTableRef = useRef(null);
  const scrollToResults = () => scrollToRef(amsTableRef);
  const scrollToRef = ref => {
    window.scrollTo(0, ref.current.offsetTop);
    ref.current.focus();
  };

  //grab fileTypes on mount
  useEffect(() => {
    dispatch(selectTab({ key: 8, pageTitle: 'Survey Questions' }));
    dispatch(fetchAmsLookup('easReviewTypes')).then(data => {
      setReviewTypes(
        data.lookups
          .filter(x => x.value?.value && !x.parent && x.active)
          .map(x => x.value?.label)
      );
    });
    dispatch(fetchAmsLookup('reviewFiscalYear')).then(data => {
      setFiscalYears(
        data.lookups
          .filter(x => x.value?.value && !x.parent && x.active)
          .map(x => x.value?.label)
      );
    });
    dispatch(fetchAmsLookup('easEvidenceSource')).then(data => {
      setEvidenceSources(
        data.lookups
          .filter(x => x.value?.value && !x.parent && x.active)
          .map(x => x.value?.label)
      );
    });
    dispatch(fetchAmsLookup('easProgramOption')).then(data => {
      setProgramOptionObjects(
        data.lookups.filter(x => x.value?.value && !x.parent && x.active)
      );
      setProgramOptions(
        data.lookups
          .filter(x => x.value?.value && !x.parent && x.active)
          .map(x => x.value?.label)
      );
    });
    dispatch(fetchCitations()).then(data => {
      setCitations(
        data.citations.citations
          .filter(x => x.standardNumber)
          .map(x => x.standardNumber)
      );
    });
    dispatch(fetchAmsLookup('findingCategory')).then(data => {
      setFindingCategories(
        data.lookups
          .filter(x => x.value?.value && !x.parent && x.active)
          .map(x => x.value?.label)
      );
    });
    dispatch(fetchSurveyLookups({})).then(data => {
      setSurveyLookups(data.surveyLookups);
      mappingLookups(data.surveyLookups);
    });
    dispatch(fetchContentAreaLookups({})).then(data => {
      setContentAreas(data.contentAreaLookups);
    });
    dispatch(fetchPerformanceAreaLookups({})).then(data => {
      setPerformanceAreas(data.performanceAreaLookups);
    });
    dispatch(fetchPerformanceMeasureLookups({})).then(data => {
      setPerformanceMeasures(data.performanceMeasureLookups);
    });
  }, []);

  const mappingLookups = surveyLookups => {
    surveyLookups.map((lookup, index) => {
      const contentAreaMap =
        lookup.reviewType + '-' + lookup.fiscalYear + '-' + lookup.contentArea;
      const performanceAreaMap = contentAreaMap + '-' + lookup.performanceArea;
      const performanceMeasureMap =
        performanceAreaMap + '-' + lookup.performanceMeasure;
      mapContentAreas.push(contentAreaMap);
      mapPerformanceAreas.push(performanceAreaMap);
      mapPerformanceMeasures.push(performanceMeasureMap);
    });
  };

  const prepareObj = results => {
    const selectedResults = _.cloneDeep(results);
    Object.keys(selectedResults).forEach(e => {
      const filter = selectedResults[e];
      if (
        (!!filter === false && !_.isArray(results)) ||
        (_.isArray(results) && filter.length === 0)
      )
        delete selectedResults[e];
    });
    return selectedResults;
  };

  // Data
  const getQuestions = () => {
    const { page, limit, sortName, sortOrder } = paginationOptions;
    setLoading(true);
    let selectedFilters = prepareObj(filters);
    selectedFilters['isActive'] = true;
    selectedFilters.attributes = prepareObj(attributes);

    let requestInput = {
      filters: selectedFilters,
      page,
      limit,
      sortName,
      sortOrder,
    };

    dispatch(fetchQuestionSchemaJSONs(requestInput))
      .then(data => {
        setLoading(false);
        setData(data);
      })
      .catch(error => {
        setLoading(false);
        setResponseErrors([...responseErrors, error.response]);
      });
  };

  const deleteQuestionClicked = id => {
    dispatch(deleteQuestion(id))
      .then(data => {
        getQuestions();
      })
      .catch(error => {
        setResponseErrors([...responseErrors, error.response.data]);
      });
  };

  // File
  const exportQuestions = (template, fiscalYear) => {
    setLoading(true);
    const { sortName, sortOrder } = paginationOptions;
    let selectedFilters = prepareObj(filters);
    selectedFilters.attributes = prepareObj(attributes);
    selectedFilters.fiscalYear = [Number(fiscalYear)];
    selectedFilters.isActive = true;
    selectedFilters.attributes.template = template;
    let requestInput = {
      filters: selectedFilters,
      page: 1,
      limit: 10000,
      sortName,
      sortOrder,
    };

    dispatch(fetchQuestionSchemaJSONs(requestInput))
      .then(results => {
        var data = results.data.filter(d => d.attributesObj);
        // Map data to Excel Sheet
        let rows = exportColumns(template, data, programOptionObjects);

        let worksheet = XLSX.utils.json_to_sheet(rows);
        let new_workbook = XLSX.utils.book_new();
        let filename = `${template}-${fiscalYear}-survey-questions.xlsx`;

        XLSX.utils.book_append_sheet(
          new_workbook,
          worksheet,
          'SurveyQuestions'
        );

        var wopts = { bookType: 'xlsx', bookSST: false, type: 'array' };
        var wbout = XLSX.write(new_workbook, wopts);

        let file = new Blob([wbout], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        fileDownload(
          file,
          `${filename}`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setResponseErrors([...responseErrors, error.response]);
      });
  };

  const importQuestions = file => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = e => {
        const bufferAray = e.target.result;
        const wb = XLSX.read(bufferAray, { type: 'buffer' });
        const data = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        resolve(data);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });

    promise.then(items => {
      items.map((item, index) => {
        // Calculate Data
        item.Delete = item.Action == 'Delete';
        item.IsNew = _.isEmpty(item.Id);
        item.Modify = item.Action == 'Modify';
        item.PerformanceArea =
          item.PerformanceArea && item.PerformanceArea.length > 0
            ? _.trim(item.PerformanceArea.replace(/&amp;/g, '&'))
            : _.trim(item.PerformanceArea);

        item.Template = template.value;
        item.FiscalYear = Number(template.fiscalYear);

        let check = validateQuestion(
          item,
          reviewTypes,
          mapContentAreas,
          mapPerformanceAreas,
          mapPerformanceMeasures,
          evidenceSources,
          programOptions,
          citations,
          findingCategories,
          fiscalYears
        );

        if (
          _.findIndex(items, { Id: item.Id }) !==
          _.findLastIndex(items, { Id: item.Id })
        ) {
          check = 'Duplicate id found';
        }
        if (item.Modify) {
          var matchingQuestion = existingQuestions.filter(
            x => x._id == item.Id
          );
          if (matchingQuestion && matchingQuestion[0]) {
            if (!matchingQuestion[0].isActive) {
              check = 'Question is not active.';
            }
            item.Version = matchingQuestion[0].questionVersion;
            if (item.FiscalYear != matchingQuestion[0].fiscalYear) {
              check =
                'Question fiscal year do not match. Fiscal year is not editable';
            }
          }
        }
        item.Row = index + 2;
        item.CheckStatus = check;
        item.IsValid = check == 'Valid';
        item.UploadStatus = item.IsValid ? 'Not Started' : 'Not Valid';

        if (item.IsValid && !item.Delete && (item.IsNew || item.Modify)) {
          try {
            let templateFileName = QuestionTemplates.filter(
              x => x.label == item.Template || x.value == item.Template
            )[0];
            // get a copy of the template.
            let schema = JSON.parse(
              JSON.stringify(
                require(`./Templates/${template?.fiscalYear}/${templateFileName.value}.json`)
              )
            );
            let uiSchema = JSON.parse(
              JSON.stringify(
                require(`./Templates/${template?.fiscalYear}/${templateFileName.value}_ui.json`)
              )
            );

            // change key to a random key
            var newkey = uuidv4();
            var oldkey = Object.keys(schema.properties)[0];

            // set new values for schema
            schema.properties[newkey] = schema.properties[oldkey];
            delete schema.properties[oldkey];

            // set new values for ui schema
            uiSchema[newkey] = uiSchema[oldkey];
            delete uiSchema[oldkey];
            uiSchema['ui:order'] = [newkey];

            populateTemplate(
              schema,
              uiSchema,
              newkey,
              item,
              programOptionObjects
            );

            item.Schema = schema;
            item.uiSchema = uiSchema;
          } catch (ex) {
            item.IsValid = false;
            item.CheckStatus = 'Unable to load schema and ui schema ' + ex;
          }
        }
      });
      setQuestionsToImport(items);
    });
  };

  const processQuestionsToImport = () => {
    setLoading(true);
    setShowImportModal(false);
    setTemplate(null);
    let promises = questionsToImport
      .filter(item => item.IsValid)
      .map(item => {
        if (
          TemplatesRequireCitations.includes(trim(template.value)) &&
          Number(template.fiscalYear) > 2024 &&
          !item.Delete
        ) {
          modifySchema(item.Schema);
        }
        if (item.Delete) {
          if (!item.IsNew) {
            return dispatch(deleteQuestion(item.Id))
              .then(e => {
                item.UploadStatus = 'Successfull';
                return item;
              })
              .catch(e => {
                item.UploadStatus = e;
                return item;
              });
          } else {
            item.UploadStatus = 'Ignored';
            return item;
          }
        } else if (item.IsNew) {
          let q = buildQuestionDataUsingSchema(
            item.Id,
            item.Action,
            JSON.stringify(item.Schema),
            JSON.stringify(item.uiSchema),
            0,
            item.Template,
            'file'
          );
          return dispatch(addQuestion(q))
            .then(e => {
              item.UploadStatus = 'Successfull';
              return item;
            })
            .catch(e => {
              item.UploadStatus = e;
              return item;
            });
        } else {
          if (item.Modify) {
            let q = buildQuestionDataUsingSchema(
              item.Id,
              item.Action,
              JSON.stringify(item.Schema),
              JSON.stringify(item.uiSchema),
              item.Version + 1,
              item.Template,
              'file'
            );
            return dispatch(updateQuestion(q))
              .then(e => {
                item.UploadStatus = 'Successfull';
                return item;
              })
              .catch(e => {
                item.UploadStatus = e;
                return item;
              });
          } else {
            item.UploadStatus = 'Ignored';
            return item;
          }
        }
      });
    Promise.allSettled(promises).then(array => {
      setQuestionsToImport(array.map(x => x.value));
      getQuestions();
      setLoading(false);
      setShowAfterImportModal(true);
    });
  };

  // Retrieve data whenever pagination options are changed
  useEffect(() => {
    getQuestions();
  }, [paginationOptions]);

  useEffect(() => {
    const attributesKeys = Object.keys(attributes);
    if (
      attributesKeys.includes('contentArea') &&
      attributesKeys.includes('performanceArea') &&
      attributes.contentArea != null &&
      attributes.performanceArea != null &&
      attributes.performanceMeasure == null
    ) {
      dispatch(fetchPerformanceMeasureLookups(prepareLookupFilter())).then(
        data => {
          setPerformanceMeasures(data.performanceMeasureLookups);
        }
      );
    } else if (
      attributesKeys.includes('contentArea') &&
      attributes.contentArea != null &&
      attributes.performanceArea == null &&
      attributes.performanceMeasure == null
    ) {
      dispatch(fetchPerformanceAreaLookups(prepareLookupFilter())).then(
        data => {
          setPerformanceAreas(data.performanceAreaLookups);
        }
      );
      dispatch(fetchPerformanceMeasureLookups(prepareLookupFilter())).then(
        data => {
          setPerformanceMeasures(data.performanceMeasureLookups);
        }
      );
    } else if (filters.reviewType != null || filters.fiscalYear != null) {
      fetchAllLookups(prepareLookupFilter());
    } else {
      fetchAllLookups(prepareLookupFilter());
    }
  }, [filters, attributes]);

  // Filters
  const filtersOnSubmit = e => {
    e.preventDefault();
    getQuestions();
  };

  const fetchAllLookups = () => {
    dispatch(fetchContentAreaLookups(prepareLookupFilter())).then(data => {
      setContentAreas(data.contentAreaLookups);
    });
    dispatch(fetchPerformanceAreaLookups(prepareLookupFilter())).then(data => {
      setPerformanceAreas(data.performanceAreaLookups);
    });
    dispatch(fetchPerformanceMeasureLookups(prepareLookupFilter())).then(
      data => {
        setPerformanceMeasures(data.performanceMeasureLookups);
      }
    );
  };

  const handleFiltesrValueChange = (e, { name, value }) => {
    setFilters({ ...filters, [name]: value });
    if (name == 'fiscalYear') {
      setFilters({ reviewType: null, fiscalYear: value });
      if (value.length == 0) {
        setFilters({ reviewType: null, fiscalYear: null });
      }
      setAttributes({
        contentArea: null,
        performanceArea: null,
        evidenceSource: null,
        performanceMeasure: null,
      });
    }
    if (name == 'reviewType') {
      setAttributes({
        contentArea: null,
        performanceArea: null,
        evidenceSource: null,
        performanceMeasure: null,
      });
    }
  };

  const prepareLookupFilter = () => {
    return {
      fiscalYear: filters.fiscalYear,
      reviewType: filters.reviewType,
      contentArea: attributes.contentArea,
      performanceArea: attributes.performanceArea,
    };
  };

  const handleAttributesValueChange = (e, { name, value }) => {
    setAttributes({ ...attributes, [name]: value });
    if (name == 'contentArea') {
      setAttributes({
        contentArea: value,
        performanceArea: null,
        performanceMeasure: null,
      });
    }
    if (name == 'performanceArea') {
      setAttributes({
        contentArea: attributes.contentArea,
        performanceArea: value,
        performanceMeasure: null,
      });
    }
  };

  const handleFilterReset = () => {
    setFilters({ reviewType: null, fiscalYear: null }, () => {});
    setAttributes(
      {
        contentArea: null,
        performanceArea: null,
        evidenceSource: null,
        performanceMeasure: null,
        fiscalYear: null,
      },
      () => {
        getQuestions();
      }
    );
  };

  // Renders

  const renderImportModal = () => {
    var data = existingQuestions;
    if (existingQuestions?.length > 0)
      data = existingQuestions.filter(
        x => x.fiscalYear == template?.fiscalYear
      );

    return (
      <AmsModal
        open={showImportModal}
        size="large"
        closeOnDimmerClick={false}
        onClose={() => {
          setShowImportModal(false);
          setExistingQuestions(null);
          setTemplate(null);
          setQuestionsToImport(null);
        }}
        className="ams-semantic-modal-fix"
        // closeIcon
      >
        <Modal.Header>Import Questions ({template?.label})</Modal.Header>
        <Modal.Content>
          <input
            onChange={e => {
              const file = e.target.files[0];
              importQuestions(file);
            }}
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          <br />
          <p>
            There are <strong>{data?.length}</strong> question(s) in the system
            all created from the {template?.label} - {template?.fiscalYear}{' '}
            template.
          </p>
          {!questionsToImport ? null : (
            <div>
              <br />
              <label
                className="badge badge-success"
                style={{ backgroundColor: '#157e2e' }}
              >
                {questionsToImport?.filter(i => i?.IsNew).length}
              </label>{' '}
              New Questions.
              <br />
              <label
                className="badge badge-info"
                style={{ backgroundColor: '#245e9d' }}
              >
                {questionsToImport?.filter(i => i?.Modify).length}
              </label>{' '}
              Modify Questions
              <br />
              <label
                className="badge badge-danger"
                style={{ backgroundColor: '#db2828' }}
              >
                {questionsToImport?.filter(i => i?.Delete).length}
              </label>{' '}
              Delete Questions
              <br />
              <label
                className="badge badge-default"
                style={{ backgroundColor: '#bbbbbb' }}
              >
                {
                  questionsToImport?.filter(
                    i => !i?.Delete && !i?.Modify && !i?.IsNew
                  ).length
                }
              </label>{' '}
              Ignored Questions
            </div>
          )}
          {questionsToImport != null &&
          questionsToImport.filter(x => !x.IsValid).length == 0 ? (
            <h3>Press button below to start importing questions.</h3>
          ) : null}
          {!questionsToImport ||
          questionsToImport.filter(x => !x.IsValid).length == 0 ? null : (
            <div>
              <hr />
              <h3>Please fix all invalid rows below:</h3>
              <table className="table table-border">
                <thead>
                  <tr>
                    <th>Row in Excel</th>
                    <th>Column in Excel</th>
                    <th>TQ Number</th>
                    <th>Action</th>
                    <th>Valid</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {questionsToImport
                    .filter(x => !x.IsValid)
                    .map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.Row}</td>
                          <th>{item.Column}</th>
                          <td>{item.TQNumber}</td>
                          <td>
                            {item.Delete
                              ? 'Delete'
                              : item.IsNew
                              ? 'Add'
                              : 'Modify'}
                          </td>
                          <td>
                            {item.IsValid ? (
                              <label
                                className="badge badge-success"
                                style={{ backgroundColor: '#157e2e' }}
                              >
                                Yes
                              </label>
                            ) : (
                              <span>
                                <label
                                  className="badge badge-danger"
                                  style={{ backgroundColor: '#db2828' }}
                                >
                                  No
                                </label>
                              </span>
                            )}
                          </td>
                          <td>{item.CheckStatus}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            disabled={
              questionsToImport == null ||
              questionsToImport?.filter(x => !x.IsValid).length > 0
            }
            style={{ float: 'right' }}
            content="Start Import"
            onClick={() => {
              processQuestionsToImport();
            }}
          />
          <Button
            basic
            content="Close"
            onClick={() => {
              setQuestionsToImport(null);
              setExistingQuestions(null);
              setShowImportModal(false);
              setTemplate(null);
            }}
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const renderAfterImportModal = () => (
    <AmsModal
      open={showAfterImportModal}
      size="large"
      closeOnDimmerClick={false}
      onClose={() => {
        setShowAfterImportModal(false);
        setQuestionsToImport(null);
      }}
      className="ams-semantic-modal-fix"
      // closeIcon
    >
      <Modal.Header>Import Questions Results</Modal.Header>
      <Modal.Content>
        {!questionsToImport ? null : (
          <div>
            <br />
            <label
              className="badge badge-success"
              style={{ backgroundColor: '#157e2e' }}
            >
              {
                questionsToImport?.filter(i => i.UploadStatus == 'Successfull')
                  .length
              }
            </label>{' '}
            Question successfully saved.
            <br />
            <label
              className="badge badge-danger"
              style={{ backgroundColor: '#db2828' }}
            >
              {
                questionsToImport?.filter(i => i.UploadStatus != 'Successfull')
                  .length
              }
            </label>{' '}
            Questions failed saving
          </div>
        )}
        {!questionsToImport || questionsToImport.length == 0 ? null : (
          <div>
            <hr />
            <h3>Review Results:</h3>
            <table className="table table-border">
              <thead>
                <tr>
                  <th>Row</th>
                  <th>TQ Number</th>
                  <th>Question</th>
                  <th>Upload Status</th>
                </tr>
              </thead>
              <tbody>
                {questionsToImport.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.Row}</td>
                      <td>{item.TQNumber}</td>
                      <td>{item.Question}</td>
                      <td>
                        {item.UploadStatus == 'Successfull' ? (
                          <label
                            className="badge badge-success"
                            style={{ backgroundColor: '#157e2e' }}
                          >
                            Successful
                          </label>
                        ) : (
                          <span>
                            <label
                              className="badge badge-danger"
                              style={{ backgroundColor: '#db2828' }}
                            >
                              Failed
                            </label>{' '}
                            {item.UploadStatus}
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          content="Close"
          onClick={() => {
            setQuestionsToImport(null);
            setShowAfterImportModal(false);
          }}
        />
      </Modal.Actions>
    </AmsModal>
  );

  const showResponseErrors = () => {
    if (responseErrors && responseErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {responseErrors.map(
              (errorObject, index) =>
                errorObject && (
                  <li key={index}>
                    {errorObject.message} code:(
                    {errorObject.code})
                  </li>
                )
            )}
          </ul>
        </Alert>
      );
  };

  const prepareDropDowns = items => {
    return (
      items &&
      items.map(item => ({
        key: item,
        text: item,
        value: item,
      }))
    );
  };

  const renderSurveyQuestionsFilters = () => {
    const { reviewType, fiscalYear } = filters;
    const {
      contentArea,
      performanceArea,
      performanceMeasure,
      evidenceSource,
    } = attributes;
    return (
      <Segment>
        <Form onSubmit={filtersOnSubmit} noValidate className="filter-form">
          <AmsSkipLinks
            links={[{ title: 'Skip filters and go to results', to: 'results' }]}
          />

          <Form.Group>
            <Form.Field width={3}>
              <AmsFormLabel name="Fiscal Year" fieldLabel="filterFiscalYear">
                <AmsLookupDropdown
                  placeholder="Select Fiscal Year"
                  value={fiscalYear}
                  fluid
                  search
                  selection
                  multiple
                  name="fiscalYear"
                  control={AmsLookupDropdown}
                  category={'reviewFiscalYear'}
                  onChange={handleFiltesrValueChange}
                  aria-labelledby="filterFiscalYear"
                  searchInput={{
                    id: 'filterFiscalYear',
                    title: 'Filter by Fiscal Year',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field width={3}>
              <AmsFormLabel name="Review Type" fieldLabel="filterReviewType">
                <AmsLookupDropdown
                  placeholder="Select Review Type"
                  value={reviewType}
                  fluid
                  search
                  selection
                  name="reviewType"
                  control={AmsLookupDropdown}
                  category={'easReviewTypes'}
                  onChange={handleFiltesrValueChange}
                  aria-labelledby="filterReviewType"
                  searchInput={{
                    id: 'filterReviewType',
                    title: 'Filter by Review Type',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field width={3}>
              <AmsFormLabel name="Content Area" fieldLabel="filterContentArea">
                <Dropdown
                  placeholder="Select Content Area"
                  value={contentArea}
                  fluid
                  search
                  selection
                  name="contentArea"
                  options={prepareDropDowns(contentAreas)}
                  onChange={handleAttributesValueChange}
                  aria-labelledby="filterContentArea"
                  searchInput={{
                    id: 'filterContentArea',
                    title: 'Filter by Content Area',
                  }}
                  openOnFocus={false}
                  selectOnBlur={false}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field width={3}>
              <AmsFormLabel
                name="Performance Area"
                fieldLabel="filterPerformanceArea"
              >
                <Dropdown
                  placeholder="Select Performance Area"
                  value={performanceArea}
                  fluid
                  search
                  selection
                  name="performanceArea"
                  options={prepareDropDowns(performanceAreas)}
                  onChange={handleAttributesValueChange}
                  aria-labelledby="filterPerformanceArea"
                  searchInput={{
                    id: 'filterPerformanceArea',
                    title: 'Filter by Performance Area',
                  }}
                  openOnFocus={false}
                  selectOnBlur={false}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field width={3}>
              <AmsFormLabel
                name="Performance Measure"
                fieldLabel="filterPerformanceMeasure"
              >
                <Dropdown
                  placeholder="Select Performance Measure"
                  value={performanceMeasure}
                  fluid
                  search
                  selection
                  name="performanceMeasure"
                  options={prepareDropDowns(performanceMeasures)}
                  onChange={handleAttributesValueChange}
                  aria-labelledby="filterPerformanceMeasure"
                  searchInput={{
                    id: 'filterPerformanceMeasure',
                    title: 'Filter by Performance Measure',
                  }}
                  openOnFocus={false}
                  selectOnBlur={false}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field width={3}>
              <AmsFormLabel name="Guide" fieldLabel="filterEvidenceSource">
                <SurveyLookupDropdown
                  placeholder="Select Guide"
                  value={evidenceSource}
                  fluid
                  search
                  selection
                  name="evidenceSource"
                  control={SurveyLookupDropdown}
                  category={'easEvidenceSource'}
                  onChange={handleAttributesValueChange}
                  aria-labelledby="filterEvidenceSource"
                  searchInput={{
                    id: 'filterEvidenceSource',
                    title: 'Filter by Guide',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            &nbsp;
            <Form.Button
              width={2}
              className="filter-button"
              primary
              content={'Filter'}
              fluid
            />
            <Form.Button
              width={1}
              className="filter-button"
              content="Reset"
              fluid
              onClick={handleFilterReset}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  };

  const renderSurveyBuilderTable = () => {
    const actionFormatter = (cell, row) => {
      return (
        <Button.Group icon>
          {row.isActive ? (
            <div>
              <Button
                onClick={event => {
                  browserHistory.push(`/admin/surveyquestions/${row._id}/edit`);
                }}
                basic
                aria-label="Edit"
                color="blue"
              >
                <Icon name="pencil" />
              </Button>
              <Button
                basic
                onClick={event => {
                  setQuestionIdToDelete(row._id);
                  setQuestionCodeToDelete(row.questionCode);
                  setShowDeleteConfirmation(true);
                }}
                aria-label="Delete"
                color="red"
              >
                <Icon name="trash" />
              </Button>
            </div>
          ) : (
            <Button
              onClick={event => {
                browserHistory.push(`/admin/surveyquestions/${row._id}/edit`);
              }}
              basic
              aria-label="View"
              color="blue"
            >
              <Icon name="eye" />
            </Button>
          )}
        </Button.Group>
      );
    };

    const evidenceSourceFormatter = (cell, row) => {
      let length = cell.length;
      return (
        <div>
          {cell.map((e, index) => {
            return (
              <div key={index}>
                {e}
                {index + 1 !== length ? ',' : ''}
              </div>
            );
          })}
        </div>
      );
    };

    const programOptionsFormatter = (cell, row) => {
      let length = cell?.length;
      return (
        <div>
          {cell?.map((e, index) => {
            return (
              <div key={index}>
                {e}
                {index + 1 !== length ? ',' : ''}
              </div>
            );
          })}
        </div>
      );
    };

    const descriptionFormatter = (cell, row) => {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(cell),
          }}
        />
      );
    };

    const onSizePerPageChange = (sizePerPage, page) => {
      setPaginationOptions({ ...paginationOptions, page, limit: sizePerPage });
      scrollToResults();
    };

    const onPageChange = (page, sizePerPage) => {
      setPaginationOptions({
        ...paginationOptions,
        page: page,
        limit: sizePerPage,
      });
      scrollToResults();
    };

    const onTableChange = (type, { sortField, sortOrder }) => {
      if (
        sortField === paginationOptions.sortName &&
        sortOrder === paginationOptions.sortOrder
      )
        return null;

      if (type === 'sort') {
        setPaginationOptions({
          ...paginationOptions,
          sortName: sortField || paginationOptions.sortName,
          sortOrder: sortOrder || paginationOptions.sortOrder,
        });
      }
    };

    const columns = [
      {
        dataField: 'fiscalYear',
        text: 'Fiscal Year',
      },
      {
        dataField: 'questionCode',
        text: 'TQ Number',
      },
      {
        dataField: 'reviewType',
        text: 'Review Type',
      },
      {
        dataField: 'shortDescription',
        text: 'Question',
        formatter: descriptionFormatter,
      },
      {
        dataField: 'attributesObj.contentArea',
        text: 'Content Area',
      },
      {
        dataField: 'attributesObj.performanceArea',
        text: 'Performance Area',
      },
      {
        dataField: 'attributesObj.performanceMeasure',
        text: 'Performance Measure',
      },
      {
        dataField: 'attributesObj.programType',
        text: 'Program Type',
      },
      {
        dataField: 'attributesObj.programOptions',
        formatter: programOptionsFormatter,
        text: 'Program Options',
      },
      {
        dataField: 'attributesObj.evidenceSource',
        formatter: evidenceSourceFormatter,
        text: 'Guide',
      },
      {
        dataField: 'questionVersion',
        text: 'Version',
      },
      {
        dataField: 'action',
        text: 'Action',
        formatter: actionFormatter,
        headerStyle: { width: '5%' },
      },
    ];

    const defaultSorted = [
      {
        dataField: paginationOptions.sortName ?? 'questionCode',
        order: paginationOptions.sortOrder ?? 'asc',
      },
    ];

    const importQuestionsDropdownProps = {
      setLoading,
      setTemplate,
      paginationOptions,
      setExistingQuestions,
      setShowImportModal,
      setResponseErrors,
      responseErrors,
    };
    const exportQuestionsDropdownProps = { exportQuestions };

    return (
      <div ref={amsTableRef} tabIndex="-1">
        <div className="all-questions-wrapper">
          <Button
            color="blue"
            size="large"
            style={{ float: 'right' }}
            onClick={() => {
              browserHistory.push('/admin/surveyquestions/view');
            }}
          >
            Preview
          </Button>
          <CreateQuestionButton />
          <ImportQuestionsDropdown {...importQuestionsDropdownProps} />
          <ExportQuestionsDropdown {...exportQuestionsDropdownProps} />
          <DownloadTemplateDropdown />
        </div>
        <div id="all-questions-table">
          <AmsTable
            data={data.data || []}
            defaultSorted={defaultSorted}
            rowClasses={(row, rowIndex) => {
              return 'question-table-row';
            }}
            rowEvents={{}}
            page={paginationOptions.page}
            total={data.total || 0}
            limit={data.limit || 10}
            loading={loading}
            columns={columns}
            keyField="_id"
            onTableChange={onTableChange}
            onPageChange={onPageChange}
            onSizePerPageChange={onSizePerPageChange}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="survey-questions">
      <AmsAlert
        show={showDeleteConfirmation}
        title="Delete Question"
        text={`Are you sure you want delete quesiton ${questionCodeToDelete}?`}
        type={'error'}
        confirmButtonColor={'#db2828'}
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        showConfirm
        showCancelButton
        onCancel={() => {
          setQuestionCodeToDelete(null);
          setQuestionIdToDelete(null);
          setShowDeleteConfirmation(false);
        }}
        onConfirm={() => {
          setLoading(true);
          setShowDeleteConfirmation(false);
          deleteQuestionClicked(questionIdToDelete);
        }}
      />
      {showResponseErrors()}
      {renderImportModal()}
      {renderAfterImportModal()}
      {renderSurveyQuestionsFilters()}
      {renderSurveyBuilderTable()}
    </div>
  );
}

export default SurveyQuestionsAdminPage;
