import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

// Import utils
import enforceRole from '../../../../utils/EnforceRole';

// Import config
import { acl } from '../../../../config';

const ReviewEditButton = ({ selectedReview, currentUser }, { router }) => {
  const reviewStatuses = ['Cancelled', 'Shipped'];
  if (
    selectedReview &&
    selectedReview.reviewStatus &&
    reviewStatuses.includes(selectedReview.reviewStatus)
  )
    return null;

  const id = router.params.reviewId || router.params.id;
  return selectedReview.reviewId &&
    selectedReview.reviewAccessLevel &&
    selectedReview.reviewAccessLevel.isReviewAccessible === true &&
    selectedReview?.reviewStatus !== 'Report Signed' &&
    selectedReview?.reviewStatus !== 'Shipped' &&
    (
      selectedReview?.editReview || 
      (selectedReview?.editGuideAssignments && selectedReview?.reviewType == "Special")
    ) ?
        <Button
          color="green"
          content="Edit Review"
          size="large"
          onClick={() =>
            router.push({
              pathname: `/review/${id}/edit`,
              state: { selectedReview: selectedReview, edit: true },
            })
          }
        /> 
    : null
};

ReviewEditButton.propTypes = {
  selectedReview: PropTypes.object.isRequired,
};

ReviewEditButton.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  selectedReview: state.review.selectedReview,
});

export default connect(mapStateToProps)(ReviewEditButton);