import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  Button,
  Card,
  Dimmer,
  Dropdown,
  Form,
  Header,
  Icon,
  Loader,
  Modal,
  TextArea,
} from 'semantic-ui-react';

import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import { findingCardClasses } from './FindingsUtils';

//import actions
import { isEmpty } from 'lodash';
import {
  fetchCapByFindingStatus,
  findingStatusUpdate,
} from '../../../actions/reviewActions';
import AmsAlert from '../../../utils/AmsAlert';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsModal from '../../../utils/AmsModal';
import { isAllUnchecked } from './FindingsUtils';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';

const FindingsStatusCard = props => {
  const { citation } = props;
  const dispatch = useDispatch();
  const { selectedReview } = useSelector(state => state.review);
  const currentUser = useSelector(state => state.auth.user);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [changeReason, setChangeReason] = useState('');
  const [changeReasonCategory, setChangeReasonCategory] = useState('');
  const [changeReasonDescription, setChangeReasonDescription] = useState('');
  const [showHistory, setShowHistory] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showCapConfirmation, setShowCapConfirmation] = useState(false);
  const [correctiveActionTimeFrame, setCorrectiveActionTimeFrame] = useState(
    ''
  );
  const [showChangeReasonError, setShowChangeReasonError] = useState(false);
  const boldLabelStyle = { fontFamily: 'PublicSans-Bold' };

  const isFUDForFindingStatusInValid =
    'Follow-up' === selectedReview.reviewType &&
    ['Abandoned', 'Uncorrected Deficiency', 'Corrected', 'Withdrawn'].includes(
      citation?.followUpDetermination
    );

  const isAllParentUnchecked =
    isAllUnchecked(selectedReview, citation) &&
    'New Findings' !== citation.collectDataSubType;

  const isAllNewUnchecked =
    isAllUnchecked(selectedReview, citation) &&
    'New Findings' === citation.collectDataSubType;

  useEffect(() => {
    if (citation?.findingStatus !== null)
      setSelectedStatus(citation?.findingStatus);

    if (citation?.correctiveActionTimeFrame !== null) {
      setCorrectiveActionTimeFrame(citation?.correctiveActionTimeFrame);
    }
  }, [citation]);

  const handleChangeReasonCategory = (event, data) => {
    event.preventDefault();
    setChangeReasonCategory(data.value);
  };

  const updateStatus = () => {
    setLoading(true);
    let request = {
      findingId: citation.findingId,
      reason: changeReason,
      newCAP: correctiveActionTimeFrame,
      addNew: true,
      who: currentUser.oid,
      newStatus: selectedStatus,
    };
    if (selectedReview.fiscalYear > 2024) {
      request = {
        ...request,
        reason: changeReasonDescription,
        reasonCategory: changeReasonCategory,
      };
    }
    dispatch(
      findingStatusUpdate(
        request,
        { reviewId: selectedReview.reviewId },
        { reviewType: selectedReview.reviewType },
        { isNewForms: selectedReview.isNewForms }
      )
    )
      .then(data => {
        setLoading(false);
        setChangeReason('');
        setCorrectiveActionTimeFrame(data.correctiveActionTimeFrame);
      })
      .catch(e => {
        setLoading(false);
        setChangeReason('');
      });
  };

  const handleDropdownChange = (event, data) => {
    event.preventDefault();
    setSelectedStatus(data.value);

    dispatch(
      fetchCapByFindingStatus({
        findingStatus: data.value,
      })
    )
      .then(({ data }) => {
        setLoading(false);
        setCorrectiveActionTimeFrame(data.correctiveActionTimeFrame);
      })
      .catch(e => {
        setLoading(false);
        setCorrectiveActionTimeFrame('');
      });
  };

  const handleTextAreaChange = event => {
    if (selectedReview.fiscalYear > 2024) {
      setChangeReasonDescription(event.target.value);
    } else {
      setChangeReason(event.target.value);
    }
  };

  if (
    !selectedReview ||
    !selectedReview.grantees ||
    selectedReview.grantees.length === 0
  ) {
    return null;
  }

  const renderHistory = () => {
    return (
      <ul className="eas-ul">
        {citation.findingStatusHistory
          .sort((a, b) =>
            AmsDateFormatters.getMoment(b.updatedTime).diff(
              AmsDateFormatters.getMoment(a.updatedTime)
            )
          )
          .map((e, index) => {
            return e.statusFrom !== e.statusTo ? (
              <>
                <li key={index} className="history-item eas-li">
                  <span class="line"></span>
                  <span class="number"></span>
                  <div class="blob">
                    Status changed from{' '}
                    <span className="bold">{e.statusFrom}</span> to{' '}
                    <span className="bold">{e.statusTo}</span> {e.source} by{' '}
                    <span className="bold">{e.who}</span> on{' '}
                    <span className="bold">
                      {AmsDateFormatters.getMoment(e.updatedTime).format(
                        'M/D/YYYY, h:mm A'
                      )}
                    </span>
                    {e.correctiveActionTimeFrame ? (
                      <>
                        <br></br>
                        <span className="bold">
                          Corrective Action Timeframe:{' '}
                        </span>
                        {e.correctiveActionTimeFrame}
                      </>
                    ) : (
                      ''
                    )}
                    {selectedReview.fiscalYear < 2025 && e.reason.length > 1 ? (
                      <>
                        <br></br>
                        <span className="bold">Reason: </span>
                        {e.reason}
                      </>
                    ) : (
                      ''
                    )}
                    {selectedReview.fiscalYear > 2024 &&
                    e.reasonCategory &&
                    e.reasonCategory.length > 1 ? (
                      <>
                        <br></br>
                        <span className="bold">Change Reason Category: </span>
                        {e.reasonCategory}
                      </>
                    ) : (
                      ''
                    )}
                    {selectedReview.fiscalYear > 2024 && e.reason.length > 1 ? (
                      <>
                        <br></br>
                        <span className="bold">
                          Change Reason Description:{' '}
                        </span>
                        {e.reason}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </li>
                <br />
              </>
            ) : (
              <li key={index} className="history-item eas-li">
                <span class="line"></span>
                <span class="number"></span>
                <div class="blob">
                  Corrective Action Timeframe changed to{' '}
                  <span className="bold"> {e.correctiveActionTimeFrame}</span>{' '}
                  by <span className="bold">{e.who}</span> on{' '}
                  <span className="bold">
                    {AmsDateFormatters.getMoment(e.updatedTime).format(
                      'M/D/YYYY, h:mm A'
                    )}
                  </span>
                  {selectedReview.fiscalYear < 2025 && e.reason.length > 1 ? (
                    <>
                      <br></br>
                      <span className="bold">Reason: </span>
                      {e.reason}
                    </>
                  ) : (
                    ''
                  )}
                  {selectedReview.fiscalYear > 2024 &&
                  e.reasonCategory &&
                  e.reasonCategory.length > 1 ? (
                    <>
                      <br></br>
                      <span className="bold">Change Reason Category: </span>
                      {e.reasonCategory}
                    </>
                  ) : (
                    ''
                  )}
                  {selectedReview.fiscalYear > 2024 && e.reason.length > 1 ? (
                    <>
                      <br></br>
                      <span className="bold">Change Reason Description: </span>
                      {e.reason}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </li>
            );
          })}
      </ul>
    );
  };

  const showConfirmationAlert = () => {
    return (
      <AmsAlert
        show={showAlert}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={`Are you sure?`}
        onConfirm={() => {
          setShowAlert(false);
          updateStatus();
          setChangeReasonDescription('');
          setChangeReasonCategory('');
          props.handleSaveButtonAction('STATUS_DROPPED');
        }}
        onCancel={() => {
          setShowAlert(false);
        }}
      />
    );
  };

  const showValidationMessage = () => {
    return (
      <AmsAlert
        show={showChangeReasonError}
        title="Something went wrong!"
        text={`Change Reason is a required field`}
        type={'error'}
        showConfirm
        onConfirm={() => {
          setShowChangeReasonError(false);
        }}
      />
    );
  };

  const capDaysFormatter = () => {
    const allowedCapDays = ['10', '30', '45', '60', '90', '120', '180'];
    let options = allowedCapDays.map(value => ({
      value: `${value} days`,
      text: `${value} days`,
      key: `${value} days`,
    }));

    if (correctiveActionTimeFrame === 'TBD') return correctiveActionTimeFrame;

    if (citation?.findingStatus === 'Abandoned') {
      return 'N/A';
    }
    if (correctiveActionTimeFrame === 'N/A') {
      return 'N/A';
    }
    return (
      <AmsFormLabel
        table={true}
        hidden
        fieldLabel={`capDaySelection`}
        name={`Select FUD for ${citation?.citation}`}
      >
        <Dropdown
          disabled={selectedReview.readOnly}
          placeholder={
            correctiveActionTimeFrame
              ? correctiveActionTimeFrame
              : 'Select CAP Days'
          }
          fluid
          selection
          search
          searchInput={{ id: `capDaySelection` }}
          aria-labelledby={`capDaySelection`}
          options={options}
          value={correctiveActionTimeFrame || ''}
          onChange={(e, { name, value }) => {
            //setPreviousCapDays(correctiveActionTimeFrame);
            //setNewCapDays(value);
            setShowCapConfirmation(true);
            setCorrectiveActionTimeFrame(value);
          }}
          openOnFocus={false}
        />
      </AmsFormLabel>
    );
  };

  const showCapConfirmationAlert = () => {
    const inlineStyle = {
      modal: {
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
      },
    };
    return (
      <AmsModal
        centered
        size="tiny"
        style={inlineStyle.modal}
        open={showCapConfirmation}
      >
        <Header
          icon="warning circle"
          content="Update Timeframe for Correction"
        />
        <Modal.Content>
          <p>
            Are you sure you want to update the timeframe for Correction for
            selected finding?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => {
              setShowCapConfirmation(false);
            }}
          >
            No
          </Button>
          <Button
            color="green"
            onClick={() => {
              setShowCapConfirmation(false);
            }}
          >
            Yes
          </Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  const findingStatusField = () => {
    const validFindingStatuses = ['ANC', 'AOC', 'DEF', 'DROPPED'];
    let options = validFindingStatuses.map(v => {
      return { value: v, text: v };
    });
    if (['Withdrawn', 'Abandoned'].includes(citation?.findingStatus)) {
      return citation?.findingStatus;
    }
    //if (isAllParentUnchecked) return 'Withdrawn';
    //if (isAllNewUnchecked) return 'DROPPED';
    return (
      <AmsFormLabel table={true} hidden fieldLabel={`capDaySelection`}>
        <Dropdown
          disabled={
            selectedReview.readOnly ||
            ('Follow-up' === selectedReview.reviewType &&
              'Elevated to Deficiency' === citation?.followUpDetermination)
          }
          placeholder="Select Status"
          fluid
          selection
          search
          options={options}
          onChange={handleDropdownChange}
          value={selectedStatus}
          openOnFocus={false}
        />
      </AmsFormLabel>
    );
  };

  const determineFindingStatus = () => {
    const isDataCollectedAndTqYes =
      !isEmpty(citation?.collectedData) &&
      citation.collectedData.every(c => c?.tqAnswer === 'yes');

    const isDataDropped =
      'DROPPED' === citation?.findingStatus &&
      ((!isEmpty(citation?.collectedData) &&
        citation.collectedData.every(arr =>
          arr.collectedOptions.every(item => !item.checked)
        )) ||
        (citation?.findingStatusHistory?.length > 0 &&
          [
            'on Data Collection Guide',
            'User dropped the finding from the data collection page',
          ].includes(
            citation.findingStatusHistory[0]?.source ||
              citation.findingStatusHistory[0]?.reason
          )));

    const isReviewStatusSignOrShipped = ['signed', 'Shipped'].includes(
      selectedReview?.reviewStatus
    );

    const isReportStageFinalized = ['finalized'].includes(
      selectedReview?.reportStage
    );

    const isFindingStatusAbandoned = 'Abandoned' === citation?.findingStatus;

    const isFindingDisabled = citation?.isActive === false;

    if (
      (isDataCollectedAndTqYes &&
        isEmpty(citation?.classroomExploration) &&
        isEmpty(citation?.centerExploration)) ||
      isReviewStatusSignOrShipped ||
      isReportStageFinalized ||
      isFindingStatusAbandoned ||
      isDataDropped ||
      isFUDForFindingStatusInValid ||
      isAllParentUnchecked ||
      isAllNewUnchecked ||
      isFindingDisabled
    ) {
      return 'DROPPED';
    }
    return '';
  };

  return (
    <Card
      className={findingCardClasses(
        'findings-card',
        determineFindingStatus(selectedReview, citation)
      )}
    >
      {showCapConfirmationAlert()}
      <Dimmer active={loading} inverted>
        <Loader active={loading} inverted>
          Loading
        </Loader>
      </Dimmer>
      {showConfirmationAlert()}
      {showValidationMessage()}
      <Card.Content
        style={{ backgroundColor: '#E9E9E9', fontSize: '18px' }}
        header="Finding Outcome"
      />
      <Form style={{ margin: '10px' }}>
        <Form.Field>
          <label className="visually-hidden">Select Status</label>
          {findingStatusField()}
        </Form.Field>
        <label style={boldLabelStyle}>Timeframe for Correction:</label>
        <Form.Field>
          {/* {capDaysFormatter()} */}
          <span>
            {' '}
            {selectedStatus &&
            (selectedStatus === 'OCI' || selectedStatus === 'AOC')
              ? 'Follow up with Regional Office for support'
              : ['DROPPED', 'Withdrawn'].includes(selectedStatus)
              ? // &&
                // selectedStatus === 'Not Applicable'
                'N/A'
              : capDaysFormatter()}
          </span>
        </Form.Field>
        {selectedReview.fiscalYear > 2024 && (
          <>
            <label style={boldLabelStyle}>Change Reason:</label>
            <Form.Field>
              <AmsLookupDropdown
                selection
                placeholder="Select Change Reason Category"
                fluid
                onChange={handleChangeReasonCategory}
                value={changeReasonCategory}
                category={'changeReasonCategory'}
              />
            </Form.Field>
          </>
        )}
        {!isFUDForFindingStatusInValid && (
          <label style={boldLabelStyle}>{`Change Reason ${
            selectedReview.fiscalYear > 2024 ? 'Description' : ''
          }:`}</label>
        )}
        {!isFUDForFindingStatusInValid && (
          <Form.Field
            control={TextArea}
            name={`changeReason${
              selectedReview.fiscalYear > 2024 ? 'Description' : ''
            }:`}
            rows={5}
            disabled={selectedReview.readOnly}
            onChange={handleTextAreaChange}
            value={
              selectedReview.fiscalYear > 2024
                ? changeReasonDescription
                : changeReason
            }
          />
        )}
        <Button
          fluid
          disabled={
            (selectedStatus === citation?.findingStatus &&
              correctiveActionTimeFrame ===
                citation?.correctiveActionTimeFrame) ||
            selectedReview.readOnly ||
            (selectedReview.fiscalYear > 2024 &&
              (isEmpty(changeReasonCategory) ||
                isEmpty(changeReasonDescription)))
          }
          style={{
            backgroundColor: '#245E9D',
            color: 'white',
            marginTop: '10px',
          }}
          onClick={() => {
            if (selectedReview.fiscalYear > 2024) {
              if (
                !isEmpty(changeReasonCategory) &&
                !isEmpty(changeReasonDescription)
              ) {
                setShowAlert(true);
              } else {
                setShowChangeReasonError(true);
              }
            } else {
              if (!isEmpty(changeReason)) {
                setShowAlert(true);
              } else {
                setShowChangeReasonError(true);
              }
            }
          }}
        >
          Save Outcome
        </Button>
      </Form>
      {!isFUDForFindingStatusInValid &&
        citation?.findingStatusHistory &&
        citation.findingStatusHistory.length > 0 && (
          <div
            style={{
              margin: '10px',
              textAlign: 'center',
              opacity: '0 !important',
            }}
          >
            <Accordion className={'enabled-section'}>
              <Accordion.Title
                active={showHistory}
                index={0}
                onClick={() => {
                  setShowHistory(!showHistory);
                }}
              >
                {!showHistory ? 'See History' : 'Hide History'}
                <Icon name="dropdown" />
              </Accordion.Title>
              <Accordion.Content active={showHistory}>
                <div style={{ textAlign: 'left' }}>{renderHistory()}</div>
              </Accordion.Content>
            </Accordion>
          </div>
        )}
    </Card>
  );
};

export default FindingsStatusCard;
