import _ from 'lodash';
import {
  FA1_FORM_DETAIL_FETCHED,
  FA1_GUIDE_SELECTED,
  FA1_PERFORMANCE_MEASURE_SELECTED,
  FA1_CENTER_SELECTED,
  FA1_CLASSROOM_SELECTED,
  FA1_FORM_SCHEMA_FETCHED,
  FA1_SUBMISSION_FETCHED,
  FA1_UPDATE_GUIDE,
  FORM_SCHEMA_FETCHED,
  SUBMISSION_FETCHED,
  PRESITE_PERFORMANCE_MEASURE_SELECTED,
  PRESITE_FORM_DETAIL_FETCHED,
  PRESITE_FORM_SCHEMA_FETCHED,
  PRESITE_SUBMISSION_FETCHED,
  PRESITE_SUBMISSION_CLEARED,
  PRESITE_UPDATE_GUIDE,
  FA2_GUIDE_SELECTED,
  FA2_PERFORMANCE_MEASURE_SELECTED,
  FA2_FORM_DETAIL_FETCHED,
  FA2_FORM_SCHEMA_FETCHED,
  FA2_SUBMISSION_FETCHED,
  FA2_CENTER_SELECTED,
  FA2_CLASSROOM_SELECTED,
  FA2_UPDATE_GUIDE,
  RAN_FORM_DETAIL_FETCHED,
  RAN_GUIDE_SELECTED,
  RAN_PERFORMANCE_MEASURE_SELECTED,
  RAN_CENTER_SELECTED,
  RAN_CLASSROOM_SELECTED,
  RAN_FORM_SCHEMA_FETCHED,
  RAN_SUBMISSION_FETCHED,
  RAN_UPDATE_GUIDE,
  FA2_CENTERS_CLASSROOMS_UPDATED,
  CENTER_CLASSROOM_FINDINGS_FETCHED,
  FA2_CLEAR_FORMS,
  FA1_CLEAR_FORMS,
  RAN_CLEAR_FORMS,
  CENTER_FETCHED,
  CLASSROOM_FETCHED,
  ENABLE_DISABLED_CENTER,
  ENABLE_DISABLED_CLASSROOM,
  ACTIVE_CENTERS_FETCHED,
  CENTER_ADDED,
  CLASSROOM_ADDED,
  CENTER_UPDATED,
  CLASSROOM_UPDATED,
} from '../redux/types';

const initialState = {
  centers: {},
  activeCenter: {},
  centerEnableDisableResult: false,
  classroomEnableDisableResult: false,
  classroomAddedResult: false,
  centerAddedResult: false,
  classroomEditedResult: false,
  centerEditedResult: false,
  classrooms: {},
  fa1: {
    reviewId: '',
    reviewType: '',
    granteeInfo: [],
    selectedGuide: {},
    guides: [],
    selectedGuidePerformanceMeasures: [],
    selectedPerformanceMeasure: {},
    selectedCenter: '',
    selectedClassroom: '',
    submission: {},
  },
  fa2: {
    reviewId: '',
    reviewType: '',
    granteeInfo: [],
    selectedGuide: {},
    guides: [],
    selectedGuidePerformanceMeasures: [],
    selectedPerformanceMeasure: {},
    selectedCenter: '',
    selectedClassroom: '',
    submission: {},
    centerClassroomFindings: [],
    isReportFinalized: '',
  },
  presite: {
    reviewId: '',
    reviewType: '',
    granteeInfo: [],
    performanceMeasures: [],
    selectedPerformanceMeasure: {},
    submission: {},
  },
  ran: {
    reviewId: '',
    reviewType: '',
    granteeInfo: [],
    selectedGuide: {},
    guides: [],
    selectedGuidePerformanceMeasures: [],
    selectedPerformanceMeasure: {},
    selectedCenter: '',
    selectedClassroom: '',
    submission: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // FA-1
    case FA1_FORM_DETAIL_FETCHED: {
      if (
        (action.fa1 || {}).reviewAccessLevel &&
        (action.fa1 || {}).reviewAccessLevel.isReviewAccessible === false
      ) {
        action.fa1.readOnly = true;
      }

      return {
        fa1: { ...action.fa1, submission: {} },
      };
    }

    case FA1_GUIDE_SELECTED: {
      let guide = '';
      typeof action.guide === 'object'
        ? (guide = action.guide.name)
        : (guide = action.guide);

      const selectedGuide =
        _.find(state.fa1.guides, { name: guide }) || state.fa1.guides[0];

      return {
        ...state,
        fa1: {
          ...state.fa1,
          selectedGuide: selectedGuide,
        },
      };
    }

    case FA1_PERFORMANCE_MEASURE_SELECTED: {
      // If there is a selected PM set it to that.
      let selected = '';

      typeof action.selected === 'object'
        ? (selected = action.selected.name)
        : (selected = action.selected);

      const selectedPerformanceMeasure =
        _.find(state.fa1.selectedGuide.performanceMeasures, {
          name: selected,
        }) || state.fa1.guides[0].performanceMeasures[0];

      return {
        fa1: {
          ...state.fa1,
          selectedPerformanceMeasure,
        },
      };
    }

    case FA1_CENTER_SELECTED: {
      // If there is a selected Center set it to that.
      let selectedCenter = action.center;

      if (!selectedCenter)
        selectedCenter = state.fa1.selectedPerformanceMeasure
          ? state.fa1.selectedPerformanceMeasure.centers[0].centerName
          : state.fa1.performanceMeasures[0].centers[0].centerName;

      return {
        fa1: {
          ...state.fa1,
          submission: {},
          selectedCenter,
        },
      };
    }

    case FA1_CLASSROOM_SELECTED: {
      let selectedClassroom = action.classroom;

      if (!selectedClassroom)
        selectedClassroom = state.fa1.selectedPerformanceMeasure
          ? state.fa1.selectedPerformanceMeasure.centers[0].classrooms[0]
              .classroomName
          : state.fa1.performanceMeasures[0].centers[0].classrooms[0]
              .classroomName;

      return {
        fa1: {
          ...state.fa1,
          submission: {},
          selectedClassroom,
        },
      };
    }

    case FA1_FORM_SCHEMA_FETCHED: {
      return {
        fa1: {
          ...state.fa1,
          selectedPerformanceMeasure: {
            ...state.fa1.selectedPerformanceMeasure,
            ...action.schema,
          },
        },
      };
    }

    case FA1_SUBMISSION_FETCHED: {
      return {
        fa1: {
          ...state.fa1,
          submission: action.submission,
        },
      };
    }

    case FA1_UPDATE_GUIDE: {
      const updatedGuides = state.fa1.guides.map(guide => {
        if (guide.name === action.guide) {
          return {
            ...guide,
            performanceMeasures: guide.performanceMeasures.map(
              performanceMeasure => {
                // Find the correct PM.
                if (performanceMeasure.name === action.performanceMeasure) {
                  // All other submissions
                  if (
                    performanceMeasure.forms &&
                    performanceMeasure.forms.length
                  ) {
                    performanceMeasure.forms[0].amsSubmissionId =
                      action.submissionId;
                  }
                }

                return performanceMeasure;
              }
            ),
          };
        }
        return guide;
      });

      return {
        ...state,
        fa1: {
          ...state.fa1,
          guides: updatedGuides,
        },
      };
    }

    case FA1_CLEAR_FORMS: {
      return {
        ...initialState,
      };
    }

    // FA-2
    case FA2_FORM_DETAIL_FETCHED: {
      if (
        (action.fa2 || {}).reviewAccessLevel &&
        (action.fa2 || {}).reviewAccessLevel.isReviewAccessible === false
      ) {
        action.fa2.readOnly = true;
      }

      return {
        fa2: { ...action.fa2, submission: {} },
      };
    }

    case FA2_GUIDE_SELECTED: {
      let guide = '';
      typeof action.guide === 'object'
        ? (guide = action.guide.name)
        : (guide = action.guide);

      const selectedGuide =
        _.find(state.fa2.guides, { name: guide }) || state.fa2.guides[0];

      return {
        ...state,
        fa2: {
          ...state.fa2,
          submission: {},
          selectedGuide: selectedGuide,
        },
      };
    }

    case FA2_PERFORMANCE_MEASURE_SELECTED: {
      // If there is a selected PM set it to that.
      let selected = '';

      typeof action.selected === 'object'
        ? (selected = action.selected.name)
        : (selected = action.selected);

      const selectedPerformanceMeasure =
        _.find(state.fa2.selectedGuide.performanceMeasures, {
          name: selected,
        }) || state.fa2.guides[0].performanceMeasures[0];

      return {
        fa2: {
          ...state.fa2,
          submission: {},
          selectedPerformanceMeasure,
        },
      };
    }

    case FA2_CENTER_SELECTED: {
      // If there is a selected Center set it to that.
      let selectedCenter = action.center;

      if (!selectedCenter)
        selectedCenter = state.fa2.selectedPerformanceMeasure
          ? state.fa2.selectedPerformanceMeasure.centers[0].centerName
          : state.fa2.performanceMeasures[0].centers[0].centerName;

      return {
        fa2: {
          ...state.fa2,
          submission: {},
          selectedCenter,
        },
      };
    }

    case FA2_CLASSROOM_SELECTED: {
      let selectedClassroom = action.classroom;

      if (!selectedClassroom)
        selectedClassroom = state.fa2.selectedPerformanceMeasure
          ? state.fa2.selectedPerformanceMeasure.centers[0].classrooms[0]
              .classroomName
          : state.fa2.performanceMeasures[0].centers[0].classrooms[0]
              .classroomName;

      return {
        fa2: {
          ...state.fa2,
          submission: {},
          selectedClassroom,
        },
      };
    }

    case FA2_FORM_SCHEMA_FETCHED: {
      return {
        fa2: {
          ...state.fa2,
          selectedPerformanceMeasure: {
            ...state.fa2.selectedPerformanceMeasure,
            ...action.schema,
          },
        },
      };
    }

    case FA2_SUBMISSION_FETCHED: {
      return {
        fa2: {
          ...state.fa2,
          submission: action.submission,
        },
      };
    }

    case FORM_SCHEMA_FETCHED: {
      const formId = action.form.amsFormId;
      return {
        ...state,
        fa2: { ...state.fa2, submission: {} },
        formsList: {
          [formId]: {
            ...state.formsList[formId],
            formData: { data: { surveyData: {} } },
            formDetails: action.form,
            schema: action.schema,
          },
        },
      };
    }

    case SUBMISSION_FETCHED: {
      const formId = action.formId;
      return {
        ...state,
        fa2: { ...state.fa2, submission: action.submission },
        formsList: {
          [formId]: {
            ...state.formsList[formId],
            formData: action.submission,
          },
        },
      };
    }

    // TODO: Update logic to update enabling and disabling centers/classrooms
    case FA2_CENTERS_CLASSROOMS_UPDATED: {
      return {
        ...state,
        fa2: {
          ...state.fa2,
          guides:
            action.guideCode === 'CLS'
              ? _.filter(state.fa2.guides, guide => {
                  if (action.guideCode === guide.guideCode) {
                    _.filter(guide.performanceMeasures, performanceMeasure => {
                      if (performanceMeasure.name === action.performanceName) {
                        performanceMeasure.centers = action.centers;
                      }
                      return performanceMeasure;
                    });
                  }
                  return guide;
                })
              : _.filter(
                  _.filter(state.fa2.guides, guide => {
                    if (action.guideCode === guide.guideCode) {
                      _.filter(
                        guide.performanceMeasures,
                        performanceMeasure => {
                          if (
                            performanceMeasure.name === action.performanceName
                          ) {
                            performanceMeasure.centers = action.centers;
                          }
                          return performanceMeasure;
                        }
                      );
                    }
                    return guide;
                  }),
                  guide => {
                    if (guide.guideCode === 'CLS') {
                      _.filter(
                        guide.performanceMeasures,
                        performanceMeasure => {
                          if (
                            performanceMeasure.name ===
                              'Classroom Exploration (Effective and Intentional Teaching Practices)' ||
                            performanceMeasure.name ===
                              'Teaching Practices in Classroom'
                          ) {
                            performanceMeasure.centers.map(center => {
                              let updatedCenter = action.centers.find(
                                c1 => c1.centerName === center.centerName
                              );
                              center.classrooms.map(classroom => {
                                classroom.enabled = updatedCenter.enabled;
                                return classroom;
                              });
                              center.enabled = updatedCenter.enabled;
                              return center;
                            });
                          }
                          return performanceMeasure;
                        }
                      );
                    }
                    return guide;
                  }
                ),
        },
      };
    }

    case FA2_CLEAR_FORMS: {
      return {
        ...initialState,
      };
    }

    // PRESITE
    case PRESITE_FORM_DETAIL_FETCHED: {
      if (
        (action.presite || {}).reviewAccessLevel &&
        (action.presite || {}).reviewAccessLevel.isReviewAccessible === false
      ) {
        action.presite.readOnly = true;
      }

      return {
        presite: { ...action.presite, submission: {} },
      };
    }

    case PRESITE_PERFORMANCE_MEASURE_SELECTED: {
      if (!_.isObject(action.selected)) {
        const selected = _.find(state.presite.performanceMeasures, {
          name: action.selected,
        });
        action.selected = selected;
      }
      return {
        presite: {
          ...state.presite,
          selectedPerformanceMeasure: action.selected
            ? action.selected
            : state.presite.performanceMeasures[0],
        },
      };
    }

    case PRESITE_FORM_SCHEMA_FETCHED: {
      return {
        presite: {
          ...state.presite,
          selectedPerformanceMeasure: {
            ...state.presite.selectedPerformanceMeasure,
            ...action.schema,
          },
        },
      };
    }

    case PRESITE_SUBMISSION_FETCHED: {
      return {
        presite: {
          ...state.presite,
          submission: action.submission,
        },
      };
    }

    case PRESITE_SUBMISSION_CLEARED: {
      return {
        presite: {
          ...state.presite,
          submission: {},
        },
      };
    }

    case PRESITE_UPDATE_GUIDE: {
      const updatedPerformancMeasures = state.presite.performanceMeasures.map(
        pm => {
          if (pm.name === action.performanceMeasure) {
            return {
              ...pm,
              forms: pm.forms.map(form => {
                form.amsSubmissionId = action.submissionId;
                return form;
              }),
            };
          }
          return pm;
        }
      );
      return {
        presite: {
          ...state.presite,
          performanceMeasures: updatedPerformancMeasures,
        },
      };
    }

    case FA2_UPDATE_GUIDE: {
      const updatedGuides = state.fa2.guides.map(guide => {
        if (guide.name === action.guide) {
          return {
            ...guide,
            performanceMeasures: guide.performanceMeasures.map(
              performanceMeasure => {
                // Find the correct PM.
                if (performanceMeasure.name === action.performanceMeasure) {
                  // Logic for center exploration
                  if (
                    action.centerName &&
                    action.classSampleId === '' &&
                    performanceMeasure.centers &&
                    performanceMeasure.centers.length
                  ) {
                    const centers = performanceMeasure.centers.map(center => {
                      if (center.centerName === action.centerName) {
                        center.hasSubmission = true;
                        center.forms[0].amsSubmissionId = action.submissionId;
                      }

                      return center;
                    });

                    performanceMeasure.centers = centers;
                  }

                  // Logic for classroom exploration
                  if (
                    action.centerName &&
                    action.classSampleId &&
                    performanceMeasure.centers &&
                    performanceMeasure.centers.length
                  ) {
                    const centers = performanceMeasure.centers.map(center => {
                      if (center.centerName === action.centerName) {
                        if (center.classrooms && center.classrooms.length) {
                          const classrooms = center.classrooms.map(
                            classroom => {
                              if (
                                classroom.classSampleId === action.classSampleId
                              ) {
                                classroom.hasSubmission = true;
                                classroom.forms[0].amsSubmissionId =
                                  action.submissionId;
                              }

                              return classroom;
                            }
                          );

                          center.classrooms = classrooms;
                        }
                      }

                      return center;
                    });

                    performanceMeasure.centers = centers;
                  }

                  // All other submissions
                  if (
                    performanceMeasure.forms &&
                    performanceMeasure.forms.length
                  ) {
                    performanceMeasure.forms[0].amsSubmissionId =
                      action.submissionId;
                  }
                }

                return performanceMeasure;
              }
            ),
          };
        }
        return guide;
      });

      return {
        ...state,
        fa2: {
          ...state.fa2,
          guides: updatedGuides,
        },
      };
    }

    // RAN
    case RAN_FORM_DETAIL_FETCHED: {
      if (
        (action.ran || {}).reviewAccessLevel &&
        (action.ran || {}).reviewAccessLevel.isReviewAccessible === false
      ) {
        action.ran.readOnly = true;
      }

      return {
        ran: { ...action.ran, submission: {} },
      };
    }

    case RAN_GUIDE_SELECTED: {
      let guide = '';
      typeof action.guide === 'object'
        ? (guide = action.guide.name)
        : (guide = action.guide);

      const selectedGuide =
        _.find(state.ran.guides, { name: guide }) || state.ran.guides[0];

      return {
        ...state,
        ran: {
          ...state.ran,
          submission: {},
          selectedGuide: selectedGuide,
          //selectedPerformanceMeasure:
          // _.find(selectedGuide.performanceMeasures, { name: guide }) ||
          // selectedGuide.performanceMeasures[0], // TODO: Needs to be Changed if there are more additional Guides for RAN, for now Guide is same as Performance measure,
        },
      };
    }

    case RAN_PERFORMANCE_MEASURE_SELECTED: {
      // If there is a selected PM set it to that.
      let selected = '';

      typeof action.selected === 'object'
        ? (selected = action.selected.name)
        : (selected = action.selected);

      const selectedPerformanceMeasure =
        _.find(state.ran.selectedGuide.performanceMeasures, {
          name: selected,
        }) || state.ran.guides[0].performanceMeasures[0];

      return {
        ran: {
          ...state.ran,
          submission: {},
          selectedPerformanceMeasure: {
            ...state.ran.selectedPerformanceMeasure,
            ...selectedPerformanceMeasure,
          },
        },
      };
    }

    case RAN_CENTER_SELECTED: {
      // If there is a selected Center set it to that.
      let selectedCenter = action.center;

      if (!selectedCenter)
        selectedCenter = state.ran.selectedPerformanceMeasure
          ? state.ran.selectedPerformanceMeasure.centers[0].centerName
          : state.ran.performanceMeasures[0].centers[0].centerName;

      return {
        ran: {
          ...state.ran,
          submission: {},
          selectedCenter,
        },
      };
    }

    case RAN_CLASSROOM_SELECTED: {
      let selectedClassroom = action.classroom;

      if (!selectedClassroom)
        selectedClassroom = state.ran.selectedPerformanceMeasure
          ? state.ran.selectedPerformanceMeasure.centers[0].classrooms[0]
              .classroomName
          : state.ran.performanceMeasures[0].centers[0].classrooms[0]
              .classroomName;

      return {
        ran: {
          ...state.ran,
          submission: {},
          selectedClassroom,
        },
      };
    }

    case RAN_FORM_SCHEMA_FETCHED: {
      return {
        ran: {
          ...state.ran,
          selectedPerformanceMeasure: {
            ...state.ran.selectedPerformanceMeasure,
            ...action.schema,
          },
        },
      };
    }

    case RAN_SUBMISSION_FETCHED: {
      return {
        ran: {
          ...state.ran,
          submission: action.submission,
        },
      };
    }

    case RAN_UPDATE_GUIDE: {
      const updatedGuides = state.ran.guides.map(guide => {
        if (guide.name === action.guide) {
          return {
            ...guide,
            performanceMeasures: guide.performanceMeasures.map(
              performanceMeasure => {
                // Find the correct PM.
                if (performanceMeasure.name === action.performanceMeasure) {
                  // All other submissions
                  if (
                    performanceMeasure.forms &&
                    performanceMeasure.forms.length
                  ) {
                    performanceMeasure.forms[0].amsSubmissionId =
                      action.submissionId;
                  }
                }

                return performanceMeasure;
              }
            ),
          };
        }
        return guide;
      });

      return {
        ...state,
        ran: {
          ...state.ran,
          guides: updatedGuides,
        },
      };
    }

    case RAN_CLEAR_FORMS: {
      return {
        ...initialState,
      };
    }

    case CENTER_CLASSROOM_FINDINGS_FETCHED:
      return {
        ...state,
        fa2: {
          ...state.fa2,
          centerClassroomFindings: action.centerClassroomFindings,
        },
      };
    case ENABLE_DISABLED_CENTER:
      return {
        ...state,
        centerEnableDisableResult:
          action.center.result != null || action.center.result != undefined
            ? !state.centerEnableDisableResult
            : state.centerEnableDisableResult,
      };
    case ENABLE_DISABLED_CLASSROOM:
      return {
        ...state,
        classroomEnableDisableResult:
          action.classroom.result != null ||
          action.classroom.result != undefined
            ? !state.classroomEnableDisableResult
            : state.classroomEnableDisableResult,
      };
    case CENTER_ADDED:
      return {
        ...state,
        centerAddedResult: action.center.result
          ? !state.centerAddedResult
          : state.centerAddedResult,
      };
    case CLASSROOM_ADDED:
      return {
        ...state,
        classroomAddedResult: action.classroom.result
          ? !state.classroomAddedResult
          : state.classroomAddedResult,
      };
    case CENTER_UPDATED:
      return {
        ...state,
        centerEditedResult: action.center.result
          ? !state.centerEditedResult
          : state.centerEditedResult,
      };
    case CLASSROOM_UPDATED:
      return {
        ...state,
        classroomEditedResult: action.classroom.result
          ? !state.classroomEditedResult
          : state.classroomEditedResult,
      };
    case CENTER_FETCHED:
      return { ...state, centers: action.centers };
    case ACTIVE_CENTERS_FETCHED:
      return { ...state, activeCenters: action.centers };
    case CLASSROOM_FETCHED:
      return { ...state, classrooms: action.classrooms };

    default:
      return state;
  }
};
