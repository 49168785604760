import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router';
import { isEmpty } from 'lodash';

const ReviewManageCenterClassroom = ({ selectedReview }) => {
  if (
    !isEmpty(selectedReview.reviewId) &&
    (selectedReview.reviewType === 'FA2-CR' ||
      selectedReview.reviewType === 'Special') &&
    selectedReview.fiscalYear > 2024 &&
    selectedReview.centerAndClassroomManagementPageAccess === true
  ) {
    const fiscalYear =
      selectedReview.fiscalYear &&
      selectedReview.fiscalYear.toString().slice(-2);

    return (
      <Menu.Item
        as={Link}
        to={`/review/fy${fiscalYear}/${selectedReview.reviewId}/manage-center-classroom`}
      >
        Manage Centers/Classrooms
      </Menu.Item>
    );
  }
  return null;
};

ReviewManageCenterClassroom.propTypes = {
  selectedReview: PropTypes.object,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
});

export default connect(mapStateToProps)(ReviewManageCenterClassroom);
