import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';
import { Progress } from 'semantic-ui-react';
import {
  differenceInObjects,
  prepData,
  dataURLtoBlob,
  addNameToDataURL,
  removeNameFromDataURL,
  formatBytes,
} from '../../FormHelpers/helpers/utils.js';
import AmsAlert from '../../../../utils/AmsAlert';

// Import actions
import {
  evidenceFileUpload,
  downloadEvidenceFileAttachment,
} from '../../../../actions/reviewActions';
import { fetchAmsLookup } from '../../../../actions/lookupActions';
import {
  downloadSurveyAttachment,
  fetchSurveyFotpFindings,
} from '../../../../actions/surveyActions';

class AttachmentField extends Component {
  state = {
    downloading: false,
    id: '',
    type: null,
    name: '',
    file: this.props.value,
    showError: false,
    progress: 0,
    recentlyUploaded: false,
    multipleFiles: false,
    totalSize: 0,
    uploadedSize: 0,
    success: false,
    inProgress: false,
    failedUploads: [],
    totalFiles: 0,
    completedFiles: 0,
    showFileTypeDialog: false,
  };

  componentDidMount = () => {
    this.inputRef = React.createRef();
    this.props.fetchAmsLookup('fileTypes');
    if (this.props.formData) {
      let parsedData = this.props.formData && this.props.formData.split('|');
      if (parsedData && parsedData.length >= 4) {
        this.setState({
          name: parsedData[0],
          type: parsedData[1],
          size: parsedData[2],
          id: parsedData[3],
          file: null, // File content is not included in the new format
        });
      } else {
        this.setState({ ...prepData(this.props.formData) });
      }
    }

    this.reader = new FileReader();
    this.reader.addEventListener('load', this.handleFileLoad, true);
  };

  componentWillUnmount() {
    this.reader.removeEventListener('load', this.handleFileLoad, true);
  }

  renderMaxSizeAlert = () => {
    return (
      <AmsAlert
        show={this.state.errorAlert}
        title="Error!"
        text={
          'The total size limit of uploads is 150 MB. Please try again with a selection of files that does not exceed 150 MB in size.'
        }
        type={'error'}
        showConfirm={true}
        confirmButtonText={'OK'}
        onConfirm={() => {
          this.setState({ errorAlert: false });

          setTimeout(() => {
            this.inputRef.current.click(); // Triggering the click event on the input field
          }, 500);
        }}
      />
    );
  };

  renderFileTypeErrorAlert = () => {
    return (
      <AmsAlert
        show={this.state.showFileTypeDialog}
        title="Unsupported File Type"
        text="The selected file(s) are not supported."
        type={'error'}
        showConfirm={true}
        confirmButtonText={'OK'}
        onConfirm={() => {
          this.setState({ showFileTypeDialog: false });
          setTimeout(() => {
            this.inputRef.current.click(); // Triggering the click event on the input field
          }, 500);
        }}
      />
    );
  };

  renderUploadProgressDialog = () => {
    const errorDialog = this.state.showError ? (
      <p
        style={{
          color: '#9F3A38',
          width: '400px',
          margin: '0 auto',
          textAlign: 'left',
        }}
      >
        The following files are not supported:
        <ul>
          {this.state.unsupportedFiles &&
            this.state.unsupportedFiles.map(fileName => <li>{fileName}</li>)}
        </ul>
      </p>
    ) : null;
    const remaining = this.state.totalFiles - this.state.completedFiles;
    return (
      <AmsAlert
        closeIcon={true}
        closerOnDimmerClick={false}
        progress={(this.state.uploadedSize / this.state.totalSize) * 100}
        showProgress={true}
        show={this.state.inProgress}
        title="Alert!"
        text={`Upload is in progress. Please wait until the action is completed to proceed. ${this.state.completedFiles}/${this.state.totalFiles} files completed.  `}
        subtext={'Note: Closing this dialog will cancel remaining uploads.'}
        errorText={errorDialog}
        type={'warning'}
        showConfirm={this.state.showError}
        cancelButtonText={`Cancel Uploads (${remaining} remaining)`}
        showCancelButton={true}
        disableCancelButton={remaining === 0}
        disableConfirmButton={this.state.success === false}
        onCancel={() => {
          window.location.reload();
        }}
        onConfirm={() => {
          this.setState({ inProgress: false, success: true });

          this.props.onAllUploadsComplete();
        }}
      />
    );
  };

  componentWillReceiveProps(nextProps) {
    const parent = _.find(nextProps.fileTypes, { parent: true }); // Find lookup category parent.
    const activeFileTypes = _.filter(nextProps.fileTypes, {
      parent: false,
      active: true,
    }); // Find active lookup items.
    if (parent && parent.active) {
      if (activeFileTypes.length) {
        let allowedFileTypes = activeFileTypes.map(fileType => {
          return `${fileType.value.toLowerCase()}`;
        });
        this.setState({ ...this.state, allowedFileTypes });
      }
    }

    const diff = differenceInObjects(this.props, nextProps);

    if (_.isEmpty(diff)) {
      return;
    }

    if (!_.isEqual(nextProps.formData, this.props.formData)) {
      if (!nextProps.formData && !this.props.formData) {
        this.setState({
          downloading: false,
          id: '',
          name: '',
          file: this.props.value,
        });
      } else {
        let parsedData = nextProps.formData && nextProps.formData.split('|');
        if (parsedData && parsedData.length === 4) {
          this.setState({
            name: parsedData[0],
            type: parsedData[1],
            size: parsedData[2],
            id: parsedData[3],
            file: null, // File content is not included in the new format
          });
        } else {
          this.setState({ ...prepData(nextProps.formData) });
        }
      }
    } else {
      if (this.state.name && !this.state.id && !this.state.file) {
        this.setState({
          ...this.state,
          file: removeNameFromDataURL(nextProps.formData),
        });
      }
    }
  }

  handleFileLoad = () => {
    if (!this.state.showError) {
      this.setState(
        { file: this.reader.result, recentlyUploaded: true },
        () => {
          if (this.props.onChange && this.state.file && this.state.name) {
            const dataUrlWithName = addNameToDataURL(
              this.state.file,
              this.state.name
            );

            if (dataUrlWithName) this.props.onChange(dataUrlWithName);

            let questionId = this.parseQuestionId(this.props.idSchema.$id);
            let questionCode;

            if (questionId) {
              questionCode = questionId;
              questionId = this.props.registry.rootSchema.properties[questionId]
                .questionId;
            } else {
              console.log('no schema found');
            }

            const formData = new FormData();
            formData.append('reviewId', this.props.params.id);
            formData.append('questionCode', questionCode);
            formData.append('questionId', questionId);
            formData.append('file', this.state.blobFile, this.state.name);
            formData.append('dcSessionId', this.props.dcSessionId);
            this.appendAccordionKey(formData, questionCode);
            this.appendIsValidated(formData, questionCode, this.state.name);
            // inside handleFileLoad method

            this.props
              .evidenceFileUpload(formData, event => {
                const progress = Math.round((event.loaded / event.total) * 100);
                this.setState({ progress });
              })
              .then(e => {
                setTimeout(() => {
                  this.props.onAllUploadsComplete();
                }, 1500);
              });
          }
        }
      );
    }
  };

  parseQuestionId = questionId => {
    let match;

    if (this.props.location.search.includes('citation')) {
      match =
        questionId.match(/^root_([a-fA-F0-9-]+_\d+)/) === null
          ? questionId.match(/^root_([0-9a-fA-F-]+)_/)
          : questionId.match(/^root_([a-fA-F0-9-]+_\d+)/);
    } else {
      match =
        this.props.isMultiForm ||
        this.props.formContext.attributes.evidenceSource ==
          'Center Exploration' ||
        this.props.formContext.attributes.evidenceSource ==
          'Classroom Exploration'
          ? questionId.match(/^root_([a-fA-F0-9-]+_\d+)/)
          : questionId.match(/^root_([0-9a-fA-F-]+)_/);
    }

    return match ? match[1] : null;
  };

  appendAccordionKey = (formData, questionCode) => {
    if (!this.props.isMultiForm) return;

    if (!this.props.questionsData) return;

    var accordion = this.props.questionsData.performanceAreas?.filter(area =>
      area.uiSchema.hasOwnProperty(questionCode)
    );
    if (accordion && accordion.length > 0 && accordion[0]) {
      formData.append('accordionKey', accordion[0].accordionTitle);
    }
  };

  appendIsValidated = (formData, questionCode, fileName) => {
    if (!this.props.formContext.validateQuestion) return;
    if (!this.isEvidenceRequired(this.props.registry.rootSchema, questionCode))
      return;

    // append file name to pass validation
    var data = _.cloneDeep(this.props.formContext?.formData);
    if (data && data[questionCode]) {
      if (data[questionCode].evidence) {
        // remove null and undefined names.
        data[questionCode].evidence = data[questionCode].evidence.filter(
          x => x
        );
        data[questionCode].evidence.push(fileName);
      } else {
        data[questionCode]['evidence'] = [fileName];
      }
    }

    var isValid = this.props.formContext.validateQuestion(
      questionCode,
      data,
      this.props.registry.rootSchema
    );
    formData.append('isValidated', isValid);
  };

  isEvidenceRequired = (schema, questionCode) => {
    return (
      schema &&
      schema.properties &&
      schema.properties[questionCode] &&
      schema.properties[questionCode].required &&
      schema.properties[questionCode].required.includes('evidence')
    );
  };

  handleOnChange = async e => {
    // Detect cancel action

    let files = Array.from(e.target.files);
    let totalSize = files.reduce((total, file) => total + file.size, 0);

    if (totalSize > 150 * 1024 * 1024) {
      // 250MB in bytes
      this.setState({
        errorAlert: true,
      });
      return;
    }

    // Check if any of the files have unsupported types.

    let unsupportedFiles = files.filter(
      file => !_.includes(this.state.allowedFileTypes, file.type)
    );

    if (unsupportedFiles.length > 0) {
      this.setState({
        showError: true,
        unsupportedFiles: unsupportedFiles.map(file => file.name),
      });
      files = files.filter(file =>
        _.includes(this.state.allowedFileTypes, file.type)
      ); // Only retain supported files for upload
    }

    totalSize = files.reduce((total, file) => total + file.size, 0);
    if (files.length === 0 && unsupportedFiles.length === 0) {
      this.deleteCurrent();
      return;
    }
    if (files.length === 0) {
      this.inputRef.current.value = '';
      this.setState({ showFileTypeDialog: true, showError: false });
      return;
    }
    this.setState({
      totalSize,
      multipleFiles: true,
      recentlyUploaded: true,
      totalFiles: files.length,
      completedFiles: 0,
    });

    this.setState({ inProgress: true });
    let onChangeName;

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          let questionId = this.parseQuestionId(this.props.idSchema.$id);
          let questionCode;

          if (questionId) {
            questionCode = questionId;
            questionId = this.props.registry.rootSchema.properties[questionId]
              .questionId;
          } else {
            console.log('no schema found');
          }

          const formData = new FormData();
          // Add relevant data to formData (similar to how it's done in handleFileLoad)

          formData.append('reviewId', this.props.params.id);
          formData.append('questionCode', questionCode);
          formData.append('questionId', questionId);
          formData.append('file', file, file.name);
          let dcSessionId = this.props.dcSessionId;

          if (
            this.props.attributes &&
            this.props.attributes.hasOwnProperty('citation') &&
            this.props.attributes.citation
          ) {
            let citation =
              this.props &&
              this.props.answersData &&
              this.props.answersData.surveyData &&
              this.props.answersData.surveyData[questionCode] &&
              this.props.answersData.surveyData[questionCode].CitationPicker;

            if (!citation) {
              citation = this.props.questionsData.filters.citation;
            } else {
              dcSessionId = this.props.addCitationInfo?.dcSessionId;
            }

            if (citation) {
              formData.append('citation', citation);
              this.setState({ citation: citation });
            }
          }

          formData.append('dcSessionId', dcSessionId);

          this.appendAccordionKey(formData, questionCode);
          if (
            this.props.attributes.evidenceSource ==
            'Findings Outside the Protocol'
          ) {
            const keyss = this.props.idSchema.$id.split('_');
            const itemIndex = keyss[3];
            let input = {
              reviewId: this.props.params.id,
              findingIndex: parseInt(itemIndex),
            };
            this.props.fetchSurveyFotpFindings(input);
            const findingObj = this.props.fotpFindings.findings[0];
            if (findingObj) {
              formData.append('findingId', findingObj.findingId);
              formData.append('findingIndex', itemIndex);
              formData.append('citation', findingObj.CitationPicker);
              formData.append('contentArea', findingObj.CAPA?.content_area);
              formData.append(
                'performanceArea',
                findingObj.CAPA?.performance_area
              );
            }
          }
          if (this.props.formContext.attributes.center) {
            formData.append(
              'centerId',
              this.props.formContext.attributes.center.centerId
            );
            formData.append(
              'centerName',
              this.props.formContext.attributes.center.centerName
            );
          }
          if (this.props.formContext.attributes.classroom) {
            formData.append(
              'classSampleId',
              this.props.formContext.attributes.classroom.classSampleId
            );
            formData.append(
              'classRoomName',
              this.props.formContext.attributes.classroom.classRoomName
            );
          }
          this.appendIsValidated(formData, questionCode, file.name);

          let uploadedSizeForThisFile = 0;
          this.props
            .evidenceFileUpload(formData, event => {
              const newBytesUploaded = event.loaded - uploadedSizeForThisFile;
              uploadedSizeForThisFile = event.loaded;

              const fileProgress = event.loaded; // size uploaded from the current file
              console.log(this.state.uploadedSize, this.state.totalSize);
              this.setState(prevState => ({
                uploadedSize: prevState.uploadedSize + newBytesUploaded,
              }));
            })
            .then(response => {
              this.setState(prevState => ({
                completedFiles: prevState.completedFiles + 1,
              }));

              if (index === 0) {
                onChangeName = response;
              }

              resolve(); // resolve the promise once the file is uploaded
            })
            .catch(error => {
              console.error('Error uploading file:', error);
              this.setState(prevState => ({
                failedUploads: [...prevState.failedUploads, file.name],
                showError: true,
                inProgress: false,
              }));
              reject();
            });
        };

        reader.onerror = reject;
      });
    }

    this.setState({ success: true });
    if (!this.state.showError) {
      setTimeout(() => {
        this.setState({ inProgress: false });
      }, 2000);

      setTimeout(async () => {
        this.setState({ success: true });

        if (this.state.citation && this.state.citation.length > 0) {
          await this.props.onAllUploadsComplete({
            citation: this.state.citation,
          });
        } else {
          await this.props.onAllUploadsComplete();
        }

        if (
          this.props.attributes &&
          this.props.attributes.hasOwnProperty('citation') &&
          this.props.attributes.citation
        ) {
          console.log('skip');
        } else {
          this.props.onChange(onChangeName + '|autosave');
        }
      }, 1000);
    }
  };

  deleteCurrent = () => {
    const inputElement = this.inputRef.current;

    if (inputElement) {
      // Find the parent element of the input.
      const parentElement = inputElement.parentElement.parentElement;

      // Find the delete button within the parent element.
      const deleteButton = parentElement.querySelector('.eas-delete-button');

      if (deleteButton) {
        deleteButton.click(); // Triggering the click event on the delete button.
      }
    }
  };

  showError = () => {
    return (
      <>
        {this.state.failedUploads && this.state.failedUploads.length > 0 && (
          <p style={{ color: '#9F3A38' }}>
            The following files failed to upload:
            {this.state.failedUploads &&
              this.state.failedUploads.length > 0 &&
              this.state.failedUploads.map(fileName => <div>{fileName}</div>)}
          </p>
        )}
      </>
    );
  };

  triggerDownload = e => {
    e.preventDefault();
    if (this.state.id && this.state.name) {
      this.setState({ downloading: true });

      if (
        this.props.attributes &&
        this.props.attributes.hasOwnProperty('citation') &&
        this.props.attributes.citation
      ) {
        let questionId = this.parseQuestionId(this.props.idSchema.$id);

        let keys = Object.keys(this.props.answersData.surveyData);
        let isLegacy;
        keys.forEach(e => {
          if (e === questionId) {
            isLegacy = this.props.answersData.surveyData[e].isLegacy;
          }
        });

        if (isLegacy) {
          this.props.downloadSurveyAttachment(this.state.id, this.state.name);
        }
      }

      this.props
        .downloadEvidenceFileAttachment(this.state.id, this.state.name)
        .then(() => this.setState({ downloading: false }))
        .catch(() => this.setState({ downloading: false }));
    }
  };

  createFileDownloadLink = () => {
    if (this.state.downloading) return <div>Downloading...</div>;

    // During upload.
    if (this.state.file || this.state.blobFile) {
      const blob = this.state.file
        ? dataURLtoBlob(this.state.file)
        : this.state.blobFile;
      const url = URL.createObjectURL(blob);

      let fileNameNoExt = '';
      let extension = '';
      let recentlyUploaded = false;
      if (this.state.name) {
        let splitPath = this.state.name.split('.');
        if (splitPath.length > 1) {
          extension = splitPath.pop();
          fileNameNoExt = splitPath.join('.');
        } else {
          fileNameNoExt = this.state.name;
          recentlyUploaded = true;
        }
      }

      return (
        <>
          <a
            style={{
              maxWidth: '230px',
              display: 'flex',
              fontSize: '14px',
              textDecorationLine: 'underline',
              color: '#000000',
              fontWeight: '200px',
            }}
            href={url}
            download={this.state.name}
          >
            <span
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              className="name"
            >
              {fileNameNoExt}
            </span>
            <span className="extension">.{extension}</span>
          </a>
        </>
      );
    }

    // During rendering attached file.
    if (this.state.id && this.state.name) {
      let fileName = this.state.name;

      if (this.state.size) fileName = `${fileName}`;

      let fileNameNoExt = '';
      let extension = '';

      if (fileName) {
        let splitPath = fileName.split('.');
        if (splitPath.length > 1) {
          extension = splitPath.pop();
          fileNameNoExt = splitPath.join('.');
        } else {
          fileNameNoExt = fileName;
        }
      }

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            style={{ backgroundColor: 'rgb(93, 158, 82) !important' }}
            size="small"
            circular
            color="green"
            inverted
            name="check"
          />
          <a
            style={{}}
            href=""
            download={this.state.name}
            onClick={this.triggerDownload}
          >
            <span
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                marginLeft: '5px',
                fontSize: '16px',
                fontWeight: 400,
              }}
              className="name"
            >
              {fileNameNoExt}
            </span>
            <span
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                fontSize: '16px',
                fontWeight: 400,
              }}
              className="extension"
            >
              .{extension} {formatBytes(this.state.size)}
            </span>
          </a>
          {this.state.showError && this.showError()}
        </div>
      );
    }

    return null;
  };

  render() {
    return (
      this.createFileDownloadLink() || (
        <>
          {this.renderUploadProgressDialog()}
          {this.state.showError && this.showError()}
          {this.renderMaxSizeAlert()}
          {this.renderFileTypeErrorAlert()}
          {this.props.fileTypes && (
            <>
              {!this.state.success && (
                <input
                  ref={this.inputRef}
                  onClick={event => {
                    event.stopPropagation();
                  }}
                  name="eas-custom-attach"
                  type="file"
                  multiple
                  title="Add supporting documents"
                  className="custom eas-custom-attach"
                  value={this.props.value}
                  onChange={this.handleOnChange}
                />
              )}
              {this.state.recentlyUploaded && this.state.multipleFiles ? (
                <Progress
                  className="eas-progress-bar"
                  success={this.state.success}
                  percent={
                    (this.state.uploadedSize / this.state.totalSize) * 100
                  }
                />
              ) : null}
              {this.state.success && (
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '16px',
                    color: '#777676',
                    textAlign: 'left',
                  }}
                >
                  {this.state.fileName}
                  Upload Complete
                </span>
              )}
            </>
          )}
        </>
      )
    );
  }
}

const mapStateToProps = state => ({
  fileTypes: state.lookups.amsLookups.fileTypes,
  isMultiForm: state.survey.consolidatedQuestions ? false : true,
  answersData: state.survey.consolidatedAnswers,
  dcSessionId: state.survey.consolidatedQuestions
    ? state.survey.consolidatedQuestions?.dcSessionId
    : state.survey.multiSurveyForm?.dcSessionId,
  addCitationInfo: state.survey.additionalCitationForm,
  questionsData: state.survey.consolidatedQuestions
    ? state.survey.consolidatedQuestions
    : state.survey.multiSurveyForm,
  fotpFindings: state.survey.fotpFindings,
});

export default connect(mapStateToProps, {
  downloadEvidenceFileAttachment,
  downloadSurveyAttachment,
  fetchSurveyFotpFindings,
  fetchAmsLookup,
  evidenceFileUpload,
})(withRouter(AttachmentField));
