import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Alert } from 'react-bootstrap';
import { Segment } from 'semantic-ui-react';

// Import components.
import GranteeItems from './GranteeItems';
import SlidingContainer from '../../utils/layout/SlidingContainer';
import AmsSkipLinks from '../../utils/AmsSkipLinks';
import SummaryFilters from '../Shared/FilterHelpers/SummaryFilters';

// Import actions.
import { fetchGrantees } from '../../actions/granteeActions';

// Import style.
import './assets/style.css';

class GranteePage extends Component {
  state = {
    loading: false,
    rowStart: 0,
    rowEnd: 99,
    orderBy: 'granteeName',
    responseErrors: [],
    selection: false,
    selectedFilters: { status: ['Active'] },
  };

  constructor(props) {
    super(props);
    this.filterSegment = React.createRef();
  }

  componentDidMount() {
    this.setState(
      {
        selectedFilters: {
          ...this.state.selectedFilters,
          status: ['Active'],
        },
      },
      () => {
        this.getData(this.state.selectedFilters);
      }
    );
  }

  handleFilterSubmit = filters => {
    this.triggerFilter(filters);
  };

  scrollToRef = () => {
    window.scrollTo(0, this.filterSegment.current.offsetTop);
    this.filterSegment.current.focus();
  };

  handleFilterReset = () => {
    this.triggerFilter({ status: ['Active'] });
    this.scrollToRef();
  };

  getData = fil => {
    let filterInputs = {};

    if (!isEmpty(fil.region)) {
      filterInputs = {
        ...filterInputs,
        region: fil.region,
      };
    }

    if (!isEmpty(fil.status)) {
      filterInputs = {
        ...filterInputs,
        status: fil.status,
      };
    }

    if (fil.fiscalYear !== '') {
      filterInputs = {
        ...filterInputs,
        fiscalYear: fil.fiscalYear,
      };
    }

    if (!isEmpty(fil.selectedGrantees)) {
      filterInputs = {
        ...filterInputs,
        selectedGrantees: fil.selectedGrantees,
      };
    }

    if (fil.agencyId !== '') {
      filterInputs = { ...filterInputs, agencyId: fil.agencyId };
    }

    if (!isEmpty(fil.outcome)) {
      filterInputs = {
        ...filterInputs,
        outcome: fil.outcome,
      };
    }
    this.setState({ selectedFilters: filterInputs }, () => {
      this.granteeItems.getData(this.state.selectedFilters);
    });
  };

  renderGranteeFilters() {
    const defaultFilters = [
      {
        key: 'region',
        value: true,
      },
      {
        key: 'selectedGrantees',
        value: true,
      },
      {
        key: 'agencyId',
        value: true,
      },
      {
        key: 'status',
        value: true,
      },
    ];
    return (
      <div ref={this.filterSegment} tabIndex="-1">
        <Segment>
          {/* <Well className="page-filters">
        <div className="filter-header">
          <h2>Select filters</h2>
        </div> */}
          <AmsSkipLinks
            links={[{ title: 'Skip filters and go to results', to: 'results' }]}
          />
          <SummaryFilters
            page="Grantee"
            defaultFilters={defaultFilters}
            status={this.state.selectedFilters.status}
            data={{
              handleFilterSubmit: this.handleFilterSubmit,
              handleFilterReset: this.handleFilterReset,
            }}
            {...this.props}
          />
          {/* </Well> */}
        </Segment>
      </div>
    );
  }

  triggerFilter = filters => {
    let selection = this.state.selection;
    this.setState({ selection: !selection }, () => {
      this.getData(filters);
    });
  };

  renderGranteeList() {
    return (
      <GranteeItems
        filters={this.state.selectedFilters}
        selection={this.state.selection}
        onFilter={ref => (this.granteeItems = ref)}
        onError={this.showResponseErrors.bind(this)}
        {...this.props}
      />
    );
  }

  setResponseError(error) {
    this.setState({
      responseErrors: [...this.state.responseErrors, error.response.data],
    });
  }

  showResponseErrors(error) {
    const { responseErrors } = this.state;

    if (responseErrors && responseErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {responseErrors.map((errorObject, index) => (
              <li key={index}>
                {errorObject.message} code:(
                {errorObject.code})
              </li>
            ))}
          </ul>
        </Alert>
      );
  }

  render() {
    return (
      <div className="grantee-page">
        {this.showResponseErrors()}

        <SlidingContainer calendar>
          {this.renderGranteeFilters()}
          <br />
          {this.renderGranteeList()}
        </SlidingContainer>

        {/* Render child routes */}
        {this.props.children}
      </div>
    );
  }
}

GranteePage.propTypes = {
  // grantees: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  fetchGrantees: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    // grantees: state.grantees,
    currentUser: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, { fetchGrantees })(GranteePage);
