import axios from 'axios';

import {
  FA2_PERFORMANCE_MEASURE_SELECTED,
  FA2_FORM_DETAIL_FETCHED,
  FA2_DRAFT_SAVED,
  FA2_SUBMISSION_SAVED,
  FA2_SUBMISSION_UPDATED,
  FA2_SUBMISSION_FETCHED,
  FA2_FORM_SCHEMA_FETCHED,
  FA2_GUIDE_SELECTED,
  FA2_CENTER_SELECTED,
  FA2_CLASSROOM_SELECTED,
  FA2_UPDATE_GUIDE,
  FA2_CENTERS_CLASSROOMS_UPDATED,
  CENTER_FETCHED,
  CLASSROOM_FETCHED,
  ENABLE_DISABLED_CENTER,
  ENABLE_DISABLED_CLASSROOM,
  CENTER_CLASSROOM_FINDINGS_FETCHED,
  FA2_CLEAR_FORMS,
  CENTER_ADDED,
  CLASSROOM_ADDED,
  CENTER_UPDATED,
  CLASSROOM_UPDATED,
  ACTIVE_CENTERS_FETCHED,
} from '../redux/types';

export const fa2PerformanceMeasureSelected = selected => ({
  type: FA2_PERFORMANCE_MEASURE_SELECTED,
  selected,
});

export const fa2CenterSelected = center => ({
  type: FA2_CENTER_SELECTED,
  center,
});

export const fa2ClassroomSelected = classroom => ({
  type: FA2_CLASSROOM_SELECTED,
  classroom,
});

export const fa2FormDetailFetched = fa2 => ({
  type: FA2_FORM_DETAIL_FETCHED,
  fa2,
});

export const fa2FormSchemaFetched = schema => ({
  type: FA2_FORM_SCHEMA_FETCHED,
  schema,
});

export const fa2SubmissionFetched = submission => ({
  type: FA2_SUBMISSION_FETCHED,
  submission,
});

export const fa2DraftSaved = () => ({
  type: FA2_DRAFT_SAVED,
});

export const fa2SubmissionSaved = () => ({
  type: FA2_SUBMISSION_SAVED,
});

export const fa2SubmissionUpdated = () => ({
  type: FA2_SUBMISSION_UPDATED,
});

export const fa2GuideSelected = (guide, performanceMeasure) => ({
  type: FA2_GUIDE_SELECTED,
  guide,
  performanceMeasure,
});

export const fa2CentersClassroomsUpdated = (
  centers,
  guideCode,
  performanceName
) => ({
  type: FA2_CENTERS_CLASSROOMS_UPDATED,
  centers,
  guideCode,
  performanceName,
});

export const fa2UpdateGuide = (
  guide,
  performanceMeasure,
  submissionId,
  centerName = '',
  classSampleId = ''
) => ({
  type: FA2_UPDATE_GUIDE,
  guide,
  performanceMeasure,
  submissionId,
  centerName,
  classSampleId,
});

export const centerClassroomFindingsFetched = centerClassroomFindings => {
  return {
    type: CENTER_CLASSROOM_FINDINGS_FETCHED,
    centerClassroomFindings,
  };
};
export const activeCenterFetched = centers => {
  return {
    type: ACTIVE_CENTERS_FETCHED,
    centers,
  };
};
export const centerFetched = centers => {
  return {
    type: CENTER_FETCHED,
    centers,
  };
};
export const classroomFetched = classrooms => {
  return {
    type: CLASSROOM_FETCHED,
    classrooms,
  };
};

export const centerAdded = center => {
  return {
    type: CENTER_ADDED,
    center,
  };
};
export const classroomAdded = classroom => {
  return {
    type: CLASSROOM_ADDED,
    classroom,
  };
};
export const centerUpdated = center => {
  return {
    type: CENTER_UPDATED,
    center,
  };
};
export const classroomUpdated = classroom => {
  return {
    type: CLASSROOM_UPDATED,
    classroom,
  };
};
export const enableDisableCenter = center => {
  return {
    type: ENABLE_DISABLED_CENTER,
    center,
  };
};
export const enableDisableClassroom = classroom => {
  return {
    type: ENABLE_DISABLED_CLASSROOM,
    classroom,
  };
};

export const clearFA2Forms = () => ({
  type: FA2_CLEAR_FORMS,
});

export const fetchFA2FormDetail = request => dispatch =>
  axios
    .post(`/survey/fa2/fetch-form-detail`, request)
    .then(response => dispatch(fa2FormDetailFetched(response.data)));

export const fetchFA2FormSchema = formId => dispatch =>
  axios
    .post(`/survey/fa2/fetch-form-schema`, formId)
    .then(response => dispatch(fa2FormSchemaFetched(response.data)));

export const fetchFA2Submission = request => dispatch =>
  axios
    .post(`/survey/fa2/fetch-submission`, request)
    .then(response => dispatch(fa2SubmissionFetched(response.data)));

export const fa2SaveDraft = request => dispatch =>
  axios.post(`/survey/fa2/save-draft`, request).then(response => {
    dispatch(fa2DraftSaved());
    return response.data;
  });

export const fa2SaveSubmission = request => dispatch =>
  axios.post(`/survey/fa2/save-submission`, request).then(response => {
    dispatch(fa2SubmissionSaved());
    return response.data;
  });

export const fa2UpdateSubmission = request => dispatch =>
  axios.post(`/survey/fa2/update-submission`, request).then(response => {
    dispatch(fa2SubmissionUpdated());
    return response.data;
  });

export const fa2UpdateCentersClassrooms = (
  request,
  centers,
  performanceName
) => dispatch =>
  axios
    .post('/survey/fa2/update-centers-classrooms', request)
    .then(response => {
      dispatch(
        fa2CentersClassroomsUpdated(centers, request.guideCode, performanceName)
      );
      return response.data;
    });

export const fetchCenterClassroomFindings = requestInput => dispatch =>
  axios
    .post(`/review/fetchCenterClassroomFindings`, requestInput)
    .then(response => dispatch(centerClassroomFindingsFetched(response.data)));

export const fetchCenters = requestInput => dispatch =>
  axios
    .post(`/review/fetch-centers`, requestInput)
    .then(response => dispatch(centerFetched(response.data)));

export const fetchActiveCenters = requestInput => dispatch =>
  axios
    .post(`/review/fetch-active-centers`, requestInput)
    .then(response => dispatch(activeCenterFetched(response.data)));

export const fetchClassrooms = requestInput => dispatch =>
  axios
    .post(`/review/fetch-classrooms`, requestInput)
    .then(response => dispatch(classroomFetched(response.data)));

export const addCenter = requestInput => dispatch =>
  axios
    .post(`/review/add-center`, requestInput)
    .then(response => dispatch(centerAdded(response.data)));

export const editCenter = requestInput => dispatch =>
  axios
    .post(`/review/edit-center`, requestInput)
    .then(response => dispatch(centerUpdated(response.data)));

export const addClassroom = requestInput => dispatch =>
  axios
    .post(`/review/add-classroom`, requestInput)
    .then(response => dispatch(classroomAdded(response.data)));

export const editClassroom = requestInput => dispatch =>
  axios
    .post(`/review/edit-classroom`, requestInput)
    .then(response => dispatch(classroomUpdated(response.data)));

export const enableOrDisableCenter = requestInput => dispatch => {
  axios
    .post(`/review/enable-center`, requestInput)
    .then(response => dispatch(enableDisableCenter(response.data)));
};

export const enableOrDisableClassroom = requestInput => dispatch =>
  axios
    .post(`/review/enable-classroom`, requestInput)
    .then(response => dispatch(enableDisableClassroom(response.data)));

export const fa2ChildFileSave = request => dispatch =>
  axios.post(`/survey/fa2/child-files/save`, request).then(response => {
    dispatch(fa2SubmissionSaved());
    return response.data;
  });
