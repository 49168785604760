import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';

// Import Actions
import { fetchClassMethodologyReport } from '../../actions/submissions';

// Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';
import AmsTable from '../../utils/AmsTable';

class CLASSMethodology extends Component {
  state = {
    classMethodologyReportList: {},
  };

  componentDidMount() {
    let input = {
      reviewId: this.context.router.params.id, //"1805555C"
    };
    this.props.fetchClassMethodologyReport(input).then(data => {
      console.log(data);
      this.setState({
        classMethodologyReportList: {
          ...data.classMethodologyReportList,
        },
      });
    });
  }

  isExpandableRow = row => {
    if (row.classList && row.classList.length > 0) return true;
    else return false;
  };

  expandComponent = row => {
    const columns = [
      {
        dataField: 'classSampleId',
        text: 'id',
        hidden: true,
      },
      {
        dataField: 'center',
        text: 'Center',
        headerStyle: {
          width: '33.33%',
          whiteSpace: 'normal',
          fontWeight: 'bold',
        },
        style: { width: '33.33%', whiteSpace: 'normal' },
      },
      {
        dataField: 'class',
        text: 'Classroom',
        headerStyle: {
          width: '33.33%',
          whiteSpace: 'normal',
          fontWeight: 'bold',
        },
        style: { width: '33.33%', whiteSpace: 'normal' },
        classes: 'td-dropdown',
      },
    ];

    return (
      <BootstrapTable
        keyField="classSampleId"
        data={row.classList}
        columns={columns}
        bordered={true}
      />
    );
  };

  prepareClassMethodologyList = () => {
    const { classMethodologyReportList } = this.state;

    const expandRowMessage = (cell, row) => {
      if (row.classList && row.classList.length > 0)
        return 'Expand row to see list of center and classrooms';
      else return '';
    };

    const columns = [
      { dataField: 'criteria', text: 'Criteria' },
      { dataField: 'classCount', text: 'Count of Classrooms' },
      {
        dataField: 'classList',
        classes: 'td-dropdown',
        formatter: expandRowMessage,
        text: 'List of Center-Classrooms',
      },
    ];

    const nonExpandable = [];
    const data = classMethodologyReportList
      ? classMethodologyReportList.data
      : [];
    if (data && data.length > 0) {
      for (let m = 0; m < data.length; m++) {
        if (!this.isExpandableRow(data[m]))
          nonExpandable.push(data[m].criteria);
      }
    }

    const columRenderer = ({ expanded, rowKey, expandable }) => {
      let content = '';

      if (expandable) {
        content = expanded ? '-' : '+';
      } else {
        content = ' ';
      }
      return <div> {content} </div>;
    };

    const expandRow = {
      renderer: this.expandComponent,
      showExpandColumn: true,
      expandColumnRenderer: columRenderer,
      nonExpandable: nonExpandable,
    };

    return (
      <AmsTable
        data={classMethodologyReportList ? classMethodologyReportList.data : []}
        expandRow={expandRow}
        remote={false}
        total={
          classMethodologyReportList.data
            ? classMethodologyReportList.data.length
            : 0
        }
        columns={columns}
        keyField="criteria"
      ></AmsTable>
    );
  };

  render() {
    const { classMethodologyReportList } = this.props;
    const { reviewId, reviewType, GrantInfo } = classMethodologyReportList;
    const grantee = GrantInfo ? GrantInfo[0] : {};
    return (
      <div>
        <SlidingContainer
          // calendar
          title={
            'CLASS Methodology for Review ID ' + this.context.router.params.id
          }
        >
          {/* {assignments.assignmentChangeFlag &&
            <Message
              negative
              icon='warning'
              header='Notice'
              content={`The New ${assignments.sampleLabel} Class Sampling report has been generated on ${moment(assignments.sampleGenrationDate).format("MMM Do YYYY")}. All the earlier assignments made if any based on older version of samples will be replaced.`}
            />
          } */}
          <div className="well survey-header-info">
            {(grantee || {}).granteeName} ( {(grantee || {}).granteeId} ) |{' '}
            {reviewType} ( {reviewId} )
          </div>
          {classMethodologyReportList && this.prepareClassMethodologyList()}
        </SlidingContainer>
      </div>
    );
  }
}

CLASSMethodology.propTypes = {};

CLASSMethodology.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    classMethodologyReportList: state.submissions.classMethodologyReportList,
    classAssignmentLoading: state.submissions.classAssignmentLoading,
  };
}

export default connect(mapStateToProps, { fetchClassMethodologyReport })(
  CLASSMethodology
);
