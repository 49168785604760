/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Modal from 'react-modal';
import { Button, FormGroup, FormControl, HelpBlock } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Dropdown, Header, List, Icon } from 'semantic-ui-react';
import { Link } from 'react-router';

// Import helper component
import { FieldGroupField } from '../../utils/FieldGroup';

//Import actions
import {
  fetchResources,
  addResource,
  updateResource,
  deleteResource,
} from '../../actions/resourceActions';

// Import util.
import enforceRole from '../../utils/EnforceRole';

// Import config.
import { acl } from '../../config';

import './asset/style.css';

const resourceOptions = [
  { key: 0, value: 'TRAINING', text: 'Training' },
  { key: 1, value: 'REFERENCE', text: 'Reference' },
  { key: 2, value: 'FAQ', text: 'FAQ' },
];
class Resoruces extends Component {
  constructor() {
    super();

    this.state = {
      modelIsOpen: false,
      loading: true,
      errors: {},
      data: {
        resourceId: '',
        resourceName: '',
        resourceType: '',
        resourceLink: '',
      },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleInputValueChage = this.handleInputValueChage.bind(this);
  }

  componentWillMount() {
    //  const { oid } = this.props.currentUser;

    // if (oid)
    this.props.fetchResources();
    // .then(() => this.setState({ loading: false }))
    // .catch(error => {
    //   this.setState({ loading: false });
    // });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      errors: {},
      data: {
        resourceId: '',
        resourceName: '',
        resourceType: '',
        resourceLink: '',
      },
    });
  }

  handleInputValueChage(event) {
    this.setState({
      data: { ...this.state.data, [event.target.id]: event.target.value },
      errors: { ...this.state.errors, [event.target.id + 'Error']: '' },
    });
  }

  handleOptionChange(event, data) {
    this.setState({
      data: { ...this.state.data, resourceType: data.value },
      errors: { ...this.state.errors, resourceTypeError: '' },
    });
  }

  hand(value) {
    this.setState({
      data: { ...this.state.data, resourceType: value.value },
      errors: { ...this.state.errors, resourceTypeError: '' },
    });
  }

  referenceResources() {
    const { resources } = this.props;

    return (
      <ul>
        {_.filter(resources, { type: 'REFERENCE' }).map(item => {
          const itemLink = new URL(item.link);
          if (_.isEmpty(item.link)) {
            // eslint-disable-next-line array-callback-return
            return;
          } else if (
            window.location.hostname.toLowerCase() ===
            itemLink.hostname.toLowerCase()
          ) {
            return (
              <li key={item._id}>
                <Link
                  href={`${item.link}`}
                  rel="noopener noreferrer"
                  aria-label={`Go to ${item && item.name}`}
                >
                  {item.name}
                </Link>
              </li>
            );
          } else
            return (
              <li key={item._id}>
                <Link
                  href={`/external-link/?url=${item.link}`}
                  rel="noopener noreferrer"
                  aria-label={`Go to ${item && item.name} external link`}
                >
                  {item.name} <span className="sr-only">external icon</span>
                  <Icon name="external" />
                </Link>
              </li>
            );
        })}
      </ul>
    );
  }

  selfHelpResources() {
    const { resources } = this.props;

    return (
      <ul>
        {_.filter(resources, { type: 'FAQ' }).map(item => {
          const itemLink = new URL(item.link);
          if (_.isEmpty(item.link)) {
            // eslint-disable-next-line array-callback-return
            return;
          } else if (
            window.location.hostname.toLowerCase() ===
            itemLink.hostname.toLowerCase()
          ) {
            return (
              <li className="resource-item" key={item._id}>
                <List.Item
                  as="a"
                  href={`${item.link}`}
                  rel="noopener noreferrer"
                  aria-label={`Go to ${item && item.name}`}
                >
                  {item.name}
                </List.Item>
              </li>
            );
          } else
            return (
              <li className="resource-item" key={item._id}>
                <Link
                  as="a"
                  href={`/external-link/?url=${item.link}`}
                  rel="noopener noreferrer"
                  aria-label={`Go to ${item && item.name} external link`}
                >
                  {item.name} <span className="sr-only">external icon</span>
                  <Icon name="external" />
                </Link>
              </li>
            );
        })}
      </ul>
    );
  }

  trainingResources() {
    const { resources } = this.props;

    return (
      <ul>
        {_.filter(resources, { type: 'TRAINING' }).map(item => {
          const itemLink = new URL(item.link);
          if (_.isEmpty(item.link)) {
            // eslint-disable-next-line array-callback-return
            return;
          } else if (
            window.location.hostname.toLowerCase() ===
            itemLink.hostname.toLowerCase()
          ) {
            return (
              <li key={item._id}>
                <Link
                  href={`${item.link}`}
                  rel="noopener noreferrer"
                  aria-label={`Go to ${item && item.name}`}
                >
                  {item.name}
                </Link>
              </li>
            );
          } else
            return (
              <li key={item._id}>
                <Link
                  href={`/external-link/?url=${item.link}`}
                  rel="noopener noreferrer"
                  aria-label={`Go to ${item && item.name} external link`}
                >
                  {item.name} <span className="sr-only">external icon</span>
                  <Icon name="external" />
                </Link>
              </li>
            );
        })}
      </ul>
    );
  }

  triggerModalOpen() {
    this.openModal();
  }

  addResources() {
    const { currentUser } = this.props;

    // const isContentEditor = _.includes(this.props.currentUser.roles, 'ContentEditor')

    let resourceStyle = 'col-md-4 col-sm-4';

    // if (_.includes(this.props.currentUser.roles, 'Reviewer')) {
    //   resourceContainer = "col-md-6 col-sm-6 col-md-offset-2";
    //   resourceStyle = "col-md-12 col-sm-12";
    // }

    return (
      <div>
        <h2 className="block-title">
          Resources &nbsp;
          {enforceRole(
            <button
              onClick={this.triggerModalOpen.bind(this)}
              className="btn btn-default btn-sm btn-outline"
            >
              Add/Modify
            </button>,
            acl.actions.resource.create, // Allowed roles
            currentUser.roles // Current user roles
          )}
        </h2>
        <div className={resourceStyle}>
          <div className="resource-title">
            <span className="fa fa-question resource-icon" />
            FAQ
          </div>
          {this.selfHelpResources()}
        </div>

        <div className={resourceStyle}>
          <div className="resource-title">
            <span className="fa fa-book resource-icon" />
            References
          </div>
          {this.referenceResources()}
        </div>

        <div className={resourceStyle}>
          <div className="resource-title">
            <span className="fa fa-graduation-cap resource-icon" />
            Performance Support Material
          </div>
          {this.trainingResources()}
        </div>
      </div>
    );
  }

  validate = data => {
    const errors = {};
    if (!data.resourceName) errors.resourceNameError = 'Title is required.';
    if (!data.resourceLink) errors.resourceLinkError = 'Link is rquired.';
    if (!data.resourceType)
      errors.resourceTypeError = 'Resource type is rquired.';

    return errors;
  };

  onSubmit = e => {
    e.preventDefault();

    const errors = this.validate(this.state.data);
    this.setState({ errors });

    // No more errors show confirmation dialog.
    if (Object.keys(errors).length === 0) {
      this.saveResource();
      this.clearForm();
    }
  };

  saveResource() {
    const {
      resourceId,
      resourceName,
      resourceType,
      resourceLink,
    } = this.state.data;

    const resourceObject = {
      name: resourceName,
      link: resourceLink,
      type: resourceType,
    };

    // If there's a resource id, its an update. Else a new record.
    if (resourceId) {
      this.props.updateResource(resourceId, resourceObject);
    } else {
      this.props.addResource(resourceObject);
    }
  }

  handleResourceEdit(resource, event) {
    event.preventDefault();

    this.setState({
      data: {
        resourceId: resource._id,
        resourceName: resource.name,
        resourceLink: resource.link,
        resourceType: resource.type,
      },
    });
  }

  handleResourceRemove(id, event) {
    event.preventDefault();
    if (id) {
      this.props.deleteResource(id);
      this.clearForm();
    }
  }

  addResourceList() {
    let { resources } = this.props;

    const options = {};

    const linkData = cell => (cell ? <a href={cell.link}>{cell}</a> : null);

    const action = (cell, row) => {
      return (
        <span>
          <span
            onClick={this.handleResourceEdit.bind(this, row)}
            className="clickable"
          >
            <i className="fa fa-pencil fa-md" />
          </span>
          &nbsp; | &nbsp;
          <span
            onClick={this.handleResourceRemove.bind(this, row._id)}
            className="clickable"
          >
            <i className="fa fa-trash fa-md" />
          </span>
        </span>
      );
    };
    const columns = [
      {
        hidden: true,
        dataField: '_id',
        isKey: true,
        sort: true,
      },
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        headerStyle: { width: '15%' },
      },
      {
        dataField: 'type',
        text: 'Type',
        sort: true,
        headerStyle: { width: '10%' },
      },
      {
        dataField: 'link',
        formatter: linkData,
        sort: true,
      },
      {
        dataField: 'action',
        headerStyle: { width: '15%' },
        formatter: action,
        sort: true,
      },
    ];

    return (
      <div className="table-container">
        <BootstrapTable
          keyField="_id"
          data={resources}
          columns={columns}
          bordered={false}
          pagination={resources?.length > 10 ? paginationFactory() : undefined}
          headerWrapperClasses="table-header"
          bodyClasses="table-body"
        />
      </div>
    );
  }

  clearForm() {
    this.setState({
      errors: {},
      data: {
        resourceId: '',
        resourceName: '',
        resourceType: '',
        resourceLink: '',
      },
    });
  }

  showModal() {
    const { modalIsOpen } = this.state;
    const { resourceName, resourceType, resourceLink } = this.state.data;
    const {
      resourceNameError,
      resourceLinkError,
      resourceTypeError,
    } = this.state.errors;

    return (
      <Modal
        className="modal-container"
        overlayClassName="modal-overlay-container"
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Resource Modal"
      >
        <div className="row" style={{ overflow: 'visible' }}>
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-11">
                <h2 className="no-top-margin">Add/Modify Resources</h2>
              </div>
              <div className="col-sm-1">
                <span onClick={this.closeModal} className="clickable">
                  <i className="fa fa-close fa-2x" />
                </span>
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <hr />
          </div>
          <form onSubmit={this.onSubmit}>
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-12">
                  <FieldGroupField
                    id="resourceName"
                    validationState={resourceNameError ? 'error' : null}
                    value={resourceName}
                    type="text"
                    onChange={this.handleInputValueChage}
                    label="Title"
                    placeholder="Resource title"
                    help={resourceNameError || 'Resource Title'}
                  />
                </div>

                <div className="col-sm-12">
                  <FieldGroupField
                    id="resourceLink"
                    validationState={resourceLinkError ? 'error' : null}
                    value={resourceLink}
                    type="text"
                    onChange={this.handleInputValueChage}
                    label="Link"
                    placeholder="Resource Link"
                    help={resourceLinkError || 'Resource Link'}
                  />
                </div>

                <div className="col-sm-12">
                  <FormGroup
                    validationState={resourceTypeError ? 'error' : null}
                  >
                    <label className="control-label">Resource type</label>
                    <Dropdown
                      placeholder="Select type"
                      fluid
                      selection
                      value={resourceType}
                      error={resourceTypeError ? true : false}
                      options={resourceOptions}
                      onChange={this.handleOptionChange.bind(this)}
                    />
                    <HelpBlock>
                      {resourceTypeError || 'Resource Type'}
                    </HelpBlock>
                    <FormControl.Feedback />
                  </FormGroup>
                  <div>&nbsp;</div>
                </div>

                <div className="text-right col-sm-12">
                  <a
                    onClick={this.clearForm.bind(this)}
                    style={{ cursor: 'pointer' }}
                  >
                    Clear Form
                  </a>
                </div>

                <div className="col-sm-12">
                  <h3>Added Resources:</h3>
                </div>
                <div className="col-sm-12">{this.addResourceList()}</div>
              </div>
            </div>

            <div className="col-sm-6">
              <Button
                className="btn-block"
                type="submit"
                bsSize="large"
                bsStyle="primary"
              >
                Save Resource{' '}
              </Button>
            </div>

            <div className="col-sm-6">
              <Button
                className="btn-block"
                onClick={this.closeModal}
                bsSize="large"
                bsStyle="primary"
              >
                Close{' '}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }

  // render() {
  //   return (
  //     <div className="row">
  //       {this.showModal()}
  //       {this.addResources()}
  //     </div>
  //   );
  // }

  render() {
    return (
      <div>
        <Header as="h2">Resources</Header>

        <div>
          <div>
            <h3>References</h3>
          </div>
          {this.referenceResources()}
        </div>

        <div>
          <div>
            <h3>Performance Support Material</h3>
          </div>
          {this.trainingResources()}
        </div>
      </div>
    );
  }

  renderOld() {
    return (
      <>
        <Header as="h3">Resources</Header>

        {/* <div>
          <div className="resource-title">
            <span className="fa fa-question resource-icon" />
            FAQ
          </div>
          {this.selfHelpResources()}
        </div> */}

        <div>
          <div className="resource-title">
            <span className="fa fa-book resource-icon" />
            References
          </div>
          {this.referenceResources()}
        </div>

        <div>
          <div className="resource-title">
            <span className="fa fa-graduation-cap resource-icon" />
            Performance Support Material
          </div>
          {this.trainingResources()}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    resources: _.sortBy(state.resource, ['type', 'name']),
    currentUser: state.auth.user,
  };
}

Resoruces.propTypes = {
  resources: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  fetchResources: PropTypes.func,
  addResource: PropTypes.func,
  updateResource: PropTypes.func,
};

export default connect(mapStateToProps, {
  fetchResources,
  addResource,
  updateResource,
  deleteResource,
})(Resoruces);
