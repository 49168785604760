import { filter, find, includes, intersection, isEmpty, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Well } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';
import {
  Button,
  Comment,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  List,
  Menu,
  Message,
  Modal,
  Popup,
  Segment,
  TextArea,
  Dimmer,
  Loader,
  Input,
} from 'semantic-ui-react';

import AmsAlert from '../../utils/AmsAlert';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsFormLabel from '../../utils/AmsFormLabel';
import AmsModal from '../../utils/AmsModal';
import AmsTable from '../../utils/AmsTable';
import ReviewLayout from '../../utils/layout/ReviewLayout';
import { createReportStageMessage } from '../../utils/TextFormatters';
import ReviewDetailCLASSSidebar from '../Review/ReviewDetailCLASSSidebar';
import ReportActivityButtons from './ReportActivityButtons';
import ReportExternalComments from './ReportExternalComments';
// Import config
import { acl } from '../../config';

//import actions

import {
  fetchCommentsFileList,
  fetchExternalUsers,
  fetchFileContents,
  fetchReportDraftTemplate,
  fetchReportNew,
  generateReportAction,
  reportConversationReplyAction,
  reportSaveActionNew,
  reportShareAction,
  reportSharePDFAction,
  surveyReportSaveActionNew,
  fetchReportEditDetails,
  saveReportDetails,
} from '../../actions/reportActions';
import {
  fetchEmailNotifyQAARequest,
  fetchEmailNotifyRLRequest,
  fetchEmailReturnToFOM,
} from '../../actions/reviewActions';
import {
  fetchSpecialAssignments,
  fetchSpecialSurveyAssignments,
} from '../../actions/specialActions';
import {
  fetchInternalTeamUsers,
  fetchInternalUsers,
} from '../../actions/userActions';

export default function ReportDetail(props) {
  const { newReport, externalUsers, commentFileList } = useSelector(
    state => state.report
  );
  const { selectedReview, fetchedFindingDetails } = useSelector(
    state => state.review
  );
  const { internalUsers, internalTeamUsers } = useSelector(
    state => state.users
  );
  const { user } = useSelector(state => state.auth);

  const [loading, setLoading] = useState(true);
  const [versions, setVersions] = useState([]);
  const [params, setParams] = useState({});
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareType, setShareType] = useState('');
  const [sharedInternalUsers, setSharedInternalUsers] = useState([]);
  const [sharedExternalUsers, setSharedExternalUsers] = useState([]);
  const [internalUsersOptions, setInternalUsersOptions] = useState([]);
  const [externalUsersOptions, setExternalUsersOptions] = useState([]);
  const [sharedInternalTeamUsers, setSharedInternalTeamUsers] = useState([]);
  const [assignedInternalTeamUser, setAssignedInternalTeamUser] = useState();
  const [internalTeamUsersOptions, setInternalTeamUsersOptions] = useState([]);
  const [commentsFileList, setCommentsFileList] = useState([]);
  const [shareMessage, setShareMessage] = useState('');
  const [shareExpirationDate, setShareExpirationDate] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [showActivityInfoAlert, setShowActivityInfoAlert] = useState(false);
  const [activityInfoType, setActivityInfoType] = useState('success');
  const [activityInfoMessage, setActivityInfoMessage] = useState('');
  const [activityAction, setActivityAction] = useState('');
  const [activeItem, setActiveItem] = useState('sharedLog');
  const [modalReplyIndex, setModalReplyIndex] = useState(-1);
  const [modalDetailsIndex, setModalDetailsIndex] = useState(-1);
  const [replyMessage, setReplyMessage] = useState('');
  const [reportSignee, setReportSignee] = useState('');
  const [reportSigneeOptions, setReportSigneeOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [sending, setSending] = useState(false);
  const [reportStatusMessage, setReportStatusMessage] = useState('');
  const dispatch = useDispatch();
  const [latestVersion, setLatestVersion] = useState({});
  const [versionBannerText, setVersionBannerText] = useState([]);
  const [versionSaveType, setVersionSaveType] = useState(null);
  const [
    showMissingConfirmedGranteeAlert,
    setShowMissingConfirmedGranteeAlert,
  ] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalDetails, setEditModalDetails] = useState(null);
  const [selectedGuideData, setSelectedGuideData] = useState({
    guideName: '',
    performanceArea: '',
    citation: '',
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const [readOnly, setReadOnly] = useState(false);

  const buttonRef = React.createRef();

  const editTypes = [
    { type: 'Report Summary', category: 'templateLanguage' },
    { type: 'Report Distribution', category: 'distribution' },
    { type: 'Grant Recipient Name', category: 'granteeName' },
    { type: 'Program Overview', category: 'programOverview' },
    { type: 'Performance Summary', category: 'performanceSummary' },
    { type: 'Report Finding Summary', category: 'reportFindingSummary' },
    { type: 'Performance Measure Statements', category: 'performanceArea' },
    { type: 'Strong Practice Details', category: 'strongPracticeDetails' },
  ];

  useEffect(() => {
    const getParams = async () => {
      let id = props.params && props.params.id && props.params.id;
      setParams({
        reviewId: id,
      });
    };
    getParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      showEditModal &&
      editModalDetails &&
      editModalDetails.category &&
      !editModalDetails.fetchedInformation
    ) {
      dispatch(
        fetchReportEditDetails({
          reviewId: params.reviewId,
          category: editModalDetails.category,
          guideName: selectedGuideData.guideName,
          performanceArea: selectedGuideData.performanceArea,
        })
      ).then(data => {
        if (data) {
          setEditModalDetails({
            ...editModalDetails,
            fetchedInformation: data,
          });
        }
      });
    }
  }, [JSON.stringify(selectedGuideData), showEditModal]);

  useEffect(() => {
    const validReviewTypes = ['FA2-CR', 'FA1-FR', 'Special'];
    if (!validReviewTypes.includes(newReport?.reviewType)) return;

    const timer = setTimeout(() => {
      const linkTypes = [
        'Program Overview',
        'Performance Summary',
        'Report Finding Summary',
        'Strong Practice Details',
      ];
      editTypes.forEach(({ type, category }) => {
        const elementCategories = document.querySelectorAll(
          `[id="${category}"]`
        );
        elementCategories.forEach(elementCategory => {
          if (linkTypes.includes(type)) {
            addEditLink(elementCategory, { type, category });
          } else {
            addEditButton(elementCategory, { type, category });
          }
        });
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [newReport]);

  const addEditButton = (targetElement, editType) => {
    const editButtonHTML = `<button style="position: absolute; left: 0" class="ui blue large button">Edit</button>`;
    targetElement.innerHTML = editButtonHTML + targetElement.innerHTML;
    const button = targetElement.querySelector('button');
    button.addEventListener('click', e => {
      e.preventDefault();
      const guidename = targetElement.getAttribute('guidename');
      const performancearea = targetElement.getAttribute('performancearea');
      const citation = targetElement.getAttribute('citation');
      setEditModalDetails(editType);
      setSelectedGuideData({
        guideName: guidename,
        performanceArea: performancearea,
        citation: citation,
      });
      setShowEditModal(true);
    });
  };

  const addEditLink = (targetElement, editType) => {
    const fiscalYear =
      selectedReview.fiscalYear &&
      selectedReview.fiscalYear.toString().slice(-2);

    //add program overview query params
    const queryParams = new URLSearchParams();
    if (editType.type === 'Program Overview')
      queryParams.set('openProgramOverview', 'true');
    else if (editType.type === 'Report Finding Summary') {
      queryParams.set(
        'defaultCitation',
        targetElement.getAttribute('citation')
      );
    } else {
      const performanceSummaryTable = document.getElementById(
        'performanceSummary'
      );
      const citation = performanceSummaryTable
        ? performanceSummaryTable.getAttribute('citation')
        : '';

      queryParams.set('defaultCitation', citation);
    }

    const editLinkHTML = `<a target="_blank" href="${
      'Program Overview' == editType.type
        ? `/review/fy${fiscalYear}/${
            selectedReview.reviewId
          }?${queryParams.toString()}`
        : `/review/fy${fiscalYear}/${
            selectedReview.reviewId
          }/findings-analysis?${queryParams.toString()}`
    }"
    style="position: absolute; left: 0; text-decoration: underline; ${
      !selectedReview?.editReport
        ? 'color: gray; pointer-events: none;'
        : 'text-decoration-color: blue;'
    }"
    ${!selectedReview?.editReport ? 'onclick="return false;"' : ''}
     >
        ${
          'Program Overview' == editType.type
            ? `Edit in<br>Review<br>Details`
            : `Edit in<br>Findings`
        }
        </a>
    `;
    targetElement.innerHTML = editLinkHTML + targetElement.innerHTML;
    const link = targetElement.querySelector('a');
    link.addEventListener('click', e => {});
  };

  const isValidReportStage = () => {
    return (newReport &&
      (newReport.reportStage === 'signed' ||
        newReport.reportStage === 'Shipped' ||
        newReport.reportStage === 'finalized' ||
        (newReport.reportVersion &&
          latestVersion &&
          newReport.reportKey !== latestVersion.reportKey))) ||
      !selectedReview?.editReport
      ? 'disabled'
      : '';
  };

  useEffect(() => {
    const getData = async () => {
      if (!isEmpty(params)) {
        try {
          setLoading(true);
          await dispatch(fetchReportNew(params));
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params]);

  useEffect(() => {
    const getVersions = async () => {
      if (newReport && newReport.revisions) {
        const orderedRevisions = orderBy(
          newReport.revisions,
          ['timeStamp'],
          ['desc']
        );

        const versionsList = orderedRevisions.map(version => {
          if (isEmpty(version)) return;

          const timeStamp = AmsDateFormatters.getMoment(version.timeStamp)
            .tz('America/New_York')
            .format('L, LT');

          version.timeStamp = timeStamp;
          return {
            key: version.reportKey,
            text: `${version.reportVersionName} - ${timeStamp}`,
            value: version,
          };
        });

        setVersions(versionsList);
      }
    };

    const getReportStatusMessage = async () => {
      if (newReport && newReport.stageLabel && newReport.reportStage) {
        let message = createReportStageMessage(
          newReport.stageLabel,
          newReport.reportStage
        );
        setReportStatusMessage(message);
      }
    };

    const getDraftTemplate = async () => {
      if (
        newReport &&
        (newReport.reviewType === 'CLASS' ||
          newReport.reviewType === 'AIAN-CLASS' ||
          newReport.reviewType === 'PR-CLASS' ||
          newReport.reviewType === 'VP-CLASS' ||
          newReport.reviewType === 'CLASS-Video' ||
          newReport.reviewType === 'AIAN CLASS Self-Observations' ||
          newReport.reviewType === 'CLASS AIAN Onsite' ||
          newReport.reviewType === 'CLASS AIAN Video')
      )
        dispatch(
          fetchReportDraftTemplate(
            { reviewId: newReport.reviewID },
            newReport.reviewType
          )
        );
    };

    const getFileList = async () => {
      if (!isEmpty(newReport)) {
        try {
          dispatch(
            fetchCommentsFileList({
              reviewId: newReport.reviewID,
              reportId: newReport.reportID,
            })
          );
          // setCommentsFileList(files);
        } catch (error) {
          console.error(error);
        }
      }
    };

    const checkLatestVersion = () => {
      let versionLength = newReport.revisions && newReport.revisions.length;
      let max =
        newReport.revisions &&
        versionLength > 0 &&
        newReport.revisions.reduce((max, p) => {
          if (parseFloat(p.reportVersion) > parseFloat(max.reportVersion))
            return p;
          else if (parseFloat(p.reportVersion) < parseFloat(max.reportVersion))
            return max;
          else return p.timeStamp > max.timeStamp ? p : max;
        });
      if (newReport.revisions && versionLength > 1) {
        max = newReport.revisions[versionLength - 1];
      }
      setLatestVersion(max);
    };

    const getReportSigneeList = () => {
      const options =
        newReport.signaturePOCList && newReport.signaturePOCList.length > 0
          ? newReport.signaturePOCList.map(e => {
              if (e.roleName === 'Director') {
                setReportSignee(e);
              }

              return {
                key: e.roleName,
                text: `${e.userName} (${e.roleName})`,
                value: e,
              };
            })
          : [];
      setReportSigneeOptions(options);
    };

    getReportSigneeList();
    getVersions();
    getReportStatusMessage();
    getDraftTemplate();
    getFileList();
    checkLatestVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newReport]);

  useEffect(() => {
    const getOptions = async () => {
      setExternalUsersOptions(externalOptions());
      setInternalUsersOptions(internalOptions());
      setInternalTeamUsersOptions(internalTeamOptions());
    };
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalUsers, externalUsers, internalTeamUsers]);

  useEffect(() => {
    const saveShareMessage = async () => {
      const messageInfo =
        newReport &&
        newReport.messageTemplates &&
        find(newReport.messageTemplates, message => {
          return message.type === shareType;
        });
      if (messageInfo && messageInfo.message)
        setShareMessage(messageInfo.message);
    };

    const getShareUsers = async () => {
      if (shareType && shareType !== 'Grantee' && selectedReview) {
        dispatch(
          fetchExternalUsers({
            group: shareType,
            reviewId: (selectedReview || {}).reviewId,
            reviewType: (selectedReview || {}).reviewType,
          })
        );
        dispatch(
          fetchInternalUsers({
            reviewType: (selectedReview || {}).reviewType,
          })
        );
        dispatch(
          fetchInternalTeamUsers({
            reviewType: (selectedReview || {}).reviewType,
            reviewId: (selectedReview || {}).reviewId,
          })
        );
      }
    };

    const getGuides = async () => {
      const { roles } = user;

      let hasPermissionSpecialReview = intersection(
        acl.actions.review.specialAction,
        roles
      );

      if (isEmpty(hasPermissionSpecialReview) && includes(roles, 'Reviewer'))
        hasPermissionSpecialReview = ['Reviewer'];

      if (
        !isEmpty(hasPermissionSpecialReview) &&
        selectedReview &&
        selectedReview.reviewId &&
        (selectedReview.reviewType === 'Special' ||
          selectedReview.reviewType === 'AIAN-DEF' ||
          selectedReview.reviewType === 'ERSEA')
      ) {
        if (
          selectedReview.reviewType === 'Special' &&
          selectedReview?.isNewForms
        ) {
          dispatch(
            fetchSpecialSurveyAssignments({
              reviewId: selectedReview.reviewId,
            })
          );
        } else {
          dispatch(
            fetchSpecialAssignments({
              reviewId: selectedReview.reviewId,
            })
          );
        }
      }
    };

    saveShareMessage();
    getShareUsers();
    getGuides();

    if (
      (selectedReview || {}).reviewAccessLevel &&
      (selectedReview || {}).reviewAccessLevel.isReviewAccessible === false &&
      (selectedReview || {}).reviewStatus !== 'Report Signed' &&
      (selectedReview || {}).reviewStatus !== 'Shipped'
    ) {
      setReadOnly(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareType, selectedReview]);

  useEffect(() => {
    const getFileList = () => {
      if (!isEmpty(commentFileList)) setCommentsFileList(commentFileList);
    };

    getFileList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentFileList]);

  useEffect(() => {
    if (activeItem === 'internalConversation' && showActivityModal === true) {
      setTimeout(() => {
        buttonRef.current._reactInternalFiber.child.child.stateNode.focus();
      }, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActivityModal, activeItem]);

  const onSubmitReply = id => {
    let input = { id, message: replyMessage };

    dispatch(
      reportConversationReplyAction(
        input,
        result => {
          setReplyMessage('');
          setModalReplyIndex(-1);

          if (result instanceof Error) {
            setShowActivityInfoAlert(true);
            setActivityInfoType('error');
            setActivityInfoMessage(
              `'Error: ${result.response.data.message || 'unknown error'}`
            );
          } else {
            setShowActivityInfoAlert(true);
            setActivityInfoType('success');
            setActivityInfoMessage('You have replied to the conversation.');
          }
        },
        newReport.reviewID
      )
    );
  };

  const renderInternalConversation = () => {
    const { internalConversations } = newReport;

    return (
      <Comment.Group>
        <Header as="h3" dividing>
          Internal Shared Log
        </Header>
        {internalConversations && internalConversations.length > 0
          ? orderBy(internalConversations, ['sharedDate'], ['desc']).map(
              (e, index) => {
                let sharedWith = false;
                if (e.sharedWithInfo.length > 0) {
                  sharedWith = e.sharedWithInfo.map(e => e.fullName).join(', ');
                }

                return (
                  <Comment>
                    <Comment.Content>
                      <Comment.Author as="span">{e.sharedBy}</Comment.Author>
                      <Comment.Metadata>
                        <div>
                          {AmsDateFormatters.formatUtcDateTime(e.sharedDate)}
                        </div>
                      </Comment.Metadata>

                      <Link
                        to={'#'}
                        onClick={e => {
                          e.preventDefault();
                          setModalDetailsIndex(
                            index === modalDetailsIndex ? -1 : index
                          );
                        }}
                      >
                        {modalDetailsIndex === index
                          ? `(Hide Details)`
                          : `(Show Details)`}
                      </Link>
                      {index === modalDetailsIndex && (
                        <>
                          <Comment.Metadata
                            style={{
                              display: 'block',
                              marginLeft: '0',
                              margin: '.5em 0 0 0',
                            }}
                          >
                            <div>
                              Assigned To:{' '}
                              {e.assigneeInfo ? e.assigneeInfo.fullName : 'N/A'}
                            </div>
                          </Comment.Metadata>
                          <Comment.Metadata
                            style={{
                              display: 'block',
                              marginLeft: '0',
                              margin: '.5em 0 0 0',
                            }}
                          >
                            <div>
                              Due Date:{' '}
                              {AmsDateFormatters.formatDate(e.expirationDate)}
                            </div>
                          </Comment.Metadata>
                          {sharedWith && (
                            <Comment.Metadata
                              style={{
                                display: 'block',
                                marginLeft: '0',
                                margin: '.5em 0 0 0',
                              }}
                            >
                              <div>Shared With: {sharedWith}</div>
                            </Comment.Metadata>
                          )}
                        </>
                      )}
                      <Comment.Text>{e.message}</Comment.Text>
                      {e.sharedGroup && e.sharedGroup !== null && (
                        <Comment.Actions>
                          <Comment.Action
                            onClick={() => setModalReplyIndex(index)}
                          >
                            Reply
                          </Comment.Action>
                        </Comment.Actions>
                      )}
                    </Comment.Content>
                    {e.replies && e.replies.length > 0 && (
                      <Comment.Group>
                        {orderBy(e.replies, ['addedAt'], ['desc']).map(
                          reply => {
                            return (
                              <Comment>
                                <Comment.Content>
                                  <Comment.Author as="span">
                                    {reply.addedBy.fullName}
                                  </Comment.Author>
                                  <Comment.Metadata>
                                    <div>
                                      {' '}
                                      {AmsDateFormatters.formatUtcDateTime(
                                        reply.addedAt
                                      )}
                                    </div>
                                  </Comment.Metadata>
                                  <Comment.Text>{reply.message}</Comment.Text>
                                </Comment.Content>
                              </Comment>
                            );
                          }
                        )}
                      </Comment.Group>
                    )}
                    {index === modalReplyIndex && (
                      <Form
                        reply
                        onSubmit={() => onSubmitReply(e.conversationId)}
                      >
                        <TextArea
                          value={entityToChar(replyMessage, 'textarea')}
                          onChange={e => {
                            setReplyMessage(e.target.value);
                          }}
                          rows={3}
                          style={{ height: '100px' }}
                        />
                        <Form.Group style={{ marginTop: '5px' }}>
                          <Form.Button
                            type="submit"
                            primary
                            content={'Add Reply'}
                          />
                          <Form.Button
                            onClick={() => {
                              setModalDetailsIndex(-1);
                              setModalReplyIndex(-1);
                            }}
                            type="cancel"
                            content={'Cancel'}
                          />
                        </Form.Group>
                      </Form>
                    )}
                  </Comment>
                );
              }
            )
          : 'No Conversations found.'}
      </Comment.Group>
    );
  };

  const renderSharedWithTable = () => {
    const sharedBy = (cell, row) => {
      if (row && !isEmpty(row.sharedBy))
        return <>{row.sharedBy.fullName && row.sharedBy.fullName}</>;
    };

    const sharedWith = (cell, row) => {
      if (row && !isEmpty(row.sharedWith))
        return <>{row.sharedWith.fullName && row.sharedWith.fullName}</>;
    };

    const sharedDateFormatter = (cell, row) => {
      if (row && row.sharedDate) {
        const date = new Date(row.sharedDate);
        return <>{AmsDateFormatters.getMoment(date).format('L')}</>;
      }
    };

    const expirationDateFormatter = (cell, row) => {
      if (row && row.expirationDate) {
        const date = new Date(row.expirationDate);
        return <>{AmsDateFormatters.getMoment(date).format('L')}</>;
      }
    };

    const data =
      newReport &&
      newReport.sharedMessages &&
      newReport.sharedMessages.length > 0 &&
      filter(newReport.sharedMessages, message => {
        if (!isEmpty(message.sharedWith)) return message;
      });

    const columns = [
      {
        formatter: sharedBy,
        dataField: 'sharedBy.fullName',
        text: 'Shared By',
        sort: true,
        headerStyle: {
          width: '30%',
        },
      },
      {
        formatter: sharedWith,
        dataField: 'sharedWith.fullName',
        text: 'Shared With',
        sort: true,
        headerStyle: {
          width: '30%',
        },
      },
      {
        dataField: 'sharedDate',
        text: 'Shared Date',
        sort: true,
        formatter: sharedDateFormatter,
      },
      {
        dataField: 'expirationDate',
        text: 'Expiration Date',
        sort: true,
        formatter: expirationDateFormatter,
      },
    ];

    return (
      <div>
        <AmsTable
          data={data || []}
          remote={false}
          total={data && data.length}
          keyField="_id"
          columns={columns}
          pagination={false}
          options={{
            hideSizePerPage: true,

            showTotal: false,
          }}
          showTotal={false}
          paginationTotalHeaderSize="h3"
          basic={data && data.length ? false : true}
          sizePerPageOption={data && data.length ? true : false}
        />
      </div>
    );
  };

  const closeModal = () => {
    setShowActivityModal(false);
    setActiveItem('sharedLog');
    setReplyMessage('');
    setModalReplyIndex(-1);
    setModalDetailsIndex(-1);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setEditModalDetails({});
    setSelectedGuideData({});
  };

  const closeShareModal = () => {
    setShowShareModal(false);
    setShareType('');
    setSharedExternalUsers([]);
    setSharedInternalUsers([]);
    setSharedInternalTeamUsers([]);
    setAssignedInternalTeamUser();
    setShareMessage('');
    setShareExpirationDate(null);
    setErrors({});
  };

  const activityModalView = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>
          <Menu vertical>
            <Menu.Item
              to="#"
              onClick={(e, data) => {
                e.preventDefault();
                setActiveItem('sharedLog');
              }}
              link
              as={Link}
            >
              External Shared Log
            </Menu.Item>
            <Menu.Item
              to="#"
              onClick={(e, data) => {
                e.preventDefault();
                setActiveItem('comments');
              }}
              link
              as={Link}
            >
              External Comments
            </Menu.Item>
            <Menu.Item
              to="#"
              onClick={(e, data) => {
                e.preventDefault();
                setActiveItem('internalConversation');
              }}
              link
              as={Link}
              ref={buttonRef}
            >
              Internal Shared Log
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column width={12}>
          {activeItem === 'internalConversation'
            ? renderInternalConversation()
            : activeItem === 'sharedLog'
            ? renderSharedWithTable()
            : renderExternalComments()}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  function stripHTML(html) {
    let div = document.createElement('div');

    // Convert <br> and <br/> tags to newline characters before stripping other HTML

    html = html.replace(/<br\s*\/?>/gi, '\n');

    div.innerHTML = html;

    return div.textContent || div.innerText || '';
  }

  const activityModal = () => (
    <AmsModal
      size="large"
      closeOnDimmerClick={false}
      onClose={closeModal}
      open={showActivityModal}
      className="ams-semantic-modal-fix"
    >
      <Header as="h2" content="Activity Dashboard" />
      <Modal.Content>
        <Modal.Description> {activityModalView()}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} content="Close" primary />
      </Modal.Actions>
    </AmsModal>
  );

  const handleEditModalDetails = (event, setter) =>
    setter({ ...editModalDetails, newValue: [event.target.value] });

  const editSummaryModalView = () => {
    const originalText =
      (editModalDetails &&
        editModalDetails.fetchedInformation &&
        editModalDetails.fetchedInformation.value[0]) ||
      '';
    const cleanText = stripHTML(originalText);

    return (
      <div>
        <p>Note: Start Date and End Date are not editable.</p>
        <span>
          {editModalDetails &&
            editModalDetails.fetchedInformation &&
            editModalDetails.fetchedInformation.headerText + ','}
        </span>
        <TextArea
          value={
            editModalDetails && editModalDetails.newValue
              ? editModalDetails.newValue
              : cleanText
          }
          onChange={e => {
            handleEditModalDetails(e, setEditModalDetails);
          }}
          rows={15}
          style={{ marginBottom: '20px', width: '100%' }}
        />
      </div>
    );
  };

  const editDistributionModalView = () => {
    if (!editModalDetails) return null;

    const handleChange = (index, field, value) => {
      // First, check if newValue exists, if not create it by copying fetchedInformation.value
      const newValue = editModalDetails.newValue
        ? [...editModalDetails.newValue]
        : [...editModalDetails.fetchedInformation.value];

      // Modify the specific role/name that was changed
      newValue[index][field] = value;

      // Update the editModalDetails state
      setEditModalDetails({
        ...editModalDetails,
        newValue: newValue,
      });
    };

    return (
      <div>
        <p>Copies of this report will be distributed to the following:</p>
        {(
          (editModalDetails && editModalDetails.newValue) ||
          (editModalDetails &&
            editModalDetails.fetchedInformation &&
            editModalDetails.fetchedInformation.value) ||
          []
        ).map(
          (item, index) =>
            !isEmpty(item) && (
              <div
                className="input-wrapper"
                key={index}
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  {item?.role}
                </span>
                <Input
                  style={{ flex: 1, marginRight: '10px' }}
                  placeholder="Name"
                  value={item?.name}
                  onChange={e => handleChange(index, 'name', e.target.value)}
                />
              </div>
            )
        )}
      </div>
    );
  };

  const editGrantNameModalView = () => {
    if (!editModalDetails) return null;

    const handleChange = (index, field, value) => {
      const newValue = editModalDetails.newValue
        ? [...editModalDetails.newValue]
        : [...editModalDetails.fetchedInformation.value];
      if (field === 'granteeName') {
        newValue[index][field] = value;
      }
      setEditModalDetails({
        ...editModalDetails,
        newValue: newValue,
      });
    };

    return (
      <div>
        {(
          (editModalDetails && editModalDetails.newValue) ||
          (editModalDetails &&
            editModalDetails.fetchedInformation &&
            editModalDetails.fetchedInformation.value) ||
          []
        ).map((item, index) => (
          <div
            key={index}
            style={{
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Input
              style={{ flex: 1 }}
              placeholder="Grantee Name"
              value={item.granteeName}
              onChange={e => handleChange(index, 'granteeName', e.target.value)}
            />
          </div>
        ))}
      </div>
    );
  };

  const editPerformanceMeasuresModalView = () => {
    if (!editModalDetails) return null;
    const renderDeleteConfirmation = () => {
      return (
        <AmsAlert
          show={showDeleteConfirmation}
          title="Delete Performance Measure"
          text={`Are you sure you want delete Performance Measure ?`}
          type={'error'}
          confirmButtonColor={'#db2828'}
          confirmButtonText={'Yes'}
          cancelButtonText={'No'}
          showConfirm
          showCancelButton
          onCancel={() => {
            setShowDeleteConfirmation(false);
          }}
          onConfirm={() => {
            const updatedValues = editModalDetails.newValue
              ? editModalDetails.newValue
              : editModalDetails.fetchedInformation.value;
            updatedValues.splice(deleteIndex, 1);
            setEditModalDetails({
              ...editModalDetails,
              newValue: updatedValues,
            });
            setShowDeleteConfirmation(false);
          }}
        />
      );
    };

    const handleEdit = (index, newValue) => {
      const updatedValues = editModalDetails.newValue
        ? editModalDetails.newValue
        : editModalDetails.fetchedInformation.value;
      updatedValues[index] = newValue;
      setEditModalDetails({
        ...editModalDetails,
        newValue: updatedValues.filter(str => str.trim() !== ''),
      });
    };

    const handleDelete = index => {
      setDeleteIndex(index);
      setShowDeleteConfirmation(true);
    };

    return (
      <div>
        {renderDeleteConfirmation()}
        <label>
          <span style={{ fontFamily: 'PublicSans-Bold' }}>
            Performance Area:{' '}
          </span>
          <span>{selectedGuideData.performanceArea}</span>
        </label>
        <List relaxed>
          {(
            (editModalDetails && editModalDetails.newValue) ||
            (editModalDetails &&
              editModalDetails.fetchedInformation &&
              editModalDetails.fetchedInformation.value) ||
            []
          ).map((value, index) => (
            <List.Item key={index}>
              <List.Content floated="right">
                <Icon name="trash" onClick={() => handleDelete(index)} />
              </List.Content>
              <List.Content>
                <Input
                  fluid
                  placeholder="Enter description Text"
                  style={{ flex: 1 }}
                  value={value}
                  onChange={(e, { value }) => handleEdit(index, value)}
                />
              </List.Content>
            </List.Item>
          ))}
        </List>
      </div>
    );
  };

  const editModal = () => {
    const activeLoader =
      showEditModal &&
      (!editModalDetails || !editModalDetails.fetchedInformation)
        ? true
        : false;

    const renderModalDescription = () => {
      if (editModalDetails?.type === 'Report Summary') {
        return editSummaryModalView();
      } else if (editModalDetails?.type === 'Report Distribution') {
        return editDistributionModalView();
      } else if (editModalDetails?.type === 'Grant Recipient Name') {
        return editGrantNameModalView();
      } else if (editModalDetails?.type === 'Performance Measure Statements') {
        return editPerformanceMeasuresModalView();
      }
    };

    return (
      <AmsModal
        size="large"
        closeOnDimmerClick={false}
        onClose={closeEditModal}
        open={showEditModal}
        className="ams-semantic-modal-fix"
      >
        <Header>
          {editModalDetails ? editModalDetails.type : ''}
          <Button
            icon="close"
            onClick={closeEditModal}
            style={{
              float: 'right',
              backgroundColor: 'white',
              padding: '0',
              fontSize: '20px',
              marginTop: '-10px',
              marginRight: '-10px',
            }}
            size="mini"
          />
        </Header>

        <Modal.Content>
          {activeLoader && (
            <Dimmer active inverted>
              <Loader content="Loading..." />
            </Dimmer>
          )}
          <Modal.Description> {renderModalDescription()}</Modal.Description>
        </Modal.Content>

        <Modal.Actions>
          <Button
            primary
            onClick={saveEditModal}
            content="Save"
            disabled={isEmpty(editModalDetails?.fetchedInformation?.value)}
          />
          <Button onClick={closeEditModal} content="Close without saving" />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const saveEditModal = () => {
    const newValue = editModalDetails.newValue
      ? editModalDetails.newValue
      : editModalDetails.fetchedInformation.value;
    setLoading(true);
    dispatch(
      saveReportDetails({
        reviewId: params.reviewId,
        category: editModalDetails.category,
        guideName: selectedGuideData?.guideName,
        performanceArea: selectedGuideData?.performanceArea,
        value: newValue,
      })
    ).then(data => {
      if (data) {
        setShowEditModal(false);
        setLoading(false);
      }
    });
    const validReviewTypes = ['FA2-CR', 'FA1-FR', 'Special'];
    const input = {
      reviewID: newReport.reviewID,
      reportContent: newReport.content,
      reportStatus: newReport.status,
      versionStatus: 'draft',
      saveType: 'editReport',
      who: user.oid,
      versionNumber: '0.01',
      reportRegenerated: false,
      when: AmsDateFormatters.getMoment().format(),
    };
    if (validReviewTypes.includes(newReport?.reviewType)) {
      dispatch(surveyReportSaveActionNew(input));
    }
  };

  const renderFiles = () => {
    return (
      <Comment.Group>
        <Header as="h3">
          {`Files (${commentsFileList &&
            commentsFileList.commentsFileList &&
            commentsFileList.commentsFileList.length})`}
        </Header>
        <List>
          {commentsFileList &&
            commentsFileList.commentsFileList &&
            commentsFileList.commentsFileList.map((file, key) => {
              if (isEmpty(file))
                return <List.Item key={key}>No Files Found</List.Item>;

              return (
                <List.Item
                  as="a"
                  key={key}
                  onClick={() =>
                    dispatch(
                      fetchFileContents(
                        {
                          binaryFileName: file.binaryFileName,
                          displayFileName: file.displayFileName,
                        },
                        true
                      )
                    )
                  }
                >
                  {file.displayFileName}
                </List.Item>
              );
            })}
        </List>
      </Comment.Group>
    );
  };

  const renderExternalComments = () => {
    return (
      <>
        <ReportExternalComments />
        {commentsFileList &&
          commentsFileList.commentsFileList &&
          commentsFileList.commentsFileList.length > 0 &&
          renderFiles()}
      </>
    );
  };

  const showAlertToggle = (alert, alertMessage, action) => {
    if ('finalize' === action) {
      if (noConfirmedGranteeOnFindingPageCheck()) {
        setShowMissingConfirmedGranteeAlert(true);
      } else {
        setShowAlert(alert);
        setAlertMessage(alertMessage);
        setActivityAction(action);
      }
    } else {
      setShowAlert(alert);
      setAlertMessage(alertMessage);
      setActivityAction(action);
    }
  };

  const renderSignReportModal = () => {
    const style = {
      content: { textAlign: 'center' },
      iconGroup: { display: 'block', marginBottom: '10px' },
      buttonGroup: { display: 'block', marginTop: '20px' },
    };

    return (
      <AmsModal
        role="alertdialog"
        size="tiny"
        className="ams-semantic-modal-fix"
        open={showAlert}
      >
        <Modal.Content style={style.content}>
          {' '}
          <Header>
            <Icon.Group size="big" style={style.iconGroup}>
              <Icon size="big" name="circle outline" color="yellow" />
              <Icon name="warning" color="yellow" />
            </Icon.Group>
          </Header>
          <p style={style.text}>
            You are about to sign the Report. Are you sure you want to continue?
          </p>
          <Form.Field
            style={{
              textAlign: 'left',
              margin: '30px',
            }}
          >
            <AmsFormLabel
              name="Select Authorized OHS Personnel as Signee"
              helpText={true}
              fieldLabel="selectSignee"
            >
              <Dropdown
                width={5}
                placeholder="Select Authorized OHS Personnel as Signee"
                onChange={(e, { value }) => {
                  setReportSignee(value);
                }}
                value={reportSignee}
                options={reportSigneeOptions}
                fluid
                search
                selection
                id="selectSignee"
                aria-labelledby="selectSignee"
                openOnFocus={false}
                searchInput={{
                  id: 'selectSignee',
                  title: 'Select Authorized OHS Personnel as Signee',
                  'aria-labelledby': 'selectSignee',
                }}
              />
            </AmsFormLabel>
          </Form.Field>
          <div style={style.buttonGroup}>
            <Button
              onClick={() => {
                //set report signee option back to default if user reopens sign dialog
                if (reportSignee && reportSignee.roleName !== 'Director') {
                  reportSigneeOptions.forEach(e => {
                    if (e.value.roleName === 'Director') {
                      setReportSignee(e.value);
                    }
                  });
                }

                setShowAlert(false);
              }}
            >
              Cancel
            </Button>

            <Button
              primary
              onClick={() => {
                setShowAlert(false);
                submitActivity();
              }}
            >
              Continue
            </Button>
          </div>
        </Modal.Content>
      </AmsModal>
    );
  };

  const showActivityConfirmationAlert = () => {
    if (activityAction === 'sign') {
      return renderSignReportModal();
    }

    return (
      <AmsAlert
        show={showAlert}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        confirmCancelText="No"
        confirmButtonText="Yes"
        confirmButtonColor={'#DD6B55'}
        text={alertMessage}
        onConfirm={() => {
          setShowAlert(false);
          submitActivity();
        }}
        onCancel={() => {
          setShowAlert(false);
        }}
      />
    );
  };
  const showMissingConfirmedGranteeWarningAlert = () => {
    const fiscalYear =
      selectedReview.fiscalYear &&
      selectedReview.fiscalYear.toString().slice(-2);
    const newFormReviewTypes = ['RAN', 'Special', 'Follow-up'];
    return (
      <AmsAlert
        show={showMissingConfirmedGranteeAlert}
        title=""
        type="warning"
        showConfirm
        confirmButtonText="Ok"
        confirmButtonColor={'#DD6B55'}
        text={
          <span>
            <span>
              There are one or more finding(s) for which grant(s) are not
              selected. Please go to the
            </span>
            {['FA2-CR', 'FA1-FR'].includes(selectedReview?.reviewType) ||
            (newFormReviewTypes.includes(selectedReview?.reviewType) &&
              selectedReview?.isNewForms) ? (
              <Link
                to={`/review/fy${fiscalYear}/${selectedReview.reviewId}/findings-analysis`}
              >
                {' '}
                Findings Analysis Page{' '}
              </Link>
            ) : (
              <Link
                to={`/review/fy${fiscalYear}/${selectedReview.reviewId}/findings`}
              >
                {' '}
                findings page{' '}
              </Link>
            )}
            <span>and associate grant(s) for the finding.</span>
          </span>
        }
        onConfirm={() => {
          setShowMissingConfirmedGranteeAlert(false);
        }}
      />
    );
  };

  const submitActivity = () => {
    let input = {
      reviewId: newReport.reviewID,
    };
    let version = newReport.reportVersion
      ? parseFloat(newReport.reportVersion)
      : 0.01;
    let increment = version * 100;
    const validReviewTypes = ['FA2-CR', 'FA1-FR'];
    const newFormReviewTypes = ['RAN', 'Special', 'Follow-up'];
    switch (activityAction) {
      case 'returnToManager':
        dispatch(
          fetchEmailReturnToFOM(input, result => {
            if (result instanceof Error) {
              setShowActivityInfoAlert(true);
              setActivityInfoType('error');
              setActivityInfoMessage(
                `'Error: ${result.message || 'unknown error'}`
              );
            } else {
              setShowActivityInfoAlert(true);
              setActivityInfoType('success');
              setActivityInfoMessage('Returned to Manager');
            }
          })
        );
        break;
      case 'submitToQAA':
        dispatch(
          fetchEmailNotifyQAARequest(input, result => {
            if (result instanceof Error) {
              setShowActivityInfoAlert(true);
              setActivityInfoType('error');
              setActivityInfoMessage(
                `'Error: ${result.message || 'unknown error'}`
              );
            } else {
              setShowActivityInfoAlert(true);
              setActivityInfoType('success');
              setActivityInfoMessage('Notification was submitted to QAA');
            }
          })
        );
        break;
      case 'returnToLead':
        dispatch(
          fetchEmailNotifyRLRequest(input, result => {
            if (result instanceof Error) {
              setShowActivityInfoAlert(true);
              setActivityInfoType('error');
              setActivityInfoMessage(
                `'Error: ${result.message || 'unknown error'}`
              );
            } else {
              setShowActivityInfoAlert(true);
              setActivityInfoType('success');
              setActivityInfoMessage('Notification was submitted to Lead');
            }
          })
        );
        break;
      case 'generateReport':
        dispatch(
          generateReportAction({
            reviewID: newReport.reviewID,
            reportID: newReport.reportID || undefined,
            reportContent: newReport.content,
            who: user.oid,
            when: AmsDateFormatters.getMoment().format(),
            versionStatus: newReport.status,
            reportRegenerated: newReport.reportVersion !== '1',
            versionNumber: newReport.reportVersion || 0.01,
            recreateReport: false,
          })
        );
        break;
      case 'finalize':
        setActivityAction('');
        const inputFinalize = {
          reviewID: newReport.reviewID,
          reportID: newReport.reportID || undefined,
          saveType: 'finalized',
          reportContent: newReport.content,
          reportStatus: newReport.status,
          versionStatus: 'finalized',
          who: user.oid,
          versionNumber: ++increment / 100,
          reportRegenerated: newReport.reportVersion !== '1',
          when: AmsDateFormatters.getMoment().format(),
        };
        if (
          validReviewTypes.includes(newReport?.reviewType) ||
          (newFormReviewTypes.includes(newReport?.reviewType) &&
            newReport?.isNewForms)
        ) {
          dispatch(surveyReportSaveActionNew(inputFinalize));
        } else {
          dispatch(reportSaveActionNew(inputFinalize));
        }
        break;
      case 'sign':
        const inputSigned = {
          reviewID: newReport.reviewID,
          reportID: newReport.reportID || undefined,
          saveType: 'signed',
          reportContent: newReport.content,
          reportStatus: newReport.status,
          reportSignatureAssignee: reportSignee,
          versionStatus: 'signed',
          who: user.oid,
          versionNumber: ++increment / 100,
          reportRegenerated: newReport.reportVersion !== '1',
          when: AmsDateFormatters.getMoment().format(),
        };
        if (
          validReviewTypes.includes(newReport?.reviewType) ||
          (newFormReviewTypes.includes(newReport?.reviewType) &&
            newReport?.isNewForms)
        ) {
          dispatch(surveyReportSaveActionNew(inputSigned));
        } else {
          dispatch(reportSaveActionNew(inputSigned));
        }
        break;
    }
  };

  const activityInfoAlert = () => {
    return (
      <AmsAlert
        show={showActivityInfoAlert}
        title=""
        type={activityInfoType}
        showConfirm
        confirmButtonText="Ok"
        confirmButtonColor={'#DD6B55'}
        text={activityInfoMessage}
        onConfirm={() => {
          setShowActivityInfoAlert(false);
        }}
      />
    );
  };

  const internalOptions = () => {
    return internalUsers
      .filter(user => {
        return (
          sharedInternalUsers &&
          sharedInternalUsers.filter(
            u => u.email === user.email && u.group === user.group
          ).length === 0
        );
      })
      .map(user => {
        // let name = [
        //   user.title,
        //   user.firstName,
        //   user.middleName,
        //   user.lastName,
        // ].join(' ');
        return {
          text: user.displayName,
          value: { email: user.email },
          key: user._id,
        };
      });
  };
  const internalTeamOptions = () => {
    return (
      internalTeamUsers &&
      internalTeamUsers.map(user => {
        return {
          text: user.displayName,
          value: user.oid,
          key: user.id,
        };
      })
    );
  };

  const externalOptions = () => {
    return externalUsers
      .filter(user => {
        return (
          sharedExternalUsers &&
          sharedExternalUsers.filter(
            u => u.email === user.email && u.group === user.group
          ).length === 0
        );
      })
      .map(item => {
        return {
          text:
            item.firstName +
            ' ' +
            item.lastName +
            ', ' +
            item.group +
            ', ' +
            item.region,
          value: { email: item.email, group: item.group },
          key: item._id,
        };
      });
  };

  const handleValidation = () => {
    const errors = {};

    if (shareType === 'InternalTeam') {
      if (isEmpty(shareExpirationDate)) {
        errors.shareExpirationDate = 'Due date is required';
      }
      if (!assignedInternalTeamUser) {
        errors.assignedTeamUser = 'Assignee is required';
      }
    } else if (shareType !== 'Grantee') {
      if (isEmpty(shareExpirationDate))
        errors.shareExpirationDate = 'Expiration date is required';
      if (isEmpty(sharedExternalUsers)) {
        errors.sharedExternalUsers = 'External User is required';
      }
    }
    return errors;
  };

  const shareReport = () => {
    const errors = handleValidation();
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setSending(true);
      let input =
        shareType !== 'Grantee'
          ? {
              reviewID: newReport.reviewID,
              reportID: newReport.reportID,
              versionNumber: (newReport || {}).reportVersion || null,
              reportKey: newReport.reportKey,
              message: shareMessage,
              sharedGroup: shareType,
              sharedBy: user.email,
              sharedInternal: sharedInternalUsers,
              sharedExternal: sharedExternalUsers,
              includeComments: true,
              sharedDate: new Date().toISOString(),
              reportLink: window.location.origin + '/add-comment',
              expirationDate: shareExpirationDate,
            }
          : {
              reviewID: newReport.reviewID,
              reportID: newReport.reportID,
              reportKey: newReport.reportKey,
              message: shareMessage,
              sharedGroup: shareType,
              granteeEmailList: [
                'jcamilineni_con@qssinc.com',
                'nkanand@qssinc.com',
              ],
              sharedBy: user.email,
              reportLink: window.location.origin + '/add-comment',
            };
      if (shareType === 'Grantee') {
        dispatch(
          reportSharePDFAction(input, result => {
            setSharedExternalUsers([]);
            setSharedInternalUsers([]);
            setShareMessage('');
            setShareType('');
            setShareExpirationDate(null);
            if (result instanceof Error) {
              setSending(false);
              setShowActivityInfoAlert(true);
              setActivityInfoType('error');
              setActivityInfoMessage(
                `'Error: ${result.response.data.message || 'unknown error'}`
              );
              setShareType('');
            } else {
              setSending(false);
              setShowShareModal(false);
              setShowActivityInfoAlert(true);
              setActivityInfoType('success');
              setActivityInfoMessage('The report has been shared');
            }
          })
        );
      } else {
        if (shareType === 'InternalTeam') {
          input.sharedWith = sharedInternalTeamUsers;
          input.assignedTo = assignedInternalTeamUser;

          if (assignedInternalTeamUser) {
            let stage;
            internalTeamUsers.find(e => {
              if (e.oid === assignedInternalTeamUser) {
                stage = e.reportStage;
              }
            });

            input.reportStage = stage || '';
          }
        }

        dispatch(
          reportShareAction(input, result => {
            if (result instanceof Error) {
              setSending(false);
              setShowActivityInfoAlert(true);
              setActivityInfoType('error');
              setActivityInfoMessage(
                `'Error: ${result.response.data.message || 'unknown error'}`
              );
            } else {
              setSharedExternalUsers([]);
              setSending(false);
              setSharedInternalUsers([]);
              setSharedInternalTeamUsers([]);
              setAssignedInternalTeamUser();
              setShareMessage('');
              setShareType('');
              setShareExpirationDate(null);
              setShowShareModal(false);
              setShowActivityInfoAlert(true);
              setActivityInfoType('success');
              setActivityInfoMessage('The report has been shared');
            }
          })
        );
      }
    }
  };

  const entityToChar = (str, elm) => {
    const el = document.createElement(elm);
    el.innerHTML = str;
    return el.value;
  };

  const shareReportForm = () => {
    const granteeEmails = [
      {
        text: 'Anand',
        value: {
          email: 'nkanand@qssinc.com',
          group: 'Grantee',
        },
        key: '1',
      },
      {
        text: 'Mohan',
        value: {
          email: 'jcamilineni_con@qssinc.com',
          group: 'Grantee',
        },
        key: '2',
      },
    ];

    const shareOptions = [
      {
        key: 'InternalTeam',
        text: 'Internal Team',
        value: 'InternalTeam',
        disabled:
          newReport &&
          (newReport.reportStage === 'signed' ||
            newReport.reportStage === 'Shipped' ||
            newReport.reportStage === 'finalized')
            ? true
            : false,
      },
      {
        key: 'OHS',
        text: 'OHS',
        value: 'OHS',
        description:
          newReport &&
          (newReport.reportStage === 'signed' ||
            newReport.reportStage === 'Shipped' ||
            newReport.reportStage === 'finalized')
            ? 'Report is finalized, you cannot share with OHS'
            : '',
        disabled:
          newReport &&
          (newReport.reportStage === 'signed' ||
            newReport.reportStage === 'Shipped' ||
            newReport.reportStage === 'finalized' ||
            (selectedReview &&
              selectedReview.reviewStatus === 'Report On Hold'))
            ? true
            : false,
      },
      {
        key: 'OGC',
        text: 'OGC',
        value: 'OGC',
        description:
          newReport &&
          (newReport.reportStage === 'signed' ||
            newReport.reportStage === 'Shipped' ||
            newReport.reportStage === 'finalized')
            ? 'Report is finalized, you cannot share with OGC'
            : '',
        disabled:
          newReport &&
          (newReport.reportStage === 'signed' ||
            newReport.reportStage === 'Shipped' ||
            newReport.reportStage === 'finalized' ||
            newReport.reviewType === 'AIAN-CLASS' ||
            newReport.reviewType === 'CLASS' ||
            newReport.reviewType === 'PR-CLASS' ||
            newReport.reviewType === 'VP-CLASS' ||
            newReport.reviewType === 'CLASS-Video' ||
            newReport.reviewType === 'AIAN CLASS Self-Observations' ||
            newReport.reviewType === 'CLASS AIAN Onsite' ||
            newReport.reviewType === 'CLASS AIAN Video' ||
            (selectedReview &&
              selectedReview.reviewStatus === 'Report On Hold'))
            ? true
            : false,
      },
      {
        key: 'R0',
        text: 'RO',
        value: 'RO',
        description:
          newReport &&
          (newReport.reportStage === 'signed' ||
            newReport.reportStage === 'Shipped' ||
            newReport.reportStage === 'finalized')
            ? 'Report is finalized, you cannot share with RO'
            : '',
        disabled:
          newReport &&
          (newReport.reportStage === 'signed' ||
            newReport.reportStage === 'Shipped' ||
            newReport.reportStage === 'finalized' ||
            newReport.reviewType === 'AIAN-CLASS' ||
            newReport.reviewType === 'CLASS' ||
            newReport.reviewType === 'PR-CLASS' ||
            newReport.reviewType === 'VP-CLASS' ||
            newReport.reviewType === 'CLASS-Video' ||
            newReport.reviewType === 'AIAN CLASS Self-Observations' ||
            newReport.reviewType === 'CLASS AIAN Onsite' ||
            newReport.reviewType === 'CLASS AIAN Video' ||
            (selectedReview &&
              selectedReview.reviewStatus === 'Report On Hold'))
            ? true
            : false,
      },
      {
        key: 'Grantee',
        text:
          newReport &&
          (newReport.reportStage === 'signed' ||
            newReport.reportStage === 'Shipped')
            ? 'Grantee'
            : 'Grantee - Report must be signed to share with Grantee',
        value: 'Grantee',
        disabled:
          newReport &&
          (newReport.reportStage === 'signed' ||
            newReport.reportStage === 'Shipped') &&
          selectedReview &&
          selectedReview.reviewStatus !== 'Report On Hold'
            ? false
            : true,
      },
    ];
    let filteredInternalTeamUsersOptions = [];
    if (assignedInternalTeamUser) {
      filteredInternalTeamUsersOptions = internalTeamUsersOptions.filter(e => {
        return e.value !== assignedInternalTeamUser;
      });
    }

    return (
      // <Form onSubmit={shareReport}>

      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <AmsFormLabel
              name="Share with"
              helpText={true}
              fieldLabel="shareWith"
            >
              <Dropdown
                width={5}
                placeholder="Share with"
                value={shareType}
                onChange={(e, { value }) => {
                  setShareType(value);
                }}
                options={shareOptions}
                fluid
                search
                selection
                // clearable
                id="shareWith"
                aria-labelledby="shareWith"
                searchInput={{
                  id: 'shareWithSearchInput',
                  title: 'search share with',
                  'aria-labelledby': 'shareWithSearchInput',
                }}
                openOnFocus={false}
              />
            </AmsFormLabel>
          </Form.Field>
        </Form.Group>
        {shareType && shareType === 'InternalTeam' ? (
          <>
            <Form.Group widths="equal">
              <Form.Field required>
                <AmsFormLabel
                  name={`To`}
                  helpText={true}
                  fieldLabel={`assignToInternalTeam`}
                >
                  <Dropdown
                    placeholder={`Assign to Internal Team member`}
                    value={assignedInternalTeamUser}
                    error={errors.assignedTeamUser}
                    onChange={(e, { value }) => {
                      setAssignedInternalTeamUser(value);

                      let copyShared = [...sharedInternalTeamUsers];
                      copyShared = copyShared.filter(e => {
                        return e !== value;
                      });
                      setSharedInternalTeamUsers(copyShared);
                    }}
                    options={internalTeamUsersOptions}
                    fluid
                    search
                    selection
                    // clearable
                    id={`shareWith${shareType}`}
                    aria-labelledby={`shareWith${shareType}`}
                    searchInput={{
                      id: `shareWith${shareType}SearchInput`,
                      title: `search share with ${shareType}`,
                      'aria-labelledby': `shareWith${shareType}SearchInput`,
                    }}
                    openOnFocus={false}
                  />
                </AmsFormLabel>
              </Form.Field>
              <Form.Field>
                <AmsFormLabel
                  name={`CC`}
                  helpText={true}
                  fieldLabel={`shareWithInteralTeam`}
                ></AmsFormLabel>
                <Dropdown
                  placeholder={`Share with Internal Team Members`}
                  value={sharedInternalTeamUsers}
                  onChange={(e, { value }) => {
                    setSharedInternalTeamUsers(value);
                  }}
                  options={
                    filteredInternalTeamUsersOptions || internalTeamUsersOptions
                  }
                  fluid
                  multiple
                  search
                  selection
                  // clearable
                  id={`shareWith${shareType}`}
                  aria-labelledby={`shareWith${shareType}`}
                  searchInput={{
                    id: `shareWith${shareType}SearchInput`,
                    title: `search share with ${shareType}`,
                    'aria-labelledby': `shareWith${shareType}SearchInput`,
                  }}
                  openOnFocus={false}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field
              required
              error={errors.shareExpirationDate}
              // isClearable
              selectsStart
              control={DatePicker}
              dateFormat="YYYY-MM-DD"
              placeholderText="Select Due Date"
              selected={
                shareExpirationDate &&
                AmsDateFormatters.getMoment(shareExpirationDate)
              }
              fromDate={new AmsDateFormatters.getMoment()}
              onChange={date => {
                if (date)
                  setShareExpirationDate(
                    AmsDateFormatters.getMoment(date).format('L')
                  );
                else setShareExpirationDate(null);
              }}
              minDate={new AmsDateFormatters.getMoment()}
              value={shareExpirationDate ? shareExpirationDate : ''}
              popperPlacement="top-start"
              id="dueDate"
              aria-labelledby="dueDate"
              label={{
                children: 'Due Date',
                htmlFor: 'dueDate',
              }}
            ></Form.Field>
            <Form.Field
              control={TextArea}
              rows="6"
              placeholder="your message ..."
              value={entityToChar(shareMessage, 'textarea')}
              onChange={e => {
                setShareMessage(e.target.value);
              }}
              id="message"
              aria-labelledby="message"
              label={{
                children: 'Message',
                htmlFor: 'message',
              }}
            ></Form.Field>
          </>
        ) : shareType && shareType !== 'Grantee' ? (
          <>
            <Form.Group widths="equal">
              <Form.Field>
                <AmsFormLabel
                  name="Share with DLH"
                  helpText={true}
                  fieldLabel="shareWithDLH"
                >
                  <Dropdown
                    placeholder="Share with DLH"
                    value={sharedInternalUsers}
                    onChange={(e, { value }) => {
                      setSharedInternalUsers(value);
                    }}
                    options={internalUsersOptions}
                    fluid
                    multiple
                    search
                    selection
                    // clearable
                    id="shareWithDLH"
                    aria-labelledby="shareWithDLH"
                    searchInput={{
                      id: 'shareWithDLHSearchInput',
                      title: 'search share with DLH',
                      'aria-labelledby': 'shareWithDLHSearchInput',
                    }}
                    openOnFocus={false}
                  />
                </AmsFormLabel>
              </Form.Field>
              <Form.Field required>
                <AmsFormLabel
                  name={`Share with ${shareType}`}
                  helpText={true}
                  fieldLabel={`shareWith${shareType}`}
                >
                  <Dropdown
                    placeholder={`Share with ${shareType}`}
                    value={sharedExternalUsers}
                    onChange={(e, { value }) => {
                      setSharedExternalUsers(value);
                    }}
                    options={externalUsersOptions}
                    fluid
                    error={errors.sharedExternalUsers}
                    multiple
                    search
                    selection
                    // clearable
                    id={`shareWith${shareType}`}
                    aria-labelledby={`shareWith${shareType}`}
                    searchInput={{
                      id: `shareWith${shareType}SearchInput`,
                      title: `search share with ${shareType}`,
                      'aria-labelledby': `shareWith${shareType}SearchInput`,
                    }}
                    openOnFocus={false}
                  />
                  {errors.sharedExternalUsers && (
                    <div class="ui pointing above prompt label">
                      {errors.sharedExternalUsers}
                    </div>
                  )}
                </AmsFormLabel>
              </Form.Field>
            </Form.Group>
            <Form.Field
              required
              error={errors.shareExpirationDate}
              // isClearable
              selectsStart
              control={DatePicker}
              dateFormat="YYYY-MM-DD"
              placeholderText="Select Expiration Date"
              selected={
                shareExpirationDate &&
                AmsDateFormatters.getMoment(shareExpirationDate)
              }
              fromDate={new AmsDateFormatters.getMoment()}
              onChange={date => {
                if (date)
                  setShareExpirationDate(
                    AmsDateFormatters.getMoment(date).format('L')
                  );
                else setShareExpirationDate(null);
              }}
              minDate={new AmsDateFormatters.getMoment()}
              value={shareExpirationDate ? shareExpirationDate : ''}
              popperPlacement="top-start"
              id="expirationDate"
              aria-labelledby="expirationDate"
              label={{
                children: 'Expiration Date',
                htmlFor: 'expirationDate',
              }}
            ></Form.Field>
            <Form.Field
              control={TextArea}
              rows="6"
              placeholder="your message ..."
              value={entityToChar(shareMessage, 'textarea')}
              onChange={e => {
                setShareMessage(e.target.value);
              }}
              id="message"
              aria-labelledby="message"
              label={{
                children: 'Message',
                htmlFor: 'message',
              }}
            ></Form.Field>
            <Well>
              <h3>Report File</h3>
              <p>
                HTML version of this report and its respective comment box will
                be attached and sent up on submission of this form.
              </p>
            </Well>
          </>
        ) : shareType && shareType === 'Grantee' ? (
          <>
            <Form.Field>
              <AmsFormLabel
                name={`${shareType} Contacts`}
                helpText={true}
                fieldLabel={`${shareType}Contacts`}
              >
                <Dropdown
                  placeholder={`Share with ${shareType}`}
                  value={granteeEmails.map(grantee => {
                    return grantee.value;
                  })}
                  options={granteeEmails}
                  fluid
                  multiple
                  search
                  selection
                  id={`${shareType}Contacts`}
                  aria-labelledby={`${shareType}Contacts`}
                  searchInput={{
                    id: `${shareType}ContactsSearchInput`,
                    title: ` ${shareType} contact search`,
                    'aria-labelledby': `${shareType}ContactsSearchInput`,
                  }}
                  openOnFocus={false}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field
              control={TextArea}
              rows="6"
              placeholder="your message ..."
              value={entityToChar(shareMessage, 'textarea')}
              onChange={e => {
                setShareMessage(e.target.value);
              }}
              id="message"
              aria-labelledby="message"
              label={{
                children: 'Message',
                htmlFor: 'message',
              }}
            ></Form.Field>
          </>
        ) : null}
      </Form>
    );
  };

  const shareModal = () => (
    <AmsModal
      size="large"
      closeOnDimmerClick={false}
      onClose={closeShareModal}
      open={showShareModal}
      className="ams-semantic-modal-fix"
    >
      <Header as="h2" content="Share" />
      <Modal.Content>
        <Modal.Description>{shareReportForm()}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeShareModal} content="Close" />
        <Button
          disabled={sending}
          onClick={() => {
            shareReport();
          }}
          primary
          content="Send"
        />
      </Modal.Actions>
    </AmsModal>
  );

  const actionButtons = (
    <>
      {newReport && newReport.revisions && newReport.revisions.length > 1 && (
        <List.Item>
          <List.Content>
            <div className="dropdown">
              <Button
                size="large"
                color="green"
                data-toggle="dropdown"
                as={Link}
                to={`#`}
              >
                Versions
                <span className="caret"></span>
              </Button>
              <div className="dropdown-menu">
                <Button.Group vertical fluid>
                  {versions.map(version => {
                    const omitVersionText = version.text.replace(
                      /V\s*\d+(\.\d+)?\s*-?\s*/,
                      ''
                    );

                    let dropdownText =
                      version.value.saveType === 'editReport' ? (
                        <>
                          <span style={{ fontFamily: 'PublicSans-ExtraBold' }}>
                            Version {version.value.reportVersion}:{' '}
                            {version.value.updatedBy &&
                              version.value.updatedBy.fullName}
                          </span>
                          {' made changes on '}
                          <span style={{ fontFamily: 'PublicSans-ExtraBold' }}>
                            {version.value.timeStamp}
                          </span>
                        </>
                      ) : (
                        <>
                          <span style={{ fontFamily: 'PublicSans-ExtraBold' }}>
                            Version {version.value.reportVersion}:{' '}
                          </span>
                          {omitVersionText}
                        </>
                      );

                    return (
                      <Button
                        style={{
                          background: 'none',
                          borderStyle: 'none',
                          textAlign: 'left',
                          color: 'black',
                          width: '306px',
                        }}
                        fluid
                        aria-label={version.text}
                        onClick={() => {
                          if (version.key !== params.reportKey) {
                            setParams({
                              ...params,
                              reportKey: version.key,
                            });

                            setVersionSaveType(version.value.saveType);
                            setVersionBannerText([
                              `Version ${version.value.reportVersion}: ${version
                                .value.updatedBy &&
                                version.value.updatedBy.fullName}`,
                              ' made changes on ',
                              `${version.value.timeStamp}`,
                            ]);
                          }
                        }}
                        className="dropdown-item"
                      >
                        <div>{dropdownText}</div>
                      </Button>
                    );
                  })}
                </Button.Group>
              </div>
            </div>
          </List.Content>
        </List.Item>
      )}
      <List.Item>
        <List.Content>
          <Button.Group size="large" color="blue">
            <Button
              disabled={selectedReview.reviewStatus === 'Cancelled'}
              content="Activity"
              icon="line graph"
              onClick={() => {
                setShowActivityModal(true);
              }}
            />
            <ReportActivityButtons
              disabled={
                selectedReview.reviewStatus === 'Cancelled' || readOnly === true
              }
              showAlertToggle={showAlertToggle}
            />
          </Button.Group>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <Popup
            position="bottom right"
            content={
              newReport.reportVersion &&
              latestVersion &&
              newReport.reportKey === latestVersion.reportKey
                ? ''
                : 'This is an older version of the report. You cannot share it with external users.'
            }
            trigger={
              <Button
                content="Share"
                color="blue"
                size="large"
                disabled={
                  selectedReview.reviewStatus === 'Cancelled' || readOnly
                }
                onClick={() => {
                  if (
                    newReport.reportVersion &&
                    latestVersion &&
                    newReport.reportKey === latestVersion.reportKey
                  ) {
                    if (noConfirmedGranteeOnFindingPageCheck()) {
                      setShowMissingConfirmedGranteeAlert(true);
                      setShowShareModal(false);
                    } else {
                      setShowShareModal(true);
                    }
                  } else {
                    setShowShareModal(false);
                  }
                }}
              />
            }
            disabled={
              newReport.reportVersion &&
              latestVersion &&
              newReport.reportKey === latestVersion.reportKey
                ? true
                : false
            }
          ></Popup>
        </List.Content>
      </List.Item>
    </>
  );

  const generateGranteeReplacedBanner = () => {
    const { reportStage } = newReport;
    let text = '';

    if (reportStage === 'signed') {
      return null;
    } else if (reportStage === 'finalized') {
      text =
        'Please contact the Helpdesk to move the review back in order to enable reload report.';
    } else {
      if (newReport.granteeUpdated === true) {
        text = `The Grantee details are updated, reload the report to reflect the latest changes.`;
      }
    }

    return (
      <Message
        style={{
          fontFamily: 'PublicSans-Bold',
        }}
        info
      >
        <Icon name="info" />
        <span>
          Note: {text}{' '}
          {reportStage !== 'finalized' && (
            <Link to={`/review/${newReport.reviewID}`}>
              Visit Review Detail Page to Reload.
            </Link>
          )}
        </span>
      </Message>
    );
  };

  const generateInternalConversationBanner = () => {
    const { status } = newReport;

    if (status !== 'Paused') {
      return null;
    }
    return (
      <div
        style={{
          background: '#ffbe2e',
          textAlign: 'left',
          color: '#000',
          paddingLeft: '0px',
          paddingRight: '0px',
          borderRadius: '5px',
          marginBottom: '1rem',
        }}
      >
        <Segment basic>
          <strong style={{ fontWeight: '700' }}>
            This report is currently paused.{' '}
            <Link
              to="#"
              onClick={e => {
                e.preventDefault();
              }}
            >
              See messages.
            </Link>
          </strong>{' '}
        </Segment>
      </div>
    );
  };

  const noConfirmedGranteeOnFindingPageCheck = () => {
    let isConfirmedGranteeMissing = false;
    const excludeFindingList = ['dropped', 'compliant'];
    if (
      selectedReview &&
      selectedReview.isMultigrant &&
      fetchedFindingDetails &&
      fetchedFindingDetails.findings &&
      Array.isArray(fetchedFindingDetails.findings)
    ) {
      fetchedFindingDetails.findings
        .filter(
          f =>
            !excludeFindingList.includes(f.findingStatus.toLowerCase()) &&
            'strongPractice' !== f?.collectDataType
        )
        .forEach(finding => {
          if (
            Array.isArray(finding.confirmedGrantees) &&
            isEmpty(finding.confirmedGrantees)
          ) {
            isConfirmedGranteeMissing = true;
            return isConfirmedGranteeMissing;
          }
        });
    }
    return isConfirmedGranteeMissing;
  };

  return (
    <>
      <ReviewLayout
        actionButtons={actionButtons}
        loading={{ loader: loading, loadingMessage: 'Loading reports...' }}
        sidebar={
          selectedReview.reviewType === 'CLASS' ||
          selectedReview.reviewType === 'AIAN-CLASS' ||
          selectedReview.reviewType === 'PR-CLASS' ||
          selectedReview.reviewType === 'VP-CLASS' ||
          selectedReview.reviewType === 'CLASS-Video' ||
          selectedReview.reviewType === 'AIAN CLASS Self-Observations' ||
          selectedReview.reviewType === 'CLASS AIAN Onsite' ||
          selectedReview.reviewType === 'CLASS AIAN Video' ? (
            <ReviewDetailCLASSSidebar />
          ) : null
        }
      >
        {newReport.reportVersion &&
          latestVersion &&
          newReport.reportKey !== latestVersion.reportKey && (
            <Message
              warning
              style={{
                height: versionSaveType === 'editReport' ? '106px' : 'inherit',
                padding: versionSaveType === 'editReport' ? '25px' : 'inherit',
                paddingLeft: '20px',
              }}
            >
              <Icon name="warning sign" />
              <span
                style={{
                  fontFamily:
                    versionSaveType === 'editReport'
                      ? 'PublicSans-Regular'
                      : 'PublicSans-Bold',
                }}
              >
                {versionSaveType !== 'editReport'
                  ? 'This is an older version of the report.'
                  : `You are viewing an older version of this report.`}
              </span>
              {versionSaveType === 'editReport' && (
                <span
                  style={{
                    display: 'block',
                    marginTop: '10px',
                    width: '98%',
                    float: 'right',
                  }}
                >
                  <span
                    style={{
                      fontFamily: 'PublicSans-Bold',
                    }}
                  >
                    {versionBannerText[0]}
                  </span>
                  {versionBannerText[1]}
                  <span
                    style={{
                      fontFamily: 'PublicSans-Bold',
                    }}
                  >
                    {versionBannerText[2]}
                  </span>
                </span>
              )}
            </Message>
          )}
        {reportStatusMessage && (
          <Message
            style={{
              fontFamily: 'PublicSans-Bold',
              paddingRight: '0px',
            }}
            info
          >
            <Icon name="info" />
            <span>{reportStatusMessage}</span>
            {newReport.internalConversations &&
              newReport.internalConversations.length > 0 && (
                <>
                  {' '}
                  <Link
                    to={'#'}
                    onClick={e => {
                      e.preventDefault();
                      setActiveItem('internalConversation');
                      setShowActivityModal(true);
                    }}
                  >
                    See Messages
                  </Link>
                </>
              )}
          </Message>
        )}
        {newReport.granteeUpdated === true && generateGranteeReplacedBanner()}
        {generateInternalConversationBanner()}
        {activityModal()}
        {editModal()}
        {shareModal()}
        {showActivityConfirmationAlert()}
        {activityInfoAlert()}
        {showMissingConfirmedGranteeWarningAlert()}
        {newReport && newReport.content && (
          <>
            <fieldset disabled={isValidReportStage()}>
              {(newReport.reportStage === 'signed' ||
                newReport.reportStage === 'Shipped') && (
                <span
                  role="img"
                  aria-label="Administration for Children & Families. Office of Head Start. 4th Floor - Switzer Memorial Building, 330 C Street SW, Washington DC 20024. e c l k c.o h s.a c f.h h s.gov"
                />
              )}
              <div dangerouslySetInnerHTML={{ __html: newReport.content }} />
            </fieldset>
          </>
        )}
      </ReviewLayout>
    </>
  );
}
