import React from 'react';
import { useDispatch } from 'react-redux';
import DropdownButton from './DropdownButton';
import { fetchQuestionSchemaJSONs } from '../../../../actions/surveyActions';

export const ImportQuestionsDropdown = props => {
  const dispatch = useDispatch();
  const onClick = (x, fiscalYear) => {
    const {
      setLoading,
      setTemplate,
      paginationOptions,
      setExistingQuestions,
      setShowImportModal,
      setResponseErrors,
      responseErrors,
    } = props;
    setLoading(true);
    setTemplate({ ...x, fiscalYear });
    const { sortName, sortOrder } = paginationOptions;
    let selectedFilters = {};
    let requestInput = {
      filters: selectedFilters,
      page: 1,
      limit: 10000,
      sortName,
      sortOrder,
    };
    dispatch(fetchQuestionSchemaJSONs(requestInput))
      .then(results => {
        var data = results.data.filter(
          d =>
            d.isActive &&
            d.attributesObj &&
            d.attributesObj.template === x?.value
        );
        setExistingQuestions(data);
        setShowImportModal(true);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setResponseErrors([...responseErrors, error.response]);
      });
  };

  return <DropdownButton onClick={onClick} label="Import Questions" />;
};

export default ImportQuestionsDropdown;
