import React from 'react';
import DropdownButton from './DropdownButton';

export const DownloadTemplateDropdown = () => {
  const onClick = (x, fiscalYear) => {
    window.location.href = `${process.env.PUBLIC_URL}/templates/${fiscalYear}/Template-${x.value}.xlsx`;
  };

  return <DropdownButton onClick={onClick} label="Download Template" />;
};

export default DownloadTemplateDropdown;
