import React from 'react';
import CheckboxListWidget from './CheckboxListWidget';
import SurveysCheckboxListWidget from './SurveysCheckboxListWidget';

export default function CheckboxListWidgetPaths(props) {
  const { schema, formContext } = props;
  return (
    <>
      {schema?.hasOwnProperty('readOnly') ? (
        <SurveysCheckboxListWidget {...props} />
      ) : (
        <CheckboxListWidget {...props} />
      )}
    </>
  );
}
