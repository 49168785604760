import React from 'react';
import RadioListWidget from './RadioListWidget';
import SurveysRadioListWidget from './SurveysRadioListWidget';

export default function RadioListWidgetPaths(props) {
  const { schema } = props;
  return (
    <>
      {schema?.hasOwnProperty('readOnly') ? (
        <SurveysRadioListWidget {...props} />
      ) : (
        <RadioListWidget {...props} />
      )}
    </>
  );
}
