import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

const SurveyQuestionLookups = ({
  target,
  disabled,
  options = [],
  nextDisabled,
  onChange,
  ...props
}) => {
  return (
    <Dropdown
      selectOnBlur={false}
      openOnFocus={false}
      options={options}
      disabled={!disabled}
      fluid
      search
      selection
      value={target.value}
      name={target.name}
      onChange={(ev, { name, value }) => {
        onChange({ ...target, value: value });
      }}
      {...props}
    />
  );
};

SurveyQuestionLookups.propTypes = {
  target: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  options: PropTypes.array,
  nextDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SurveyQuestionLookups;
