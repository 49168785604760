import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

export default function ReviewStatusItems({ reviews }) {
  const linkId = (cell, row) => {
    return <Link to={`/review/${cell}`}>{cell}</Link>;
  };

  const options = {};

  const columns = [
    {
      dataField: 'reviewId',
      formatter: linkId,
      isKey: true,
      sort: true,
      text: 'Review',
    },
    {
      dataField: 'workFlowStatus',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'manager',
      text: 'Manager',
      sort: true,
    },
    {
      dataField: 'progress',
      text: 'Progress',
      sort: true,
    },
  ];

  return (
    <BootstrapTable
      keyField="reviewId"
      trClassName="review-status-row"
      data={reviews}
      tableContainerClass="table-container"
      headerWrapperClasses="table-header"
      hover
      condensed
      bordered={false}
      options={options}
      pagination={reviews?.length > 10 ? paginationFactory() : undefined}
      columns={columns}
    />
  );
}
ReviewStatusItems.propTypes = {
  reviews: PropTypes.array.isRequired,
};
