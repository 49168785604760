import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Menu } from 'semantic-ui-react';

import _ from 'lodash';

// Import utils
import enforceRole from '../../utils/EnforceRole';
import AttachEvidence from './AttachEvidence';

// Import actions
import { fetchReportDraftTemplate } from '../../actions/reportActions';
import {
  approveClassSurveys,
  fetchClassSubmissionsDetail,
} from '../../actions/submissions';

// Import config
import { acl } from '../../config';

class ReviewDetailCLASSSidebar extends Component {
  componentDidMount = () => {
    this.requestedTemplate = false;
  };

  componentDidUpdate = () => {
    if (
      (this.props.selectedReview.reviewType === 'CLASS' ||
        this.props.selectedReview.reviewType === 'AIAN-CLASS' ||
        this.props.selectedReview.reviewType === 'PR-CLASS' ||
        this.props.selectedReview.reviewType === 'VP-CLASS' ||
        this.props.selectedReview.reviewType === 'CLASS-Video' ||
        this.props.selectedReview.reviewType ===
          'AIAN CLASS Self-Observations' ||
        this.props.selectedReview.reviewType === 'CLASS AIAN Onsite' ||
        this.props.selectedReview.reviewType === 'CLASS AIAN Video') &&
      !this.requestedTemplate
    ) {
      this.requestedTemplate = true;
      this.props.fetchReportDraftTemplate(
        { reviewId: this.props.selectedReview.reviewId },
        this.props.selectedReview.reviewType
      );
    }
  };
  renderTabButtons = () => {
    const classDetailButtons = [
      {
        key: 'Dual Coder Comparison Summary',
        to: `/review/${this.props.selectedReview.reviewId}/dual-comparison-summary`,
      },
      // { key: 'Observation Sample Summary' },
      {
        key: 'Evidence Binder',
        to: `/review/${this.props.selectedReview.reviewId}/evidence-binder`,
      },
      {
        key: 'Journal',
        to: `/review/${this.props.selectedReview.reviewId}/review-journal`,
      },
      {
        key: 'Manifest',
        to: `/review/${this.props.selectedReview.reviewId}/manifest`,
      },
      {
        key: 'Methodology Summary',
        to: `/review/${this.props.selectedReview.reviewId}/class-methodology`,
      },

      {
        key: 'Pass/Fail Summary',
        to: `/review/${this.props.selectedReview.fiscalYear}/${this.props.selectedReview.reviewId}/pass-fail-report`,
      },
    ];
    const {
      selectedReview: { reviewStatus },
    } = this.props;

    /* <Menu.Item
        key="ReviewChildFiles"
        to={{
          pathname: `/review/fy19/${selectedReview.reviewId}/survey-outcome/${
            selectedReview.childFileCount &&
            selectedReview.childFileCount.amsSubmissionId
              ? selectedReview.childFileCount.amsSubmissionId
              : 'no-submission-id'
          }/child-files`,
          query: { granteeId, granteeName, reviewType },
        }}
        link
        as={Link}
      >
        Child Files <Statistic.Value>{cardCount}</Statistic.Value>
      </Menu.Item>*/
    //  const { granteeName, granteeId } = this.props.selectedReview.grantees[0];
    const DetailButtons = classDetailButtons.map(buttonName => {
      const aclKey = `${_.camelCase(buttonName.key)}`;
      if (
        buttonName.key === 'Evidence Binder' ||
        buttonName.key === 'Manifest'
      ) {
        return (
          <Menu.Item as={Link} to={buttonName.to}>
            {buttonName.key}
          </Menu.Item>
        );
      } else if (buttonName.key === 'Journal') {
        return enforceRole(
          <Menu.Item
            as={Link}
            to={`/review/${this.props.selectedReview.reviewId}/review-journal`}
          >
            Journal
          </Menu.Item>,
          acl.actions.review['journal'],
          this.props.currentUser.roles
        );
      }
      return enforceRole(
        <Menu.Item
          key={aclKey}
          link
          as={Link}
          to={buttonName.to}
          disabled={
            reviewStatus.toLowerCase() === 'cancelled' &&
            aclKey === 'assignments'
          }
        >
          {buttonName.key}
        </Menu.Item>,
        acl.actions.classDetailButtons[aclKey],
        this.props.currentUser.roles
      );
    });

    DetailButtons.unshift(
      ((this.props.currentUser || {}).roles || []).indexOf('Reviewer') !== -1 ||
        ((this.props.currentUser || {}).roles || []).indexOf('ReviewLead') !==
          -1 ||
        ((this.props.currentUser || {}).roles || []).indexOf('DualCoder') !==
          -1 ? null : this.props.reportDraftTemplate ? (
        <Menu.Item
          key={'draftReport'}
          onClick={e => {
            e.preventDefault();
            let granteesIds = '';
            let granteesName = '';
            let chGrantees = (
              (this.props.selectedReview || {}).grantInfo || []
            ).filter(grantee => grantee.granteeId.includes('CH'));
            // console.log(chGrantees);
            let otherGrantees = (
              (this.props.selectedReview || {}).grantInfo || []
            ).filter(grantee => !grantee.granteeId.includes('CH'));
            // console.log(otherGrantees);
            if (!chGrantees.length) {
              // console.log('no CH grantees', otherGrantees);
              // eslint-disable-next-line array-callback-return
              otherGrantees.map((grantee, index) => {
                const sortedPreceedingGrantee =
                  grantee.precedingGrantee &&
                  _.sortBy(grantee.precedingGrantee, [
                    'granteeReplacementDate',
                  ]);
                if (index === 0) {
                  granteesName = grantee.granteeName;
                  granteesIds = grantee.granteeId;
                  if (
                    sortedPreceedingGrantee &&
                    sortedPreceedingGrantee.length > 0
                  ) {
                    granteesIds =
                      sortedPreceedingGrantee
                        .map(sortedGrantee => sortedGrantee.granteeId)
                        .join('/') +
                      '/' +
                      grantee.granteeId;
                  } else granteesIds = grantee.granteeId;
                } else {
                  if (
                    sortedPreceedingGrantee &&
                    sortedPreceedingGrantee.length > 0
                  ) {
                    granteesIds = granteesIds +=
                      ', ' +
                      sortedPreceedingGrantee
                        .map(sortedGrantee => sortedGrantee.granteeId)
                        .join('/') +
                      '/' +
                      grantee.granteeId;
                  } else granteesIds += ', ' + grantee.granteeId;
                }
              });
            } else {
              // console.log(granteesIds);
              // eslint-disable-next-line array-callback-return
              chGrantees.map((grantee, index) => {
                const sortedPreceedingGrantee =
                  grantee.precedingGrantee &&
                  _.sortBy(grantee.precedingGrantee, [
                    'granteeReplacementDate',
                  ]);
                if (index === 0) {
                  granteesName = grantee.granteeName;
                  if (
                    sortedPreceedingGrantee &&
                    sortedPreceedingGrantee.length > 0
                  ) {
                    granteesIds =
                      sortedPreceedingGrantee
                        .map(sortedGrantee => sortedGrantee.granteeId)
                        .join('/') +
                      '/' +
                      grantee.granteeId;
                  } else granteesIds = grantee.granteeId;
                } else {
                  if (
                    sortedPreceedingGrantee &&
                    sortedPreceedingGrantee.length > 0
                  ) {
                    granteesIds = granteesIds +=
                      ', ' +
                      sortedPreceedingGrantee
                        .map(sortedGrantee => sortedGrantee.granteeId)
                        .join('/') +
                      '/' +
                      grantee.granteeId;
                  } else granteesIds += ', ' + grantee.granteeId;
                }
              });

              if (otherGrantees.length) {
                // eslint-disable-next-line array-callback-return
                otherGrantees.map(otherGrantee => {
                  granteesIds += ', ' + otherGrantee.granteeId;
                });
              }
            }

            let grantees = `${granteesName} (${granteesIds})`;
            // eslint-disable-next-line no-unused-vars
            let content = this.props.reportDraftTemplate;
            try {
              // fix encoding
              content = decodeURIComponent(escape(content));
            } catch (err) {}
            let htmlPage =
              // eslint-disable-next-line no-multi-str
              '<!DOCTYPE html>\n\
                <head>\n\
                  <meta charset="utf-8">\n\
                  <base href="' +
              window.location.href +
              // eslint-disable-next-line no-multi-str
              '">\n\
                  <title>Report Preview</title>\n\
                  ' +
              (() => {
                return Array.prototype.slice
                  .call(document.getElementsByTagName('style'))
                  .map(style => {
                    return (
                      '<style type="text/css">' + style.textContent + '</style>'
                    );
                  })
                  .join('\n');
              })() +
              // eslint-disable-next-line no-multi-str
              '\n\
                  ' +
              (() => {
                return Array.prototype.slice
                  .call(document.getElementsByTagName('link'))
                  .filter(link => {
                    return link.getAttribute('rel') === 'stylesheet';
                  })
                  .map(link => {
                    return link.getAttribute('href');
                  })
                  .filter(url => {
                    return !!url;
                  })
                  .map(url => {
                    // eslint-disable-next-line no-useless-escape
                    if (!url.match(/^http(s|)\:\/\//))
                      return window.location.origin + url;
                    return url;
                  })
                  .map(url => {
                    return (
                      '<link type="text/css" rel="stylesheet" href="' +
                      url +
                      '">'
                    );
                  })
                  .join('\n');
              })() +
              // eslint-disable-next-line no-multi-str
              '\n\
                </head>\n\
                <body>' +
              // eslint-disable-next-line no-multi-str
              '<div class="review-preview-template">\
                    <div class="review-preview-template--bg animated fadeIn"></div>\
                    <div class="review-preview-template--container animated slideInRight">\
                      <div class="review-preview-template--content" grantee-id="' +
              grantees +
              // eslint-disable-next-line no-multi-str
              '">\
                        <button class="btn btn-sm btn-primary review-preview-template--close" onclick="window.close()">Close</button>\
                        <span role="img" aria-label="Administration for Children & Families. Office of Head Start. 4th Floor - Switzer Memorial Building, 330 C Street SW, Washington DC 20024. e c l k c.o h s.a c f.h h s.gov"> </span><div class="report-item--content">' +
              this.props.reportDraftTemplate.content +
              // eslint-disable-next-line no-multi-str
              '</div>\
                      </div>\
                    </div>\
                  </div>' +
              // eslint-disable-next-line no-multi-str
              '\n\
                </body>\n\
                </html>';
            let blob = new Blob([htmlPage], { type: 'text/html' });
            let url = URL.createObjectURL(blob);
            window.open(
              url,
              'preview-template',
              'width=' +
                Math.ceil(window.screen.width * 0.8) +
                ',height=' +
                Math.ceil(window.screen.height * 0.8)
            );
          }}
          as={Link}
          link
          to="#"
        >
          Draft Report
        </Menu.Item>
      ) : (
        <Menu.Item key={'draftReport'}>Loading Report Preview...</Menu.Item>
      )
    );
    DetailButtons.unshift(<AttachEvidence source="class" key="evidence" />);
    DetailButtons.unshift(
      enforceRole(
        <Menu.Item
          key={'assignments'}
          link
          as={Link}
          to={`/review/${this.props.selectedReview.reviewId}/center-and-class-assignments`}
          disabled={reviewStatus.toLowerCase() === 'cancelled'}
        >
          {'Assignments'}
        </Menu.Item>,
        acl.actions.classDetailButtons['assignments'],
        this.props.currentUser.roles
      )
    );

    return DetailButtons;
  };

  render() {
    return (
      <>
        {this.props.selectedReview.reviewType === 'CLASS' ||
        this.props.selectedReview.reviewType === 'AIAN-CLASS' ||
        this.props.selectedReview.reviewType === 'PR-CLASS' ||
        this.props.selectedReview.reviewType === 'VP-CLASS' ||
        this.props.selectedReview.reviewType === 'CLASS-Video' ||
        this.props.selectedReview.reviewType ===
          'AIAN CLASS Self-Observations' ||
        this.props.selectedReview.reviewType === 'CLASS AIAN Onsite' ||
        this.props.selectedReview.reviewType === 'CLASS AIAN Video'
          ? this.renderTabButtons()
          : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    users: state.users.users,
    classSubmissionsDetail: state.submissions.classSubmissionsDetail,
    reportDraftTemplate: state.report.reportDraftTemplate,
    selectedReview: state.review.selectedReview,
  };
}

export default connect(mapStateToProps, {
  fetchClassSubmissionsDetail,
  approveClassSurveys,
  fetchReportDraftTemplate,
})(ReviewDetailCLASSSidebar);
