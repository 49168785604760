import { filter, find, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Dimmer,
  Divider,
  Form,
  Grid,
  Header,
  Label,
  Loader,
  Menu,
  Tab,
  Accordion,
  Icon,
} from 'semantic-ui-react';

import { updateReview } from '../../../actions/reviewActions';
import {
  fetchSpecialSurveyAssignments,
  updateSpecialSurveyAssignments,
} from '../../../actions/specialActions';

const ReviewGuidesAccordionForm = props => {
  const {
    sendEmail,
    resendEmail,
    reviewId,
    reviewType,
    editReview,
  } = useSelector(state => state.review.reviewInfo);
  const [pms, setPms] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState();
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const renderGuidesAsAccordion = () => {
    const { guides } = pms;

    return guides?.map((guide, index) => {
      return (
        <div
          key={guide.name}
          style={{
            width: '98%', // Adjust the width as necessary
            marginLeft: '-1px',
            marginTop: 20,
            borderWidth: '0px 1px 1px 1px',
            borderStyle: 'solid',
            borderColor: '#D9D9D9',
          }}
        >
          <Accordion fluid>
            <Accordion.Title
              active={activeIndex === index}
              index={index}
              onClick={handleClick}
              style={{ backgroundColor: '#DCDCDC', padding: '10px' }}
            >
              <h3 style={{ margin: '0' }}>
                {guide.name}
                <Label style={{ marginLeft: '15px' }} size="mini" color="blue">
                  {
                    guide?.performanceAreas?.filter(
                      p => p.isActive && p.validProgramType
                    ).length
                  }
                </Label>
                <Icon
                  style={{ float: 'right' }}
                  name={activeIndex === index ? 'minus' : 'plus'}
                />
                {/* Include any additional buttons or icons here */}
              </h3>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === index}>
              {renderPerformanceAreas(guide)}
            </Accordion.Content>
          </Accordion>
        </div>
      );
    });
  };

  useEffect(() => {
    if (props.reviewId) {
      setLoading(true);
      dispatch(fetchSpecialSurveyAssignments({ reviewId: props.reviewId }))
        .then(pms => {
          setPms(pms.assignments);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reviewId]);

  useEffect(() => {}, [pms]);

  const handleToggleAll = (isActive, guide) => {
    props.dataHasChangedSwitch(true);
    const updatedGuides = pms.guides.map(g => {
      if (g.name === guide.name) {
        const updatedPerformanceAreas = g.performanceAreas.map(pm => ({
          ...pm,
          isActive: isActive,
        }));
        return { ...g, performanceAreas: updatedPerformanceAreas };
      }
      return g;
    });

    setPms({ ...pms, guides: updatedGuides });
  };

  const handlePMChange = (performanceArea, guide) => {
    props.dataHasChangedSwitch(true);
    const updatedGuides = pms.guides.map(g => {
      if (g.name === guide.name) {
        const updatedPerformanceAreas = g.performanceAreas.map(pm => {
          if (pm.performanceArea === performanceArea) {
            return { ...pm, isActive: !pm.isActive };
          }
          return pm;
        });
        return { ...g, performanceAreas: updatedPerformanceAreas };
      }
      return g;
    });

    setPms({ ...pms, guides: updatedGuides });
  };

  const renderPerformanceAreas = guide => {
    const allChecked =
      guide.performanceAreas?.filter(p => p.validProgramType)?.length > 0 &&
      guide.performanceAreas.every(pm => pm.isActive);

    const noneChecked =
      guide.performanceAreas?.filter(p => p.validProgramType)?.length >= 0 &&
      guide.performanceAreas.every(pm => !pm.isActive);
    const isCenterClassroom =
      guide.name.toLowerCase().trim() == 'classroom exploration' ||
      guide.name.toLowerCase().trim() == 'center exploration';
    return (
      <div key={guide.name} style={{ marginLeft: '15px' }}>
        <Form.Field
          control={Checkbox}
          label={
            <label style={{ fontWeight: 'bold' }}>
              <h4>Performance Areas</h4>
            </label>
          }
          checked={allChecked}
          indeterminate={!allChecked && !noneChecked ? true : false}
          onChange={(e, { checked }) => handleToggleAll(checked, guide)}
          disabled={isEmpty(
            guide.performanceAreas?.filter(p => p.validProgramType)
          )}
        />
        {isCenterClassroom &&
          guide.performanceAreas.map((p, i) => (
            <Form.Field
              key={`${guide.name}-${i}`}
              control={Checkbox}
              label={
                p.validProgramType
                  ? p.performanceArea
                  : `${p.performanceArea} (To enable this performance area for this grantee, please contact the Help Desk.)`
              }
              checked={p.isActive && p.validProgramType}
              onChange={e => {
                e.preventDefault();
                return;
              }}
              style={{ paddingLeft: '20px' }} // Indent child checkboxes
              id={`${guide.name}-${i}`}
              name={`${guide.name}-${i}`} // Use the appropriate name here
              value={p.performanceArea} // Use the appropriate value here
              disabled={!p.validProgramType}
            />
          ))}

        {!isCenterClassroom &&
          guide.performanceAreas.map((p, i) => (
            <Form.Field
              key={`${guide.name}-${i}`}
              control={Checkbox}
              label={
                p.validProgramType
                  ? p.performanceArea
                  : `${p.performanceArea} (To enable this performance area for this grantee, please contact the Help Desk.)`
              }
              checked={p.isActive && p.validProgramType}
              onChange={e => {
                handlePMChange(p.performanceArea, guide);
              }}
              style={{ paddingLeft: '20px' }} // Indent child checkboxes
              id={`${guide.name}-${i}`}
              name={`${guide.name}-${i}`} // Use the appropriate name here
              value={p.performanceArea} // Use the appropriate value here
              disabled={!p.validProgramType}
            />
          ))}
      </div>
    );
  };

  useEffect(() => {
    if (props.saveRequest === true) {
      savePMS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.saveRequest]);

  const savePMS = exit => {
    dispatch(updateSpecialSurveyAssignments(pms))
      .then(response => {
        if (props.saveRequest === true) {
          props.dataHasChangedSwitch('successSave');
          if (exit) props.router.push(`/review/${props.reviewId}`);
          return;
        }
        if (exit) props.router.push(`/review/${props.reviewId}`);
        if (
          response &&
          response.assignments &&
          response.assignments.result.toLowerCase() === 'success'
        )
          props.goToStep('team', props.reviewId, true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader active={loading} inverted />
      </Dimmer>
      <Header>Select Guides and Performance Areas</Header>

      {renderGuidesAsAccordion()}

      <Divider />
      {
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <Button
                form="createReviewForm"
                primary
                content={editReview ? 'Save and Continue' : 'Save and Exit'}
                onClick={() => {
                  savePMS(!editReview);
                }}
                size="huge"
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Button
                form="createReviewForm"
                content="Exit without saving"
                onClick={e => {
                  e.preventDefault();
                  if (
                    reviewType === 'Special' ||
                    reviewType === 'AIAN-DEF' ||
                    reviewType === 'ERSEA' ||
                    reviewType === 'RAN'
                  ) {
                    let data = { ...props.previousReviewInfo };
                    data['sendEmail'] = resendEmail === true ? true : false;
                    if (resendEmail && !sendEmail && reviewId && props.editMode)
                      dispatch(updateReview(data));
                  }
                  props.router.push(`/review/${props.reviewId}`);
                }}
                size="huge"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
    </>
  );
};

export default ReviewGuidesAccordionForm;
