import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import {
  fetchContentAreaLookups,
  fetchPerformanceAreaLookups,
  fetchPerformanceMeasureLookups,
} from '../actions/surveyActions';

const fetchConfig = {
  content_area: {
    fetchFunction: fetchContentAreaLookups,
    getPayload: target => ({
      fiscalYear: [target.fiscalYear],
      reviewType: target.reviewType,
      contentArea: target.contentArea,
      performanceArea: target.performanceArea,
    }),
  },
  performance_area: {
    fetchFunction: fetchPerformanceAreaLookups,
    getPayload: target => ({
      fiscalYear: [target.fiscalYear],
      reviewType: target.reviewType,
      contentArea: target.contentArea,
      performanceArea: target.performanceArea,
    }),
  },
  performance_measure: {
    fetchFunction: fetchPerformanceMeasureLookups,
    getPayload: target => ({
      fiscalYear: [target.fiscalYear],
      reviewType: target.reviewType,
      contentArea: target.contentArea,
      performanceArea: target.performanceArea,
    }),
  },
};

export const withSurveyCascadingDropdowns = Component => {
  const WrappedComponent = ({ target, disabled, ...props }) => {
    const [options, setOptions] = useState([]);
    const [nextDisabled, setNextDisabled] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
      if (!disabled && target.name && fetchConfig[target.name]) {
        const { fetchFunction, getPayload } = fetchConfig[target.name];
        dispatch(fetchFunction(getPayload(target))).then(data => {
          setOptionsByName(target.name, data);
          setNextDisabled(false);
        });
      }
    }, [target, disabled]);

    const setOptionsByName = (targetName, data) => {
      switch (targetName) {
        case 'content_area':
          setOptions(
            data?.contentAreaLookups?.map(item => ({
              key: item,
              text: item,
              value: item,
            }))
          );
          break;
        case 'performance_area':
          setOptions(
            data?.performanceAreaLookups?.map(item => ({
              key: item,
              text: item,
              value: item,
            }))
          );
          break;
        case 'performance_measure':
          setOptions(
            data?.performanceMeasureLookups?.map(item => ({
              key: item,
              text: item,
              value: item,
            }))
          );
          break;
        default:
          break;
      }
    };

    return (
      <Component
        {...props}
        target={target}
        disabled={!disabled}
        options={options}
        nextDisabled={nextDisabled}
      />
    );
  };

  WrappedComponent.propTypes = {
    target: PropTypes.object,
    disabled: PropTypes.bool.isRequired,
  };

  return WrappedComponent;
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
  fetchContentAreaLookups,
  fetchPerformanceAreaLookups,
  fetchPerformanceMeasureLookups,
})(withSurveyCascadingDropdowns);
