import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filters from '../../utils/Filters';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

// Import actions
import { fetchSurvey } from '../../actions/rtlSurveyActions';

class RtlSurveyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.currentUser.oid || '',
      startDate: AmsDateFormatters.getMoment()
        .startOf('day')
        .subtract(30, 'days')
        .format(),
      endDate: AmsDateFormatters.getMoment()
        .endOf('day')
        .format(),
    };

    this.dateFormatter = this.dateFormatter.bind(this);
  }

  componentWillMount() {
    const { userId, startDate, endDate } = this.state;

    if (userId) {
      this.props.fetchSurvey({ userId, startDate, endDate });
    }
  }

  dateFormatter(cell, row) {
    return AmsDateFormatters.formatDate(cell);
  }

  prepareRtlSurveyList() {
    const { surveys } = this.props;

    const options = {
      sizePerPage: 5,
      hideSizePerPage: true,
    };

    const columns = [
      {
        hidden: true,
        dataField: 'reviewId',
        isKey: true,
        sort: true,
        text: 'ReviewID',
      },
      {
        dataField: 'reviewer',
        text: 'Reviewer',
        sort: true,
      },
      {
        dataField: 'completedsurvey',
        text: 'Completed Survey',
        sort: true,
      },
      {
        dataField: 'startDate',
        text: 'Start Date',
        formatter: this.dateFormatter,
        sort: true,
      },
      {
        dataField: 'endDate',
        text: 'End Date',
        formatter: this.dateFormatter,
        sort: true,
      },
    ];

    return (
      <BootstrapTable
        keyField="reviewId"
        data={surveys}
        tableContainerClass="table-container"
        headerWrapperClasses="table-header"
        options={options}
        hover
        condensed
        bordered={false}
        pagination={options?.length > 10 ? paginationFactory() : undefined}
        bodyClasses="table-body"
        columns={columns}
      />
    );
  }

  refreshTable(filters) {}

  render() {
    return (
      <div className="panel panel-white">
        <div className="panel-body--old">
          <Filters filterData={this.refreshTable} />
          {this.prepareRtlSurveyList()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    surveys: state.survey.surveys,
    currentUser: state.auth.user,
  };
}

RtlSurveyList.propTypes = {
  surveys: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  fetchSurvey: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { fetchSurvey })(RtlSurveyList);
