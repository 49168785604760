import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router';
import { isEmpty } from 'lodash';

const ReviewEvidenceBinder = ({ selectedReview }) => {
  if (
    isEmpty(selectedReview.reviewId) ||
    selectedReview.reviewType === 'CLASS' ||
    selectedReview.reviewType === 'AIAN-CLASS' ||
    selectedReview.reviewType === 'PR-CLASS' ||
    selectedReview.reviewType === 'VP-CLASS' ||
    selectedReview.reviewType === 'CLASS-Video' ||
    selectedReview.reviewType === 'AIAN CLASS Self-Observations' ||
    selectedReview.reviewType === 'CLASS AIAN Onsite' ||
    selectedReview.reviewType === 'CLASS AIAN Video'
  )
    return null;

  return (
    <Menu.Item
      as={Link}
      to={`/review/${selectedReview.reviewId}/evidence-binder`}
    >
      Evidence Binder
    </Menu.Item>
  );
};

ReviewEvidenceBinder.propTypes = {
  selectedReview: PropTypes.object,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
});

export default connect(mapStateToProps)(ReviewEvidenceBinder);
