/* eslint-disable react/prop-types,react/destructuring-assignment */
import React, { useState } from 'react';
import { Button, Grid, Segment, Icon, Accordion } from 'semantic-ui-react';
import { utils } from '@rjsf/core';
import { cleanClassNames, getSemanticProps, MaybeWrap } from './utils';
import AmsAlert from '../../../utils/AmsAlert';
import { exportEligiblilityFiles } from '../../../actions/submissions';
import { useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router';
const { isFixedItems } = utils;

const ArrayFieldTitle = ({ TitleField, idSchema, uiSchema, title }) => {
  if (!title) {
    return null;
  }

  const id = `${idSchema.$id}__title`;
  return (
    <TitleField
      id={id}
      className="array-title"
      title={title}
      options={uiSchema['ui:options']}
    />
  );
};

function ArrayFieldDescription({ DescriptionField, idSchema, description }) {
  if (!description) {
    // See #312: Ensure compatibility with old versions of React.
    return null;
  }
  const id = `${idSchema.$id}__description`;
  return (
    <DescriptionField
      id={id}
      className="array-description"
      description={description}
    />
  );
}

const gridStyle = vertical => ({
  display: 'grid',
  gridTemplateColumns: `1fr ${vertical ? 65 : 110}px`,
});

// checks if its the first array item
function isInitialArrayItem(props) {
  // no underscore because im not sure if we want to import a library here
  const { idSchema } = props.children.props;
  return idSchema.target && idSchema.conditions;
}

/*const [showDeleteAlert, setShowDeleteAlert] = useState(false);

const renderDeleteItemAlert = props => {
  return (
    <AmsAlert
      show={showDeleteAlert}
      title="Are you sure you want to delete this finding"
      text={`Are you sure you want to delete this finding`}
      type={'warning'}
      confirmButtonColor={'#112e51'}
      confirmButtonText={'Delete'}
      cancelButtonText={'Cancel'}
      showConfirm
      showCancelButton
      onCancel={() => {
        setShowDeleteAlert(false);
      }}
      onConfirm={() => {
        props.onDropIndexClick(props.index);
        setShowDeleteAlert(false);
      }}
    />
  );
};
 const [activeIndexes, setActiveIndexes] = useState([]);
  const handleAccordionClick = index => {
    setActiveIndexes(prevIndexes => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter(item => item !== index);
      } else {
        return [...prevIndexes, index];
      }
    });
  };*/

// Used in the two templates
function DefaultArrayItem(props) {
  return (
    <div className="array-item" key={props.key}>
      <div className="row">
        <div className="col-md-9">
          <h5 className="array-item-label">
            {props.itemLabel + ' ' + (props.index + 1)}
          </h5>
        </div>
        <div className="col-md-3">
          <div style={{ textAlign: 'right' }}>
            {props.hasToolbar && props.hasRemove && (
              <Button.Group size="mini" vertical={false}>
                {props.hasRemove && (
                  <Button
                    className="array-item-remove"
                    tabIndex="-1"
                    icon
                    size="tiny"
                    labelPosition="left"
                    disabled={props.disabled || props.readOnly}
                    onClick={props.onDropIndexClick(props.index)}
                    style={{
                      background: '#db2828',
                      color: '#fff',
                    }}
                  >
                    <Icon name="trash" />
                    Delete {props.itemLabel + ' ' + (props.index + 1)}
                  </Button>
                )}
              </Button.Group>
            )}
          </div>
        </div>
      </div>
      <MaybeWrap wrap={props.wrapItem} component={Segment}>
        <Grid
          style={
            !isInitialArrayItem(props)
              ? { ...gridStyle(!props.horizontalButtons), alignItems: 'center' }
              : gridStyle(!props.horizontalButtons)
          }
        >
          <Grid.Column width={16} verticalAlign="middle">
            {props.children}
          </Grid.Column>
        </Grid>
      </MaybeWrap>
    </div>
  );
}

// Used for arrays that are represented as multiple selection fields
// (displayed as a multi select or checkboxes)
function DefaultFixedArrayFieldTemplate({
  uiSchema,
  idSchema,
  canAdd,
  className,
  classNames,
  disabled,
  items,
  onAddClick,
  readOnly,
  required,
  schema,
  title,
  TitleField,
  itemProps,
}) {
  const fieldTitle = uiSchema['ui:title'] || title;
  const fieldDescription = uiSchema['ui:description'] || schema.description;
  const itemLabel = schema.category || 'Item';
  return (
    <div className={cleanClassNames([className, classNames])}>
      <ArrayFieldTitle
        key={`array-field-title-${idSchema.$id}`}
        TitleField={TitleField}
        idSchema={idSchema}
        uiSchema={uiSchema}
        title={fieldTitle}
        required={required}
      />

      {fieldDescription && (
        <div
          className="field-description"
          key={`field-description-${idSchema.$id}`}
        >
          {fieldDescription}
        </div>
      )}

      <div key={`array-item-list-${idSchema.$id}`}>
        <div className="row array-item-list">
          {items &&
            items.map(p => DefaultArrayItem({ ...p, ...itemProps, itemLabel }))}
        </div>

        {canAdd && (
          <div
            style={{
              position: 'relative',
              textAlign: 'left',
            }}
          >
            <Button
              onClick={onAddClick}
              disabled={disabled || readOnly}
              icon
              size="tiny"
              labelPosition="left"
              style={{
                background: '#337ab7',
                color: '#fff',
              }}
            >
              <Icon name="plus" />
              Add {itemLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function DefaultNormalArrayFieldTemplate({
  uiSchema,
  idSchema,
  canAdd,
  className,
  classNames,
  disabled,
  DescriptionField,
  items,
  onAddClick,
  readOnly,
  required,
  schema,
  title,
  TitleField,
  itemProps,
  formContext,
}) {
  const dispatch = useDispatch();
  const fieldTitle = uiSchema['ui:title'] || title;
  const fieldDescription = uiSchema['ui:description'] || schema.description;
  const itemLabel = schema.category || 'Item';
  const category = schema.category;
  const [activeIndexes, setActiveIndexes] = useState([0]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Number of items per page
  const start = currentPage * itemsPerPage;
  const end = start + itemsPerPage;
  const paginatedItems = items; //.slice(start, end);

  const totalPages = Math.ceil(items.length / itemsPerPage);

  const handleAccordionClick = index => {
    setActiveIndexes(prevIndexes => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter(item => item !== index);
      } else {
        return [...prevIndexes, index];
      }
    });
  };
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const renderDeleteItemAlert = () => {
    return (
      <AmsAlert
        show={showDeleteAlert}
        title="Are you sure you want to delete this finding"
        text={`Are you sure you want to delete this finding`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'Delete'}
        cancelButtonText={'Cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          setShowDeleteAlert(false);
        }}
        onConfirm={() => {
          setShowDeleteAlert(false);
        }}
      />
    );
  };
  const getEligibilityFileHeader = p => {
    return (
      <>
        <div>
          {p.index +
            1 +
            '. Child/Expectant Mother ID: ' +
            (p.children.props.formData.childId != null
              ? p.children.props.formData.childId
              : '')}
        </div>
        <small
          style={{
            fontFamily: 'PublicSans-Regular',
            color: '#333',
            fontWeight: 'normal',
          }}
        >
          <span>{'Added by '}</span>
          <b style={{ color: '#000' }}>
            {p.children.props.formData.submittedBy != null
              ? p.children.props.formData.submittedBy
              : ''}
          </b>
          <span>{' on '}</span>
          <b style={{ color: '#000' }}>
            {p.children.props.formData.sbumittedIn != null
              ? p.children.props.formData.sbumittedIn
              : ''}
          </b>
        </small>
      </>
    );
  };
  let seenItems = [];
  return (
    <div
      className={cleanClassNames([
        className,
        classNames,
        'sortable-form-fields',
      ])}
    >
      {renderDeleteItemAlert()}
      {'Follow-up' !== formContext?.reviewType && (
        <ArrayFieldTitle
          key={`array-field-title-${idSchema.$id}`}
          TitleField={TitleField}
          idSchema={idSchema}
          uiSchema={uiSchema}
          title={fieldTitle}
          required={required}
        />
      )}
      {fieldDescription && (
        <ArrayFieldDescription
          key={`array-field-description-${idSchema.$id}`}
          DescriptionField={DescriptionField}
          idSchema={idSchema}
          description={fieldDescription}
        />
      )}
      {['Finding', 'Eligibility File'].includes(category) && (
        <div>
          {canAdd && (
            <div>
              {'Follow-up' !== formContext?.reviewType && (
                <Button
                  onClick={onAddClick}
                  disabled={disabled || readOnly}
                  icon
                  size="tiny"
                  labelPosition="left"
                  style={{
                    background: '#337ab7',
                    color: '#fff',
                    position: 'relative',
                    textAlign: 'left',
                  }}
                >
                  <Icon name="plus" />
                  Add {itemLabel}
                </Button>
              )}
              {['Eligibility File'].includes(category) &&
                items &&
                items.length > 0 && (
                  <Button
                    type="button"
                    onClick={() =>
                      dispatch(exportEligiblilityFiles(formContext.answerId))
                    }
                    icon
                    disabled={disabled || readOnly}
                    size="tiny"
                    primary
                    content="Export Files"
                    style={{
                      background: '#337ab7',
                      color: '#fff',
                      float: 'right',
                      position: 'relative',
                    }}
                  ></Button>
                )}
            </div>
          )}
          {/* {['Eligibility File'].includes(category) && (
          <div
            className="pagination-controls"
            style={{ marginTop: '20px', float: 'right' }}
          >
            <Link
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))}
              disabled={currentPage === 0}
              style={{
                color: '#337ab7',
              }}
            >
              Previous
            </Link>
            <span style={{ marginRight: '10px', marginLeft: '5px' }}>
              Showing {currentPage + 1} to {totalPages} of {items?.length}{' '}
              Results
            </span>
            <Link
              onClick={() =>
                setCurrentPage(prev => Math.min(prev + 1, totalPages - 1))
              }
              disabled={currentPage === totalPages - 1}
              style={{
                color: '#337ab7',
              }}
            >
              Next
            </Link>
          </div>)} */}
          <br />
          {'Follow-up' !== formContext?.reviewType ? (
            <Accordion fluid>
              <div>
                {paginatedItems &&
                  paginatedItems.map((p, index) => {
                    var seen = false;
                    if (category == 'Eligibility File') {
                      if (p.children.props.formData.childId)
                        if (
                          seenItems.includes(p.children.props.formData.childId)
                        )
                          seen = true;
                        else seenItems.push(p.children.props.formData.childId);
                    }
                    return (
                      <div key={index}>
                        <Accordion.Title
                          active={activeIndexes.includes(index)}
                          index={index}
                          style={{ backgroundColor: '#DCDCDC', marginTop: 20 }}
                          onClick={() => handleAccordionClick(index)}
                        >
                          <h3 style={{ margin: '0px 15px' }}>
                            {' '}
                            {category == 'Eligibility File'
                              ? getEligibilityFileHeader(p)
                              : itemLabel +
                                ' ' +
                                (p.index + 1) +
                                '-' +
                                ' Citation' +
                                ' ' +
                                (p.children.props.formData.CitationPicker !=
                                null
                                  ? p.children.props.formData.CitationPicker
                                  : '')}
                            <Icon
                              style={{
                                float: 'right',
                                marginTop:
                                  category == 'Eligibility File'
                                    ? '-10px'
                                    : '0px',
                              }}
                              name={
                                activeIndexes.includes(index) ? 'minus' : 'plus'
                              }
                            />
                          </h3>
                        </Accordion.Title>
                        <Accordion.Content
                          active={activeIndexes.includes(index)}
                          style={{ marginTop: '20px' }}
                        >
                          {seen ? (
                            <Alert bsStyle="danger">
                              Child/Expectant Mother ID{' '}
                              {p.children.props.formData.childId} is duplicated.
                            </Alert>
                          ) : (
                            <></>
                          )}
                          {p.hasToolbar && p.hasRemove && (
                            <Button.Group
                              size="mini"
                              vertical={false}
                              style={{ float: 'right' }}
                            >
                              {p.hasRemove && (
                                <Button
                                  className="array-item-remove"
                                  tabIndex="-1"
                                  icon
                                  size="tiny"
                                  labelPosition="right"
                                  disabled={p.disabled || p.readOnly}
                                  onClick={p.onDropIndexClick(p.index)}
                                  style={{
                                    background: '#db2828',
                                    color: '#fff',
                                  }}
                                >
                                  <Icon name="trash" />
                                  Delete{' '}
                                  {category == 'Eligibility File'
                                    ? p.index +
                                      1 +
                                      '. Child/Expectant Mother ID: ' +
                                      (p.children.props.formData.childId != null
                                        ? p.children.props.formData.childId
                                        : '')
                                    : itemLabel + ' ' + (p.index + 1)}
                                </Button>
                              )}
                            </Button.Group>
                          )}
                          {p.children}
                        </Accordion.Content>
                      </div>
                    );
                  })}
              </div>
            </Accordion>
          ) : (
            <div style={{ marginTop: '-20px', display: 'block' }}>
              {paginatedItems &&
                paginatedItems.map((p, index) => {
                  return p.children;
                })}
            </div>
          )}
        </div>
      )}
      {!['Finding', 'Eligibility File'].includes(category) && (
        <div key={`array-item-list-${idSchema.$id}`}>
          <div className="row array-item-list">
            {items &&
              items.map(p =>
                DefaultArrayItem({ ...p, ...itemProps, itemLabel })
              )}
          </div>

          {canAdd && (
            <div
              style={{
                position: 'relative',
                textAlign: 'left',
              }}
            >
              <Button
                onClick={onAddClick}
                disabled={disabled || readOnly}
                icon
                size="tiny"
                labelPosition="left"
                style={{
                  background: '#337ab7',
                  color: '#fff',
                }}
              >
                <Icon name="plus" />
                Add {itemLabel}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function ArrayFieldTemplate(props) {
  const { options, schema, uiSchema, formContext } = props;
  const semanticProps = getSemanticProps({
    options,
    uiSchema,
    formContext,
    defaultSchemaProps: { horizontalButtons: false, wrapItem: false },
  });
  const { horizontalButtons, wrapItem } = semanticProps;
  const itemProps = { horizontalButtons, wrapItem };
  if (isFixedItems(schema)) {
    return (
      <div>
        <DefaultFixedArrayFieldTemplate {...props} itemProps={itemProps} />
      </div>
    );
  }
  return (
    <div>
      <DefaultNormalArrayFieldTemplate {...props} itemProps={itemProps} />
    </div>
  );
}

export default ArrayFieldTemplate;
