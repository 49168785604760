import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  Button,
  Dimmer,
  Header,
  Icon,
  Loader,
  Message,
} from 'semantic-ui-react';

// Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';
import { granteeName } from '../../utils/TextFormatters';
import GranteeReviewItems from './GranteeReviewItems';

import GranteeInfo from './GranteeInfo';
import GranteeMap from './GranteeMap';

//Import actions.
import { fetchGeocode } from '../../actions/geocodeAction';
import { fetchGrantee, granteeFetched } from '../../actions/granteeActions';
import GranteeSidebar from '../GranteeAvailability/GranteeSidebar';

class GranteeDetailPage extends Component {
  state = {
    responseErrors: [],
    granteeAvailabilityLink: '',
    loading: false,
  };

  style = {
    boldInfo: {
      fontFamily: 'PublicSans-Bold',
    },
  };

  componentWillUnmount() {
    this.props.granteeFetched({});
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getData();
  }

  componentDidUpdate() {
    if (
      (!this.props.grantee.granteeId ||
        this.context.router.params.id !== this.props.grantee.granteeId) &&
      this.state.loading === false
    ) {
      this.setState({ loading: true });
      this.getData();
    }
  }

  getData() {
    const granteeId = this.context.router.params.id;

    this.props
      .fetchGrantee(granteeId)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({
          responseErrors: [...this.state.responseErrors, error.response.data],
        });
      });
    this.setState({
      granteeAvailabilityLink: '/grantee/' + granteeId + '/availability',
    });
  }

  renderGranteeDetail() {
    const { grantee } = this.props;
    if (!grantee) return;

    return (
      <div className="row">
        <div className="grantee-detail">
          <GranteeInfo grantee={grantee} />
          {grantee.hSAddress && <GranteeMap address={grantee.hSAddress} />}
        </div>
      </div>
    );
  }

  renderGranteeReviews() {
    const { reviews } = this.props.grantee;

    if (!reviews) return;

    return (
      <div className="row">
        <div className="col-sm-12">
          <Header as="h2">Reviews</Header>

          <GranteeReviewItems reviews={reviews} />
        </div>
      </div>
    );
  }

  showResponseErrors() {
    const { responseErrors } = this.state;

    if (responseErrors && responseErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {responseErrors.map((errorObject, index) => (
              <li key={index}>
                {errorObject.message} code:(
                {errorObject.code})
              </li>
            ))}
          </ul>
        </Alert>
      );
  }

  renderGranteeUnavailability = () => {
    const { granteeAvailabilityLink } = this.state;

    return (
      <Button
        color="blue"
        content="Show Grantee Unavailability"
        size="large"
        icon="calendar"
        onClick={() => {
          this.context.router.push(granteeAvailabilityLink);
        }}
      />
    );
  };

  showGranteeBanner = () => {
    const { succeedingGranteeId, granteeId } = this.props.grantee;
    if (succeedingGranteeId !== '') {
      return (
        <Message style={this.style.boldInfo} info>
          <Icon name="info" />
          <span>
            Note: The grantee {granteeId} has been replaced by{' '}
            {succeedingGranteeId}.
          </span>
        </Message>
      );
    }
  };

  render() {
    const { grantee } = this.props;

    return (
      <SlidingContainer
        // actionButtons={actionButtons}
        title={!_.isEmpty(grantee) && granteeName(grantee)}
        sidebarComponent={
          <GranteeSidebar hasEditableRoles={grantee?.isEditableRole} />
        }
        granteeStatusBanner={true}
        granteeStatusData={{
          id: this.context.router.params.id,
          type: 'grantee',
        }}
      >
        {_.isEmpty(grantee) || this.state.loading ? (
          <Dimmer active inverted>
            <Loader>Loading ...</Loader>
          </Dimmer>
        ) : (
          <>
            {this.showResponseErrors()}
            {this.showGranteeBanner()}
            {this.renderGranteeDetail()}
            <br />
            {this.renderGranteeReviews()}
          </>
        )}
      </SlidingContainer>
    );
  }
}

GranteeDetailPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    grantee: state.grantee,
    geocode: state.geocode,
  };
}

GranteeDetailPage.propTypes = {
  grantee: PropTypes.object.isRequired,
  fetchGrantee: PropTypes.func.isRequired,
  granteeFetched: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  fetchGeocode,
  fetchGrantee,
  granteeFetched,
})(GranteeDetailPage);
