import axios from 'axios';

import {
  USER_PROFILE_FETCHED,
  USER_PROFILE_UPDATED,
  USER_CARD_FETCHED,
  ADD_ERROR,
  REMOVE_ERROR,
  USER_PROFILE_UPDATED_FAIL,
  USER_PROFILE_TAB_SELECTED,
  USER_ROLES_FETCHED,
  USER_ROLES_UPDATED,
  USER_HISTORY_FETCHED,
} from '../redux/types';

export const userProfileTabSelected = profileTab => ({
  type: USER_PROFILE_TAB_SELECTED,
  profileTab,
});

export const userProfileFetched = profile => ({
  type: USER_PROFILE_FETCHED,
  profile,
});

export const userCardFetched = card => ({
  type: USER_CARD_FETCHED,
  card,
});

export const userHistoryFetched = history => ({
  type: USER_HISTORY_FETCHED,
  history,
});

export const userRolesFetched = roles => ({
  type: USER_ROLES_FETCHED,
  roles,
});

export const userProfileUpdated = profile => ({
  type: USER_PROFILE_UPDATED,
  profile,
});

export const userRolesUpdated = roles => ({
  type: USER_ROLES_UPDATED,
  roles,
});

export function getProfile(profile) {
  return {
    type: USER_PROFILE_FETCHED,
    profile,
  };
}

export function modifyProfile(profile) {
  return {
    type: USER_PROFILE_UPDATED,
    profile,
  };
}

export function setError(error) {
  return {
    type: ADD_ERROR,
    error,
  };
}

export function removeError(index) {
  return {
    type: REMOVE_ERROR,
    index,
  };
}

export function updateUserProfileFail(status) {
  return {
    type: USER_PROFILE_UPDATED_FAIL,
    status,
  };
}

export const fetchUserProfile = oid => dispatch =>
  axios
    .post('/users/profile', { oid })
    .then(response => dispatch(userProfileFetched(response.data)));

export const fetchUserCard = body => dispatch =>
  axios
    .post('/users/profile/lite', body)
    .then(response => dispatch(userCardFetched(response.data)));

export const fetchUserRoles = oid => dispatch =>
  axios
    .post(`/users/profile/roles/${oid}`)
    .then(response => dispatch(userRolesFetched(response.data)));

export const updateUserEmailOnAzure = (azureData, profileData) => dispatch =>
  axios.put(`/azure/users/${azureData.oid}`, { azureData }).then(response => {
    dispatch(updateUserProfile(profileData));
  });

export const updateUserProfile = data => dispatch =>
  axios
    .post(`/users/profile/update/${data.oid}`, data)
    .then(response => dispatch(userProfileUpdated(response.data)));

export const updateUserRoles = (oid, roles) => dispatch =>
  axios
    .post(`/users/profile/update/roles/${oid}`, roles)
    .then(response => dispatch(userRolesUpdated(response.data)));

export const fetchUserHistory = requestInput => dispatch =>
  axios
    .post(`/users/profile/history`, requestInput)
    .then(response => dispatch(userHistoryFetched(response.data)));
