import React, { useState, useEffect } from 'react';
import { Divider, Grid, Form } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import FindingsDownloadAttachement from './FindingsDownloadAttachement';
import DOMPurify from 'dompurify';
import { formatCheckBoxString } from './FindingsUtils';

const FindingsCenterExploration = props => {
  const { questionData, citation } = props;
  const { selectedReview } = useSelector(state => state.review);
  const [expandedItems, setExpandedItems] = useState([]);
  const maxChars = 330;
  const toggleNarrative = (questionIndex, centerIndex) => {
    const expandedItem = `${questionIndex}-${centerIndex}`;
    if (expandedItems.includes(expandedItem)) {
      setExpandedItems(expandedItems.filter(item => item !== expandedItem));
    } else {
      setExpandedItems([...expandedItems, expandedItem]);
    }
    return false;
  };

  if (
    !selectedReview ||
    !selectedReview.grantees ||
    selectedReview.grantees.length === 0
  ) {
    return null;
  }
  const boldLabelStyle = { fontFamily: 'PublicSans-Bold' };

  const capitalizeFirstChar = str => {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  };
  const { performanceArea, contentArea, guideName } = citation;
  return (
    <div>
      {questionData?.map((question, questionIndex) => {
        return (
          <div style={{ margin: '20px', marginBottom: '-25px' }}>
            <Grid style={{ margin: '0', padding: '0' }}>
              <React.Fragment key={questionIndex}>
                <Grid.Row
                  stretched
                  verticalAlign="top"
                  style={{ marginTop: '-25px' }}
                >
                  <Grid.Column width={16}>
                    {props.showGuideName == true ? (
                      <Form.Field width={16}>
                        <label>
                          <span style={{ fontFamily: 'PublicSans-Bold' }}>
                            Guide:{' '}
                          </span>
                          <span style={{ fontWeight: 400 }}>{guideName}</span>
                        </label>
                      </Form.Field>
                    ) : null}
                    <Form.Field width={16}>
                      <label>
                        <span style={{ fontFamily: 'PublicSans-Bold' }}>
                          Content Area:{' '}
                        </span>
                        <span style={{ fontWeight: 400 }}>{contentArea}</span>
                      </label>
                    </Form.Field>
                    <Form.Field width={16}>
                      <label>
                        <span style={{ fontFamily: 'PublicSans-Bold' }}>
                          Performance Area:{' '}
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          {performanceArea}
                        </span>
                      </label>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  stretched
                  verticalAlign="top"
                  style={{ marginTop: '-25px' }}
                >
                  <Grid.Column width={16}>
                    <div
                      style={{
                        fontFamily: 'PublicSans-Bold',
                        marginBottom: '10px',
                      }}
                    >
                      {capitalizeFirstChar(question.questionKey)}
                    </div>
                    <div>{question.questionText}</div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row verticalAlign="top" style={{ marginTop: '-20px' }}>
                  <Grid.Column width={16}>
                    <ul
                      style={{
                        listStyleType: 'disc',
                        marginBottom: '10px',
                        paddingLeft: '15px',
                      }}
                    >
                      <li style={{ marginBottom: '10px' }}>
                        <span style={boldLabelStyle}># of No’s selected: </span>
                        {question.noCount}/{question.totalCount}
                      </li>
                      <li style={{ marginBottom: '20px' }}>
                        <span style={boldLabelStyle}>Centers noted: </span>
                        {question?.centersNoted?.join(', ')}
                      </li>
                    </ul>
                    <Divider />
                  </Grid.Column>
                </Grid.Row>
                {question?.centersData?.map((centerData, centerIndex) => (
                  <React.Fragment key={centerIndex}>
                    <Grid.Row
                      key={centerIndex}
                      verticalAlign="top"
                      style={{ marginTop: '-25px' }}
                    >
                      <Grid.Column width={16}>
                        <div style={{ marginBottom: '10px' }}>
                          <div
                            style={{
                              fontFamily: 'PublicSans-Bold',
                              marginBottom: '10px',
                            }}
                          >
                            {centerData.centerName}
                          </div>
                          <div style={{ marginBottom: '10px' }}>
                            {expandedItems.includes(
                              `${questionIndex}-${centerIndex}`
                            ) ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    formatCheckBoxString(centerData.narrative)
                                  ),
                                }}
                              />
                            ) : (
                              <div>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      formatCheckBoxString(
                                        centerData.narrative.slice(0, maxChars)
                                      )
                                    ),
                                  }}
                                />
                                {centerData.narrative?.length > maxChars && (
                                  <span
                                    onClick={() =>
                                      toggleNarrative(
                                        questionIndex,
                                        centerIndex
                                      )
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      color: '#245E9D',
                                      textDecoration: 'underline',
                                    }}
                                  >
                                    ...Show More
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <span style={boldLabelStyle}>Evidence: </span>
                        {centerData?.evidences?.length > 0 ? (
                          <FindingsDownloadAttachement
                            evidenceFiles={centerData?.evidences}
                            isLegacy={citation.isLegacy}
                          />
                        ) : (
                          <div>No evidence is available</div>
                        )}
                        <Divider />
                      </Grid.Column>
                    </Grid.Row>
                  </React.Fragment>
                ))}
              </React.Fragment>
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

export default FindingsCenterExploration;
