import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  Button,
  Card,
  Dimmer,
  Form,
  Icon,
  Input,
  Loader,
  Radio,
  TextArea,
} from 'semantic-ui-react';

import AmsDateFormatters from '../../../utils/AmsDateFormatters';

//import actions
import { isEmpty } from 'lodash';
import { submitCitationDisallowance } from '../../../actions/reviewActions';
import AmsAlert from '../../../utils/AmsAlert';
import { findingCardClasses } from './FindingsUtils';
const FindingsDisallowanceCard = props => {
  const { citation } = props;
  const dispatch = useDispatch();
  const { selectedReview } = useSelector(state => state.review);
  const currentUser = useSelector(state => state.auth.user);
  const [estimatedDisallowanceValue, setEstimatedDisallowanceValue] = useState(
    0
  );
  const [details, setDetails] = useState('');
  const [actualDisallowanceAmount, setActualDisallowanceAmount] = useState(0);
  const [justification, setJustification] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [valueEnabled, setValueEnabled] = useState('');
  const [amountEnabled, setAmountEnabled] = useState('');

  useEffect(() => {
    setEstimatedDisallowanceValue(citation?.disallowanceDetail?.value);
    setDetails(citation?.disallowanceDetail?.details);
    setActualDisallowanceAmount(citation?.disallowanceDetail?.actualAmount);
    setJustification(citation?.disallowanceDetail?.justification);
    setValueEnabled(citation?.disallowanceDetail?.valueEnabled);
    setAmountEnabled(citation?.disallowanceDetail?.amountEnabled);
  }, [citation]);

  const isFUDForDisallowanceInValid =
    'Follow-up' === selectedReview.reviewType &&
    ['Corrected', 'Withdrawn'].includes(citation?.followUpDetermination);

  const updateStatus = () => {
    const fixedDecimalsDisallowanceValue = parseFloat(
      estimatedDisallowanceValue
    ).toFixed(2);
    const fixedDecimalsDisallowanceAmount = parseFloat(
      actualDisallowanceAmount
    ).toFixed(2);
    setActualDisallowanceAmount(fixedDecimalsDisallowanceAmount);
    setLoading(true);

    dispatch(
      submitCitationDisallowance(
        {
          reviewId: selectedReview.reviewId,
          findingId: citation.findingId,
          disallowanceValue: Number(fixedDecimalsDisallowanceValue),
          details: details,
          disallowanceAmount: Number(fixedDecimalsDisallowanceAmount),
          justification: justification,
          valueEnabled: valueEnabled,
          amountEnabled: amountEnabled,
        },
        { reviewId: selectedReview.reviewId },
        { reviewType: selectedReview.reviewType },
        { isNewForms: selectedReview.isNewForms }
      )
    )
      .then(data => {
        setLoading(false);
        setJustification('');
        setActualDisallowanceAmount('');
        setEstimatedDisallowanceValue('');
        setDetails('');
      })
      .catch(e => {
        setLoading(false);
        setJustification('');
        setActualDisallowanceAmount('');
        setEstimatedDisallowanceValue('');
        setDetails('');
      });
  };

  const handleInputChange = (event, setter) => {
    setter(event.target.value);
  };

  if (
    !selectedReview ||
    !selectedReview.grantees ||
    selectedReview.grantees.length === 0
  ) {
    return null;
  }

  const showValidationMessage = () => {
    return (
      <AmsAlert
        show={showError}
        title="Something went wrong!"
        text={showErrorMessage}
        type={'error'}
        showConfirm
        onConfirm={() => {
          setShowError(false);
        }}
      />
    );
  };

  const validateDisallowanceDetails = () => {
    const estimatedDisallowanceValueIsEmpty =
      estimatedDisallowanceValue === null ||
      estimatedDisallowanceValue === '' ||
      estimatedDisallowanceValue === undefined ||
      isNaN(estimatedDisallowanceValue);
    const actualDisallowanceAmountIsEmpty =
      actualDisallowanceAmount === null ||
      actualDisallowanceAmount === '' ||
      actualDisallowanceAmount === undefined ||
      isNaN(actualDisallowanceAmount);

    if (
      'no' === valueEnabled &&
      citation?.disallowanceDetail?.valueEnabled === valueEnabled
    ) {
      setShowError(true);
      setShowErrorMessage(
        'No details have been changed. Please make the necessary changes before saving the details.'
      );
      return false;
    }

    if (
      citation?.disallowanceDetail?.value === estimatedDisallowanceValue &&
      citation?.disallowanceDetail?.details === details &&
      citation?.disallowanceDetail?.actualAmount === actualDisallowanceAmount &&
      citation?.disallowanceDetail?.justification === justification &&
      citation?.disallowanceDetail?.valueEnabled === valueEnabled &&
      citation?.disallowanceDetail?.amountEnabled === amountEnabled
    ) {
      setShowError(true);
      setShowErrorMessage(
        'No details have been changed. Please make the necessary changes before saving the details.'
      );
      return false;
    }

    if (
      'yes' === valueEnabled &&
      'yes' === amountEnabled &&
      !estimatedDisallowanceValueIsEmpty &&
      !actualDisallowanceAmountIsEmpty &&
      isEmpty(details) &&
      isEmpty(justification)
    ) {
      setShowError(true);
      setShowErrorMessage(
        'Please enter Estimated Disallowance Details and Actual Disallowance Justification'
      );
      return false;
    }

    if (
      'yes' === valueEnabled &&
      'yes' === amountEnabled &&
      !estimatedDisallowanceValueIsEmpty &&
      !actualDisallowanceAmountIsEmpty &&
      !isEmpty(details) &&
      isEmpty(justification)
    ) {
      setShowError(true);
      setShowErrorMessage('Please enter Actual Disallowance Justification');
      return false;
    }

    if (
      'yes' === valueEnabled &&
      !estimatedDisallowanceValueIsEmpty &&
      !actualDisallowanceAmountIsEmpty &&
      isEmpty(details) &&
      isEmpty(justification)
    ) {
      setShowError(true);
      setShowErrorMessage('Please enter Estimated Disallowance Details');
      return false;
    }

    if (
      'yes' === amountEnabled &&
      'yes' === valueEnabled &&
      estimatedDisallowanceValueIsEmpty &&
      isEmpty(details) &&
      !actualDisallowanceAmountIsEmpty &&
      isEmpty(justification)
    ) {
      setShowError(true);
      setShowErrorMessage(
        'Please enter Estimated Disallowance Value, Estimated Disallowance Details and Actual Disallowance Justification'
      );
      return false;
    }

    if (
      'yes' === valueEnabled &&
      'yes' === amountEnabled &&
      estimatedDisallowanceValueIsEmpty &&
      isEmpty(details) &&
      actualDisallowanceAmountIsEmpty &&
      !isEmpty(justification)
    ) {
      setShowError(true);
      setShowErrorMessage(
        'Please enter Estimated Disallowance Value, Estimated Disallowance Details and Actual Disallowance Amount'
      );
      return false;
    }

    if (
      'yes' === valueEnabled &&
      estimatedDisallowanceValueIsEmpty &&
      isEmpty(details)
    ) {
      setShowError(true);
      setShowErrorMessage(
        'Please enter Estimated Disallowance Value and Estimated Disallowance Details'
      );
      return false;
    }

    if (
      'yes' === valueEnabled &&
      estimatedDisallowanceValueIsEmpty &&
      !isEmpty(details)
    ) {
      setShowError(true);
      setShowErrorMessage('Please enter Estimated Disallowance Value');
      return false;
    }

    if (
      'yes' === valueEnabled &&
      !estimatedDisallowanceValueIsEmpty &&
      isEmpty(details)
    ) {
      setShowError(true);
      setShowErrorMessage('Please enter details');
      return false;
    }

    if (
      'yes' === valueEnabled &&
      'yes' === amountEnabled &&
      !actualDisallowanceAmountIsEmpty &&
      isEmpty(justification)
    ) {
      setShowError(true);
      setShowErrorMessage('Please enter Actual Disallowance Justification');
      return false;
    }

    if (
      'yes' === valueEnabled &&
      'yes' === amountEnabled &&
      actualDisallowanceAmountIsEmpty &&
      !isEmpty(justification)
    ) {
      setShowError(true);
      setShowErrorMessage('Please enter Actual Disallowance Amount');
      return false;
    }

    if (
      'yes' === valueEnabled &&
      'yes' === amountEnabled &&
      actualDisallowanceAmountIsEmpty &&
      isEmpty(justification)
    ) {
      setShowError(true);
      setShowErrorMessage(
        'Please enter Actual Disallowance Amount and Actual Disallowance Justification'
      );
      return false;
    }

    return true;
  };

  const displayQuestionChangeLog = (e, question, selectedOption) => {
    return (
      <li className="eas-li history-item">
        <span class="line"></span>
        <span class="number"></span>
        <div class="blob">
          "{question}" updated to{' '}
          <span className="bold">"{selectedOption}"</span> by{' '}
          <span className="bold">{e.submittedBy}</span> on{' '}
          <span className="bold">
            {AmsDateFormatters.getMoment(e.submittedTime).format(
              'M/D/YYYY, h:mm A'
            )}
          </span>
        </div>
      </li>
    );
  };

  const fieldChangeLog = (e, field, previousField, currentField) => {
    return (
      <li className="history-item eas-li">
        <span class="line"></span>
        <span class="number"></span>
        <div class="blob">
          {field} changed from{' '}
          <span className="bold">
            $
            {previousField.toLocaleString('en-US', {
              minimumFractionDigits: 2,
            })}
          </span>{' '}
          to{' '}
          <span className="bold">
            $
            {currentField.toLocaleString('en-US', {
              minimumFractionDigits: 2,
            })}
          </span>{' '}
          by <span className="bold">{e.submittedBy}</span> on{' '}
          <span className="bold">
            {AmsDateFormatters.getMoment(e.submittedTime).format(
              'M/D/YYYY, h:mm A'
            )}
          </span>
        </div>
      </li>
    );
  };

  const notesLogByFieldType = (e, field, previousField, currentField) => {
    return (
      <li className="history-item eas-li">
        <span class="line"></span>
        <span class="number"></span>
        <div class="blob">
          {previousField !== currentField && (
            <span>
              {field} updated by <span className="bold">{e.submittedBy}</span>{' '}
              on{' '}
              <span className="bold">
                {AmsDateFormatters.getMoment(e.submittedTime).format(
                  'M/D/YYYY, h:mm A'
                )}
              </span>
            </span>
          )}
          {previousField !== currentField && currentField.length > 1 ? (
            <>
              <br></br>
              <span className="bold">{field}: </span>
              {currentField}
            </>
          ) : (
            ''
          )}
        </div>
      </li>
    );
  };

  const monitizedQuestionYesLog = e => {
    return (
      <React.Fragment>
        {e.currentAmountEnabled === 'yes' &&
          e.previousJustification !== e.justification &&
          notesLogByFieldType(
            e,
            'Actual Disallowance Justification',
            e.previousJustification,
            e.justification
          )}
        {e.currentAmountEnabled === 'yes' &&
          e.previousAmount !== e.currentAmount &&
          fieldChangeLog(
            e,
            'Actual Disallowance Amount',
            e.previousAmount,
            e.currentAmount
          )}
        {e.currentAmountEnabled === 'no' &&
          e.previousAmountEnabled !== e.currentAmountEnabled &&
          displayQuestionChangeLog(
            e,
            'Is there an actual disallowance amount?',
            'No'
          )}
        {e.currentAmountEnabled === 'yes' &&
          e.previousAmountEnabled !== e.currentAmountEnabled &&
          displayQuestionChangeLog(
            e,
            'Is there an actual disallowance amount?',
            'Yes'
          )}
        {e.previousDetails !== e.details &&
          notesLogByFieldType(
            e,
            'Estimated Disallowance Details',
            e.previousDetails,
            e.details
          )}
        {e.previousValue !== e.currentValue &&
          fieldChangeLog(
            e,
            'Estimated Disallowance Value',
            e.previousValue,
            e.currentValue
          )}
      </React.Fragment>
    );
  };

  const renderHistory = () => {
    return (
      <ul className="eas-ul">
        {citation?.disallowanceDetail?.history.map((e, index) => {
          if (e.currentValueEnabled === 'no') {
            return (
              <React.Fragment>
                {displayQuestionChangeLog(
                  e,
                  'Is there a Monetized Disallowance?',
                  'No'
                )}
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                {monitizedQuestionYesLog(e)}
                {e.currentValueEnabled === 'yes' &&
                  e.currentValueEnabled !== e.previousValueEnabled &&
                  displayQuestionChangeLog(
                    e,
                    'Is there a Monetized Disallowance?',
                    'Yes'
                  )}
              </React.Fragment>
            );
          }
        })}
      </ul>
    );
  };

  if (
    !citation ||
    !citation.disallowanceFlag ||
    citation.disallowanceFlag === false ||
    citation.disallowanceFlag === undefined
  ) {
    return null;
  }

  const showConfirmationAlert = () => {
    return (
      <AmsAlert
        show={showAlert}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={`Are you sure?`}
        onConfirm={() => {
          setShowAlert(false);
          updateStatus();
          props.handleSaveButtonAction('SAVE');
        }}
        onCancel={() => {
          setShowAlert(false);
        }}
      />
    );
  };

  const handleRadioButtonChange = (e, value) => {
    if ('actualDisallowance' === value.name) {
      setAmountEnabled(value.value);
      setActualDisallowanceAmount(0);
      setJustification('');
    } else if ('estimatedDisallowance' === value.name) {
      setValueEnabled(value.value);
      setEstimatedDisallowanceValue(0);
      setDetails('');
      setActualDisallowanceAmount(0);
      setJustification('');
      setAmountEnabled('');
    }
  };

  const determineFindingStatus = (selectedReview, citation) => {
    const isFindingDisabled = citation?.isActive === false;
    if (
      ['signed', 'Shipped'].includes(selectedReview?.reviewStatus) ||
      ['finalized'].includes(selectedReview?.reportStage) ||
      isFUDForDisallowanceInValid ||
      isFindingDisabled
    ) {
      return 'DROPPED';
    }
    return citation?.findingStatus;
  };

  return (
    <Card
      className={findingCardClasses(
        'findings-card',
        determineFindingStatus(selectedReview, citation)
      )}
    >
      <Dimmer active={loading} inverted>
        <Loader active={loading} inverted>
          Loading
        </Loader>
      </Dimmer>
      {showConfirmationAlert()}
      {showValidationMessage()}
      <Card.Content
        style={{ backgroundColor: '#E9E9E9', fontSize: '18px' }}
        header="Disallowance"
      />
      <Form style={{ margin: '10px' }}>
        <label style={{ margin: '10px' }}>
          Is there a Monetized Disallowance?
        </label>
        <Form.Field width={5} className="findings-radio">
          <Radio
            disabled={selectedReview.readOnly}
            name="estimatedDisallowance"
            label="Yes"
            value="yes"
            checked={'yes' === valueEnabled}
            onChange={handleRadioButtonChange}
          />
        </Form.Field>
        <Form.Field width={5} className="findings-radio">
          <Radio
            disabled={selectedReview.readOnly}
            name="estimatedDisallowance"
            label="No"
            value="no"
            checked={'no' === valueEnabled}
            onChange={handleRadioButtonChange}
          />
        </Form.Field>

        {'yes' === valueEnabled && (
          <Form style={{ margin: '10px' }}>
            <Form.Field>
              <label>Estimated Disallowance Value:</label>
              <Input
                disabled={selectedReview.readOnly}
                type="number"
                onKeyDown={e => {
                  if (e.keyCode === 109 || e.keyCode === 189)
                    e.preventDefault();
                }}
                // min="1"
                name="estimatedDisallowanceValue"
                value={estimatedDisallowanceValue}
                onChange={e =>
                  handleInputChange(e, setEstimatedDisallowanceValue)
                }
                fluid
                noValidate
                //  control={Input}
              />
            </Form.Field>
            <Form.Field>
              <label>Estimated Disallowance Details:</label>
              <TextArea
                className={details ? '' : 'placeholder-text'}
                placeholder="Notes here about disallowance"
                name="details"
                disabled={selectedReview.readOnly}
                onChange={e => handleInputChange(e, setDetails)}
                value={details}
              />
            </Form.Field>
            <label style={{ margin: '10px' }}>
              Is there an actual disallowance amount?
            </label>

            <Form.Field width={5} className="findings-radio">
              <Radio
                disabled={selectedReview.readOnly}
                name="actualDisallowance"
                label="Yes"
                value="yes"
                checked={'yes' === amountEnabled}
                onChange={handleRadioButtonChange}
              />
            </Form.Field>
            <Form.Field width={5} className="findings-radio">
              <Radio
                disabled={selectedReview.readOnly}
                name="actualDisallowance"
                label="No"
                value="no"
                checked={'no' === amountEnabled}
                onChange={handleRadioButtonChange}
              />
            </Form.Field>
            {'yes' === amountEnabled && (
              <Form style={{ margin: '10px' }}>
                <Form.Field>
                  <label>Actual Disallowance Amount:</label>
                  <Input
                    disabled={selectedReview.readOnly}
                    type="number"
                    onKeyDown={e => {
                      if (e.keyCode === 109 || e.keyCode === 189)
                        e.preventDefault();
                    }}
                    //min="1"
                    name="actualDisallowanceAmount"
                    onChange={e =>
                      handleInputChange(e, setActualDisallowanceAmount)
                    }
                    value={actualDisallowanceAmount}
                    fluid
                    noValidate
                    // control={Input}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Actual Disallowance Justification:</label>
                  <TextArea
                    disabled={selectedReview.readOnly}
                    name="justification"
                    onChange={e => handleInputChange(e, setJustification)}
                    value={justification}
                  />
                </Form.Field>
                {/* <br/> */}
              </Form>
            )}
          </Form>
        )}
        <br />
        {['yes', 'no'].includes(valueEnabled) && (
          <Button
            disabled={selectedReview.readOnly}
            fluid
            style={{
              backgroundColor: '#245E9D',
              color: 'white',
              marginTop: '10px',
            }}
            onClick={() => {
              if (!validateDisallowanceDetails()) {
                return null;
              }
              setShowAlert(true);
            }}
          >
            Save Disallowance
          </Button>
        )}
      </Form>
      {citation &&
        citation?.disallowanceDetail?.history &&
        citation?.disallowanceDetail?.history.length > 0 && (
          <div style={{ margin: '10px', textAlign: 'center' }}>
            <Accordion className={'enabled-section'}>
              <Accordion.Title
                active={showHistory}
                index={0}
                onClick={() => {
                  setShowHistory(!showHistory);
                }}
              >
                {!showHistory ? 'See History' : 'Hide History'}
                <Icon name="dropdown" />
              </Accordion.Title>
              <Accordion.Content active={showHistory}>
                <div style={{ textAlign: 'left' }}>{renderHistory()}</div>
              </Accordion.Content>
            </Accordion>
          </div>
        )}
    </Card>
  );
};

export default FindingsDisallowanceCard;
