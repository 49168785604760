import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import _, { sortBy } from 'lodash';

import { fetchAmsLookup } from '../actions/lookupActions';

class AmsLookupDropdown extends React.Component {
  componentDidMount = () => {
    this.getData();
  };

  getData = () => {
    const { category, fetchAmsLookup, amsLookups } = this.props;
    if (category) {
      if (_.isEmpty(amsLookups[category]))
        fetchAmsLookup(category)
          .then()
          .catch(error => {
            if (this.props.setError)
              this.props.setError({
                responseError: error.response.data.message,
              });
          });
    }
  };

  prepareLookupItems = (lookupItems, type) => {
    switch (type.toLowerCase()) {
      case 'string':
        return lookupItems.map(lookup => ({
          key: lookup._id,
          text: lookup.value,
          value: lookup.value,
        }));
      case 'number':
        return lookupItems.map(lookup => ({
          key: lookup._id,
          text: lookup.value,
          value: lookup.value,
        }));
      case 'object':
        return lookupItems.map(lookup => ({
          key: lookup._id,
          text: lookup.value.label,
          value: lookup.value.value,
        }));
      default:
        break;
    }
  };
  handleSort = options => {
    return sortBy(options, obj => obj.text.toLowerCase());
  };

  renderDropdown = () => {
    const { amsLookups, category } = this.props;

    const lookups = amsLookups[category];

    const parent = _.find(lookups, { parent: true }); // Find lookup category parent.
    const activeLookups = _.filter(lookups, { parent: false, active: true }); // Find active lookup items.

    // Only render lookup dropdown if parent is active.
    if (parent && parent.active) {
      // Check if lookup list is not empty.
      if (activeLookups.length) {
       let options = this.prepareLookupItems(activeLookups, parent.valueType); // Prepare lookups for Semantic UI dropdown list.

        if (
          category === 'reviewTypes' ||
          category === 'taskTypes' ||
          category === 'taskStatus' ||
          category === 'findingType' ||
          category === 'reviewStatus'
        )
          options = this.handleSort(options);
        const dropDownProps = _.omit(this.props, [
          'category',
          'fetchAmsLookup',
          'amsLookups',
          'setError',
        ]); // exclude props not necessary for Dropdown.
        return (
          <Dropdown
            selectOnBlur={false}
            openOnFocus={false}
            {...dropDownProps}
            options={options}
          /> // Only show dropdown if lookup items are available
        );
      }
    }
    return null;
  };

  render() {
    return this.renderDropdown();
  }
}

AmsLookupDropdown.propTypes = {
  fetchAmsLookup: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  amsLookups: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  amsLookups: state.lookups.amsLookups,
});

export default connect(mapStateToProps, { fetchAmsLookup })(AmsLookupDropdown);
