import React, { useState } from 'react';
import { Form, Input } from 'semantic-ui-react';

function PercentageCalculationWidget(props) {
  var infoStyle = {
    paddingLeft: '10px',
    opacity: '0.5',
    fontSize: '13px',
  };
  return (
    <>
      <Form.Field style={{ marginBottom: '15px' }}>
        <div style={{ display: 'block' }} htmlFor={props.id + '_numerator'}>
          {props.schema.numerator}
          {props.required ? <span class="required">*</span> : <></>}
        </div>
        <Input
          type="number"
          value={props.value?.numerator}
          name="numerator"
          min="0"
          id={props.id + '_numerator'}
          onKeyDown={function(event) {
            if (
              event.key == '.' ||
              event.key == '-' ||
              event.key == '+' ||
              event.key == 'e' ||
              event.key == 'E'
            )
              event.preventDefault();
          }}
          onChange={(e, data) => {
            var valToSave = { ...props.value };
            valToSave.numerator = data.value;
            valToSave.result = calculatePercentage(
              valToSave.numerator,
              valToSave.denominator
            );
            if (props.onChange) {
              props.onChange(valToSave);
            }
          }}
          required={props.required}
          disabled={props.readonly || props.disabled}
        />
        <span style={infoStyle}>
          This field will only accept Non-negative whole numbers.
        </span>
      </Form.Field>
      <Form.Field style={{ marginBottom: '15px' }}>
        <div style={{ display: 'block' }} htmlFor={props.id + '_denominator'}>
          {props.schema.denominator}
          {props.required ? <span class="required">*</span> : <></>}
        </div>
        <Input
          type="number"
          min="0"
          value={props.value?.denominator}
          name="denominator"
          id={props.id + '_denominator'}
          onKeyDown={function(event) {
            if (
              event.key == '.' ||
              event.key == '-' ||
              event.key == '+' ||
              event.key == 'e' ||
              event.key == 'E'
            )
              event.preventDefault();
          }}
          onChange={(e, data) => {
            var valToSave = { ...props.value };
            valToSave.denominator = data.value;
            valToSave.result = calculatePercentage(
              valToSave.numerator,
              valToSave.denominator
            );
            if (props.onChange) {
              props.onChange(valToSave);
            }
          }}
          required={props.required}
          disabled={props.readonly || props.disabled}
        />
        <span style={infoStyle}>
          This field will only accept Non-negative whole numbers.
        </span>
        {props.value?.result === 'NAN' ? (
          <div
            style={{ color: 'red', fontStyle: 'italic', fontWeight: 'bold' }}
          >
            Please provide a non zero value for '{props.schema.denominator}'.
          </div>
        ) : (
          <></>
        )}
      </Form.Field>
      <Form.Field style={{ marginBottom: '15px' }}>
        <label className="control-label">{props.schema.result}</label>
        <span style={{ paddingLeft: '5px' }} id={props.id + '_result'}>
          {props.value?.result === 'NAN' ? '' : props.value?.result}
        </span>
      </Form.Field>
    </>
  );

  function calculatePercentage(numerator, denominator) {
    if (!numerator || !denominator) return null;
    if (numerator === 0 || numerator === '0') return '0.00%';
    if (denominator === 0 || denominator === '0') return 'NAN';
    let percentage = (numerator / denominator) * 100;
    return percentage.toFixed(2) + '%'; // Return result rounded to 2 decimal places
  }
}

PercentageCalculationWidget.defaultProps = {};

export default PercentageCalculationWidget;
