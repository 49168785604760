import React from 'react';

export function PercentageCalculation({ parameters, onChange }) {
  var label_styles = { textAlign: 'left', display: 'block', marginTop: '10px' };
  return (
    <>
      <div>
        <label style={label_styles}>Numerator Description:</label>
        <input
          className="form-control percentage-calculation-numerator-input"
          name={parameters.name + '_numerator'}
          value={parameters.numerator}
          onChange={ev =>
            onChange({ ...parameters, numerator: ev.target.value })
          }
        />
      </div>
      <div>
        <label style={label_styles}>Denominator Description:</label>
        <input
          className="form-control percentage-calculation-denominator-input"
          name={parameters.name + '_denominator'}
          value={parameters.denominator}
          onChange={ev =>
            onChange({ ...parameters, denominator: ev.target.value })
          }
        />
      </div>
      <div>
        <label style={label_styles}>Results Description:</label>
        <input
          className="form-control percentage-calculation-results-input"
          name={parameters.name + '_result'}
          value={parameters.result}
          onChange={ev => onChange({ ...parameters, result: ev.target.value })}
        />
      </div>
    </>
  );
}
