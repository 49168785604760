import React from 'react';
import EligibilityFileOutcome from './EligibilityFileOutcome';

const EligibilityFileOutcomePaths = props => {
  return (
    <>
      <EligibilityFileOutcome props={props} />
    </>
  );
};
export default EligibilityFileOutcomePaths;
