import React, { useState, useEffect } from 'react';
import {
  Accordion,
  Icon,
  Divider,
  Form,
  TextArea,
  Comment,
} from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router';
import { orderBy, isEmpty } from 'lodash';

//import utils
import {
  prepData,
  humanizeFileSize,
} from '../Shared/FormHelpers/helpers/utils';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import MimeIcon from '../Shared/FormHelpers/helpers/MimeIcon';
import AmsAlert from '../../utils/AmsAlert';

//import actions
import { downloadFeedbackFile } from '../../actions/surveyActions';
import { reportExternalCommentReplyAction } from '../../actions/reportActions';

export default function ReportExternalComments(props) {
  const dispatch = useDispatch();
  const { newReport } = useSelector(state => state.report);

  const [replyMessage, setReplyMessage] = useState('');
  const [comments, setComments] = useState([]);
  const [activeComments, setActiveComments] = useState([]);
  const [modalReplyIndex, setModalReplyIndex] = useState(-1);
  const [showAlert, setShowAlert] = useState(false);
  const [activityInfo, setActivityInfo] = useState({
    type: '',
    message: '',
  });

  useEffect(() => {
    if (newReport && newReport.comments && newReport.comments.length > 0)
      setComments(newReport.comments);
  }, [newReport]);

  const [activeIndex, setActiveIndex] = useState(-1);
  const subDividerStyle = { marginLeft: '-15px', marginRight: '-15px' };
  let length = comments.length;

  const entityToChar = (str, elm) => {
    const el = document.createElement(elm);
    el.innerHTML = str;
    return el.value;
  };

  const approvedBox = (
    <div
      style={{
        float: 'right',
        width: '90px',
        fontSize: '10px',
        fontFamily: 'PublicSans-Bold',
        height: '21px',
        padding: '4px 6px 5px',
        borderRadius: '3px',
        border: 'solid 1px #007a26',
        backgroundColor: '#f5fff8',
        color: '#007a26',
      }}
    >
      <Icon name="check circle" color="green"></Icon>
      APPROVED
    </div>
  );

  const needClarificationBox = (
    <div
      style={{
        float: 'right',
        width: '125px',
        fontSize: '10px',
        fontFamily: 'PublicSans-Bold',
        height: '21px',
        padding: '4px 6px 5px',
        borderRadius: '3px',
        border: 'solid 1px rgb(189,152,77)',
        backgroundColor: 'rgb(254,248,222)',
        color: 'rgb(189,152,77)',
      }}
    >
      NEED CLARIFICATION
    </div>
  );

  const inProgressBox = (
    <div
      style={{
        float: 'right',
        width: '90px',
        fontSize: '10px',
        fontFamily: 'PublicSans-Bold',
        height: '21px',
        padding: '4px 6px 5px',
        borderRadius: '3px',
        border: 'solid 1px rgb(69,138,204)',
        backgroundColor: 'rgb(225,239,253)',
        color: 'rgb(69,138,204)',
      }}
    >
      IN PROGRESS
    </div>
  );

  const rejectedBox = (
    <div
      style={{
        float: 'right',
        width: '90px',
        fontSize: '10px',
        fontFamily: 'PublicSans-Bold',
        height: '21px',
        padding: '4px 6px 5px',
        borderRadius: '3px',
        border: 'solid 1px #d91919',
        backgroundColor: '#fff5f5',
        color: '#d91919',
      }}
    >
      <Icon
        style={{ float: 'left', lineHeight: 'inherit' }}
        name="close circle"
        color="red"
      ></Icon>
      REJECTED
    </div>
  );

  const handleClick = (idx, e) => {
    const newIndex = activeIndex === idx ? -1 : idx;
    let scrollToIndex = idx - 1;
    let exp = document.getElementById('comment-' + scrollToIndex);
    if (exp) {
      setTimeout(() => {
        exp.scrollIntoView();
      }, 300);
    }
    setActiveIndex(newIndex);
  };

  const triggerDownload = (id, name) => {
    if (id && name) {
      //this.setState({ downloading: true });

      dispatch(downloadFeedbackFile(id, name));
    }
  };

  const renderActivityInfoAlert = () => {
    return (
      <AmsAlert
        show={showAlert}
        title=""
        type={activityInfo.type}
        showConfirm
        confirmButtonText="Ok"
        confirmButtonColor={'#DD6B55'}
        text={activityInfo.message}
        onConfirm={() => {
          setShowAlert(false);
        }}
      />
    );
  };

  const renderFeedbackFiles = evidences => {
    if (evidences.length === 0) {
      return '';
    }

    let files = evidences.map(e => {
      return prepData(e);
    });

    return (
      <div style={{ marginTop: '10px' }}>
        {files.map((e, idx) => {
          let fileName = e.name;
          if (e.size) fileName = `${fileName} - (${humanizeFileSize(e.size)})`;

          return (
            <div key={idx}>
              <a
                href=""
                download={e.name}
                onClick={event => {
                  event.preventDefault();
                  triggerDownload(e.id, e.name);
                }}
              >
                <MimeIcon mime={e.type} />
                {fileName}
              </a>
            </div>
          );
        })}
      </div>
    );
  };

  const onSubmitReply = data => {
    let input = {
      findingId: data.findingId[0],
      conversationId: data.conversationId,
      reviewId: newReport.reviewID,
      response: replyMessage,
    };

    dispatch(
      reportExternalCommentReplyAction(input, result => {
        setReplyMessage('');
        setModalReplyIndex(-1);

        if (result instanceof Error) {
          setShowAlert(true);
          setActivityInfo({
            type: 'error',
            message: `'Error: ${result.response.data.message ||
              'unknown error'}`,
          });
        } else {
          setShowAlert(true);
          setActivityInfo({
            type: 'success',
            message: 'You have replied to the external comment.',
          });
        }
      })
    );
  };

  const handleCommentBox = (id, index) => {
    let copy = activeComments.slice(0);
    let value = id + index;
    if (copy.includes(value))
      copy = copy.filter(function(item) {
        return item !== value;
      });
    else copy.push(value);
    setActiveComments(copy);
  };

  const renderFindings = current => {
    if (!current) {
      return;
    }
    const subHeaderStyle = {
      fontSize: '14px',
      fontFamily: 'PublicSans-Medium',
      height: '14px',
      margin: '5px 0px 14px 0px',
    };

    const commentStyle = {
      margin: '4px 17px 9px 0px',
      fontSize: '15px',
      textAlign: 'justify',
    };

    let findings;
    if (current.findings && current.findings.length > 0) {
      findings = current.findings.map((e, index) => {
        let narrativeComment;
        let findingComment;
        let findingTypeComment;

        if (e && e.comments) {
          e.comments.forEach(comment => {
            if (comment.type === 'narrative') {
              narrativeComment = comment;
            } else if (comment.type === 'findingType') {
              findingTypeComment = comment;
            } else if (comment.type === 'finding') {
              findingComment = comment;
            }
          });
        }
        const findings = e.citationNumbers.filter(c => c !== 'N/A');
        const strongPractices = e.citationNumbers.filter(c => c === 'N/A');
        if (e.type === 'all') {
          return (
            <>
              <div
                style={{
                  border: '1px solid #ccc',
                  margin: '0px -15px 15px',
                  padding: '15px 15px',
                  fontSize: '15px',
                  borderRadius: '2px',
                }}
              >
                <Link
                  to="#"
                  style={{
                    margin: '-15px -15px',
                    padding: '15px 15px',
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleCommentBox(current.conversationId, index);
                  }}
                >
                  <span
                    style={{ color: '#00449f', fontFamily: 'PublicSans-Bold' }}
                  >
                    {!isEmpty(findings) && 'All Findings (' + findings + ') '}
                    {!isEmpty(findings) && !isEmpty(strongPractices) && ' and '}
                    {!isEmpty(strongPractices) && 'All Strong Practices'}
                  </span>
                  {e.status === 'Approved' && approvedBox}
                  {e.status === 'Rejected' && rejectedBox}
                  {e.status === 'Need Clarification' && needClarificationBox}
                  {e.status === 'In Progress' && inProgressBox}
                </Link>
                {activeComments.includes(current.conversationId + index) && (
                  <div>
                    <Divider />
                    <div style={subHeaderStyle}>
                      Comments for All Findings
                      <span
                        style={{
                          display: 'inline-block',
                          marginLeft: ' 0.5em',
                          color: 'rgba(0,0,0,.4)',
                          fontSize: '.875em',
                        }}
                        class="subheader-metadata"
                      >
                        {' '}
                        {AmsDateFormatters.formatDateTime(
                          e.comments[0].updatedTime
                        )}
                      </span>
                    </div>
                    <span style={commentStyle}>{e.comments[0].comment}</span>
                    {renderFeedbackFiles(e.comments[0].evidences)}
                  </div>
                )}
              </div>
            </>
          );
        }
        return (
          <>
            <div
              style={{
                border: '1px solid #ccc',
                margin: '0px -15px 15px',
                padding: '15px 15px',
                fontSize: '15px',
                borderRadius: '2px',
              }}
            >
              <Link
                to="#"
                style={{
                  margin: '-15px -15px',
                  padding: '15px 15px',
                  cursor:
                    (findingComment && findingComment.comment) ||
                    (findingTypeComment && findingTypeComment.comment) ||
                    (narrativeComment && narrativeComment.comment)
                      ? 'pointer'
                      : 'auto',
                  textDecoration: 'none',
                }}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleCommentBox(current.conversationId, index);
                }}
              >
                <span
                  style={{ color: '#00449f', fontFamily: 'PublicSans-Bold' }}
                >
                  {e.findingDisplayName}
                  {e.findingType !== 'N/A' ? ': ' + e.citationNumbers : <></>}
                </span>
                {e.status === 'Approved' && approvedBox}
                {e.status === 'Rejected' && rejectedBox}
                {e.status === 'Need Clarification' && needClarificationBox}
                {e.status === 'In Progress' && inProgressBox}
              </Link>
              {activeComments.includes(current.conversationId + index) && (
                <div>
                  {findingComment && findingComment.comment && (
                    <>
                      <Divider />
                      <div style={subHeaderStyle}>
                        Comments
                        <span
                          style={{
                            display: 'inline-block',
                            marginLeft: ' 0.5em',
                            color: 'rgba(0,0,0,.4)',
                            fontSize: '.875em',
                          }}
                          class="subheader-metadata"
                        >
                          {' '}
                          {AmsDateFormatters.formatDateTime(
                            findingComment.updatedTime
                          )}
                        </span>
                      </div>
                      <span style={commentStyle}>{findingComment.comment}</span>
                      <div
                        style={{ fontSize: '12px', marginTop: '10px' }}
                        class="actions"
                      >
                        {!current.isReadOnlyComment ? (
                          (findingComment.responses === null ||
                            findingComment.responses.length === 0) && (
                            <Link
                              to="#"
                              style={{ cursor: 'pointer' }}
                              onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                setModalReplyIndex(index);
                              }}
                              class=""
                            >
                              Reply
                            </Link>
                          )
                        ) : (
                          <></>
                        )}
                        {findingComment.responses &&
                          findingComment.responses.length > 0 && (
                            <Comment.Group>
                              {orderBy(
                                findingComment.responses,
                                ['updatedTime'],
                                ['desc']
                              ).map(reply => {
                                return (
                                  <Comment>
                                    <Comment.Content>
                                      <Comment.Author as="span">
                                        {reply.responseBy}
                                      </Comment.Author>
                                      <Comment.Metadata>
                                        <div>
                                          {' '}
                                          {AmsDateFormatters.formatDateTime(
                                            reply.updatedTime
                                          )}
                                        </div>
                                      </Comment.Metadata>
                                      <Comment.Text>
                                        {reply.response}
                                      </Comment.Text>
                                    </Comment.Content>
                                  </Comment>
                                );
                              })}
                            </Comment.Group>
                          )}
                        {index === modalReplyIndex && (
                          <Form
                            reply
                            onSubmit={() => {
                              onSubmitReply({
                                conversationId: current.conversationId,
                                findingId: e.findingIds,
                              });
                            }}
                          >
                            <TextArea
                              value={entityToChar(replyMessage, 'textarea')}
                              onChange={e => {
                                setReplyMessage(e.target.value);
                              }}
                              rows={3}
                              style={{
                                height: '100px',
                                marginTop: '10px',
                                marginBottom: '10px',
                              }}
                            />
                            <Form.Group style={{ marginTop: '5px' }}>
                              <Form.Button
                                type="submit"
                                primary
                                content={'Add Reply'}
                              />
                              <Form.Button
                                onClick={() => {
                                  setModalReplyIndex(-1);
                                }}
                                basic
                                type="cancel"
                                color="blue"
                                content={'Cancel'}
                              />
                            </Form.Group>
                          </Form>
                        )}
                      </div>
                      {renderFeedbackFiles(findingComment.evidences)}
                      {((narrativeComment && narrativeComment.comment) ||
                        (findingTypeComment && findingTypeComment.comment)) && (
                        <Divider style={subDividerStyle} />
                      )}
                    </>
                  )}
                  {findingTypeComment && findingTypeComment.comment && (
                    <>
                      <Divider />
                      <div style={subHeaderStyle}>
                        Comments for Finding Type ({e.findingType})
                        <span
                          style={{
                            display: 'inline-block',
                            marginLeft: ' 0.5em',
                            color: 'rgba(0,0,0,.4)',
                            fontSize: '.875em',
                          }}
                          class="subheader-metadata"
                        >
                          {' '}
                          {AmsDateFormatters.formatDateTime(
                            findingTypeComment.updatedTime
                          )}
                        </span>
                      </div>
                      <span style={commentStyle}>
                        {findingTypeComment.comment}
                      </span>
                      {renderFeedbackFiles(findingTypeComment.evidences)}
                      {narrativeComment && narrativeComment.comment && (
                        <Divider style={subDividerStyle} />
                      )}
                    </>
                  )}
                  {narrativeComment && narrativeComment.comment && (
                    <>
                      <Divider />
                      <div style={subHeaderStyle}>
                        Comments for Narrative
                        <span
                          style={{
                            display: 'inline-block',
                            marginLeft: ' 0.5em',
                            color: 'rgba(0,0,0,.4)',
                            fontSize: '.875em',
                          }}
                          class="subheader-metadata"
                        >
                          {' '}
                          {AmsDateFormatters.formatDateTime(
                            narrativeComment.updatedTime
                          )}
                        </span>
                      </div>

                      <span style={commentStyle}>
                        {narrativeComment.comment}
                      </span>
                      {renderFeedbackFiles(narrativeComment.evidences)}
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        );
      });
    } else {
      return <span>N/A</span>;
    }

    return (
      <>
        <>{findings}</>
      </>
    );
  };

  const renderComments = () => {
    const toRender = comments.map((e, idx) => {
      let legacy = e.findings === null && e.note !== null;
      return (
        <React.Fragment key={idx}>
          <Accordion.Title
            active={activeIndex === idx}
            index={idx}
            id={'comment-' + idx}
            onClick={e => handleClick(idx, e)}
            style={{ padding: '15px 0px' }}
          >
            <Link
              to="#"
              style={{
                textDecoration: 'none',
                fontSize: '15px',
                color: '#333',
              }}
              onClick={e => {
                e.preventDefault();
                handleClick(idx, e);
              }}
            >
              {e.submittedBy.fullName} |{' '}
              {legacy
                ? AmsDateFormatters.getMoment(e.timestamp).format('L')
                : e.timestamp || 'null'}
              <Icon
                style={{ float: 'right' }}
                name={activeIndex === idx ? 'angle down' : 'angle right'}
              />
            </Link>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === idx}>
            <Divider
              style={{
                marginLeft: '-15px',
                marginRight: '-15px',
                marginTop: '-10px',
              }}
            />
            {legacy ? <span>{e.note}</span> : renderFindings(e)}
          </Accordion.Content>
        </React.Fragment>
      );
    });

    return toRender;
  };

  return (
    <>
      <h2>External Comments ({length})</h2>
      <Accordion style={{ width: '100%', boxShadow: 'none' }} styled>
        {renderComments()}
        {renderActivityInfoAlert()}
      </Accordion>
    </>
  );
}
