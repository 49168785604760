import React from 'react';
import { useSelector } from 'react-redux';
import ReviewSubmissionsList from './ReviewSubmissionsList';
import FollowupSubmissions from './FollowupSubmissions';
import FollowupSubmissionsList from './FollowupSubmissionsList';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import SurveySubmissionsList from './SurveySubmissionsList';
import FollowUpSurveyDetails from './FollowUpSurveyDetails';

const ReviewSubmissions = props => {
  const { list } = useSelector(state => state.reviewSubmissions);
  const surveyReviewTypes = ['FA2-CR', 'FA1-FR'];

  const isFiscalYear2024 = props?.selectedReview?.fiscalYear === 2024;
  const isNewForms = props?.selectedReview?.isNewForms === true;

  const newFormReviewTypes = ['RAN', 'Special'];
  return (
    <>
      {props.selectedReview.reviewType === 'Follow-up' && isNewForms ? (
        <FollowUpSurveyDetails />
      ) : list && !isEmpty(list) ? (
        <FollowupSubmissionsList />
      ) : (
        <FollowupSubmissions />
      )}

      {props?.selectedReview &&
        ((newFormReviewTypes.includes(props?.selectedReview?.reviewType) &&
          props?.selectedReview?.isNewForms) ||
        surveyReviewTypes.includes(props?.selectedReview?.reviewType) ? (
          <SurveySubmissionsList {...props} />
        ) : (
          <ReviewSubmissionsList />
        ))}
    </>
  );
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
});

export default connect(mapStateToProps, {})(ReviewSubmissions);
