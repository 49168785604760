import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Checkbox,
  Dimmer,
  Divider,
  Form,
  Input,
  Loader,
} from 'semantic-ui-react';
import DOMPurify from 'dompurify';

//import actions
import { updateCollectedData } from '../../../actions/reviewActions';
import AmsAlert from '../../../utils/AmsAlert';
import FindingsDownloadAttachement from './FindingsDownloadAttachement';
import {
  findingCardClasses,
  renderLabelWithCheckbox,
  determineFindingStatus,
} from './FindingsUtils';

const FindingsStrongpracticesCard = props => {
  const { questionData, citation } = props;
  const { selectedReview } = useSelector(state => state.review);
  const [answerOptionsObj, setAnswerOptionsObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [activeQuestionId, setActiveQuestionId] = useState('');
  const boldLabelStyle = { fontFamily: 'PublicSans-Bold' };
  const dispatch = useDispatch();

  useEffect(() => {
    let toReturn = {};
    questionData.forEach(question => {
      toReturn[question.questionId] = question.collectedOptions.map(option => {
        if (option.custom) {
          return { ...option, edit: false };
        } else {
          return { ...option };
        }
      });
    });

    setAnswerOptionsObj(toReturn);
  }, [questionData]);

  const updateAnswers = questionId => {
    setLoading(true);

    const input = {
      findingId: props.findingId,
      questionId: questionId,
      collectedOptions: answerOptionsObj[questionId].map(e => {
        return { label: e.label, checked: e.checked, custom: e.custom };
      }),
    };
    dispatch(
      updateCollectedData(
        input,
        { reviewId: selectedReview.reviewId },
        { reviewType: selectedReview.reviewType },
        { isNewForms: selectedReview.isNewForms }
      )
    )
      .then(data => {
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
  };

  const handleCheckboxChange = (questionId, optionIndex) => {
    let newAnswerOptionsObj = { ...answerOptionsObj };
    newAnswerOptionsObj[questionId][optionIndex].checked = !newAnswerOptionsObj[
      questionId
    ][optionIndex].checked;
    setAnswerOptionsObj(newAnswerOptionsObj);
  };

  const handleCustomInputChange = (questionId, optionIndex, event) => {
    let newAnswerOptionsObj = { ...answerOptionsObj };
    newAnswerOptionsObj[questionId][optionIndex].label = event.target.value;
    setAnswerOptionsObj(newAnswerOptionsObj);
  };

  const handleEditButtonClick = (questionId, optionIndex) => {
    let newAnswerOptionsObj = { ...answerOptionsObj };
    newAnswerOptionsObj[questionId][optionIndex].edit = !newAnswerOptionsObj[
      questionId
    ][optionIndex].edit;
    setAnswerOptionsObj(newAnswerOptionsObj);
  };

  const showConfirmationAlert = () => {
    return (
      <AmsAlert
        show={showAlert}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Confirm"
        confirmButtonColor={'#DD6B55'}
        text={`Deselecting any answer option will cause the strong practice to be dropped from the report. Are you sure you want to continue?`}
        onConfirm={() => {
          setShowAlert(false);
          updateAnswers(activeQuestionId);
          props.handleSaveButtonAction('NO_CHECKBOX_CHECKED');
        }}
        onCancel={() => {
          setShowAlert(false);
        }}
      />
    );
  };

  return (
    <Card
      className={findingCardClasses(
        'findings-card',
        determineFindingStatus(selectedReview, citation)
      )}
    >
      <Dimmer active={loading} inverted>
        <Loader active={loading} inverted>
          Loading
        </Loader>
      </Dimmer>
      {showConfirmationAlert()}
      <Card.Content
        style={{ backgroundColor: '#E9E9E9', fontSize: '18px' }}
        header="Collected Data"
      />
      {questionData.map((question, index) => {
        const {
          evidenceSource,
          performanceArea,
          questionCode,
          shortDescription,
          questionText,
          narrative,
        } = question;

        return (
          <div style={{ margin: '10px', marginBottom: '-1px' }}>
            <Form className="findings-collect-data-card">
              {/* {index !== 0 && <Divider />} */}
              <Form.Field>
                <label>
                  <span style={{ fontFamily: 'PublicSans-Bold' }}>Guide: </span>
                  <span>{evidenceSource}</span>
                </label>
              </Form.Field>
              <Form.Field>
                <label>
                  <span style={{ fontFamily: 'PublicSans-Bold' }}>
                    Performance Area:{' '}
                  </span>
                  <span>{performanceArea}</span>
                </label>
              </Form.Field>

              <Form.Field>
                <label>{questionCode}</label>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      shortDescription || questionText
                    ),
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label style={{ fontFamily: 'PublicSans-Bold' }}>
                  Selected Answers
                </label>
                {answerOptionsObj[question.questionId] &&
                  answerOptionsObj[question.questionId].map((item, index) => {
                    if (item.custom) {
                      if (item.edit) {
                        return (
                          <Form.Group
                            inline
                            key={index}
                            className="custom-checkbox"
                          >
                            <Checkbox
                              disabled={selectedReview.readOnly}
                              onChange={() =>
                                handleCheckboxChange(question.questionId, index)
                              }
                              checked={item.checked}
                            />
                            <Input
                              disabled={selectedReview.readOnly}
                              value={item.label}
                              size={'small'}
                              onChange={event =>
                                handleCustomInputChange(
                                  question.questionId,
                                  index,
                                  event
                                )
                              }
                            />
                          </Form.Group>
                        );
                      } else {
                        return (
                          <Form.Group
                            inline
                            key={index}
                            className="custom-checkbox"
                          >
                            <Checkbox
                              disabled={selectedReview.readOnly}
                              onChange={() =>
                                handleCheckboxChange(question.questionId, index)
                              }
                              checked={item.checked}
                              label={item.label}
                            />
                            <Button
                              disabled={selectedReview.readOnly}
                              onClick={() =>
                                handleEditButtonClick(
                                  question.questionId,
                                  index
                                )
                              }
                            >
                              Edit
                            </Button>
                          </Form.Group>
                        );
                      }
                    } else {
                      return (
                        <Form.Group
                          inline
                          key={index}
                          className="custom-checkbox"
                        >
                          <Checkbox
                            disabled={selectedReview.readOnly}
                            key={index}
                            label={renderLabelWithCheckbox(
                              item,
                              handleCheckboxChange,
                              question.questionId,
                              index,
                              selectedReview.readOnly
                            )}
                            checked={item.checked}
                          />
                        </Form.Group>
                      );
                    }
                  })}
              </Form.Field>
              <Button
                style={{
                  backgroundColor: '#245E9D',
                  color: 'white',
                  width: '250px',
                  marginTop: '10px',
                }}
                disabled={selectedReview.readOnly}
                onClick={() => {
                  if (
                    Object.values(answerOptionsObj).every(arr =>
                      arr.some(item => item.checked === false)
                    )
                  ) {
                    setShowAlert(true);
                    setActiveQuestionId(question.questionId);
                  } else {
                    updateAnswers(question.questionId);
                    props.handleSaveButtonAction('SAVE');
                  }
                }}
              >
                Save Answers
              </Button>
              <Form.Field width={16}>
                <div
                  style={{ fontFamily: 'PublicSans-Bold', marginTop: '20px' }}
                >
                  Notes
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(narrative),
                  }}
                />
              </Form.Field>
              <Form.Field>
                <span style={boldLabelStyle}>Evidence: </span>
                {question?.evidenceFiles?.length > 0 ? (
                  <FindingsDownloadAttachement
                    evidenceFiles={question?.evidenceFiles}
                  />
                ) : (
                  <div>No evidence is available</div>
                )}
              </Form.Field>
              <Divider />
            </Form>
          </div>
        );
      })}
    </Card>
  );
};

export default FindingsStrongpracticesCard;
