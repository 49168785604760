import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import Actions
import { fetchPassFailReport } from '../../actions/submissions';

// Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';
import AmsTable from '../../utils/AmsTable';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import { isEmpty } from 'lodash';
import { Grid, Table } from 'semantic-ui-react';

class CLASSPassFailReport extends Component {
  state = {
    passFailReport: {},
  };

  componentDidMount() {
    let input = {
      reviewId: this.context.router.params.reviewId, //"1805555C"
    };
    this.props.fetchPassFailReport(input).then(data => {
      this.setState({
        passFailReport: {
          ...data.passFailReport,
        },
      });
    });
  }

  isExpandableRow = row => {
    return true;
  };

  observationCycle = (rowP, rowC) => {
    if (rowC.observationCycle === 1) return 'One';
    else return 'Two';
  };

  userType = (rowP, rowC) => {
    if (rowC.role === 'R') return `${rowP.reviewer} (${rowC.role})`;
    else if (rowC.role === 'DC') return `${rowP.dualCoder} (${rowC.role})`;
  };

  submissionTime = (rowP, rowC) => {
    return AmsDateFormatters.formatDateTime(rowC.cycleDate);
  };

  centerClassName = (rowP, row) => {
    return `${row.className} - ${row.classroomName}`;
  };

  expandComponent = rowP => (
    <div style={{ marginLeft: '9.5px', width: '100%' }}>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <div className="table-container">
              <Table celled className="table-bordered">
                <Table.Header className="td-column-pass-fail-header">
                  <Table.Row
                    style={{ verticalAlign: 'top', textAlign: 'left' }}
                  >
                    <Table.HeaderCell rowSpan="3">
                      Center Name-Classroom Name
                    </Table.HeaderCell>
                    <Table.HeaderCell rowSpan="3">
                      Observation Cycle
                    </Table.HeaderCell>
                    <Table.HeaderCell rowSpan="3">User (Type)</Table.HeaderCell>
                    <Table.HeaderCell rowSpan="3">
                      Submission Time
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan="10">
                      Scores and Percentage
                    </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>PC</Table.HeaderCell>
                    <Table.HeaderCell>NC</Table.HeaderCell>
                    <Table.HeaderCell>
                      {this.state.passFailReport.reviewType == 'PR-CLASS' &&
                      this.context.router.params.fiscalYear != 2022
                        ? 'ES'
                        : 'TS'}
                    </Table.HeaderCell>
                    <Table.HeaderCell>RSP</Table.HeaderCell>
                    <Table.HeaderCell>BM</Table.HeaderCell>
                    <Table.HeaderCell>PD</Table.HeaderCell>
                    <Table.HeaderCell>ILF</Table.HeaderCell>
                    <Table.HeaderCell>CD</Table.HeaderCell>
                    <Table.HeaderCell>QF</Table.HeaderCell>
                    <Table.HeaderCell>LM</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>{rowP.PC}</Table.HeaderCell>
                    <Table.HeaderCell>{rowP.NC}</Table.HeaderCell>
                    <Table.HeaderCell>{rowP.TS}</Table.HeaderCell>
                    <Table.HeaderCell>{rowP.RSP}</Table.HeaderCell>
                    <Table.HeaderCell>{rowP.BM}</Table.HeaderCell>
                    <Table.HeaderCell>{rowP.PD}</Table.HeaderCell>
                    <Table.HeaderCell>{rowP.ILF}</Table.HeaderCell>
                    <Table.HeaderCell>{rowP.CD}</Table.HeaderCell>
                    <Table.HeaderCell>{rowP.QF}</Table.HeaderCell>
                    <Table.HeaderCell>{rowP.LM}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {!isEmpty(rowP?.data) ? (
                    rowP?.data?.map((rowC, index) => (
                      <Table.Row
                        className="td-column-pass-fail-column"
                        key={index}
                      >
                        <Table.Cell>
                          {this.centerClassName(rowP, rowC)}
                        </Table.Cell>
                        <Table.Cell>
                          {this.observationCycle(rowP, rowC)}
                        </Table.Cell>
                        <Table.Cell>{this.userType(rowP, rowC)}</Table.Cell>
                        <Table.Cell>
                          {this.submissionTime(rowP, rowC)}
                        </Table.Cell>
                        <Table.Cell>{rowC.positiveClimate}</Table.Cell>
                        <Table.Cell>{rowC.negativeClimate}</Table.Cell>
                        <Table.Cell>{rowC.teacherSensitivity}</Table.Cell>
                        <Table.Cell>{rowC.studentPerspectiveRegard}</Table.Cell>
                        <Table.Cell>{rowC.behaviorManagement}</Table.Cell>
                        <Table.Cell>{rowC.productivity}</Table.Cell>
                        <Table.Cell>
                          {rowC.instructionalLearningFormats}
                        </Table.Cell>
                        <Table.Cell>{rowC.conceptDevelopment}</Table.Cell>
                        <Table.Cell>{rowC.feedbackQuality}</Table.Cell>
                        <Table.Cell>{rowC.languageModeling}</Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row>
                      <Table.HeaderCell
                        colSpan="14"
                        style={{ textAlign: 'center' }}
                      >
                        There is no data to display
                      </Table.HeaderCell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );

  preparePassFailReport = () => {
    const { passFailReport } = this.props;

    const data = passFailReport.reportData
      ? passFailReport.reportData.map((d, index) => {
          return {
            ...d,
            id: index,
          };
        })
      : [];

    const columRenderer = ({ expanded, rowKey, expandable }) => {
      let content = '';

      content = expanded ? '-' : '+';

      return <div> {content} </div>;
    };

    const expandRow = {
      renderer: this.expandComponent.bind(this),
      showExpandColumn: true,
      expandColumnRenderer: columRenderer,
    };

    const columns = [
      {
        dataField: 'id',
        hidden: true,
        text: 'id',
        headerStyle: {
          width: '20%',
        },
      },
      {
        dataField: 'reviewer',
        text: 'Reviewer',
        headerStyle: {
          width: '20%',
        },
      },
      {
        dataField: 'dualCoder',
        text: 'Dual Coder',
        headerStyle: {
          width: '20%',
        },
      },
      {
        dataField: 'results',
        text: 'Result',
        headerStyle: {
          width: '20%',
        },
      },
      {
        dataField: 'match',
        text: 'Match',
        headerStyle: {
          width: '20%',
        },
      },
      {
        dataField: 'Cnt',
        text: 'Total Observation Cycles',
        headerStyle: {
          width: '20%',
        },
      },
    ];

    return (
      <div className="table-responsive-wrapper">
        <AmsTable
          data={data || []}
          expandRow={expandRow}
          remote={false}
          total={data ? data.length : 0}
          columns={columns}
          keyField="id"
          pagination={data ? data.length > 10 : false}
        ></AmsTable>
      </div>
    );
  };

  render() {
    const { reviewId, reviewType, GrantInfo } = this.state.passFailReport;
    const grantee = GrantInfo ? GrantInfo[0] : {};
    return (
      <div>
        <SlidingContainer
          // calendar
          title={
            'Dual Coding Pass Fail Summary Report for Review ID ' +
            this.context.router.params.reviewId
          }
        >
          {/* {assignments.assignmentChangeFlag &&
            <Message
              negative
              icon='warning'
              header='Notice'
              content={`The New ${assignments.sampleLabel} Class Sampling report has been generated on ${moment(assignments.sampleGenrationDate).format("MMM Do YYYY")}. All the earlier assignments made if any based on older version of samples will be replaced.`}
            />
          } */}
          <div className="well survey-header-info">
            {(grantee || {}).granteeName} ( {(grantee || {}).granteeId} ) |{' '}
            {reviewType} ( {reviewId} )
          </div>
          {this.preparePassFailReport()}
        </SlidingContainer>
      </div>
    );
  }
}

CLASSPassFailReport.propTypes = {};

CLASSPassFailReport.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    selectedReview: state.review.selectedReview,
    passFailReport: state.submissions.passFailReport,
  };
}

export default connect(mapStateToProps, { fetchPassFailReport })(
  CLASSPassFailReport
);
