import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import _ from 'lodash';
import { Loader, Dimmer, Tab, Button, Popup, Menu } from 'semantic-ui-react';

import AmsAlert from '../../utils/AmsAlert';
import { allowedView } from '../../utils/EnforceRole';

// React Bootstrap Table 2
import BootstrapTableNext from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

// Import actions.
import {
  fetchTaskList,
  fetchDelegatedTaskList,
  deleteTask,
  fetchReportFindingsDetail,
  exportDelegatedTasks,
} from '../../actions/taskActions';

// Import utils
import enforceRole from '../../utils/EnforceRole';
import AmsTable from '../../utils/AmsTable';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

//Import components
import ReportTrackerFindingsModal from './ReportTrackerFindingsModal';

// Import config
import { acl } from '../../config';

class TaskItems extends React.Component {
  state = {
    loading: false,
    showAlert: false,
    deleteTask: '',
    tasks: [],
    page: 1,
    limit: 25,
    sortName: 'reviewEndDate',
    sortOrder: 'desc',
    filters: {},

    activePane: 0,
  };

  componentDidMount = () => {
    this.props.onFilter(this); // It registers and calls getData.
    this.amsTableRef = React.createRef();
  };

  componentWillUnmount = () => {
    this.props.onFilter(null);
  };

  componentWillReceiveProps = props => {
    const { tasks, total, showViewAllFindings, filters } = props;
    this.setState({
      tasks,
      total,
      showViewAllFindings,
      filters: filters || {},
    });
  };

  getData = () => {
    const { fetchTaskList, fetchDelegatedTaskList } = this.props;
    const { page, limit, filters, sortName, sortOrder } = this.state;

    let input = {
      filters,
      page,
      limit,
      sortName,
      sortOrder,
    };

    this.setState({ loading: true });

    fetchTaskList(input)
      .then(() => {
        fetchDelegatedTaskList(input);
      })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.onError(error);
      });
  };

  showDeleteConfirmation() {
    const { deleteTask, showAlert } = this.state;

    return (
      <AmsAlert
        show={showAlert}
        title="Delete Task"
        text={`Are you sure you want to delete this task?`}
        type={'warning'}
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes, delete it!'}
        cancelButtonText={'No, cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({ showAlert: false });
        }}
        onConfirm={() => {
          this.setState({ showAlert: false, deleteTask: '' });
          if (deleteTask)
            this.props.deleteTask(deleteTask).then(() => this.getData());
        }}
      />
    );
  }

  viewAllFindings = (type = 'default') => {
    let input = {
      filters: {},
      isViewAllFindings: true,
      page: 1,
      limit: 100,
      sortName: 'dueDate',
      sortOrder: 'desc',
    };

    if (type === 'delegated') input.delegated = true;

    this.props.fetchTaskList(input).then(data => {
      if (data) this.context.router.push(`/task/allFindings`);
    });
  };

  renderTaskTableNext = () => {
    const {
      tasks,
      total,
      limit,
      page,
      loading,
      //sortName,
      sortOrder,
    } = this.state;

    const dateFormatter = (cell, row) => {
      return AmsDateFormatters.formatDate(cell);
    };

    const linkId = (cell, row) => {
      const grantee = {
        granteeName: !_.isEmpty(row.reviewGrantInfo[0].granteeName)
          ? row.reviewGrantInfo[0].granteeName
          : '',
        granteeId: !_.isEmpty(row.reviewGrantInfo[0].granteeId)
          ? row.reviewGrantInfo[0].granteeId
          : '',
      };
      const reviewerInfo = {
        submitterName: row.taskAssigneeName,
        submitterOid: row.taskAssigneeOid,
      };
      const roleFlag = _.some(
        [
          'DualCoder',
          'ReviewLead',
          'FieldOperationsManager',
          'AmsAdmi',
          'Analyst',
          'TechnicalWriter',
          'Editor',
          'HelpDesk',
        ],
        role => _.includes(this.props.currentUser.roles, role)
      );
      const reviewStatus = row.taskStatus;
      const reviewType = row.reviewType;
      if (
        row.reviewId &&
        row.taskDefKey === 'performDataCollection' &&
        (row.reviewType === 'CLASS' ||
          row.reviewType === 'AIAN-CLASS' ||
          row.reviewType === 'PR-CLASS' ||
          row.reviewType === 'VP-CLASS' ||
          row.reviewType === 'CLASS-Video' ||
          row.reviewType === 'AIAN CLASS Self-Observations' ||
          row.reviewType === 'CLASS AIAN Onsite' ||
          row.reviewType === 'CLASS AIAN Video')
      ) {
        let params = {
          pathname: `/task/${row.fiscalYear}/${row.reviewId}/survey/class`,
          query: {
            ...reviewerInfo,
            ...grantee,
            roleFlag,
            reviewStatus,
            reviewType,
          },
        };
        return <Link to={params}>{row.reviewId}</Link>;
      } else if (row.reviewId)
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href=""
            onClick={event => {
              event.preventDefault();
              this.props.taskItemClick(row);
            }}
          >
            {cell}
          </a>
        );
    };

    const onPageChange = (page, sizePerPage) =>
      this.setState({ page, limit: sizePerPage }, () => this.getData());

    const onSortChange = (sortName, sortOrder) =>
      this.setState({ sortName, sortOrder }, () => this.getData());

    // const renderPaginationShowsTotal = (start, to, total) => (
    //   <div className="pagination-total">
    //     Showing rows {start} to {to} of {total}
    //   </div>
    // );

    const handleTableChange = (type, { sortField, sortOrder }) => {
      if (type === 'sort' && sortField && sortOrder)
        onSortChange(sortField, sortOrder);
    };

    const taskList = tasks.map(task => ({
      ...task,
      granteeId:
        !_.isEmpty(task.reviewGrantInfo) && task.reviewGrantInfo.length
          ? task.reviewGrantInfo[0].granteeId.trim()
          : '',
      granteeName:
        !_.isEmpty(task.reviewGrantInfo) && task.reviewGrantInfo.length
          ? task.reviewGrantInfo[0].granteeName.trim()
          : '',
    }));

    // const options = {
    //   sizePerPage: limit,
    //   onPageChange: onPageChange,
    //   onSortChange: onSortChange,
    //   sortName: sortName,
    //   sortOrder: sortOrder,
    //   page: page,
    //   paginationShowsTotal: renderPaginationShowsTotal,
    //   totalSize: total,
    //   hidePageListOnlyOnePage: true,
    // };

    const paginationOptions = {
      page,
      sizePerPage: limit,
      totalSize: total,
      sortOrder: sortOrder,
      onPageChange: onPageChange,
    };

    const columns = [
      {
        dataField: 'reviewId',
        text: 'Review ID',
        formatter: linkId,
        sort: true,
      },
      {
        dataField: 'granteeId',
        text: 'Grantee ID',
        sort: true,
      },
      {
        dataField: 'granteeName',
        text: 'Grantee Name',
        sort: true,
      },
      {
        dataField: 'taskAssigneeName',
        text: 'Assignee',
        sort: true,
      },
      {
        dataField: 'taskNameShort',
        text: 'Description',
        sort: true,
      },
      {
        dataField: 'reviewType',
        text: 'Review Type',
        sort: true,
      },
      {
        dataField: 'reviewStartDate',
        text: 'Start Date',
        formatter: dateFormatter,
        sort: true,
      },
      {
        dataField: 'reviewEndDate',
        text: 'End Date',
        formatter: dateFormatter,
        sort: true,
      },
    ];

    return loading ? (
      <Dimmer active inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
    ) : (
      <BootstrapTableNext
        striped
        hover
        keyField="reviewId"
        data={taskList}
        columns={columns}
        bordered={false}
        remote={{ pagination: true, sort: true }}
        pagination={paginationFactory(paginationOptions)}
        onTableChange={handleTableChange}
      />
    );
  };

  renderTasksResult = () => {
    const {
      tasksList: { data, page, total, showViewAllFindings },
    } = this.props;

    const delegatedTasks = this.props.delegatedTasksList;

    const tasks = data.map(task => ({
      ...task,
      granteeId:
        !_.isEmpty(task.reviewGrantInfo) && task.reviewGrantInfo.length
          ? task.reviewGrantInfo[0].granteeId.trim()
          : '',
      granteeName:
        !_.isEmpty(task.reviewGrantInfo) && task.reviewGrantInfo.length
          ? task.reviewGrantInfo[0].granteeName.trim()
          : '',
    }));

    const delegatedTaskItems =
      delegatedTasks &&
      delegatedTasks.data.map(task => ({
        ...task,
        granteeId:
          !_.isEmpty(task.reviewGrantInfo) && task.reviewGrantInfo.length
            ? task.reviewGrantInfo[0].granteeId.trim()
            : '',
        granteeName:
          !_.isEmpty(task.reviewGrantInfo) && task.reviewGrantInfo.length
            ? task.reviewGrantInfo[0].granteeName.trim()
            : '',
      }));

    const deletgatedShowViewAllFindings =
      delegatedTasks && delegatedTasks.showViewAllFindings === true;

    const delegatedPermissions =
      delegatedTasks && delegatedTasks.hasPermission === true;

    const dateFormatter = (cell, row) => {
      return AmsDateFormatters.formatDate(cell);
    };

    const findingsFormatter = (cell, row) => {
      if (
        (row.taskStatus === 'Expired' || row.taskStatus === 'Cancelled') &&
        row.taskDefKey === 'provideReportComments'
      ) {
        return row.findingsCount;
      } else if (row.taskDefKey === 'provideReportComments') {
        return (
          <Popup
            content={`Select to view findings for Review ID: ${row.reviewId}`}
            position="top center"
            basic
            on={['hover', 'focus']}
            trigger={
              <Link
                to="#"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();

                  if (this.state.activePane === 1) {
                    this.props
                      .fetchReportFindingsDetail({
                        ...row,
                        delegated: true,
                      })
                      .then(data => {
                        if (data && row.isNewTask !== true) {
                          this.context.router.push(
                            `/task/${data[0].reviewId}/legacyFindings`
                          );
                        } else if (data && row.isNewTask === true) {
                          this.context.router.push(
                            `/task/${data[0].reviewId}/findings`
                          );
                        }
                      });
                    return;
                  }
                  this.props.fetchReportFindingsDetail(row).then(data => {
                    if (data && row.isNewTask !== true) {
                      this.context.router.push(
                        `/task/${data[0].reviewId}/legacyFindings`
                      );
                    } else if (data && row.isNewTask === true) {
                      this.context.router.push(
                        `/task/${data[0].reviewId}/findings`
                      );
                    }
                  });
                }}
              >
                {row.findingsCount}
                <span
                  style={{ transition: 'none 0s ease 0s' }}
                  className="visually-hidden"
                >
                  {`View findings for Review ID: ${row.reviewId}`}
                </span>
              </Link>
            }
          />
        );
      }
      return 'N/A';
    };

    const reportLinkFormatter = (cell, row) => {
      if (
        (row.taskStatus === 'Expired' || row.taskStatus === 'Cancelled') &&
        row.taskDefKey === 'provideReportComments'
      ) {
        return 'N/A';
      } else if (row.taskDefKey !== 'provideReportComments') return 'N/A';
      else {
        const addComment = `/add-comment?key=${row.hashContent}`;
        return (
          <a
            aria-label={`Report Link for ${row.reviewId}`}
            href={addComment}
            target="_blank"
            rel="noopener noreferrer"
          >
            Report
          </a>
        );
      }
    };

    const actionFormatter = (cell, row) => {
      if (
        row.taskDefKey !== 'provideReportComments' ||
        (row.forwardFindings === false && this.state.activePane === 0) ||
        (row.provideFeedback === false && this.state.activePane === 1)
      )
        return 'N/A';
      else
        return (
          <>
            {row.provideFeedback === true && this.state.activePane === 1 ? (
              <ReportTrackerFindingsModal
                type="feedback"
                context="taskSummaryPage"
                level="review"
                index={row}
                allFindings={false}
                delegated={this.state.activePane === 1 ? true : false}
                callbackOnSubmit={() => {
                  this.getData();
                }}
              />
            ) : null}
            {row.forwardFindings === true ? (
              <ReportTrackerFindingsModal
                type="forward"
                context="taskSummaryPage"
                index={row}
                alLFindings={false}
              />
            ) : null}
          </>
        );
    };

    const linkId = (cell, row) => {
      const grantee = {
        granteeName: !_.isEmpty(row.reviewGrantInfo[0])
          ? row.reviewGrantInfo[0].granteeName
          : '',
        granteeId: !_.isEmpty(row.reviewGrantInfo[0])
          ? row.reviewGrantInfo[0].granteeId
          : '',
      };
      const reviewerInfo = {
        submitterName: row.taskAssigneeName,
        submitterOid: row.taskAssigneeOid,
      };
      const roleFlag = _.some(
        [
          'DualCoder',
          'ReviewLead',
          'FieldOperationsManager',
          'AmsAdmi',
          'Analyst',
          'TechnicalWriter',
          'Editor',
          'HelpDesk',
        ],
        role => _.includes(this.props.currentUser.roles, role)
      );
      const reviewStatus = row.taskStatus;
      const reviewType = row.reviewType;
      if (
        row.reviewId &&
        row.taskDefKey === 'performDataCollection' &&
        (row.reviewType === 'CLASS' ||
          row.reviewType === 'AIAN-CLASS' ||
          row.reviewType === 'PR-CLASS' ||
          row.reviewType === 'VP-CLASS' ||
          row.reviewType === 'CLASS-Video' ||
          row.reviewType === 'AIAN CLASS Self-Observations' ||
          row.reviewType === 'CLASS AIAN Onsite' ||
          row.reviewType === 'CLASS AIAN Video')
      ) {
        let params = {
          pathname: `/task/${row.fiscalYear}/${row.reviewId}/survey/class`,
          query: {
            ...reviewerInfo,
            ...grantee,
            roleFlag,
            reviewStatus,
            reviewType,
          },
        };
        return <Link to={params}>{row.reviewId}</Link>;
      } else if (
        row.reviewId &&
        row.hashContent &&
        row.taskDefKey === 'provideReportComments'
      ) {
        if (
          (row.taskStatus === 'Expired' || row.taskStatus === 'Cancelled') &&
          row.taskDefKey === 'provideReportComments'
        ) {
          return row.reviewId;
        } else {
          return (
            <a
              href={'review/' + row.reviewId}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.reviewId}
            </a>
          );
        }
      } else if (
        row.reviewId &&
        (row.taskStatus === 'Expired' || row.taskStatus === 'Cancelled') &&
        row.taskDefKey === 'provideReportComments'
      ) {
        return row.reviewId;
      } else if (row.reviewId)
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href=""
            onClick={event => {
              event.preventDefault();
              this.props.taskItemClick(row);
            }}
          >
            {cell}
          </a>
        );
    };

    const scrollToRef = () => {
      window.scrollTo(0, this.amsTableRef.current.offsetTop);
      this.amsTableRef.current.focus();
    };

    const onSizePerPageChange = (sizePerPage, page) => {
      this.setState(
        {
          page,
          limit: sizePerPage,
        },
        () => {
          this.getData();

          // Go to the top of the summary table
          scrollToRef();
        }
      );
    };

    const onPageChange = (page, sizePerPage) => {
      this.setState(
        {
          page,
          limit: sizePerPage,
        },
        () => {
          this.getData();

          // Go to the top of the summary table
          scrollToRef();
        }
      );
    };

    const onTableChange = (type, { sortField, sortOrder }) => {
      if (
        sortField === this.state.sortName &&
        sortOrder === this.state.sortOrder
      )
        return null;
      this.setState(
        {
          sortName: sortField || this.state.sortName,
          sortOrder: sortOrder || this.state.sortOrder,
        },
        () => this.getData()
      );
    };
    const columns = [
      {
        dataField: 'reviewId',
        text: 'Review ID',
        formatter: linkId,
        sort: true,
      },
      {
        dataField: 'granteeId',
        text: 'Grantee ID',
        sort: true,
      },
      {
        dataField: 'granteeName',
        text: 'Grantee Name',
        sort: true,
      },
      {
        dataField: 'taskAssigneeName',
        text: 'Assignee',
        sort: true,
      },
      {
        dataField: 'taskNameShort',
        text: 'Description',
        sort: true,
      },
      {
        dataField: 'reviewType',
        text: 'Review Type',
        sort: true,
      },
      {
        dataField: 'reviewStartDate',
        text: 'Start Date',
        formatter: dateFormatter,
        sort: true,
      },
      {
        dataField: 'reviewEndDate',
        text: 'Task Due Date',
        formatter: dateFormatter,
        sort: true,
      },
      {
        dataField: 'taskStatus',
        text: 'Status',
        sort: true,
      },
      {
        dataField: 'findingsCount',
        text: 'Total Findings',
        formatter: findingsFormatter,
        sort: true,
      },
      {
        dataField: 'reportLink',
        text: 'Report Link',
        formatter: reportLinkFormatter,
      },
      {
        dataField: 'action',
        text: 'Action',
        formatter: actionFormatter,
        headerStyle: { width: '5%' },
      },
    ];

    const panes = [
      {
        menuItem: (
          <Menu.Item key="myTasks">
            <span style={{ textDecoration: 'none', color: 'inherit' }}>
              My Tasks
            </span>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane style={{ overflowX: 'scroll' }}>
            {' '}
            <div ref={this.amsTableRef} tabIndex="-1">
              <div className="all-findings-wrapper">
                <Button
                  disabled={!showViewAllFindings}
                  style={{
                    backgroundColor: '#1875f0',
                    float: 'right',
                    color: 'white',
                  }}
                  className="all-findings-button"
                  onClick={this.viewAllFindings}
                >
                  {allowedView(
                    acl.actions.task.hideStrongPractices,
                    this.props.currentUser.roles
                  ) ? (
                    <span>View all Findings</span>
                  ) : (
                    <span>View all Findings and Strong Practices</span>
                  )}
                </Button>
              </div>
              <AmsTable
                remote
                data={tasks}
                page={page}
                total={total}
                limit={this.state.limit}
                loading={this.state.loading}
                columns={columns}
                keyField="_id"
                onTableChange={onTableChange}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
              />
            </div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="delegatedTasks">
            <span style={{ textDecoration: 'none', color: 'inherit' }}>
              OHS Delegated Tasks
            </span>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane style={{ overflowX: 'scroll' }}>
            {' '}
            <div ref={this.amsTableRef} tabIndex="-1">
              <div className="all-findings-wrapper">
                <ReportTrackerFindingsModal
                  type="feedback"
                  context="taskSummaryPage"
                  level="review"
                  extraPopupText=" on This Page"
                  allDelegated={true}
                  index={
                    delegatedTaskItems &&
                    delegatedTaskItems.filter(e => {
                      return e.provideFeedback !== false;
                    })
                  }
                  allFindings={false}
                  delegated={true}
                  callbackOnSubmit={() => {
                    this.getData();
                  }}
                />
                <Button
                  disabled={!deletgatedShowViewAllFindings}
                  style={{
                    backgroundColor: '#1875f0',
                    float: 'right',
                    color: 'white',
                  }}
                  className="all-findings-button"
                  onClick={() => this.viewAllFindings('delegated')}
                >
                  {allowedView(
                    acl.actions.task.hideStrongPractices,
                    this.props.currentUser.roles
                  ) ? (
                    <span>View all Findings</span>
                  ) : (
                    <span>View all Findings and Strong Practices</span>
                  )}
                </Button>
                <Button
                  style={{
                    float: 'right',
                    backgroundColor: 'rgb(18,64,141)',
                    color: 'white',
                  }}
                  onClick={() => {
                    this.props.exportDelegatedTasks();
                  }}
                >
                  Export
                </Button>
              </div>
              <AmsTable
                remote
                data={delegatedTaskItems || []}
                page={delegatedTasks && delegatedTasks.page}
                total={delegatedTasks && delegatedTasks.total}
                limit={this.state.limit}
                loading={this.state.loading}
                columns={columns}
                keyField="_id"
                onTableChange={onTableChange}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
              />
            </div>
          </Tab.Pane>
        ),
      },
    ];

    const handleChange = (e, { activeIndex }) => {
      if (this.state.page !== 1) {
        this.setState({ ...this.state, activePane: activeIndex, page: 1 }, () =>
          this.getData()
        );
      } else {
        this.setState({ ...this.state, activePane: activeIndex });
      }
    };

    //if permissions
    if (delegatedPermissions)
      return (
        <Tab
          onTabChange={handleChange}
          style={{ marginTop: '20px' }}
          panes={panes}
        ></Tab>
      );

    return (
      <div ref={this.amsTableRef} tabIndex="-1">
        <div className="all-findings-wrapper">
          <Button
            disabled={!showViewAllFindings}
            style={{
              backgroundColor: '#1875f0',
              float: 'right',
              color: 'white',
            }}
            className="all-findings-button"
            onClick={this.viewAllFindings}
          >
            {allowedView(
              acl.actions.task.hideStrongPractices,
              this.props.currentUser.roles
            ) ? (
              <span>View all Findings</span>
            ) : (
              <span>View all Findings and Strong Practices</span>
            )}
          </Button>
        </div>
        <AmsTable
          remote
          data={tasks}
          page={page}
          total={total}
          limit={this.state.limit}
          loading={this.state.loading}
          columns={columns}
          keyField="_id"
          onTableChange={onTableChange}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.showDeleteConfirmation()}
        {this.renderTasksResult()}
        {/* {this.renderTaskTable()} */}
        {/* {this.renderTaskTableNext()} */}
      </div>
    );
  }
}

TaskItems.contextTypes = {
  router: PropTypes.object.isRequired,
};

TaskItems.propTypes = {
  currentUser: PropTypes.object,
  tasks: PropTypes.array,
  fetchTaskList: PropTypes.func.isRequired,
  fetchDelegatedTaskList: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  tasksList: state.tasks.tasksList,
  delegatedTasksList: state.tasks.delegatedTasksList,
  total: state.tasks.total,
});

export default connect(mapStateToProps, {
  fetchTaskList,
  fetchDelegatedTaskList,
  deleteTask,
  fetchReportFindingsDetail,
  exportDelegatedTasks,
})(withRouter(TaskItems));
