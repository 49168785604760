import { groupBy, isEmpty } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dimmer, Grid, Header, Icon, Loader, Segment } from 'semantic-ui-react';

// Import Components
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import SlidingContainer from '../../../utils/layout/SlidingContainer';

//Import actions.
import { fetchGrantee } from '../../../actions/granteeActions';
import GranteeSidebar from '../../GranteeAvailability/GranteeSidebar';
const DATE_FORMAT = 'M-YYYY';

class ScheduleView extends Component {
  state = {
    loading: true,
    allUnavailabilityDates: [],
    responseErrors: [],
  };

  componentDidMount() {
    // Useful when user directly navigates using /grantee/:id not click on grantee row.
    this.getData();
  }

  getData() {
    const granteeId = this.props.router.params.id;

    this.setState({ loading: true });

    this.props
      .fetchGrantee(granteeId)
      .then(() => {
        this.getUnavailabilityDates();

        this.setState({
          loading: false,
        });
      })
      .catch(error =>
        this.setState({
          responseErrors: [...this.state.responseErrors],
        })
      );
  }

  getUnavailabilityDates() {
    const { grantee } = this.props;

    const date = d => ({
      fullDate: AmsDateFormatters.formatDate(d),
      month: AmsDateFormatters.getMoment(d).format('MMM'),
      year: AmsDateFormatters.getMoment(d).format('YYYY'),
      monthYear: AmsDateFormatters.getMoment(d).format(DATE_FORMAT),
    });

    if (grantee) {
      const allUnavailabilityDates = [];

      const notInSessionDates =
        grantee.notInSessionDates &&
        grantee.notInSessionDates.map(d => ({
          ...date(d),
          typeDescription: 'Not in Session',
          color: '#205493',
        }));

      !isEmpty(notInSessionDates) &&
        allUnavailabilityDates.push(notInSessionDates);

      const springBreakDates =
        grantee.springBreakDates &&
        grantee.springBreakDates.map(d => ({
          ...date(d),
          typeDescription: 'Spring Break',
          color: '#2e8540',
        }));

      !isEmpty(springBreakDates) &&
        allUnavailabilityDates.push(springBreakDates);

      const unavailabilityDates =
        grantee.unavailabilityDates &&
        grantee.unavailabilityDates.map(d => ({
          ...date(d),
          typeDescription: 'Unavailable',
          color: '#323a45',
        }));

      !isEmpty(unavailabilityDates) &&
        allUnavailabilityDates.push(unavailabilityDates);

      const groupedUnavailabilityDates = groupBy(
        [].concat(...Object.values(allUnavailabilityDates)),
        'monthYear'
      );

      this.setState({
        allUnavailabilityDates: this.formatUnavailablilityDates(
          groupedUnavailabilityDates
        ),
      });
    }
  }

  formatUnavailablilityDates(allUnavailabilityDates) {
    // Review year start usually runs from October - September
    const startAt = AmsDateFormatters.getMoment().set('month', 9);

    const unavailablilityDatesList = [];
    let dates = [];

    for (let i = 0; i < 12; i++) {
      const nextMonth = AmsDateFormatters.getMoment(startAt)
        .add(i, 'month')
        .format(DATE_FORMAT);

      dates = allUnavailabilityDates[nextMonth];

      if (!isEmpty(dates)) unavailablilityDatesList.push(dates);
    }

    return unavailablilityDatesList;
  }

  render() {
    const {
      grantee: { granteeId, granteeName },
    } = this.props;
    const { loading, allUnavailabilityDates } = this.state;

    const formatDates = dates =>
      dates &&
      sortDate(dates).map((date, key) => (
        <Grid.Row key={key}>
          <Grid.Column width={8}>
            <Icon name="circle" style={{ color: date.color }} /> &nbsp;
            {AmsDateFormatters.getMoment(date.fullDate).format('MMMM D')}
          </Grid.Column>
          <Grid.Column width={8}>
            {String(date.typeDescription).trim()}
          </Grid.Column>
        </Grid.Row>
      ));

    const sortDate = d =>
      d.sort(
        (a, b) =>
          new AmsDateFormatters.formatDate(a.fullDate) -
          new AmsDateFormatters.formatDate(b.fullDate)
      );

    const unavailabilityDates =
      allUnavailabilityDates &&
      allUnavailabilityDates.map((dates, key) => (
        <Grid.Row key={key}>
          <Grid.Column width={2}>
            <Segment basic>
              <Header
                as={'h3'}
                content={dates[0].month}
                style={{ textTransform: 'uppercase', marginBottom: 0 }}
              />
              <Header
                as={'h5'}
                content={dates[0].year}
                style={{
                  textTransform: 'uppercase',
                  marginTop: 0,
                  color: '#888',
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment basic>
              <Grid columns="equal">{formatDates(dates)}</Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column width={6} />
        </Grid.Row>
      ));

    return (
      <>
        <SlidingContainer
          title={granteeId && granteeName && `${granteeName} (${granteeId})`}
          sidebarComponent={
            <GranteeSidebar
              hasEditableRoles={this.props.grantee?.isEditableRole}
            />
          }
        >
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted>Loading...</Loader>
            </Dimmer>
          ) : (
            <Grid columns="equal" divided="vertically">
              {unavailabilityDates}
            </Grid>
          )}
        </SlidingContainer>
      </>
    );
  }
}

const mapStateToProps = state => ({
  grantee: state.grantee,
});

export default connect(mapStateToProps, { fetchGrantee })(
  withRouter(ScheduleView)
);
