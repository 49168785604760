import React from 'react';
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';

// react-widgets
import SelectList from 'react-widgets/lib/SelectList';
import 'react-widgets/dist/css/react-widgets.css';

// react-select
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export function FieldGroupField({
  validationState,
  bsSize,
  id,
  label,
  help,
  ...props
}) {
  return (
    <FormGroup
      validationState={validationState}
      bsSize={bsSize || 'large'}
      controlId={id}
    >
      {label && <ControlLabel>{label}</ControlLabel>}
      <FormControl {...props} />
      <FormControl.Feedback />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}

export function FieldGroupSelectList({ validationState, ...props }) {
  return (
    <FormGroup
      validationState={validationState}
      bsSize={props.bsSize || 'large'}
      controlId={props.id}
    >
      {props.label && <ControlLabel>{props.label}</ControlLabel>}
      <SelectList {...props} />
      {props.help && <HelpBlock>{props.help}</HelpBlock>}
    </FormGroup>
  );
}

export function FieldGroupDatePicker({
  bsSize,
  id,
  label,
  help,
  validationState,
  ...props
}) {
  return (
    <FormGroup
      validationState={validationState}
      bsSize={bsSize || 'large'}
      controlId={id}
    >
      {label && <ControlLabel>{label}</ControlLabel>}
      <DatePicker {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}

export function FieldGroupSelect({ bsSize, id, label, help, ...props }) {
  return (
    <FormGroup bsSize={bsSize || 'large'} controlId={id}>
      {label && <ControlLabel>{label}</ControlLabel>}
      <Select {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}
