import React from 'react';
import { useDispatch } from 'react-redux';
import DropdownButton from './DropdownButton';

export const ExportQuestionsDropdown = props => {
  const dispatch = useDispatch();
  const onClick = (x, fiscalYear) => {
    const { exportQuestions } = props;
    exportQuestions(x.value, fiscalYear);
  };

  return <DropdownButton onClick={onClick} label="Export Questions" />;
};

export default ExportQuestionsDropdown;
