import React from 'react';
import {
  Segment,
  DropdownItem,
  DropdownMenu,
  Dropdown,
} from 'semantic-ui-react';
import { BulkQuestionTemplates, FiscalYears } from '../utils';

export const DropdownButton = ({ label, onClick }) => {
  const QuestionTemplatesOptions = ({ fiscalYear }) => {
    return BulkQuestionTemplates(fiscalYear).map(x => (
      <DropdownItem
        style={{
          background: '#fff',
          zIndex: '13',
        }}
        onClick={() => onClick(x, fiscalYear)}
        className="dropdown-item"
        text={x.label}
        key={x.value}
      />
    ));
  };

  return (
    <Segment
      basic
      clearing
      style={{ padding: '0px', margin: '0px 5px 0 5px', float: 'right' }}
      className="dropdown"
    >
      <Dropdown text={label} button fluid className="ui green large button m-2">
        <DropdownMenu>
          {FiscalYears.map(year => {
            return (
              <Dropdown item text={year}>
                <DropdownMenu direction="left">
                  <QuestionTemplatesOptions fiscalYear={year} />
                </DropdownMenu>
              </Dropdown>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </Segment>
  );
};

export default DropdownButton;
