import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import Actions
import { fetchDualComparisonReport } from '../../actions/submissions';

// Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';
import AmsTable from '../../utils/AmsTable';

class CLASSDualCoderComparison extends Component {
  state = {
    dualComparisonReport: {},
  };

  componentDidMount() {
    let input = {
      reviewId: this.context.router.params.id, //"1805555C"
    };
    this.props.fetchDualComparisonReport(input).then(data => {
      this.setState({
        dualComparisonReport: {
          ...data.dualComparisonReport,
        },
      });
    });
  }

  isExpandableRow = row => {
    return true;
  };

  columnClassNameFormatOC1 = row => {
    return row.OC1difference > 1 ? 'td-column-function-red' : '';
  };

  columnClassNameFormatOC2 = row => {
    return row.OC2difference > 1 ? 'td-column-function-red' : '';
  };

  expandComponent = ({ data }) => (
    <div>
      <table className="table table-bordered">
        <thead className={'td-header-dual-comparison'}>
          <tr>
            <th
              rowSpan="2"
              width="-1%"
              style={{ verticalAlign: 'top', textAlign: 'left' }}
            >
              Area
            </th>
            <th colSpan="3">Observation Cycle 1</th>
            <th colSpan="3">Observation Cycle 2</th>
          </tr>
          <tr>
            <th>Reviewer</th>
            <th>Dual Coder</th>
            <th>Difference</th>
            <th>Reviewer</th>
            <th>Dual Coder</th>
            <th>Difference</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.area}</td>
              <td>{row.OC1reviewer}</td>
              <td>{row.OC1dualCoder}</td>
              <td className={this.columnClassNameFormatOC1(row)}>
                {row.OC1difference}
              </td>
              <td>{row.OC2reviewer}</td>
              <td>{row.OC2dualCoder}</td>
              <td className={this.columnClassNameFormatOC2(row)}>
                {row.OC2difference}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  prepareDualComparisonReport = () => {
    const { dualComparisonReport } = this.props;

    const data = dualComparisonReport.reportData
      ? dualComparisonReport.reportData.map((d, index) => {
          return {
            ...d,
            id: index,
          };
        })
      : [];

    const columns = [
      { dataField: 'id', hidden: true, text: 'id' },
      {
        headerClasses: 'td-header-dual-comparison',
        text: 'Center Name',
        dataField: 'centerName',
      },
      {
        dataField: 'classroomName',
        text: 'Classroom Name',
        headerClasses: 'td-header-dual-comparison',
      },
      {
        dataField: 'reviewer',
        text: 'Reviewer',
        headerClasses: 'td-header-dual-comparison td-dropdown',
      },
      {
        dataField: 'dualCoder',
        text: 'Dual Coder',
        headerClasses: 'td-header-dual-comparison td-dropdown',
      },
      {
        dataField: 'visitDate',
        text: 'Visit Date',
        headerClasses: 'td-header-dual-comparison',
      },
      {
        text: '# Children Enrolled',
        dataField: 'childrenEnrolled',
        headerClasses: 'td-header-dual-comparison',
      },
    ];

    const columRenderer = ({ expanded, rowKey, expandable }) => {
      let content = '';

      if (expandable) {
        content = expanded ? '-' : '+';
      } else {
        content = ' ';
      }
      return <div> {content} </div>;
    };
    const expandRow = {
      renderer: this.expandComponent,
      showExpandColumn: true,
      expandColumnRenderer: columRenderer,
    };

    return (
      <AmsTable
        remote={false}
        total={data.length}
        columns={columns}
        keyField="id"
        loading={this.state.loading}
        data={data || []}
        expandRow={expandRow}
      ></AmsTable>
    );
  };

  render() {
    const { reviewId, reviewType, GrantInfo } = this.state.dualComparisonReport;
    const grantee = GrantInfo ? GrantInfo[0] : {};
    return (
      <div>
        <SlidingContainer
          // calendar
          title={
            'Dual Coder Comparison Report for Review ID ' +
            this.context.router.params.id
          }
        >
          {/* {assignments.assignmentChangeFlag &&
            <Message
              negative
              icon='warning'
              header='Notice'
              content={`The New ${assignments.sampleLabel} Class Sampling report has been generated on ${moment(assignments.sampleGenrationDate).format("MMM Do YYYY")}. All the earlier assignments made if any based on older version of samples will be replaced.`}
            />
          } */}
          <div className="well survey-header-info">
            {(grantee || {}).granteeName} ( {(grantee || {}).granteeId} ) |{' '}
            {reviewType} ( {reviewId} )
          </div>
          {this.prepareDualComparisonReport()}
        </SlidingContainer>
      </div>
    );
  }
}

CLASSDualCoderComparison.propTypes = {};

CLASSDualCoderComparison.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    selectedReview: state.review.selectedReview,
    dualComparisonReport: state.submissions.dualComparisonReport,
  };
}

export default connect(mapStateToProps, { fetchDualComparisonReport })(
  CLASSDualCoderComparison
);
