import React from 'react';
import { Input } from 'semantic-ui-react';

function PositiveIntegerWidget(props) {
  return (
    <div>
      <Input
        type="number"
        value={props.value}
        name={props.id}
        min="0"
        id={props.id}
        style={{ width: '100%' }}
        onKeyDown={function(event) {
          if (
            event.key == '.' ||
            event.key == '-' ||
            event.key == '+' ||
            event.key == 'e' ||
            event.key == 'E'
          )
            event.preventDefault();
        }}
        onChange={(e, data) => {
          props.onChange(data.value);
        }}
        required={props.required}
        disabled={props.readonly || props.disabled}
      />
    </div>
  );
}

PositiveIntegerWidget.defaultProps = {};

export default PositiveIntegerWidget;
