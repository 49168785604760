import _, { isEmpty, last } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Step } from 'semantic-ui-react';

import {
  clearReviewPositions,
  reviewInfoFetch,
  fetchReviewDetail,
} from '../../../actions/reviewActions';
import AmsAlert from '../../../utils/AmsAlert';
import SlidingContainer from '../../../utils/layout/SlidingContainer';
import ReviewGuidesAccordionForm from './ReviewGuidesAccordionForm';
import ReviewGuidesPerformanceMeasuresForm from './ReviewGuidesPerformanceMeasuresForm';
import ReviewInfoForm from './ReviewInfoForm';
import ReviewTeamForm from './ReviewTeamForm';

const CreateReviewPage = props => {
  const dispatch = useDispatch();
  const [step, setStep] = useState('');
  const [createdReviewId, setCreatedReviewId] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [reviewId, setReviewId] = useState('');
  const [selectedReviewType, setSelectedReviewType] = useState('');
  const { reviewType } = useSelector(state => state.review.reviewInfo);
  const [previousReviewInfo, setPreviousReviewInfo] = useState({});
  const [dataChanged, setDataChanged] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState('');
  const [saveRequest, setSaveRequest] = useState(false);
  const { selectedReview } = useSelector(state => state.review);
  const currentUser = useSelector(state => state.auth.user);

  useEffect(() => {
    let edit = last(props.router.routes).path === 'edit';
    let { params } = props.router;
    // Only run during edit mode.
    if (edit && params.id) {
      // Pass reviewId to fetch review details.
      setEditMode(true);
      setReviewId(params.id);
      dispatch(reviewInfoFetch(params.id)).catch(error => {
        console.error(error);
      });
      dispatch(fetchReviewDetail({ reviewId: params.id }));
    } else {
      setStep('reviewInfo');
      dispatch(clearReviewPositions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editMode && reviewType) {
      setSelectedReviewType(reviewType);
      if (
        reviewType !== 'Special' &&
        reviewType !== 'ERSEA' &&
        reviewType !== 'AIAN-DEF'
      )
        setStep('team');
      else setStep('pms');
    }
  }, [reviewType, editMode]);

  const handleClick = (e, { value }) => {
    e.preventDefault();
    if (dataChanged) {
      setShowAlert(true);
      setAlertData(value);
      return null;
    }

    setStep(value);
  };

  const dataHasChangedSwitch = val => {
    if (val === true) {
      setDataChanged(true);
    } else if (val === false) {
      setDataChanged(false);
    } else if (val === 'successSave') {
      setSaveRequest(false);
      setStep(alertData);
      setAlertData('');
      setDataChanged(false);
    }
  };

  const renderMovingConfirmation = () => {
    return (
      <AmsAlert
        show={showAlert}
        title="Are you sure you want to move away from this form"
        text={`You selected another step, are you sure you want to leave the current step?`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'Save and continue'}
        cancelButtonText={'Continue without saving'}
        showConfirm
        showCancelButton
        showThirdButton
        thirdButtonText="Cancel"
        onThirdButtonAction={e => {
          e.preventDefault();
          setShowAlert(false);
          setAlertData(false);
        }}
        onCancel={e => {
          e.preventDefault();
          setStep(alertData);
          setShowAlert(false);
          setAlertData('');
          setDataChanged(false);
          if (step === 'team') {
            dispatch(reviewInfoFetch(reviewId)).catch(error => {
              console.error(error);
            });
          }
        }}
        onConfirm={e => {
          e.preventDefault();
          setShowAlert(false);
          setSaveRequest(true);
        }}
      />
    );
  };

  const goToStep = (step, reviewId = '', refresh = false) => {
    setDataChanged(false);
    if (reviewId) {
      props.router.push(`/review/${reviewId}/edit`);
      if (refresh)
        dispatch(reviewInfoFetch(reviewId)).then(data => {
          if (data && data.reviewInfo) setPreviousReviewInfo(data.reviewInfo);
        });
    }
    setStep(step);
  };

  const storeCreatedReviewId = reviewId => {
    console.log('saving reviewid');
    setCreatedReviewId(reviewId);
  };

  const selectReviewType = type => {
    setSelectedReviewType(type);
  };

  const isSpecialReviewType = () => {
    return (
      selectedReview.reviewType &&
      (selectedReview.reviewType === 'Special' ||
        selectedReview.reviewType === 'AIAN-DEF' ||
        selectedReview.reviewType === 'ERSEA')
    );
  };

  return (
    <SlidingContainer
      title={editMode ? 'Edit Review' : 'Create Review'}
      breadcrumbEnabled={true}
    >
      {renderMovingConfirmation()}
      <Container className="left-align">
        <Grid.Column>
          <Grid.Row>
            <Step.Group ordered widths={3}>
              {selectedReview.editReview && (
                <Step
                  active={step === 'reviewInfo'}
                  onClick={handleClick}
                  value="reviewInfo"
                  style={{ textDecoration: 'none' }}
                  href="#"
                  tabIndex={step === 'reviewInfo' ? '-1' : '0'}
                >
                  <Step.Content>
                    <Step.Title
                      style={{
                        color: step === 'reviewInfo' && '#245e9d !important',
                      }}
                    >
                      Review Info
                    </Step.Title>
                    <Step.Description>
                      Enter the review information
                    </Step.Description>
                  </Step.Content>
                </Step>
              )}
              {isSpecialReviewType() && selectedReview.editGuideAssignments && (
                <Step
                  onClick={handleClick}
                  value="pms"
                  active={step === 'pms'}
                  disabled={
                    isEmpty(createdReviewId) && isEmpty(reviewId) && !editMode
                  }
                  style={{ textDecoration: 'none' }}
                  href="#"
                  tabIndex={step === 'pms' ? '-1' : '0'}
                >
                  <Step.Content>
                    <Step.Title
                      style={{
                        color: step === 'pms' && '#245e9d !important',
                      }}
                    >
                      {selectedReviewType === 'Special' &&
                      selectedReview?.isNewForms
                        ? 'Guides and Performance Areas'
                        : 'Guides and Performance Measures'}
                    </Step.Title>
                    <Step.Description>
                      {selectedReviewType === 'Special' &&
                      selectedReview?.isNewForms
                        ? 'Select the guides and performance areas for this review'
                        : 'Select the guides and performance measures for this review'}
                    </Step.Description>
                  </Step.Content>
                </Step>
              )}
              {selectedReview.editReview && (
                <Step
                  onClick={handleClick}
                  value="team"
                  active={step === 'team'}
                  disabled={
                    isEmpty(createdReviewId) && isEmpty(reviewId) && !editMode
                  }
                  style={{ textDecoration: 'none' }}
                  href="#"
                  tabIndex={step === 'team' ? '-1' : '0'}
                >
                  <Step.Content>
                    <Step.Title
                      style={{
                        color: step === 'team' && '#245e9d !important',
                      }}
                    >
                      Team
                    </Step.Title>
                    <Step.Description>
                      Select the team that conducts this review
                    </Step.Description>
                  </Step.Content>
                </Step>
              )}
            </Step.Group>
          </Grid.Row>
          <Grid.Row>
            {step === 'reviewInfo' ? (
              <ReviewInfoForm
                goToStep={goToStep}
                dataHasChangedSwitch={dataHasChangedSwitch}
                storeCreatedReviewId={storeCreatedReviewId}
                {...props}
                editMode={editMode}
                selectReviewType={selectReviewType}
                previousReviewInfo={previousReviewInfo}
                saveRequest={saveRequest}
              />
            ) : step === 'team' ? (
              <ReviewTeamForm
                goToStep={goToStep}
                dataHasChangedSwitch={dataHasChangedSwitch}
                createdReviewId={createdReviewId}
                {...props}
                editMode={editMode}
                previousReviewInfo={previousReviewInfo}
                saveRequest={saveRequest}
              />
            ) : isSpecialReviewType() && selectedReview?.isNewForms ? (
              <ReviewGuidesAccordionForm
                reviewId={
                  createdReviewId ? createdReviewId : reviewId ? reviewId : ''
                }
                dataHasChangedSwitch={dataHasChangedSwitch}
                goToStep={goToStep}
                createdReviewId={createdReviewId}
                {...props}
                editMode={editMode}
                previousReviewInfo={previousReviewInfo}
                saveRequest={saveRequest}
                editReview={selectedReview?.editReview}
              />
            ) : (
              <ReviewGuidesPerformanceMeasuresForm
                reviewId={
                  createdReviewId ? createdReviewId : reviewId ? reviewId : ''
                }
                dataHasChangedSwitch={dataHasChangedSwitch}
                goToStep={goToStep}
                createdReviewId={createdReviewId}
                {...props}
                editMode={editMode}
                previousReviewInfo={previousReviewInfo}
                saveRequest={saveRequest}
              />
            )}
          </Grid.Row>
        </Grid.Column>
      </Container>
    </SlidingContainer>
  );
};

export default CreateReviewPage;