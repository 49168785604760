import * as React from 'react';
import CardEnumOptions from '../Shared/CardEnumOptions';
import CheckboxValidation from '../Shared/CheckboxValidation';

function getRandomId() {
  const chars = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];
  const numberOfChars = chars.length;
  const randomIdLength = 50;

  return Array.from({ length: randomIdLength })
    .map(() => chars[Math.floor(Math.random() * numberOfChars)])
    .join('');
}

export function SingleChoice({ parameters, onChange }) {
  const enumArray = Array.isArray(parameters.items.enum)
    ? parameters.items.enum
    : [];
  const [elementId] = React.useState(getRandomId());

  return (
    <div className="card-enum">
      <h3>Possible Values</h3>
      <CheckboxValidation
        onChangeValue={() => {
          if (parameters.showOther) {
            onChange({
              ...parameters,
              showOther: false,
            });
          } else {
            onChange({
              ...parameters,
              showOther: true,
            });
          }
        }}
        isChecked={parameters.showOther}
        label="Add an other option with text(required)"
        id={`${elementId}_other`}
      />
      <CheckboxValidation
        onChangeValue={() => {
          if (parameters.hasOutcome !== 1) {
            onChange({
              ...parameters,
              hasOutcome: 1,
              items: {
                type: 'string',
                enum: parameters.items.enumNames,
                enumNames: parameters.items.enumNames,
              },
            });
          } else {
            onChange({
              ...parameters,
              hasOutcome: 0,
              items: {
                type: 'string',
                enum: parameters.items.enumNames,
                enumNames: parameters.items.enumNames,
              },
            });
          }
        }}
        isChecked={parameters.hasOutcome === 1 ? true : false}
        label="Has an Outcome?"
        id={`${elementId}_outcome`}
      />
      <CardEnumOptions
        initialValues={enumArray}
        names={
          Array.isArray(parameters.items.enumNames)
            ? parameters.items.enumNames.map(val => `${val}`)
            : undefined
        }
        showNames={Array.isArray(parameters.items.enumNames)}
        showOther={parameters.showOther}
        hasOutcome={parameters.hasOutcome}
        onChange={(newEnum, enumNames) => {
          newEnum = newEnum || 'option 1';
          return onChange({
            ...parameters,
            items: {
              enum: newEnum,
              enumNames,
              type: 'string',
            },
          });
        }}
      />
    </div>
  );
}

// specify the inputs required for a string type object
export function CardDefaultParameterInputs({ parameters, onChange }) {
  return <div />;
}
