import {
  filter,
  find,
  has,
  includes,
  intersection,
  isEmpty,
  keyBy,
  mapValues,
  some,
  sortBy,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Dimmer,
  Divider,
  Form,
  Grid,
  Header,
  Loader,
  Modal,
  Segment,
} from 'semantic-ui-react';
import {
  addPrimaryUser,
  reviewInfoFetch,
  updateReview,
} from '../../../actions/reviewActions';
import { acl } from '../../../config';
import AmsAlert from '../../../utils/AmsAlert';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsModal from '../../../utils/AmsModal';
import AddTeamModal from './AddTeamModal';
import ReviewerPositionForm from './ReviewerPositionForm';
import ReviewTeamCard from './ReviewTeamCard';
import ShowUpdateConfirmationDialog from './ShowUpdateConfirmationDialog';

const ReviewTeamForm = props => {
  const dispatch = useDispatch();
  const {
    reviewType,
    specialReviewLead,
    startDate,
    endDate,
    positions,
    reviewersHistory,
    reviewId,
    primaryUsers,
    teamPlanningComplete,
    resendEmail,
    teamPositions,
  } = useSelector(state => state.review.reviewInfo);
  const { reviewInfo } = useSelector(state => state.review);
  const { user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const [selectedTeamRole, setSelectedTeamRole] = useState({});
  const [teams, setTeams] = useState([]);
  const [showOnlyDropdown, setShowOnlyDropdown] = useState(false);
  const [
    showReviewerPositionAddModal,
    setShowReviewerPositionAddModal,
  ] = useState(false);
  const [editPosition, setEditPosition] = useState(false);
  const [positionsList, setPositions] = useState({});
  const [reviewerPositionSkills, setReviewerPosition] = useState({
    languageSkills: [],
    contentAreaSkills: [],
    otherSkills: [],
    reviewersRequired: null,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showPrimaryLead, setShowPrimaryLead] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [
    currentTeamPlanningComplete,
    setCurrentTeamPlanningComplete,
  ] = useState(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const [currentTeamPositions, setCurrentTeamPositions] = useState([]);
  const [showExitDialog, setShowExitDialog] = useState(false);

  useEffect(() => {
    const updatePosition = () => {
      if (positions) {
        setPositions(positions);
      }
    };
    updatePosition();
  }, [positions]);

  useEffect(() => {
    const updateTeamPlanning = () => {
      setCurrentTeamPlanningComplete(teamPlanningComplete);
    };
    updateTeamPlanning();
  }, [teamPlanningComplete]);

  useEffect(() => {
    const updateTeamPositions = () => {
      if (teamPositions.length === 0) {
        let tp = teams.map(team => {
          return {
            type: team.value,
            languageSkills: [],
            otherSkills: [],
            contentAreaSkills: [],
            active: true,
          };
        });
        setCurrentTeamPositions(tp);
      } else setCurrentTeamPositions(teamPositions);
    };
    updateTeamPositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams, JSON.stringify(teamPositions)]);

  useEffect(() => {
    if (reviewInfo) {
      const existingTeams = sortBy(teamRoles, ['value'])
        // eslint-disable-next-line array-callback-return
        .map(role => {
          if (
            (reviewInfo[role.value] && reviewInfo[role.value].length > 0) ||
            teamPositions.find(p => p.type === role.value)
          ) {
            role = { ...role, team: reviewInfo[role.value] };
            if (
              some(primaryUsers, {
                type: role.value,
              })
            ) {
              role = {
                ...role,
                team: reviewInfo[role.value].map(member => {
                  if (
                    some(primaryUsers, {
                      oid: member.oid,
                      type: role.value,
                    }) ||
                    some(primaryUsers, {
                      userId: member.userId,
                      type: role.value,
                    })
                  ) {
                    member = { ...member, isPrimary: true };
                  } else member = { ...member, isPrimary: false };
                  return member;
                }),
              };
            } else {
              role = {
                ...role,
                team: reviewInfo[role.value].map(member => {
                  member = { ...member, isPrimary: false };
                  return member;
                }),
              };
            }
            return role;
          }
        })
        .filter(team => team !== undefined);
      setTeams(existingTeams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewInfo]);

  useEffect(() => {
    if (props.saveRequest === true) {
      saveChanges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.saveRequest]);

  const teamRoles = [
    {
      key: 'fiscalSpecialist',
      value: 'fiscalSpecialists',
      text: 'Fiscal Specialists',
      ariaLabelledBy: 'fiscalSpecialist',
      reviewType: ['RAN'],
    },
    {
      key: 'fieldOperationsManager',
      value: 'fieldOperationsManagers',
      text: 'Managers',
      ariaLabelledBy: 'fieldOperationsManager',
      reviewType: [
        'CLASS',
        'FA-1',
        'FA1-FR',
        'FA-2',
        'FA2-CR',
        'AIAN-CLASS',
        'Special',
        'AIAN-DEF',
        'ERSEA',
        'RAN',
        'Follow-up',
        'PR-CLASS',
        'VP-CLASS',
        'CLASS-Video',
        'AIAN CLASS Self-Observations',
        'CLASS AIAN Onsite',
        'CLASS AIAN Video',
      ],
    },
    {
      key: 'analyst',
      value: 'analysts',
      text: 'Analyst',
      ariaLabelledBy: 'analyst',
      reviewType: [
        'CLASS',
        'FA-1',
        'FA1-FR',
        'FA-2',
        'FA2-CR',
        'AIAN-CLASS',
        'Special',
        'AIAN-DEF',
        'ERSEA',
        'Follow-up',
        'Targeted',
        'Other-Offsite',
        'Desk',
        'Desk/FTL Solo',
        'RAN',
        'PR-CLASS',
        'VP-CLASS',
        'CLASS-Video',
        'AIAN CLASS Self-Observations',
        'CLASS AIAN Onsite',
        'CLASS AIAN Video',
      ],
    },
    {
      key: 'editor',
      value: 'editors',
      text: 'Editor',
      ariaLabelledBy: 'editor',
      reviewType: [
        'CLASS',
        'FA-1',
        'FA1-FR',
        'FA-2',
        'FA2-CR',
        'AIAN-CLASS',
        'Special',
        'AIAN-DEF',
        'ERSEA',
        'Follow-up',
        'Targeted',
        'Other-Offsite',
        'Desk',
        'Desk/FTL Solo',
        'RAN',
        'PR-CLASS',
        'VP-CLASS',
        'CLASS-Video',
        'AIAN CLASS Self-Observations',
        'CLASS AIAN Onsite',
        'CLASS AIAN Video',
      ],
    },
    {
      key: 'reviewLead',
      value: 'reviewLeads',
      text: 'Review Team Leads',
      ariaLabelledBy: 'reviewLead',
      reviewType: [
        'CLASS',
        'FA-1',
        'FA1-FR',
        'FA-2',
        'FA2-CR',
        'AIAN-CLASS',
        'Other',
        'Targeted',
        'Other-Offsite',
        'Desk',
        'Desk/FTL Solo',
        'PR-CLASS',
        'VP-CLASS',
        'CLASS-Video',
        'AIAN CLASS Self-Observations',
        'CLASS AIAN Onsite',
        'CLASS AIAN Video',
      ],
    },
    {
      key: 'logisticsAssociate',
      value: 'logisticsAssociates',
      text: 'Logistics Associates',
      ariaLabelledBy: 'logisticsAssociate',
      reviewType: [
        'CLASS',
        'FA-1',
        'FA1-FR',
        'FA-2',
        'FA2-CR',
        'AIAN-CLASS',
        'Special',
        'AIAN-DEF',
        'ERSEA',
        'Follow-up',
        'Other',
        'Targeted',
        'Other-Offsite',
        'Desk',
        'Desk/FTL Solo',
        'PR-CLASS',
        'VP-CLASS',
        'CLASS-Video',
        'AIAN CLASS Self-Observations',
        'CLASS AIAN Onsite',
        'CLASS AIAN Video',
      ],
    },
    {
      key: 'dualCoder',
      value: 'dualCoders',
      text: 'Dual Coders',
      ariaLabelledBy: 'dualCoder',
      reviewType: [
        'CLASS',
        'AIAN-CLASS',
        'PR-CLASS',
        'VP-CLASS',
        'CLASS-Video',
        'AIAN CLASS Self-Observations',
        'CLASS AIAN Onsite',
        'CLASS AIAN Video',
      ],
    },
    {
      key: 'followupLead',
      value: 'followupLeads',
      text: 'Follow-up Leads',
      ariaLabelledBy: 'followupLead',
      reviewType: ['RAN', 'Follow-up'],
    },
    {
      key: 'grantSpecialist',
      value: 'grantSpecialists',
      text: 'Grant Specialists',
      ariaLabelledBy: 'grantSpecialist',
      reviewType: [],
    },
    {
      key: 'programSpecialist',
      value: 'programSpecialists',
      text: 'Program Specialists',
      ariaLabelledBy: 'programSpecialist',
      reviewType: ['RAN', 'Follow-up'],
    },
    {
      key: 'pendingShadowReviewer',
      value: 'pendingShadowReviewers',
      text: 'Shadow Reviewers (Invite)',
      ariaLabelledBy: 'pendingShadowReviewer',
      reviewType: [
        'CLASS',
        'FA-1',
        'FA1-FR',
        'FA-2',
        'FA2-CR',
        'AIAN-CLASS',
        'Special',
        'AIAN-DEF',
        'ERSEA',
        'RAN',
        'Follow-up',
        'Other',
        'Targeted',
        'Other-Offsite',
        'Desk',
        'Desk/FTL Solo',
        'PR-CLASS',
        'VP-CLASS',
        'CLASS-Video',
        'AIAN CLASS Self-Observations',
        'CLASS AIAN Onsite',
        'CLASS AIAN Video',
      ],
    },
    {
      key: 'shadowReviewer',
      value: 'shadowReviewers',
      text: 'Shadow Reviewers (Assign)',
      ariaLabelledBy: 'shadowReviewer',
      reviewType: [
        'CLASS',
        'FA-1',
        'FA1-FR',
        'FA-2',
        'FA2-CR',
        'AIAN-CLASS',
        'Special',
        'AIAN-DEF',
        'ERSEA',
        'RAN',
        'Follow-up',
        'Other',
        'Targeted',
        'Other-Offsite',
        'Desk',
        'Desk/FTL Solo',
        'PR-CLASS',
        'VP-CLASS',
        'CLASS-Video',
        'AIAN CLASS Self-Observations',
        'CLASS AIAN Onsite',
        'CLASS AIAN Video',
      ],
    },
    {
      key: 'reviewer',
      value: 'reviewers',
      text: 'Reviewers',
      ariaLabelledBy: 'reviewer',
      reviewType: [
        'CLASS',
        'FA-1',
        'FA1-FR',
        'FA-2',
        'FA2-CR',
        'AIAN-CLASS',
        'Special',
        'AIAN-DEF',
        'ERSEA',
        'Follow-up',
        'Targeted',
        'Other-Offsite',
        'Desk',
        'Desk/FTL Solo',
        'PR-CLASS',
        'VP-CLASS',
        'CLASS-Video',
        'AIAN CLASS Self-Observations',
        'CLASS AIAN Onsite',
        'CLASS AIAN Video',
      ],
    },
    {
      key: 'technicalWriter',
      value: 'technicalWriters',
      text: 'Technical Writer',
      ariaLabelledBy: 'technicalWriter',
      reviewType: [
        'CLASS',
        'FA-1',
        'FA1-FR',
        'FA-2',
        'FA2-CR',
        'AIAN-CLASS',
        'Special',
        'AIAN-DEF',
        'ERSEA',
        'Follow-up',
        'Targeted',
        'Other-Offsite',
        'Desk',
        'Desk/FTL Solo',
        'RAN',
        'PR-CLASS',
        'VP-CLASS',
        'CLASS-Video',
        'AIAN CLASS Self-Observations',
        'CLASS AIAN Onsite',
        'CLASS AIAN Video',
      ],
    },
  ];

  const handleTeamRoleSelection = (e, teamRole) => {
    setSelectedTeamRole(teamRole);
  };

  const filterTeamRoles = () => {
    if (reviewType) {
      return filter(teamRoles, role => {
        if (role.key === 'reviewLead' && specialReviewLead === 'dlh')
          role.reviewType = [
            ...role.reviewType,
            'Special',
            'AIAN-DEF',
            'ERSEA',
          ];
        if (role.key === 'grantSpecialist' && specialReviewLead === 'regional')
          role.reviewType = [
            ...role.reviewType,
            'Special',
            'AIAN-DEF',
            'ERSEA',
          ];
        if (
          role.key === 'programSpecialist' &&
          specialReviewLead === 'regional'
        )
          role.reviewType = [
            ...role.reviewType,
            'Special',
            'AIAN-DEF',
            'ERSEA',
          ];
        if (includes(role.reviewType, reviewType)) return role;
      });
    }
  };

  const showTeamModalForm = () => {
    const hasMultiple =
      reviewType === 'Special' ||
      reviewType === 'AIAN-DEF' ||
      reviewType === 'RAN' ||
      reviewType === 'Follow-up' ||
      reviewType === 'ERSEA';

    return (
      <AddTeamModal
        selectedTeamRole={selectedTeamRole}
        showAddTeamModal={showAddTeamModal}
        showOnlyDropdown={showOnlyDropdown}
        teamRoles={filterTeamRoles()}
        handleTeamRoleSelection={handleTeamRoleSelection}
        hasMultiple={hasMultiple}
        currentTeams={teamPositions}
        updatePositionPrimaryLead={updatePositionPrimaryLead}
        editMode={props.editMode}
        closeAddTeamModal={closeAddTeamModal}
        updateAlertModal={updateAlertModal}
        updateShowOnlyDropdown={value => setShowOnlyDropdown(value)}
        dataHasChangedSwitch={props.dataHasChangedSwitch}
      />
    );
  };

  const updatePositionPrimaryLead = (member, type) => {
    dispatch(addPrimaryUser({ ...member, type }));
    props.dataHasChangedSwitch(true);
  };

  const addNewTeamMember = teamRole => {
    setSelectedTeamRole(teamRole);
    setShowOnlyDropdown(true);
    setShowAddTeamModal(true);
    props.dataHasChangedSwitch(true);
  };

  const handleModalClose = () => {
    setEditPosition(false);
    setShowReviewerPositionAddModal(false);
    setSelectedTeamRole({});
    setReviewerPosition({});
  };

  const editReviewerPosition = position => {
    if (!isEmpty(position)) {
      setReviewerPosition(position);
      setEditPosition(true);
      setShowReviewerPositionAddModal(true);
    } else {
      setReviewerPosition({});
      setEditPosition(false);
      setShowReviewerPositionAddModal(false);
    }
  };

  const saveTeams = () => {
    let users = mapValues(keyBy(teams, 'value'), 'team');

    let updatedPrimaryUsers = primaryUsers;
    // eslint-disable-next-line array-callback-return
    Object.keys(users).map(role => {
      if (
        users[role].length === 1 &&
        !some(primaryUsers, { type: role }) &&
        role !== 'shadowReviewers' &&
        role !== 'pendingShadowReviewers' &&
        role !== 'dualCoders' &&
        role !== 'reviewers'
      )
        updatedPrimaryUsers.push({ oid: users[role][0].oid });
    });

    const canUpdateReview = intersection(acl.actions.review.update, user.roles);
    const data = {
      ...reviewInfo,
      ...users,
      positions,
      startDate: startDate
        ? AmsDateFormatters.getMoment(startDate)
            .startOf('day')
            .format()
        : startDate,
      endDate: endDate
        ? AmsDateFormatters.getMoment(endDate)
            .endOf('day')
            .format()
        : endDate,
      teamPlanningComplete: currentTeamPlanningComplete,
      primaryUsers: updatedPrimaryUsers,
      teamPositions:
        currentTeamPositions &&
        currentTeamPositions.filter(position => position.type !== 'reviewers'),
    };
    if (
      reviewType === 'Special' ||
      reviewType === 'AIAN-DEF' ||
      reviewType === 'ERSEA'
    )
      data['sendEmail'] = resendEmail === true ? true : false;

    if (!isEmpty(canUpdateReview)) {
      dispatch(updateReview(data))
        .then(response => {
          if (props.saveRequest === true) {
            props.dataHasChangedSwitch('successSave');
            return;
          }
          if (isEmpty(response)) {
            setAlertMessage('Saved Successfully');
            setShowAlert(true);
            setAlertType('success');
            setShowUpdateConfirmation(false);
            dispatch(reviewInfoFetch(reviewId));
            props.router.push(`/review/${reviewId}`);
          }
        })
        .catch(error => {
          console.log(error);
          setAlertMessage(error.response);
          setShowUpdateConfirmation(false);
          setShowAlert(true);
          setAlertType('error');
        });
    } else {
      // this.setState({ saving: false });
      if (reviewId) {
        setAlertMessage('Not able to save');
        setShowAlert(true);
        setAlertType('error');
        setShowUpdateConfirmation(false);
      }
    }
  };

  const showMessageDialog = () => {
    return (
      <AmsAlert
        show={showAlert}
        type={alertType || 'success'}
        showConfirm
        title={alertType === 'success' ? 'Review saved' : 'Oops...'}
        text={alertMessage}
        onConfirm={() => {
          setAlertMessage('');
          setShowAlert(false);
          setAlertType('');
        }}
      />
    );
  };

  const showPrimaryLeadDialog = () => {
    return (
      <AmsAlert
        show={showPrimaryLead}
        type={alertType || 'success'}
        showConfirm
        showCancelButton
        title={'Do you want to continue?'}
        text={alertMessage}
        onConfirm={() => {
          if (checkShadowReviewerLocations()) {
            setShowPrimaryLead(false);
            setAlertMessage(
              'You have not updated all the On-site/Off-site locations for invited/assigned shadow reviewers. Please update them before saving.'
            );
            setShowAlert(true);
            setAlertType('error');
          } else if (showExitDialog) {
            setAlertMessage('');
            setShowPrimaryLead(false);
            setAlertType('');
            saveTeams();
            setShowExitDialog(false);
          } else {
            setAlertMessage('');
            setShowPrimaryLead(false);
            setAlertType('');
            setShowUpdateConfirmation(true);
          }
        }}
        onCancel={() => {
          setShowPrimaryLead(false);
          setAlertMessage('');
          setAlertType('');
        }}
      />
    );
  };

  const updateAlertModal = (message, show, type) => {
    setAlertMessage(message);
    setShowAlert(show);
    setAlertType(type);
  };

  const closeAddTeamModal = () => {
    setSelectedTeamRole({});
    setShowAddTeamModal(false);
    setShowOnlyDropdown(false);
  };

  const saveChanges = () => {
    let teamsCount = filter(teams, team => {
      return (
        team.value !== 'shadowReviewers' &&
        team.value !== 'reviewers' &&
        team.value !== 'pendingShadowReviewers' &&
        team.value !== 'dualCoders' &&
        team.team.length !== 0
      );
    }).length;

    if (teamsCount !== primaryUsers.length) {
      setAlertMessage(
        'You are about to update the team without selecting a primary for one of the teams. Are you sure you want to continue?'
      );
      setAlertType('warning');
      setShowPrimaryLead(true);
    } else if (checkShadowReviewerLocations()) {
      setAlertMessage(
        'You have not updated all the On-site/Off-site locations for invited/assigned shadow reviewers. Please update them before saving.'
      );
      setShowAlert(true);
      setAlertType('error');
    } else {
      setShowUpdateConfirmation(true);
    }
  };

  const checkShadowReviewerLocations = () => {
    let shadowReviewerTeams = filter(teams, team => {
      return (
        team.value === 'shadowReviewers' ||
        team.value === 'pendingShadowReviewers'
      );
    });
    let missingReviewLocation = false;
    for (let team of shadowReviewerTeams) {
      let missingReviewLocationCount = filter(team.team, shadowReviewer => {
        return !has(shadowReviewer, 'onSite') || shadowReviewer.onSite === null;
      }).length;
      if (missingReviewLocationCount > 0) {
        missingReviewLocation = true;
        break;
      }
    }
    return missingReviewLocation;
  };

  const exitDialogModal = () => {
    return (
      <AmsModal
        size="large"
        centered={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setShowExitDialog(false);
        }}
        open={showExitDialog}
        className="ams-semantic-modal-fix"
      >
        <Header content="You are about to Exit" />

        <Modal.Content>
          <Modal.Description>
            <Header content="You are about to exit editing this review. Are you sure?" />
            <Form>
              <>
                Is the Team planning complete? (If Yes select the checkbox, if
                No, no action is required)
              </>
              <Form.Field
                label={{
                  children: 'Team planning is complete',
                  htmlFor: 'updateTeamPlanning',
                }}
                role="group"
                id={'updateTeamPlanning'}
                aria-labelledby={'updateTeamPlanning'}
                checked={currentTeamPlanningComplete}
                onChange={() => {
                  setCurrentTeamPlanningComplete(!currentTeamPlanningComplete);
                }}
                control={Checkbox}
              ></Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowExitDialog(false);
              if (
                reviewType === 'Special' ||
                reviewType === 'AIAN-DEF' ||
                reviewType === 'ERSEA'
              ) {
                let data = { ...props.previousReviewInfo };
                data['sendEmail'] = resendEmail === true ? true : false;
                if (resendEmail) dispatch(updateReview(data));
              }
              props.router.push(`/review/${reviewId}`);
            }}
            negative
            content="Exit without saving"
          />
          <Button
            onClick={() => {
              setShowExitDialog(false);
            }}
            content="No, cancel"
          />
          <Button
            onClick={() => {
              let teamsCount = filter(teams, team => {
                return (
                  team.value !== 'shadowReviewers' &&
                  team.value !== 'reviewers' &&
                  team.value !== 'pendingShadowReviewers' &&
                  team.value !== 'dualCoders' &&
                  team.team.length !== 0
                );
              }).length;

              if (teamsCount !== primaryUsers.length) {
                setAlertMessage(
                  'You are about to update the team without selecting a primary for one of the teams. Are you sure you want to continue?'
                );
                setAlertType('warning');
                setShowPrimaryLead(true);
              } else if (checkShadowReviewerLocations()) {
                setAlertMessage(
                  'You have not updated all the On-site/Off-site locations for invited/assigned shadow reviewers. Please update them before saving.'
                );
                setShowAlert(true);
                setAlertType('error');
              } else {
                saveTeams();
                setShowExitDialog(false);
              }
            }}
            primary
            content="Save changes and exit"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader active={loading} inverted />
      </Dimmer>

      <Grid columns="equal" style={{ marginTop: '20px' }}>
        <Grid.Row>
          <Grid.Column floated="left" style={{ marginLeft: '15px' }}>
            <Header as="h2">Team</Header>
          </Grid.Column>
          <Grid.Column floated="right" style={{ marginRight: '-15px' }}>
            {(!isEmpty(teams) || !isEmpty(positions)) && (
              <Button
                floated="right"
                size="large"
                color="blue"
                content={`Update/Edit Teams`}
                onClick={() => setShowAddTeamModal(true)}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {showTeamModalForm()}
      {
        <ReviewerPositionForm
          dataHasChangedSwitch={props.dataHasChangedSwitch}
          showReviewerPositionAddModal={showReviewerPositionAddModal}
          handleModalClose={handleModalClose}
          reviewerPositionSkills={reviewerPositionSkills}
          editPosition={editPosition}
          setEditPosition={value => setEditPosition(value)}
        ></ReviewerPositionForm>
      }
      {showMessageDialog()}
      {showPrimaryLeadDialog()}
      {exitDialogModal()}
      {
        <ShowUpdateConfirmationDialog
          close={() => setShowUpdateConfirmation(false)}
          save={() => saveTeams()}
          showUpdateConfirmation={showUpdateConfirmation}
          teamPlanningComplete={currentTeamPlanningComplete}
          setTeamPlanningComplete={() => {
            setCurrentTeamPlanningComplete(!currentTeamPlanningComplete);
          }}
        />
      }
      <Divider />
      {(teams.length === 0 && positions.length === 0) ||
      (currentTeamPositions.length === 0 && positionsList.length === 0) ||
      (currentTeamPositions.length > 0 &&
        find(currentTeamPositions, t => t.active) === undefined) ? (
        <Segment placeholder textAlign="center">
          <Header>{`Build your ${reviewType} Team`}</Header>
          <strong style={{ fontSize: '20px' }}>
            Add the appropriate team and team members for the current review.
          </strong>
          <Segment.Inline>
            <br />
            <Button primary onClick={() => setShowAddTeamModal(true)}>
              Add New Team
            </Button>
          </Segment.Inline>
        </Segment>
      ) : (
        <ReviewTeamCard
          team={teams}
          addNewTeamMember={addNewTeamMember}
          positions={positionsList}
          history={reviewersHistory}
          editReviewerPosition={position => editReviewerPosition(position)}
          currentTeamPositions={currentTeamPositions}
          dataHasChangedSwitch={props.dataHasChangedSwitch}
        />
      )}
      <br />
      <Divider />
      {
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <Button
                form="createReviewForm"
                primary
                content={'Save'}
                onClick={() => {
                  // saveReview('continue');
                  saveChanges();
                }}
                size="huge"
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Button
                form="createReviewForm"
                content="Exit"
                onClick={e => {
                  e.preventDefault();
                  setShowExitDialog(true);
                }}
                size="huge"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
    </>
  );
};

export default ReviewTeamForm;
