import { isEmpty, forIn } from 'lodash';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';

const areTwoWeeksAvailable = (
  selectedMonth,
  unavailableDates,
  sessionDates,
  breakDates,
  holidays
) => {
  let selectedMonthYear = AmsDateFormatters.getMoment();
  if (
    selectedMonth === 'October' ||
    selectedMonth === 'November' ||
    selectedMonth === 'December'
  ) {
    selectedMonthYear = AmsDateFormatters.getUTCDate(
      AmsDateFormatters.getMoment(
        selectedMonth + AmsDateFormatters.getMoment().year()
      )
    );
  } else {
    selectedMonthYear = AmsDateFormatters.getUTCDate(
      AmsDateFormatters.getMoment(
        selectedMonth +
          AmsDateFormatters.getMoment()
            .add(1, 'y')
            .year()
      )
    );
  }

  //If the month is November or January then do not check for 2 week availability
  if (selectedMonthYear.month() === 10) {
    return true;
  }

  const monthYearTwo = AmsDateFormatters.getUTCDate(
    AmsDateFormatters.getMoment(unavailableDates[0]).year() + selectedMonth
  );
  let currentMonth = getAvailableDaysInMonth(monthYearTwo);
  const month = selectedMonthYear.format('MMMM');
  let allDates = [].concat(
    parseHolidays(holidays, month),
    parseSelectedMonthOnly(sessionDates, month),
    parseSelectedMonthOnly(breakDates, month),
    parseSelectedMonthOnly(unavailableDates, month)
  );

  for (let i = 0; i < allDates.length; i++) {
    for (let k = 0; k < currentMonth.length; k++) {
      for (let j = 0; j < currentMonth[k].length; j++) {
        if (currentMonth[k][j].isSame(allDates[i], 'day')) {
          currentMonth[k].splice(j, 1);
        }
      }
    }
  }

  return are2WeeksAvailableWith4DaysInaRow(currentMonth);
};

const parseHolidays = (holidays, selectedMonth) => {
  let parsed = [];

  // eslint-disable-next-line array-callback-return
  holidays.map(day => {
    if (AmsDateFormatters.getMoment(day.date).format('MMMM') === selectedMonth)
      parsed.push(AmsDateFormatters.getMoment(day.date));
  });

  return parsed;
};

const parseSelectedMonthOnly = (days, selectedMonth) => {
  let parsed = [];

  // eslint-disable-next-line array-callback-return
  days.map(day => {
    let utcDate = AmsDateFormatters.getMoment(day)
      .utc(day)
      .endOf('day');
    if (utcDate.format('MMMM') === selectedMonth)
      parsed.push(AmsDateFormatters.getMoment(utcDate));
  });

  return parsed;
};

const are2WeeksAvailableWith4DaysInaRow = currentMonth => {
  let numberOfWeeks = 0;
  currentMonth.forEach(function(week) {
    if (week.length >= 4) {
      if (week.length > 4) {
        numberOfWeeks++;
      } else {
        let day1 = parseInt(week[0].format('DD'));
        let day2 = parseInt(week[1].format('DD'));
        let day3 = parseInt(week[2].format('DD'));
        let day4 = parseInt(week[3].format('DD'));
        if (day2 === day1 + 1 && day3 === day2 + 1 && day4 === day3 + 1)
          numberOfWeeks++;
      }
    }
  });

  if (numberOfWeeks >= 2) return true;
  return false;
};

const getAvailableDaysInMonth = currentlySelectedMonthYear => {
  const mondays = getMondaysOfSelectedMonth(currentlySelectedMonthYear);
  let month = [];
  mondays.forEach(function(monday) {
    let date = monday.endOf('day');
    let daysOfweek = [];
    let day = 0;
    while (day < 5) {
      day++;
      daysOfweek.push(date);
      date = date.clone().add(1, 'd');
      if (date.format('dddd') === 'Saturday') day = 5;
      if (date.format('MMMM') !== currentlySelectedMonthYear.format('MMMM'))
        day = 5;
    }
    if (daysOfweek.length >= 4) month.push(daysOfweek);
  });
  return month;
};

const getMondaysOfSelectedMonth = currentlySelectedMonthYear => {
  let mondays = [];
  let currentMonth = currentlySelectedMonthYear.clone();
  const startOfMonth = currentMonth.startOf('month');
  if (startOfMonth.format('dddd') === 'Tuesday') {
    mondays.push(startOfMonth.clone());
  }
  let monday = currentMonth.startOf('month').day('Monday');
  if (monday.date() > 7) monday = monday.clone().add(7, 'd');
  const month = monday.month();
  while (month === monday.month()) {
    mondays.push(monday);
    monday = monday.clone().add(7, 'd');
  }
  return mondays;
};

const validateGranteeDetails = (
  hsStartDate,
  hsEndDate,
  hsCloseDate,
  ehsStartDate,
  ehsEndDate,
  ehsCloseDate,
  selectedMonths,
  granteeType
) => {
  const errors = {};
  if (granteeType === 'Head Start') {
    if (isEmpty(hsStartDate))
      errors.hsStartDate = { content: 'HS start date is required' };
    if (isEmpty(hsEndDate))
      errors.hsEndDate = { content: 'HS end date is required' };
    if (isEmpty(hsCloseDate))
      errors.hsCloseDate = { content: 'HS classroom close date is required' };
  } else if (granteeType === 'Early Head Start') {
    if (isEmpty(ehsStartDate))
      errors.ehsStartDate = { content: 'EHS start date is required' };
    if (isEmpty(ehsEndDate))
      errors.ehsEndDate = { content: 'EHS end date is required' };
    if (isEmpty(ehsCloseDate))
      errors.ehsCloseDate = { content: 'EHS classroom close date is required' };
  } else if (granteeType === 'Head Start and Early Head Start') {
    if (isEmpty(hsStartDate))
      errors.hsStartDate = { content: 'HS start date is required' };
    if (isEmpty(hsEndDate))
      errors.hsEndDate = { content: 'HS end date is required' };
    if (isEmpty(hsCloseDate))
      errors.hsCloseDate = { content: 'HS classroom close date is required' };
    if (isEmpty(ehsStartDate))
      errors.ehsStartDate = { content: 'EHS start date is required' };
    if (isEmpty(ehsEndDate))
      errors.ehsEndDate = { content: 'EHS end date is required' };
    if (isEmpty(ehsCloseDate))
      errors.ehsCloseDate = { content: 'EHS classroomclose date is required' };
  }
  if (isEmpty(selectedMonths))
    errors.incompeletedMonths = {
      content:
        'You have not completed reviewing all the months for the fiscal year, please complete reviewing them before submitting the Unavailability Calendar. The reviewed months will have checkmarks next to them.',
    };
  else if (Object.keys(selectedMonths).length === 12)
    forIn(selectedMonths, (value, key) => {
      if (value === false)
        errors.incompeletedMonths = {
          content:
            'You have not completed reviewing all the months for the fiscal year, please complete reviewing them before submitting the Unavailability Calendar. The reviewed months will have checkmarks next to them.',
        };
    });
  else
    errors.incompeletedMonths = {
      content:
        'You have not completed reviewing all the months for the fiscal year, please complete reviewing them before submitting the Unavailability Calendar. The reviewed months will have checkmarks next to them.',
    };
  if (granteeType === 'Head Start') {
    if (
      new Date(hsCloseDate) < new Date(hsStartDate) ||
      new Date(hsCloseDate) > new Date(hsEndDate)
    ) {
      errors.hsCloseDate = {
        content:
          'HS classroom close date should be between School Start and School End date. Also, please check school start date and school end date as well.',
      };
    }
  } else if (granteeType === 'Early Head Start') {
    if (
      new Date(ehsCloseDate) < new Date(ehsStartDate) ||
      new Date(ehsCloseDate) > new Date(ehsEndDate)
    ) {
      errors.ehsCloseDate = {
        content:
          'EHS classroom close date should be between School Start and School End date. Also, please check school start date and school end date as well.',
      };
    }
  } else if (granteeType === 'Head Start and Early Head Start') {
    if (
      new Date(hsCloseDate) < new Date(hsStartDate) ||
      new Date(hsCloseDate) > new Date(hsEndDate)
    ) {
      errors.hsCloseDate = {
        content:
          'HS classroom close date should be between School Start and School End date. Also, please check school start date and school end date as well.',
      };
    }
    if (
      new Date(ehsCloseDate) < new Date(ehsStartDate) ||
      new Date(ehsCloseDate) > new Date(ehsEndDate)
    ) {
      errors.ehsCloseDate = {
        content:
          'EHS classroom close date should be between School Start and School End date. Also, please check school start date and school end date as well.',
      };
    }
  }

  return errors;
};

export { areTwoWeeksAvailable, validateGranteeDetails };
