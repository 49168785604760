import React from 'react';
import { Menu } from 'semantic-ui-react';

// Import components.
import ReviewManifest from './ReviewManifest';
import ReviewFindings from './ReviewFindings';
import ReviewPreSite from './ReviewPreSite';
import ReviewChildFiles from './ReviewChildFiles';
import ReviewOCISummary from './ReviewOCISummary';
import ReviewFollowUps from './ReviewFollowUps';
import ReviewEvidenceBinder from './ReviewEvidenceBinder';
import ReviewDetailCLASSSidebar from '../../ReviewDetailCLASSSidebar';
import ReportPreview from '../../../OldReports/ReportPreview';
import ReviewJournal from './ReviewJournal';
import AttachEvidence from '../../AttachEvidence';
import ReviewPlanFollowUp from './ReviewPlanFollowUp';
import ReviewCenterExploration from './ReviewCenterExploration';
import ReviewManagementAnalysisReport from './ReviewManagementAnalysisReport';
import PreReviewSummary from './PreReviewSummary';
import ReviewManageCenterClassroom from './ReviewManageCenterClassroom';
// import ReviewComprehensiveDataCollection from './ReviewComprehensiveDataCollection';
// import SurveyDataCollection from './SurveyDataCollection';

const ReviewCards = props => {
  return (
    <Menu vertical fluid>
      <AttachEvidence key="evidence" source="cards" />
      <ReviewCenterExploration />
      <ReviewChildFiles />
      {/* <SurveyDataCollection /> */}
      <ReviewEvidenceBinder />
      {/* <ReviewComprehensiveDataCollection /> */}
      <ReviewFindings />
      <ReviewFollowUps />
      <ReviewJournal />
      <ReviewManagementAnalysisReport />
      <ReviewManageCenterClassroom />
      <ReviewManifest />
      <ReviewOCISummary />
      <ReviewPlanFollowUp />
      <PreReviewSummary />
      <ReviewPreSite />
      <ReportPreview />
      <ReviewDetailCLASSSidebar />
    </Menu>
  );
};

ReviewCards.propTypes = {};

export default ReviewCards;
