import React, { useEffect, useRef, useState } from 'react';
import { find, isEmpty } from 'lodash';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import DOMPurify from 'dompurify';

import {
  Accordion,
  Button,
  Checkbox,
  Form,
  Header,
  Icon,
  Message,
  Modal,
  Popup,
  Ref,
  Select,
} from 'semantic-ui-react';

//Import components.

import AmsAlert from '../../utils/AmsAlert';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsFormLabel from '../../utils/AmsFormLabel';
import AmsModal from '../../utils/AmsModal';
import { addNameToDataURL } from '../Shared/FormHelpers/helpers/utils';

//Import actions
import {
  fetchReportFindingsDetail,
  provideDelegatedTaskFeedback,
  provideReportFindingsFeedback,
} from '../../actions/taskActions';

import {
  fetchExternalUsers,
  reportShareAction,
} from '../../actions/reportActions';

const ReportTrackerFindingsModal = ({
  type,
  level,
  data,
  context = 'findingsPage',
  index,
  allFindings = false,
  delegated = false,
  allDelegated = false,
  delegatedFindingsView = false,
  callbackOnSubmit = null,
  onFeedbackSubmitted = null,
  extraPopupText = '',
}) => {
  const dispatch = useDispatch();

  //modal data and modal form data
  const [showModal, setShowModal] = useState(false);
  const [activePanel, setActivePanel] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [confirmationDialogData, setConfirmationDialogData] = useState({
    title: '',
    message: '',
  });

  const [showConfirmCompletedDialog, setShowConfirmCompletedDialog] = useState(
    false
  );

  const [formData, setFormData] = useState({
    feedback: null,
    comment: '',
    files: [],
    emails: [],
    approver: null,
    message: '',
  });

  const [loading, setLoading] = useState(false);

  //errors
  const [errors, setErrors] = useState({});
  const [modalErrors, setModalErrors] = useState(null);
  const [options, setOptions] = useState([]);

  //fetched data for task details, current user, and external user email list
  const taskDetail = useSelector(state => state.taskDetail);
  const currentUser = useSelector(state => state.auth.user);
  const externalUsers = useSelector(state => state.report.externalUsers);

  const iconLinkStyle = { color: 'inherit', textDecoration: 'none' };

  const focusTextArea = useRef();

  const handleTogglePanel = (e, titleProps) => {
    const newState = !activePanel;

    setActivePanel(newState);
  };

  useEffect(() => {
    if (
      context === 'taskSummaryPage' &&
      showModal === true &&
      delegated === false
    ) {
      setLoading(true);
      dispatch(fetchReportFindingsDetail(index)).then(e => {
        setLoading(false);
        if (type === 'forward' && e.length > 0) {
          dispatch(
            fetchExternalUsers({
              group: e[0].sharedGroup,
              reviewId: e[0].reviewId,
              reviewType: e[0].reviewType,
            })
          );
        }
      });
    } else if (showModal === true) {
      if (type === 'forward') {
        dispatch(
          fetchExternalUsers({
            group: data.sharedGroup,
            reviewId: data.reviewId,
            reviewType: data.reviewType,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, showModal]);

  useEffect(() => {
    setOptions(getEmailToOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalUsers]);

  const getEmailToOptions = (input, callback) => {
    return externalUsers.map(item => {
      return {
        text:
          item.firstName +
          ' ' +
          item.lastName +
          ', ' +
          item.group +
          ', ' +
          item.region,
        value: { email: item.email, group: item.group },
        key: item.email,
      };
    });
  };

  const handleEmailChange = (e, data) => {
    setFormData({ ...formData, emails: data.value });
  };

  const fileOnDrop = files => {
    const toReturn = {};

    toReturn.name = files.name;

    var reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = function(e) {
      toReturn.file = reader.result;
      const fileWithName = addNameToDataURL(toReturn.file, toReturn.name);
      if (fileWithName) {
        setFormData({ ...formData, files: [...formData.files, fileWithName] });
      }
    };
    reader.onerror = function(error) {
      //console.log('Error: ', error);
      //setError
    };
  };

  const renderFileInput = () => {
    let toRender = [];

    for (let i = 0; i < formData.files.length + 1; i++) {
      const fileInput = (
        <input
          aria-label="Add attachments to feedback"
          style={{ marginTop: '5px' }}
          key={i}
          type="file"
          accept=".jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf"
          onChange={ev => {
            {
              let filesError = [];

              Array.prototype.slice
                .call(ev.target.files)
                .filter(file => {
                  if (file.size > 20 * 1024 * 1024) {
                    filesError.push({
                      file: file,
                      reason: 'file size exceed 20 Mb',
                    });
                    return false;
                  }
                  if (
                    !file.name.match(
                      /\.(doc|docx|xls|xlsx|pdf|jpg|jpeg|png|PNG)$/
                    )
                  ) {
                    filesError.push({
                      file: file,
                      reason:
                        'file format is not supported, please use .doc,.docx,.xls,.xlsx,.pdf',
                    });
                    return false;
                  }
                  return true;
                })
                .forEach(file => {
                  fileOnDrop(file);
                });
            }
          }}
        ></input>
      );
      toRender.push(fileInput);
    }

    return toRender;
  };

  const handleChange = (e, { value, name }) => {
    setFormData({ ...formData, [name]: value });

    if (value === 'Need Clarification' || value === 'Rejected') {
      focusTextArea.current.children[0].focus();
    }
  };

  const generateModalAlert = () => {
    if (delegated === true && allDelegated === true && index.length === 0) {
      return (
        <Message
          negative
          icon={'cancel'}
          header={'Warning!'}
          content={`All delegated tasks have been completed or expired`}
        ></Message>
      );
    }
    if (!isEmpty(modalErrors)) {
      return (
        <Message
          negative
          icon={'cancel'}
          header={'Something went wrong!'}
          content={`${modalErrors}`}
        ></Message>
      );
    }
  };

  const renderModal = () => {
    let dataToUse;
    let optionsToUse = options;
    if (context === 'taskSummaryPage') {
      dataToUse = taskDetail.reportFindings && taskDetail.reportFindings[0];
    } else {
      dataToUse = data;
    }

    const granteeLink = dataToUse
      ? `${dataToUse.granteeName} (${dataToUse.granteeId})`
      : '';
    let header;
    let subheader = (
      <div style={{ fontSize: '15px', marginTop: '10px' }}>
        <span>
          Review ID:{' '}
          <span style={{ color: '#00449f' }}>
            {dataToUse ? dataToUse.reviewId : ''}
          </span>
        </span>
        <span style={{ color: '#00449f', marginLeft: '25px' }}>
          {granteeLink}
        </span>
      </div>
    );

    if (type === 'feedback') {
      if (delegated === true) {
        if (allDelegated === true) {
          header = (
            <>
              Comments for All Assigned Reviews({(index && index.length) || 0})
            </>
          );
        } else {
          header = (
            <>
              Comments for Review ID -
              <span style={{ color: 'rgb(20,70,153)' }}>{index.reviewId}</span>
            </>
          );
        }
      } else if (level === 'review') {
        header = (
          <>
            All Findings
            {subheader}
          </>
        );
      } else {
        header = (
          <>
            Comment for Finding {index + 1} -{' '}
            {dataToUse.findings[index].findingType}{' '}
            {dataToUse.findings[index].citation}
            {level === 'Narrative' ? ` - ${level}` : ''}
            {level === 'Finding Type'
              ? ` - ${dataToUse.findings[index].findingType} ${dataToUse.findings[index].citation}`
              : ''}
            {subheader}
          </>
        );
      }

      return (
        <AmsModal
          size="small"
          centered={false}
          closeOnDimmerClick={false}
          open={showModal}
          className="ams-semantic-modal-fix"
        >
          <Header style={{ fontSize: '21px' }} as="h2">
            {header}
          </Header>

          {generateModalAlert()}
          {delegated === false && level !== 'review' ? (
            <Accordion style={{ margin: '20px', marginBottom: '0px' }}>
              <Accordion.Title
                active={activePanel === true}
                index={0}
                onClick={handleTogglePanel}
              >
                <Link
                  to="#"
                  style={{ textDecoration: 'none' }}
                  onClick={e => {
                    e.preventDefault();
                    handleTogglePanel();
                  }}
                >
                  <Icon name="dropdown" />
                  View Narrative
                </Link>
              </Accordion.Title>
              <Accordion.Content active={activePanel === true}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      dataToUse.findings[index].narrative
                    ),
                  }}
                ></div>
              </Accordion.Content>
            </Accordion>
          ) : null}
          <Modal.Content>
            <Modal.Description>
              <Form loading={loading}>
                <fieldset style={{ padding: 0 }}>
                  <legend className="visually-hidden">Feedback</legend>

                  <Form.Group inline>
                    <Form.Field width={4}>
                      <Checkbox
                        radio
                        aria-label="Approve"
                        label="Approve"
                        name="feedback"
                        value="Approved"
                        checked={formData.feedback === 'Approved'}
                        onChange={handleChange}
                      />
                    </Form.Field>
                    <Form.Field width={4}>
                      <Checkbox
                        radio
                        label="Reject"
                        aria-label="Reject"
                        name="feedback"
                        value="Rejected"
                        checked={formData.feedback === 'Rejected'}
                        onChange={handleChange}
                      />
                    </Form.Field>
                    {delegated === false ? (
                      <Form.Field width={6}>
                        <Checkbox
                          radio
                          label="Need Clarification"
                          aria-label="Need Clarification"
                          name="feedback"
                          value="Need Clarification"
                          checked={formData.feedback === 'Need Clarification'}
                          onChange={handleChange}
                        />
                      </Form.Field>
                    ) : null}
                  </Form.Group>
                </fieldset>
                {delegated === true ? (
                  <>
                    <fieldset style={{ padding: 0 }}>
                      <legend
                        style={{
                          fontFamily: 'PublicSans-Bold',
                          marginBottom: '6px',
                          display: 'block',
                          fontSize: '14px',
                          border: 0,
                        }}
                      >
                        SELECT APPROPRIATE APPROVER
                      </legend>
                      <Form.Group>
                        <Form.Field>
                          <Checkbox
                            radio
                            label="OHS Director"
                            name="approver"
                            value="OHS Director"
                            checked={formData.approver === 'OHS Director'}
                            onChange={handleChange}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Checkbox
                            radio
                            label="Deputy Director"
                            name="approver"
                            value="Deputy Director"
                            checked={formData.approver === 'Deputy Director'}
                            onChange={handleChange}
                          />
                        </Form.Field>
                      </Form.Group>
                    </fieldset>
                  </>
                ) : null}

                <Form.Field>
                  <label
                    style={{
                      fontFamily: 'PublicSans-Bold',
                      marginBottom: '6px',
                    }}
                    htmlFor={'feedbackComment'}
                  >
                    PROVIDE COMMENT
                    {formData.feedback === 'Need Clarification' ||
                    formData.feedback === 'Rejected'
                      ? '(REQUIRED)'
                      : '(OPTIONAL)'}
                  </label>
                  <Ref innerRef={focusTextArea}>
                    <Form.TextArea
                      id={'feedbackComment'}
                      control="textarea"
                      style={{ margin: 0 }}
                      placeholder=""
                      rows={6}
                      onChange={e => {
                        setFormData({ ...formData, comment: e.target.value });
                      }}
                    />
                  </Ref>
                </Form.Field>
                <div className="buttons add-files-button">
                  {renderFileInput()}
                </div>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                setShowModal(false);
                setModalErrors(null);
                setFormData({ feedback: null, comment: '', files: [] });
              }}
              content="Cancel"
            />
            <Button
              onClick={() => {
                submitFeedback();
              }}
              disabled={
                (delegated === true && !formData.approver) ||
                formData.feedback === null ||
                ((formData.feedback === 'Rejected' ||
                  formData.feedback === 'Need Clarification') &&
                  formData.comment === '') ||
                (delegated === true &&
                  allDelegated === true &&
                  index.length === 0)
              }
              primary
              content="Submit"
            />
          </Modal.Actions>
        </AmsModal>
      );
    } else {
      header = (
        <>
          Forward Findings
          {dataToUse && dataToUse.strongPracticeCount > 0
            ? ' and Strong Practices'
            : ''}
          {subheader}
        </>
      );

      return (
        <AmsModal
          size="small"
          centered={false}
          closeOnDimmerClick={false}
          open={showModal}
          className="ams-semantic-modal-fix"
        >
          <Header style={{ fontSize: '21px' }} as="h2">
            {header}
          </Header>
          {generateModalAlert()}
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.Field
                  error={errors.expirationDate}
                  selectsStart
                  disabled
                  control={DatePicker}
                  dateFormat="YYYY-MM-DD"
                  placeholderText="Select Expiration Date"
                  selected={
                    formData.expirationDate &&
                    AmsDateFormatters.getMoment(formData.expirationDate)
                  }
                  fromDate={new AmsDateFormatters.getMoment()}
                  onChange={date => {
                    if (date)
                      setFormData({
                        ...formData,
                        expirationDate: AmsDateFormatters.getMoment(
                          date
                        ).format('L'),
                      });
                    else
                      setFormData({
                        ...formData,
                        expirationDate: null,
                      });
                  }}
                  minDate={new AmsDateFormatters.getMoment()}
                  value={dataToUse && dataToUse.expirationDate} //formData.expirationDate ? formData.expirationDate : ''}
                  popperPlacement="top-start"
                  id="expirationDate"
                  aria-labelledby="expirationDate"
                  label={{
                    children: 'Expiration Date',
                    htmlFor: 'expirationDate',
                  }}
                ></Form.Field>
                <Form.Field required>
                  <AmsFormLabel
                    name="External User Email"
                    fieldLabel="recipientEmail"
                  >
                    <Select
                      search
                      required
                      options={optionsToUse}
                      onChange={handleEmailChange}
                      placeholder=""
                      selection
                      multiple
                      value={formData.emails}
                      aria-labelledby="recipientEmail"
                      searchInput={{
                        id: 'recipientEmail',
                        title: 'External User Email',
                        'aria-labelledby': 'recipientEmail',
                      }}
                    />
                  </AmsFormLabel>
                </Form.Field>

                <Form.Field required>
                  <label htmlFor="forwardMessage">Message</label>
                  <Form.TextArea
                    id="forwardMessage"
                    control="textarea"
                    style={{ margin: 0 }}
                    placeholder=""
                    rows={6}
                    onChange={e => {
                      setFormData({ ...formData, message: e.target.value });
                    }}
                  />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                setFormData({});
                setShowModal(false);
                setModalErrors(null);
              }}
              content="Cancel"
            />
            <Button
              onClick={() => {
                submitForward();
              }}
              disabled={isEmpty(formData.emails) || formData.message === ''}
              primary
              content="Submit"
            />
          </Modal.Actions>
        </AmsModal>
      );
    }
  };

  const submitFeedback = () => {
    let dataToUse;
    if (context === 'taskSummaryPage') {
      dataToUse = taskDetail.reportFindings && taskDetail.reportFindings[0];
    } else {
      dataToUse = data;
    }
    setModalErrors(null);

    if (
      level !== 'review' &&
      delegated !== true &&
      dataToUse.remainingFindingsCount !== 0
    ) {
      setShowConfirmCompletedDialog(true);
      return;
    }

    if (delegated === true) {
      const input = {
        status: formData.feedback,
        reason: formData.comment,
        approver: formData.approver,
      };
      if (formData.files && formData.files.length > 0) {
        input.evidences = formData.files;
      }
      if (allDelegated) {
        input.reviewList = index.map(e => {
          return {
            reviewId: e.reviewId,
            conversationId: e.conversationId,
          };
        });
      } else {
        input.reviewList = [
          { reviewId: index.reviewId, conversationId: index.conversationId },
        ];
      }

      dispatch(provideDelegatedTaskFeedback(input))
        .then(e => {
          setShowModal(false);
          setFormData({
            feedback: null,
            comment: '',
            files: [],
            approver: null,
          });
          setErrors({});
          setShowConfirmationDialog(true);
          setConfirmationDialogData({
            title: 'Feedback Submitted.',
            message: `Success! Feedback successfully submitted.`,
          });
        })
        .catch(e => {
          setModalErrors(e);
        });

      return;
    }
    const input = {
      reviewId: dataToUse.reviewId,
      reason: formData.comment,
      status: formData.feedback,
      conversationId: dataToUse.conversationId,
    };

    //check level and form input object
    if (level === 'review') {
      let findingIds = [];
      let citationNumbers = [];
      dataToUse.findings.forEach(e => {
        findingIds.push(e.findingId);
        citationNumbers.push(e.citation);
      });
      input.findingIds = findingIds;
      input.citationNumbers = citationNumbers;
      input.type = 'all';
    } else {
      let levelText =
        level === 'Finding Type'
          ? 'findingType'
          : level === 'Narrative'
          ? 'narrative'
          : level;

      const currFinding = dataToUse.findings[index];
      input.findingIds = [currFinding.findingId];
      input.citationNumbers = [currFinding.citation];
      input.findingType = currFinding.findingType;
      input.type = levelText;
    }
    if (formData.files && formData.files.length > 0) {
      input.evidences = formData.files;
    }

    //submit feedback, then upload files upon success, reset page ,reload data
    dispatch(provideReportFindingsFeedback(input))
      .then(e => {
        setShowModal(false);
        setFormData({ feedback: null, comment: '', files: [] });
        setErrors({});
        setShowConfirmationDialog(true);
        setConfirmationDialogData({
          title: 'Feedback Submitted.',
          message: `Success! Feedback successfully submitted.`,
        });
      })
      .catch(e => {
        setModalErrors(e);
      });
  };

  const renderConfirmation = () => {
    const { title, message } = confirmationDialogData;

    let dataToUse;
    if (context === 'taskSummaryPage') {
      dataToUse = taskDetail.reportFindings && taskDetail.reportFindings[0];
    } else {
      dataToUse = data;
    }

    return (
      <AmsAlert
        show={showConfirmationDialog}
        title={title}
        type="success"
        showConfirm
        text={message}
        onConfirm={() => {
          if (title === 'Feedback Submitted.' && callbackOnSubmit !== null) {
            callbackOnSubmit();
          }
          if (allDelegated && delegated) {
            dispatch(
              fetchReportFindingsDetail({
                allFindings: true,
                delegated: true,
              })
            );
          } else if (delegated) {
            dispatch(
              fetchReportFindingsDetail({ ...dataToUse, delegated: true })
            );
          } else {
            dispatch(fetchReportFindingsDetail(dataToUse)).then(data => {
              if (onFeedbackSubmitted !== null) {
                onFeedbackSubmitted(dataToUse._id, data);
              }
            });
          }
          setShowConfirmationDialog(false);
          setConfirmationDialogData({ title: '', message: '' });
        }}
      />
    );
  };

  const renderConfirmCompletedDialog = () => {
    let dataToUse = data;

    //submit feedback, then upload files upon success, reset page ,reload data

    const text = `There are still ${dataToUse &&
      dataToUse.remainingFindingsCount} finding(s) for which you have not provided feedback. Are you done providing feedback for this report?`;

    return (
      <AmsAlert
        show={showConfirmCompletedDialog}
        title={'Alert!'}
        type="info"
        subtext={`Note: If Yes is selected, then the task status will be updated to "Completed", you can no longer provide feedback, and all remaining findings will be marked as approved. If No is selected, you can continue providing feedback.`}
        showConfirm
        showCancelButton={true}
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        text={text}
        onConfirm={() => {
          const input = {
            reviewId: dataToUse.reviewId,
            reason: formData.comment,
            status: formData.feedback,
            conversationId: dataToUse.conversationId,
          };

          //check level and form input object

          let levelText =
            level === 'Finding Type'
              ? 'findingType'
              : level === 'Narrative'
              ? 'narrative'
              : level;

          const currFinding = dataToUse.findings[index];
          if (!currFinding) {
            return null;
          }
          input.findingIds = [currFinding.findingId];
          input.citationNumbers = [currFinding.citation];
          input.findingType = currFinding.findingType;
          input.type = levelText;

          if (formData.files && formData.files.length > 0) {
            input.evidences = formData.files;
          }
          input.isFeedbackCompleted = true;
          dispatch(provideReportFindingsFeedback(input))
            .then(e => {
              setShowModal(false);
              setFormData({ feedback: null, comment: '', files: [] });
              setErrors({});
              setShowConfirmationDialog(true);
              setShowConfirmCompletedDialog(false);
              setConfirmationDialogData({
                title: 'Feedback Submitted.',
                message: `Success! Feedback successfully submitted.`,
              });
            })
            .catch(e => {
              setModalErrors(e);
            });
        }}
        onCancel={() => {
          const input = {
            reviewId: dataToUse.reviewId,
            reason: formData.comment,
            status: formData.feedback,
            conversationId: dataToUse.conversationId,
          };

          //check level and form input object

          let levelText =
            level === 'Finding Type'
              ? 'findingType'
              : level === 'Narrative'
              ? 'narrative'
              : level;

          const currFinding = dataToUse.findings[index];
          if (!currFinding) {
            return null;
          }
          input.findingIds = [currFinding.findingId];
          input.citationNumbers = [currFinding.citation];
          input.findingType = currFinding.findingType;
          input.type = levelText;

          if (formData.files && formData.files.length > 0) {
            input.evidences = formData.files;
          }
          input.isFeedbackCompleted = false;
          dispatch(provideReportFindingsFeedback(input))
            .then(e => {
              setShowModal(false);
              setFormData({ feedback: null, comment: '', files: [] });
              setErrors({});
              setShowConfirmationDialog(true);
              setShowConfirmCompletedDialog(false);
              setConfirmationDialogData({
                title: 'Feedback Submitted.',
                message: `Success! Feedback successfully submitted.`,
              });
            })
            .catch(e => {
              setModalErrors(e);
            });
        }}
      />
    );
  };

  const submitForward = () => {
    let dataToUse;
    if (context === 'taskSummaryPage') {
      dataToUse = taskDetail.reportFindings && taskDetail.reportFindings[0];
    } else {
      dataToUse = data;
    }

    setModalErrors(null);

    //add current user to sharedInternal
    let sharedInternal = dataToUse.sharedInternal;
    const hasCurrent = find(dataToUse.sharedInternal, function(o) {
      return o.oid === currentUser.oid;
    });

    if (!hasCurrent) {
      let toAdd = {
        email: currentUser.email,
        fullName: currentUser.fullName,
        oid: currentUser.oid,
        userName: currentUser.userName,
      };

      const splitName = currentUser.fullName.split(' ');
      if (splitName.length === 2) {
        toAdd.firstName = splitName[0];
        toAdd.lastName = splitName[1];
      }
      sharedInternal.push(toAdd);
    }

    const input = {
      reviewID: dataToUse.reviewId,
      reportID: dataToUse.reportId,
      reportKey: dataToUse.reportKey,
      sharedGroup: dataToUse.sharedGroup,
      internalUsers: sharedInternal,
      forwardFinding: true,
      reportLink: dataToUse.reportLink,
      sharedDate: new Date().toISOString(),
      sharedExternal: formData.emails,
      message: formData.message,
      sharedBy: dataToUse.sharedBy,
      expirationDate: dataToUse.expirationDate,
    };

    //submit forward, reset page, reload data
    dispatch(
      reportShareAction(input, result => {
        if (result instanceof Error) {
          setModalErrors(
            `'Error: ${result.response.data.message || 'unknown error'}`
          );
        } else {
          setShowModal(false);
          setFormData({ feedback: null, comment: '', files: [] });
          setErrors({});
          setShowConfirmationDialog(true);
          setConfirmationDialogData({
            title: 'Success',
            message: `The report has been shared.`,
          });
        }
      })
    );
  };

  const icon =
    type === 'forward' ? 'mail forward' : type === 'feedback' ? 'cog' : null;

  if (context === 'taskSummaryPage' && type === 'feedback') {
    iconLinkStyle.marginRight = '10px';
  }

  if (delegated === true && allDelegated === true) {
    iconLinkStyle.fontSize = '25px';
    iconLinkStyle.float = 'right';
    iconLinkStyle.display = 'inline-block';
    iconLinkStyle.marginTop = delegatedFindingsView ? '4px' : '8px';
    iconLinkStyle.marginLeft = '10px';
  }

  const generatePopupcontent = (aria = false) => {
    let dataToUse;
    if (context === 'taskSummaryPage') {
      dataToUse = index;
    } else {
      dataToUse = data;
    }

    let header;
    if (type === 'feedback') {
      if (delegated === true && allDelegated === true) {
        header = aria ? '' : 'Select to ';
        header += 'Provide Feedback for All Delegated Tasks' + extraPopupText;
      } else {
        header = aria ? '' : 'Select to ';
        header += 'Provide Feedback for Review ID: ' + dataToUse.reviewId;
        if (level !== 'review') {
          let additionalInfo = ` (Finding ${index + 1})`;
          additionalInfo += level === 'Narrative' ? ` - ${level}` : '';
          additionalInfo += level === 'Finding Type' ? ` - Finding Type` : '';

          header += additionalInfo;
        }
      }
    } else {
      header =
        'Select to Forward Findings' +
        (dataToUse.strongPracticeCount > 0 ? ' and Strong Practices' : '') +
        ' for Review ID: ' +
        dataToUse.reviewId;
    }
    return header;
  };

  if (delegated && allDelegated && !index) {
    return null;
  }

  return (
    <>
      <Popup
        content={generatePopupcontent()}
        position="top center"
        basic
        on={['hover', 'focus']}
        trigger={
          <Link
            to="#"
            style={iconLinkStyle}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShowModal(true);
            }}
          >
            <Icon name={icon} />
            <span
              style={{ transition: 'none 0s ease 0s' }}
              className="visually-hidden"
            >
              {generatePopupcontent(true)}
            </span>
          </Link>
        }
      />

      {renderConfirmation()}
      {renderModal()}
      {renderConfirmCompletedDialog()}
    </>
  );
};
export default ReportTrackerFindingsModal;
