import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';

// Import components.
import GranteeUnavailability from './GranteeUnavailability';
import ScheduleExtenstions from './ScheduleExtenstions';

const GranteeSidebar = props => {
  return (
    <Menu vertical fluid>
      <GranteeUnavailability />
      {props?.hasEditableRoles && <ScheduleExtenstions />}
    </Menu>
  );
};

GranteeSidebar.propTypes = { hasEditableRoles: PropTypes.bool.isRequired };

export default GranteeSidebar;
