/* eslint-disable jsx-a11y/anchor-is-valid */
import generator from 'generate-password';
import $ from 'jquery';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, HelpBlock } from 'react-bootstrap';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Icon, Input } from 'semantic-ui-react';
import isEmail from 'validator/lib/isEmail';
import { FieldGroupField, FieldGroupSelectList } from '../../utils/FieldGroup';

// Import actions.
import {
  createUserOnAzure,
  getAmsRoles,
  userCreatedOnAzure,
  userProfileCreated,
} from '../../actions/adminActions';

// Import Components.
import AmsAlert from '../../utils/AmsAlert';
import AmsLookupDropdown from '../../utils/AmsLookupDropdown';

class CreateUserModal extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: true,
      showCreationSuccess: true,
      showConfirmCreation: false,
      showErrorAlert: false,
      errorMessage: '',
      creating: false,
      errors: {},
      data: {
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        temporaryPassword: generator.generate({
          length: 8,
          numbers: true,
        }),
        region: [],
        forcePasswordChange: true,
        enableAccount: true,
        roles: [],
      },
    };

    this.closeModal = this.closeModal.bind(this);
    this.handleInputValueChage = this.handleInputValueChage.bind(this);
    this.handeleAppRolsSelect = this.handeleAppRolsSelect.bind(this);
    this.handeleForcePasswordChange = this.handeleForcePasswordChange.bind(
      this
    );
    this.handeleEnableAccount = this.handeleEnableAccount.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    // Temporary fix for react-widgets tabindex.
    // Known bug of react-widgets. Bug reported on https://github.com/jquense/react-widgets/issues/630;
    $('ul.rw-list').attr('tabindex', '0');
  }

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {
    const { createdUser, createdProfile } = this.props;

    if (!_.isEmpty(createdUser)) this.props.userCreatedOnAzure({});
    if (!_.isEmpty(createdProfile)) this.props.userProfileCreated({});
  }

  getData() {
    this.props.getAmsRoles();
  }

  closeModal() {
    console.log('clicked');
    this.setState(
      {
        data: {},
        modalIsOpen: false,
        showCreationSuccess: false,
        showConfirmCreation: false,
      },
      () => this.context.router.push(`/admin/users`)
    );
  }

  handleInputValueChage(event) {
    this.setState({
      data: { ...this.state.data, [event.target.id]: event.target.value },
      errors: { ...this.state.errors, [event.target.id + 'Error']: '' },
    });
  }

  handlePasswordRefresh(e) {
    e.preventDefault();
    this.setState({
      data: {
        ...this.state.data,
        temporaryPassword: generator.generate({
          length: 8,
          numbers: true,
        }),
      },
    });
  }

  handeleAppRolsSelect(selections) {
    this.setState({
      data: { ...this.state.data, roles: selections },
      errors: { ...this.state.errors, rolesError: '' },
    });
  }

  handeleForcePasswordChange(value) {
    if (value && value === 'Yes') {
      this.setState({
        data: { ...this.state.data, forcePasswordChange: true },
      });
    } else {
      this.setState({
        data: { ...this.state.data, forcePasswordChange: false },
      });
    }
  }

  handeleEnableAccount(value) {
    if (value && value === 'Yes') {
      this.setState({ data: { ...this.state.data, enableAccount: true } });
    } else {
      this.setState({ data: { ...this.state.data, enableAccount: false } });
    }
  }

  saveData() {
    const {
      firstName,
      lastName,
      userName,
      email,
      temporaryPassword,
      forcePasswordChange,
      enableAccount,
      roles,
      region,
    } = this.state.data;

    // Data used to create a suer account on Azure.
    const userData = {
      accountEnabled: enableAccount,
      displayName: `${firstName} ${lastName}`.trim(),
      mailNickname: userName && userName.split('@')[0].trim(),
      userPrincipalName: userName && userName.trim().toLocaleLowerCase(),
      mail: email,
      passwordProfile: {
        forceChangePasswordNextSignIn: forcePasswordChange,
        password: temporaryPassword,
      },
    };
    // Portion of user data for AMS user profile.
    const profileData = {
      firstName,
      lastName,
      email,
      roles,
      region,
    };

    this.props.createUserOnAzure(userData, profileData).catch(error => {
      const { message } = error.response.data;
      if (message)
        this.setState({
          loading: false,
          showErrorAlert: true,
          errorMessage: message,
          creating: false,
        });
    });
  }

  validate = data => {
    const errors = {};

    if (!data.firstName) errors.firstNameError = 'First name is required.';
    if (!data.lastName) errors.lastNameError = 'Last name is required.';
    if (!data.userName) errors.userNameError = 'User name is required.';
    if (!data.roles.length)
      errors.rolesError = 'One or more roles must be selected.';
    if (!isEmail(data.email)) errors.emailError = 'Invalid email.';

    return errors;
  };

  onSubmit = e => {
    e.preventDefault();

    const errors = this.validate(this.state.data);
    this.setState({ errors });

    // No more errors show confirmation dialog.
    if (Object.keys(errors).length === 0) {
      this.setState({ showConfirmCreation: true });
    }
  };

  handleRegionChange = (e, { name, value }) =>
    this.setState({
      ...this.state,
      data: { ...this.state.data, region: value },
      errors: _.omit(this.state.errors, name),
    });

  buildFormBody() {
    const { userRoles } = this.props;
    const {
      firstName,
      lastName,
      userName,
      email,
      temporaryPassword,
      region,
    } = this.state.data;
    const {
      firstNameError,
      lastNameError,
      userNameError,
      emailError,
      rolesError,
    } = this.state.errors;

    return (
      <div className="col-sm-12">
        <form onSubmit={this.onSubmit}>
          <div className="col-sm-6">
            <FieldGroupField
              id="firstName"
              validationState={firstNameError ? 'error' : null}
              value={firstName}
              onChange={this.handleInputValueChage}
              label="First name"
              help={firstNameError || 'Example: John'}
            />
          </div>

          <div className="col-sm-6">
            <FieldGroupField
              id="lastName"
              validationState={lastNameError ? 'error' : null}
              value={lastName}
              onChange={this.handleInputValueChage}
              label="Last Name"
              help={lastNameError || 'Example: Doe'}
            />
          </div>

          <div className="col-sm-6">
            <FieldGroupField
              id="userName"
              validationState={userNameError ? 'error' : null}
              value={userName}
              onChange={this.handleInputValueChage}
              label="Username"
              help={userNameError || 'Example: jdoe'}
            />
          </div>

          <div className="col-sm-6">
            <FieldGroupField
              id="email"
              validationState={emailError ? 'error' : null}
              value={email}
              onChange={this.handleInputValueChage}
              label="Email"
              help={emailError || 'Example: user@danya.com'}
            />
          </div>

          <div className="col-sm-6">
            <FieldGroupSelectList
              id="appRoles"
              validationState={rolesError ? 'error' : null}
              name="appRoles"
              label="AMS Roles"
              onChange={this.handeleAppRolsSelect}
              multiple
              data={userRoles}
              valueField="role"
              textField="displayName"
              tabIndex={'0'}
              style={{ minHeight: '378px' }}
              help={rolesError || 'Select one or more roles'}
            />
          </div>

          <div className="col-sm-6">
            <div className="form-group form-group-lg">
              <label className="control-label">Region</label>
              <AmsLookupDropdown
                selection
                multiple
                fluid
                onChange={this.handleRegionChange}
                value={region}
                category={'regions'}
              />
              <HelpBlock>
                Select user's region.{' '}
                <a
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ data: { ...this.state.data, region: '' } });
                  }}
                >
                  Clear Selection
                </a>
              </HelpBlock>
            </div>

            <FieldGroupSelectList
              id="enableAccount"
              name="enableAccount"
              label="Enable account"
              defaultValue={'Yes'}
              onChange={this.handeleEnableAccount}
              valueField="value"
              textField="label"
              data={['Yes', 'No']}
              help="Select account status"
            />

            <FieldGroupSelectList
              id="forcePasswordChange"
              name="forcePasswordChange"
              label="Force password change on next sign in"
              onChange={this.handeleForcePasswordChange}
              defaultValue={'Yes'}
              valueField="value"
              textField="label"
              data={['Yes', 'No']}
              help="Enforce password change"
            />

            <div className="form-group form-group-lg">
              <label className="control-label">Temporary password</label>
              <Input
                action={{
                  icon: 'refresh',
                  onClick: this.handlePasswordRefresh.bind(this),
                }}
                type="readOnly"
                size="big"
                fluid
                value={temporaryPassword}
              />
              <HelpBlock>
                Temporary password. User must change it at first sign in
              </HelpBlock>
            </div>
          </div>

          <div className="col-sm-12">
            <Button
              type="submit"
              bsSize="lg"
              className="btn btn-primary"
              disabled={this.state.creating}
            >
              {' '}
              {this.state.creating ? 'Creating....' : 'Create'}{' '}
            </Button>
          </div>
        </form>
      </div>
    );
  }

  renderModal() {
    const { modalIsOpen } = this.state;

    return (
      <Modal
        className="modal-container"
        overlayClassName="modal-overlay-container"
        isOpen={modalIsOpen}
        onRequestClose={this.closeModa}
        shouldCloseOnOverlayClick={false}
        contentLabel="Create User Modal"
      >
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-11">
              <h2 className="no-top-margin">Create New User</h2>
            </div>

            <div className="col-sm-1">
              <Button
                // color="black"
                style={{ float: 'right', background: 'none' }}
                icon
                size="large"
                onClick={this.closeModal}
                aria-label="close"
              >
                <Icon name="close"></Icon>
              </Button>
            </div>

            <div className="col-sm-12">
              <hr />
            </div>

            <div className="row">{this.buildFormBody()}</div>
          </div>
        </div>
      </Modal>
    );
  }

  showConfirmationAlert() {
    // Check if Required fileds are added

    return (
      <AmsAlert
        show={this.state.showConfirmCreation}
        title="Create Account"
        text={`You are about to create a new user account. Are you sure you want continue?`}
        type={'warning'}
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes, create it!'}
        cancelButtonText={'No, cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({ showConfirmCreation: false });
        }}
        onConfirm={() => {
          this.setState({ creating: true, showConfirmCreation: false });
          this.saveData();
        }}
      />
    );
  }

  showCreationSuccessAlert() {
    const { showCreationSuccess, data } = this.state;
    const { createdProfile } = this.props;

    return (
      <AmsAlert
        show={showCreationSuccess && !_.isEmpty(createdProfile)}
        title="User created"
        type="success"
        text={`${createdProfile.firstName}'s account has been created successfully.`}
        subtext={`Temporary password: ${data.temporaryPassword}`}
        html={true}
        onConfirm={() => {
          this.setState(
            {
              data: {},
              modalIsOpen: false,
              showCreationSuccess: false,
              showConfirmCreation: false,
            },
            () => this.context.router.push(`/admin/users`)
          );
        }}
      />
    );
  }

  showErrorAlert() {
    const { showErrorAlert, errorMessage } = this.state;

    return (
      <AmsAlert
        show={showErrorAlert}
        title="Oops!"
        text={errorMessage}
        type={'error'}
        showConfirm
        onCancel={() => {
          this.setState({ showErrorAlert: false, errorMessage: '' });
        }}
        onConfirm={() => {
          this.setState({ showErrorAlert: false, errorMessage: '' });
        }}
      />
    );
  }

  render() {
    return (
      <div>
        {this.showConfirmationAlert()}
        {this.showCreationSuccessAlert()}
        {this.showErrorAlert()}
        {this.renderModal()}
      </div>
    );
  }
}

CreateUserModal.propTypes = {
  createUserOnAzure: PropTypes.func.isRequired,
  getAmsRoles: PropTypes.func.isRequired,
  userCreatedOnAzure: PropTypes.func.isRequired,
  userProfileCreated: PropTypes.func.isRequired,
  createdUser: PropTypes.object.isRequired,
  createdProfile: PropTypes.object.isRequired,
  userRoles: PropTypes.array.isRequired,
};

CreateUserModal.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    createdUser: state.admin.createdUser,
    createdProfile: state.admin.createdProfile,
    userRoles: state.admin.userRoles,
  };
}

export default connect(mapStateToProps, {
  createUserOnAzure,
  userCreatedOnAzure,
  userProfileCreated,
  getAmsRoles,
})(CreateUserModal);
