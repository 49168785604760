import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router';

import {
  Grid,
  Comment,
  Message,
  Header,
  Segment,
  Popup,
  Label,
  Icon,
  Accordion,
} from 'semantic-ui-react';

//Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import ReportTrackerFindingsModalFY24 from './ReportTrackerFindingsModalFY24';

import {
  fetchReportFindingsDetail,
} from '../../actions/taskActions';

import {
  approvedBox,
  needClarificationBox,
  inProgressBox,
  rejectedBox,
  redirectToTasksSummary,
  generateReviewDetails,
  renderFindingsAndPracticesCount,
  hasFindings,
  hideStrongPractices,
  disableStrongPracticesComment,
  getContentAreaFindings
} from './util'

import './assets/style.css';
import { uniqBy, orderBy } from 'lodash';
import DOMPurify from 'dompurify';

const SurveyReportTrackerFindingsPage = props => {
  const dispatch = useDispatch();

  //fetched data for task details, current user, and external user email list
  const taskDetail = useSelector(state => state.taskDetail);
  const { user } = useSelector(state => state.auth);
  
  //task data
  const [reviewFindings, setReviewFindings] = useState([]);
  const [activeComments, setActiveComments] = useState([]);
  const [delegatedTasks, setDelegatedTasks] = useState(false);
  const [activeIndex, setActiveIndex] = useState([0]);
  const STRONG_PRACTICE = 'strongPractice';
  const FINDING = 'finding'; 
  //html style / components

  //set data upon fetch
  useEffect(() => {
    if (taskDetail && taskDetail.reportFindings) {
      const payload = taskDetail.reportFindings;

      if (payload.delegated === true) {
        setDelegatedTasks(true);
      }

      if (payload.length > 0) {
        setReviewFindings(payload);
      } else {
        redirectToTasksSummary(props);
      }
    } else {
      if (
        props &&
        props.params &&
        props.params.reviewId &&
        props.params.conversationId
      ) {
        dispatch(
          fetchReportFindingsDetail({
            reviewId: props.params.reviewId,
            conversationId: props.params.conversationId,
          })
        );
      } else {
        redirectToTasksSummary(props);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDetail]);

  const handleAccordion = (value) => {
    let copy = activeIndex.slice(0);

    if (copy.includes(value))
      copy  = copy.filter(function(item) { return item !== value });
    else
      copy.push(value);
  
    setActiveIndex(copy);
  };
  

  const generateFindingsDetails = (data, idx) => {

    if (!data || !data.reviewType) {
      return null;
    }    
    return (<>
      {data.taskFindings?.map((item, index) => {
        if (hideStrongPractices(user) && !hasFindings(item))
          return (<></>);
        var findings = getContentAreaFindings(item);

        return (<Accordion key={index} fluid style={{marginBottom: '15px'}}>
        <Accordion.Title
          active={activeIndex.includes(index)}
          index={0}
          onClick={x => { handleAccordion(index) }}
          style={{ backgroundColor: '#DCDCDC', padding: '15px', borderRadius: "5px", color: '#000000de' }}
        >
          <h3 style={{ margin: '0' }}>
              {item.contentArea}
            <Icon
              style={{ float: 'right' }}
              name={activeIndex.includes(index) ? 'minus' : 'plus'}
            />
          </h3>
        </Accordion.Title>
        <Accordion.Content active={activeIndex.includes(index)}>
          <Segment style={{border: "none", padding: "10px 0px 0px", boxShadow: "none"}}>
            {findings?.map((e, index) => {
              const headerStyle = { marginBottom: '10px', fontSize: '1rem' };
              const isStrongPractice = e.collectDataType == STRONG_PRACTICE;
              const isFinding = e.collectDataType == FINDING;
              if (isStrongPractice && hideStrongPractices(user))
                return (<></>);

              return (
                <Segment.Group key={index} style={{ border: '1px solid #ccc', boxShadow: 'none', paddingBottom: '10px'}}>
                  <Segment style={{padding: '0px 10px 12px 5px', 
                    margin: '15px 15px 0px 10px', 
                    borderBottom: '1px solid #ccc'}}>
                    <Header as="h5">
                      {isStrongPractice ? e.findingDisplayName : e.findingDisplayName + ": " + e.citation}
                      <span
                        style={{ color: 'black', float: 'right', marginRight: '-10px' }}
                      >
                        {e.feedbackStatus !== null
                          ? e.feedbackStatus === 'Approved'
                            ? approvedBox
                            : e.feedbackStatus === 'Rejected'
                            ? rejectedBox
                            : e.feedbackStatus === 'Need Clarification'
                            ? needClarificationBox
                            : e.feedbackStatus === 'In Progress'
                            ? inProgressBox
                            : null
                          : null}
                        {e.provideFeedback && (!isStrongPractice || !disableStrongPracticesComment(user)) && (
                          <ReportTrackerFindingsModalFY24
                            type="feedback"
                            level="finding"
                            data={data}
                            index={index}
                            item={e}
                            allFindings={
                              (reviewFindings && reviewFindings.length > 1) ||
                              (props.params.reviewId === undefined ? true : false)
                            }
                          />
                        )}
                      </span>
                    </Header>
                  </Segment>
                  <Grid style={{ margin: '0px', clear: 'both' }}>
                    <Grid.Row columns={isFinding ? 3 : 1} style={{ margin: 'auto', paddingBottom: '10px'}}>
                      {isFinding ? 
                      <Grid.Column>
                        <Header style={headerStyle} as="h5">
                          Citation
                        </Header>
                        <Link
                          to={{
                            pathname: `/review/citations/definition`,
                            query: { citation: e.citation },
                          }}
                          style={{fontSize: '1rem'}}
                          target="_blank"
                        >
                          <Popup
                            trigger={
                              <Label
                                style={{
                                  color: 'blue',
                                  background: 'white',
                                  padding: '0px',
                                  fontSize: '14px',
                                  textDecorationLine: 'underline',
                                }}
                              >
                                {' '}
                                {e ? e.citation : ''}
                              </Label>
                            }
                            content="Select to see Citation Definition"
                          />
                        </Link>
                      </Grid.Column> : null}
                      <Grid.Column>
                        <Header style={headerStyle} as="h5">
                          Performance Area
                        </Header>
                        {e.performanceAreas?.join(', ')}
                      </Grid.Column>
                      {isFinding ? 
                      <Grid.Column>
                        <Header style={headerStyle} as="h5">
                          Finding Type
                          {e.findingTypeStatus !== null && ` (${e.findingTypeStatus})`}
                        </Header>
                        <div>{e.findingType}</div>
                      </Grid.Column> : null}
                    </Grid.Row>
                    <Grid.Row style={{ margin: 'auto', paddingBottom: '10px'}}>
                      <Grid.Column>
                        <Header style={headerStyle} as="h5">
                          {isFinding ? 'Collected Data and Narrative' : 'Collected Data'}
                          {e.narrativeStatus !== null && ` (${e.narrativeStatus})`}
                          <span
                            style={{
                              color: 'black',
                              float: 'right',
                              marginRight: '-15px',
                            }}
                          ></span>
                        </Header>
                        <Message style={{borderRadius: '2px', backgroundColor: 'transparent'}}>
                          <div
                            style={{
                              whiteSpace: 'pre-wrap',
                            }}
                          >
                            {(
                              <ul style={{ margin: '0px', paddingLeft: '10px' }}>
                                {e.collectedData?.map((x, index) => (
                                  <li key={index} style={DOMPurify.sanitize(x)?.includes("_") ? {marginLeft: '10px'} : {}}>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(x?.replace(/\d+_/, '')),
                                      }}
                                    ></span>
                                  </li>
                                ))}
                              </ul>
                            )}
                            <br />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(e.narrative),
                              }}
                            ></div>
                          </div>
                        </Message>
                      </Grid.Column>
                    </Grid.Row>
                    {e.comments && e.comments.length > 0 && (
                      <Grid.Row style={{ margin: 'auto', padding: '5px 0px 0px'}}>
                        <Grid.Column>
                          {generateCommentsView('finding', e)}
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </Grid>
                </Segment.Group>
              );

            })}
          </Segment>
        </Accordion.Content>
      </Accordion>
      )})}
    </>)
  };

  const generateSingleDelegatedReviewView = data => {
    const title = `${data.reviewType} Review ID - ${data.reviewId} | ${data.grantType}: `;
    const granteeLink = `${data.granteeName} (${data.granteeId})`;
    return (
      <>
        <Header as="h3">
          {title}{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/grantee/${data ? data.granteeId : ''}`}
          >
            {granteeLink}
          </a>
          <div style={{
            display: 'inline-block',
            float: 'right',
            marginRight: '15px',
            marginTop: '5px'
          }}>
            {data && data.provideFeedback && (
              <ReportTrackerFindingsModalFY24
                type="feedback"
                context="taskSummaryPage"
                level="review"
                allFindings={props.params.reviewId === undefined ? true : false}
                index={data}
                delegated={true}
              />
            )}
          </div>
        </Header>
        <div>
          {renderFindingsAndPracticesCount(data, hideStrongPractices(user))}
          <div style={{ float: 'right', marginRight: '35px' }} className="actionButtons">
           
             {data && data.findingsFeedbackStatus !== null
              ? data.findingsFeedbackStatus === 'Approved'
                ? approvedBox
                : data.findingsFeedbackStatus === 'Rejected'
                ? rejectedBox
                : data.findingsFeedbackStatus === 'Need Clarification'
                ? needClarificationBox
                : data.findingsFeedbackStatus === 'In Progress'
                ? inProgressBox
                : null
              : null}
          </div>
        </div>
       
        
        <Grid stackable style={{ width: '100', paddingLeft: '15px', paddingRight: '15px', marginTop: '5px' }}>
          <Grid.Row>
            <Grid.Column>
              <Segment>{generateReviewDetails(data)}</Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column> {generateDelegatedTaskDetails(data)}</Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  };

  const generateDelegatedTaskDetails = (data) => {
      return (<>
        {data.taskFindings?.map((item, index) => {
          var findings = getContentAreaFindings(item);
          return (<Accordion key={index} fluid style={{marginBottom: '15px'}}>
            <Accordion.Title
              active={activeIndex.includes(index)}
              index={0}
              onClick={x => { handleAccordion(index) }}
              style={{ backgroundColor: '#DCDCDC', padding: '15px', borderRadius: "5px", color: '#000000de' }}
            >
              <h3 style={{ margin: '0' }}>
                  {item.contentArea}
                <Icon
                  style={{ float: 'right' }}
                  name={activeIndex.includes(index) ? 'minus' : 'plus'}
                />
              </h3>
            </Accordion.Title>
            <Accordion.Content active={activeIndex.includes(index)}>
              <Segment style={{border: "none", padding: "10px 0px 0px", boxShadow: "none"}}>
                {findings?.map((e, index) => {
                  const headerStyle = { marginBottom: '10px', fontSize: '1rem' };
                  const isStrongPractice = e.collectDataType == STRONG_PRACTICE;
                  const isFinding = e.collectDataType == FINDING;
                  return (
                    <Segment.Group key={index} style={{ border: '1px solid #ccc', boxShadow: 'none', paddingBottom: '10px'}}>
                    <Segment style={{padding: '0px 10px 12px 5px', 
                      margin: '15px 15px 0px 10px', 
                      borderBottom: '1px solid #ccc'}}>
                      <Header as="h5">
                        {isStrongPractice ? e.findingDisplayName : e.findingDisplayName + ": " + e.citation}
                        <span
                          style={{ color: 'black', float: 'right', marginRight: '-10px' }}
                        >
                          {e.feedbackStatus !== null
                            ? e.feedbackStatus === 'Approved'
                              ? approvedBox
                              : e.feedbackStatus === 'Rejected'
                              ? rejectedBox
                              : e.feedbackStatus === 'Need Clarification'
                              ? needClarificationBox
                              : e.feedbackStatus === 'In Progress'
                              ? inProgressBox
                              : null
                            : null}
                        </span>
                      </Header>
                    </Segment>
            
                    <Grid as={Segment} style={{ margin: '5px', border: 'none' }}>
                      <Grid.Row
                        columns="4"
                        style={{ marginLeft: '5px', marginRight: '5px' }}
                      >
                      {isFinding ? 
                      <Grid.Column>
                        <Header style={headerStyle} as="h5">
                          Citation
                        </Header>
                        <Link
                          to={{
                            pathname: `/review/citations/definition`,
                            query: { citation: e.citation },
                          }}
                          style={{fontSize: '1rem'}}
                          target="_blank"
                        >
                          <Popup
                            trigger={
                              <Label
                                style={{
                                  color: 'blue',
                                  background: 'white',
                                  padding: '0px',
                                  fontSize: '14px',
                                  textDecorationLine: 'underline',
                                }}
                              >
                                {' '}
                                {e ? e.citation : ''}
                              </Label>
                            }
                            content="Select to see Citation Definition"
                          />
                        </Link>
                      </Grid.Column> : null}
                      {isStrongPractice ?
                      <Grid.Column>
                        <Header style={headerStyle} as="h5">
                          Performance Area
                        </Header>
                        {e.performanceAreas?.join(', ')}
                      </Grid.Column> : null}
                      {isFinding ? 
                      <Grid.Column>
                        <Header style={headerStyle} as="h5">
                          Finding Type
                          {e.findingTypeStatus !== null && ` (${e.findingTypeStatus})`}
                        </Header>
                        <div>{e.findingType}</div>
                      </Grid.Column> : null}
                      </Grid.Row>
                      <Grid.Row style={{ marginLeft: '5px', marginRight: '5px' }}>
                        <Grid.Column>
                          <Header style={headerStyle} as="h5">
                            {isFinding ? 'Collected Data and Narrative' : 'Collected Data'}
                            {e.narrativeStatus !== null && ` (${e.narrativeStatus})`}
                            <span
                              style={{
                                color: 'black',
                                float: 'right',
                                marginRight: '-15px',
                              }}
                            ></span>
                          </Header>
                          <Message style={{borderRadius: '2px', backgroundColor: 'transparent'}}>
                            <div
                              style={{
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              {(
                                <ul style={{ margin: '0px', paddingLeft: '10px' }}>
                                  {e.collectedData?.map((x, index) => (
                                    <li key={index} style={DOMPurify.sanitize(x)?.includes("_") ? {marginLeft: '10px'} : {}}>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(x?.replace(/\d+_/, '')),
                                        }}
                                      ></span>
                                    </li>
                                  ))}
                                </ul>
                              )}
                              <br />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(e.narrative),
                                }}
                              ></div>
                            </div>
                          </Message>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment.Group> 
                  )
                })}
              </Segment>
            </Accordion.Content>
          </Accordion>
          )}
        )}
      </>)
  }

  const generateCommentsView = (type, data) => {
    const expanded = data.findingId ? activeComments.includes(data.findingId) : activeComments.includes(type);
    
    const handleExpandComments = () => {
      const value = data.findingId  ? data.findingId : type;
      let copyComments = activeComments.slice(0);

      if (copyComments.includes(value))
        copyComments  = copyComments.filter(function(item) { return item !== value });
      else
        copyComments.push(value);
    
      setActiveComments(copyComments);
    };

    return (
      <Accordion
        className={type === 'all' ? '' : 'finding-level-comments'}
        style={{ boxShadow: 'none', margin: '0px' }}
      >
        <Accordion.Title
          active={expanded === true}
          index={0}
        >
          <Link
            to="#"
            style={{ textDecoration: 'none', color: 'rgba(0,0,0,.87)' }}
            onClick={e => {
              e.preventDefault();
              handleExpandComments(e);
            }}
          >
            <Icon name="angle right" />
            View All Comments
          </Link>
        </Accordion.Title>
        <Accordion.Content active={expanded}>
          <Comment.Group style={{ marginLeft: '15px' }}>
            {data.comments?.map((e, index) => {
              return (
                <Comment key={index}>
                  <Comment.Content>
                    <Comment.Author as="span">{e.commentBy}</Comment.Author>
                    <Comment.Metadata>
                      <div>
                        {AmsDateFormatters.formatDateTime(e.updatedTime)}
                      </div>
                    </Comment.Metadata>
                    <Comment.Text>{e.comment}</Comment.Text>
                  </Comment.Content>
                  {e.responses && e.responses.length > 0 && (
                    <Comment.Group>
                      {orderBy(e.responses, ['updatedTime'], ['desc']).map(
                        reply => {
                          return (
                            <Comment>
                              <Comment.Content>
                                <Comment.Author as="span">
                                  {reply.responseBy}
                                </Comment.Author>
                                <Comment.Metadata>
                                  <div>
                                    {' '}
                                    {AmsDateFormatters.formatDateTime(
                                      reply.updatedTime
                                    )}
                                  </div>
                                </Comment.Metadata>
                                <Comment.Text>{reply.response}</Comment.Text>
                              </Comment.Content>
                            </Comment>
                          );
                        }
                      )}
                    </Comment.Group>
                  )}
                </Comment>
              );
            })}
          </Comment.Group>
        </Accordion.Content>
      </Accordion>
    );
  };

  const generateSingleReviewFindingsView = data => {
    if (delegatedTasks) {
      return generateSingleDelegatedReviewView(data);
    }
    const title = `${data.reviewType} Review ID - ${data.reviewId} | ${data.grantType}: `;
    const granteeLink = `${data.granteeName} (${data.granteeId})`;

    const hasCommentAll = data.comments && data.comments.length > 0;
    return (
      <>
        <Header as="h3">
          {title}{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/grantee/${data ? data.granteeId : ''}`}
          >
            {granteeLink}
          </a>
        </Header>
        <div>
          {renderFindingsAndPracticesCount(data, hideStrongPractices(user))}
          <div style={{ float: 'right', marginRight: '35px' }} className="actionButtons">
            {data && data.forwardFindings && (
              <ReportTrackerFindingsModalFY24
                type="forward"
                data={data}
                allFindings={reviewFindings && reviewFindings.length > 1}
              />
            )}
          </div>
        </div>
        {data && data.findingsFeedbackStatus !== null
          ? data.findingsFeedbackStatus === 'Approved'
            ? approvedBox
            : data.findingsFeedbackStatus === 'Rejected'
            ? rejectedBox
            : data.findingsFeedbackStatus === 'Need Clarification'
            ? needClarificationBox
            : data.findingsFeedbackStatus === 'In Progress'
            ? inProgressBox
            : null
          : null}
        <Grid stackable style={{ width: '100', paddingLeft: '15px', paddingRight: '15px', marginTop: '5px' }}>
          <Grid.Row>
            <Grid.Column>
              <Segment style={{border:"1px solid #ccc", boxShadow: "none"}}>{generateReviewDetails(data)}</Segment>
            </Grid.Column>
          </Grid.Row>
          {hasCommentAll && (
            <Grid.Row>
              <Grid.Column>
                <Segment>{generateCommentsView('all', data)}</Segment>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column>{generateFindingsDetails(data)}</Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  };

  return (
    <SlidingContainer title={'Tasks'}>
      {
        generateSingleReviewFindingsView(
          reviewFindings.length > 0 && reviewFindings[0]
        )
      }
    </SlidingContainer>
  );
};
export default SurveyReportTrackerFindingsPage;
