import _, { startsWith } from 'lodash';
import filesize from 'filesize';

export const humanizeFileSize = bytes => {
  var i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (bytes / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'KB', 'MB', 'GB', 'TB'][i]
  );
};

export const differenceInObjects = (object, base) => {
  function changes(object, base) {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
};

export const formatBytes = bytes => {
  if (bytes < 1024 * 1024) {
    // less than 1 MB
    return (bytes / 1024).toFixed(2) + ' KB';
  } else {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  }
};

//used specifically for Survey Data, hence the logic to check if the key/value pair includes Citation. If its a citation key value
//pair we will omit it, to ensure that the flattened object only contains survey data inputted by user
export const flattenObj = ob => {
  // The object which contains the
  // final result
  let result = {};

  // loop through the object "ob"
  for (const i in ob) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        // Store temp in result
        result[i + '.' + j] = temp[j];
      }
    }

    // Else store ob[i] in result directly
    else {
      if (i.includes('Citation')) {
        result[i] = '';
      } else {
        result[i] = ob[i];
      }
    }
  }
  return result;
};

export const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  a.click();
  return a;
};

export const dataURLtoBlob = dataUrl => {
  var arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const blobToDataURL = (blob, callback) => {
  var a = new FileReader();
  a.onload = function(e) {
    callback(e.target.result);
  };
  a.readAsDataURL(blob);
};

/***
 * A temporary hack used to extract file info from incoming string formatted as:
 * "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;name=FY19 Pre-Site.docx;base64,oid//5c489a84ba133e2bec30c43f//"
 * returns object containing file information.
 * {
 *    id: "5c489a84ba133e2bec30c43f"
 *    name: "FY19 Pre-Site.docx"
 *    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
 * }
 **/

export const prepData = data => {
  let dataObject = {};
  if (data)
    data.split(';').forEach(item => {
      if (startsWith(item, 'data:'))
        dataObject = {
          ...dataObject,
          type: item.split(':').pop(),
        };
      if (startsWith(item, 'name='))
        dataObject = {
          ...dataObject,
          name: item.split('=').pop(),
        };
      if (startsWith(item, 'base64,oid//')) {
        dataObject = {
          ...dataObject,
          id: item.match('base64,oid//(.*)//')[1],
        };
      }
      if (startsWith(item, 'size='))
        dataObject = {
          ...dataObject,
          size: item.split('=').pop(),
        };
    });
  return dataObject;
};

export const attachmentFileData = data => {
  let dataObject = {};
  if (data) {
    const [name, type, size, id] = data.split('|');
    dataObject = {
      name,
      type,
      size,
      id,
    };
  }
  return dataObject;
};

export const addNameToDataURL = (dataURL, name) => {
  if (dataURL && name)
    return dataURL.replace(';base64', `;name=${name};base64`);
  // return dataURL.replace(
  //   ';base64',
  //   `;name=${encodeURIComponent(name)};base64`
  // );
  return '';
};

export const removeNameFromDataURL = dataURL => {
  const start = dataURL.indexOf('name=');
  const end = dataURL.indexOf(';', start);
  return dataURL.substr(0, start) + dataURL.substr(end + 1);
};

export const fileSizeFormat = size => {
  return filesize(size, { base: 10 });
};

export function removeEmptyObjects(obj) {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  });
  return obj;
}

export function addSubmittedByAndDate(obj, currentUser) {
  var currentdate = new Date();
  var hours = currentdate.getHours();
  var minutes = currentdate.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;

  var datetime =
    currentdate.getMonth() +
    1 +
    '/' +
    currentdate.getDate() +
    '/' +
    currentdate.getFullYear() +
    ', ' +
    hours +
    ':' +
    minutes +
    ' ' +
    ampm;

  Object.keys(obj).forEach(key => {
    if (obj[key].childFiles) {
      for (var i = 0; i < obj[key].childFiles.length; i++) {
        if (!obj[key].childFiles[i].submittedBy)
          obj[key].childFiles[i].submittedBy = currentUser?.name;
        if (!obj[key].childFiles[i].sbumittedIn)
          obj[key].childFiles[i].sbumittedIn = datetime;
      }
    }
  });

  return obj;
}

export function addFindingId(fotpFindings, autoSaveData, key) {
  const mapFindingIds = Object.fromEntries(
    fotpFindings.map(item => [item.CitationPicker, item.findingId])
  );
  if (
    autoSaveData[key].findings &&
    autoSaveData[key].findings != null &&
    mapFindingIds != null
  ) {
    const autoSaveFindings = autoSaveData[key].findings;
    autoSaveFindings &&
      autoSaveFindings.forEach((item, index) => {
        if (item && item.findingId === undefined) {
          item.findingId = mapFindingIds[item.CitationPicker];
        }
      });
  }
  return autoSaveData;
}

export function transformErrors(errors) {
  var e = [];
  errors.map(error => {
    // This code handles the functionality of a custom checkbox widget when used in
    // conjunction with other and contingent lists. If the specified line is removed,
    // it will result in the TQ Checkbox List Other and TQ Checkbox Contingent Items
    // being marked as inactive.
    if (error.message !== 'should be equal to one of the allowed values') {
      e.push(error);
    }
  });
  return e;
}
