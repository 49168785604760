/* eslint-disable no-multi-str */
/* eslint-disable no-useless-escape */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router';

import {
  reportTemplateAction,
  fetchReportDraftTemplate,
  reportNewFaTwoAction,
  newDraftReportAction,
  reportRanAction,
  reportSpecialAction,
  reportAIANDEFAction,
  reportERSEAAction,
  reportNewFaOneAction,
} from '../../actions/reportActions';

import moment from 'moment';
import { sortBy } from 'lodash';

import './assets/reports.css';

function applyFindingsForReviewTemplate(template, findings) {
  let rule = /\<\!\-\-[\s\n]+?PLACEHOLDER\:START[\s\n]+?([\s\S]*?)[\s\n]+?PLACEHOLDER\:END[\s\n]+?\-\-\>/g;
  let label = statusCode => {
    if (statusCode === 'MET_REQ') {
      return '<img class="label" src="/report-preview/checked.png" align="absmiddle" />';
    } else if (statusCode === 'NOT_MET_REQ') {
      return '<img class="label" src="/report-preview/deny.png" align="absmiddle" />';
    } else if ((statusCode + '').match(/no\s*data/i)) {
      return '<strong>Not Applicable</strong>';
    }
    return '<img class="label" src="/report-preview/alert.png" align="absmiddle" />';
  };
  let trim = s => {
    return ((s || '') + '').replace(/^\s+/, '').replace(/\s$/, '');
  };
  let trimStructure = o => {
    let i;
    for (i in o) {
      if (typeof o[i] === 'string') {
        o[i] = trim(o[i]);
      }

      if (typeof o[i] === 'object' && o[i]) {
        trimStructure(o[i]);
      }
    }
  };

  let interceptText = text => {
    if ((text + '').match(/^\s*no\s*data\s*$/i)) {
      return 'Not Applicable';
    } else {
      return text;
    }
  };
  let isMulti = false;
  if (findings) {
    let isMulti = findings.granteeDetailsList.length > 1;

    if (!isMulti) {
      let i;
      for (i in findings.summaryBygrantees[0]) {
        if (i in findings) {
          console.warn('key already used', i);
        } else {
          findings[i] = findings.summaryBygrantees[0][i];
        }
      }
    }
  }

  let replaceLogic = (match_str, param0) => {
    param0 = param0.replace(/^\s+/, '').replace(/\s+$/, '');
    let data = null;
    try {
      data = JSON.parse(param0);
    } catch (err) {
      console.error(err);
    }

    if (data) {
      if (data.type) {
        data.type = data.type.split(':');
      }

      if (data.type && data.type[0] === 'section') {
        let parts = data.id.split(':');
        let isSuffixTable = data.type[1] === 'suffix-table';
        if (isMulti) {
          let paSectionItems = {};
          let sectionDatas = findings.summaryBygrantees
            .map(grantee => {
              return { grantee: grantee, sections: grantee[parts[0]] };
            })
            .filter(info => !!info.sections)
            .map(info => {
              info.sectionData =
                info.sections.filter(item => {
                  return item.paName.indexOf(parts[1]) !== -1;
                })[0] || null;
              return info;
            })
            .filter(info => !!info.sectionData);

          if (!sectionDatas.length) return '';

          sectionDatas.forEach(info => {
            info.sectionData.paSections.forEach(item => {
              paSectionItems[item.paSectionName] =
                paSectionItems[item.paSectionName] || {};
              paSectionItems[item.paSectionName][info.grantee.granteeId] = {
                statusCode: item.summaryStatus.statusCode,
                statusCodeHtml: label(item.summaryStatus.statusCode),
              };
            });
          });

          return [
            (isSuffixTable
              ? ''
              : '<h2 class="summary">' +
                sectionDatas[0].sectionData.paName +
                ' Performance Summary</h2>') +
              (Object.keys(paSectionItems).length
                ? '<table class="summary" cellspacing="0">\n\
                  <tr><td colspan=' +
                  (1 + findings.summaryBygrantees.length) +
                  '>' +
                  sectionDatas[0].sectionData.paName +
                  '</td></tr>\n\
                  <tr>\n\
                    <td align="center">Performance Area</td>\n\
                    ' +
                  findings.summaryBygrantees
                    .map(grantee => {
                      return (
                        '<td align="center" class="header-cell">Performance Status<br />' +
                        grantee.granteeId +
                        '</td>'
                      );
                    })
                    .join('') +
                  '\n\
                  </tr>' +
                  Object.keys(paSectionItems)
                    .map(function(paSectionName) {
                      return (
                        '<tr>\n\
                        <td>' +
                        paSectionName +
                        '</td>\n\
                        ' +
                        findings.summaryBygrantees
                          .map(grantee => {
                            return (
                              '<td align="center">' +
                              ((
                                paSectionItems[paSectionName][
                                  grantee.granteeId
                                ] || {}
                              ).statusCodeHtml || '') +
                              '</td>'
                            );
                          })
                          .join('') +
                        '\n\
                      </tr>'
                      );
                    })
                    .join('') +
                  (data.tableSuffix || [])
                    .map(tableSuffix => {
                      return (
                        '<tr><td colspan=' +
                        (1 + findings.summaryBygrantees.length) +
                        ' class="table-suffix-container"><!-- PLACEHOLDER:START\n\
                      ' +
                        JSON.stringify(tableSuffix) +
                        '\n\
                    PLACEHOLDER:END --></td></tr>'
                      );
                    })
                    .join('') +
                  '</table>'
                : ''),
            data.comment && !isSuffixTable
              ? '<p><i>' + data.comment + '</i></p>'
              : '',
            isSuffixTable
              ? ''
              : sectionDatas
                  .map((info, infoIndex) => {
                    return [
                      // info.sectionData.paSections.length ? ( infoIndex === 0 ? "<h2>" + info.sectionData.paName + " Summary</h2>" : '' ) : ''
                      info.sectionData.paSections.length
                        ? infoIndex === 0
                          ? '<h2>Performance Area Summary</h2>'
                          : ''
                        : '',
                      info.sectionData.paSections
                        .map(item => {
                          var MET_REQ =
                            item.summaryStatus.statusCode === 'MET_REQ';
                          return (
                            (infoIndex === 0
                              ? '<p><strong>' +
                                item.paSectionName +
                                '</strong> ' +
                                interceptText(item.summaryStatus.statusText) +
                                '</p>' +
                                '<br />'
                              : '') +
                            (MET_REQ
                              ? ''
                              : (item.criteriaStatuses || [])
                                  .filter(criteria => {
                                    return criteria.statusCode !== 'MET_REQ';
                                  })
                                  .map(criteria => {
                                    return (
                                      '<p><b>' +
                                      criteria.paSectionName +
                                      '<sup>Grantee ID ' +
                                      info.grantee.granteeId +
                                      '</sup></b> ' +
                                      (criteria.statusCode && !MET_REQ
                                        ? '(<i><u>Status Code</u>: ' +
                                          ({
                                            'NO DATA': 'Not Applicable',
                                            NODATA: 'Not Applicable',
                                            AON: 'Area of Noncompliance',
                                            ANC: 'Area of Noncompliance',
                                            AOC: 'Area of Concern',
                                            NOT_MET_REQ:
                                              '<img class="label" src="/report-preview/deny.png" align="absmiddle" />',
                                            DEF: '',
                                            'AOC OR ANC':
                                              'Area of Concern OR Area of Noncompliance',
                                            MET_REQ:
                                              '<img class="label" src="/report-preview/checked.png" align="absmiddle" />',
                                          }[
                                            (criteria.statusCode + '')
                                              .replace(/^\s+/, '')
                                              .replace(/\s+$/, '')
                                              .toUpperCase()
                                          ] ||
                                            criteria.statusCode ||
                                            'unknown') +
                                          '</i>)'
                                        : '') +
                                      '<br />' +
                                      interceptText(criteria.statusText) +
                                      '</p>'
                                    );
                                  })
                                  .join('\n'))
                          );
                        })
                        .join('\n\n'),
                    ].join('');
                  })
                  .join(''),
          ].join('');
        }
        let sectionData =
          ((findings || {})[parts[0]] || []).filter(item => {
            return item.paName.indexOf(parts[1]) !== -1;
          })[0] || null;

        if (sectionData) {
          return (
            (isSuffixTable
              ? ''
              : '<h2 class="summary">' +
                sectionData.paName +
                ' Performance Summary</h2>') +
            (sectionData.paSections.length
              ? '<table class="summary" cellspacing="0">\n\
                <tr><td colspan="2">' +
                sectionData.paName +
                '</td></tr>\n\
                <tr>\n\
                  <td align="center">Performance Area</td>\n\
                  <td align="center" class="header-cell">Performance Status</td>\n\
                </tr>' +
                sectionData.paSections
                  .map(item => {
                    return (
                      '<tr>\n\
                  <td>' +
                      item.paSectionName +
                      '</td>\n\
                  <td align="center">' +
                      label(item.summaryStatus.statusCode) +
                      '</td>\n\
                </tr>'
                    );
                  })
                  .join('') +
                (data.tableSuffix || [])
                  .map(tableSuffix => {
                    return (
                      '<tr><td colspan="2" class="table-suffix-container"><!-- PLACEHOLDER:START\n\
                  ' +
                      JSON.stringify(tableSuffix) +
                      '\n\
                PLACEHOLDER:END --></td></tr>'
                    );
                  })
                  .join('') +
                '</table>'
              : '') +
            (data.comment && !isSuffixTable
              ? '<p><i>' + data.comment + '</i></p>'
              : '') +
            (isSuffixTable
              ? ''
              : // sectionData.paSections.length ? ( "<h2>" + sectionData.paName + " Summary</h2>" ) : ''
                (sectionData.paSections.length
                  ? '<h2>Performance Area Summary</h2>'
                  : '') +
                sectionData.paSections
                  .map(item => {
                    var MET_REQ = item.summaryStatus.statusCode === 'MET_REQ';
                    return (
                      '<p><strong>' +
                      item.paSectionName +
                      '</strong> ' +
                      interceptText(item.summaryStatus.statusText) +
                      '</p>' +
                      '<br />' +
                      (MET_REQ
                        ? ''
                        : (item.criteriaStatuses || [])
                            .filter(criteria => {
                              return criteria.statusCode !== 'MET_REQ';
                            })
                            .map(criteria => {
                              return (
                                '<p><b>' +
                                criteria.paSectionName +
                                '</b> ' +
                                (criteria.statusCode && !MET_REQ
                                  ? '(<i><u>Status Code</u>: ' +
                                    ({
                                      'NO DATA': 'Not Applicable',
                                      NODATA: 'Not Applicable',
                                      AON: 'Area of Noncompliance',
                                      ANC: 'Area of Noncompliance',
                                      AOC: 'Area of Concern',
                                      NOT_MET_REQ:
                                        '<img class="label" src="/report-preview/deny.png" align="absmiddle" />',
                                      DEF: '',
                                      'AOC OR ANC':
                                        'Area of Concern OR Area of Noncompliance',
                                      MET_REQ:
                                        '<img class="label" src="/report-preview/checked.png" align="absmiddle" />',
                                    }[
                                      (criteria.statusCode + '')
                                        .replace(/^\s+/, '')
                                        .replace(/\s+$/, '')
                                        .toUpperCase()
                                    ] ||
                                      criteria.statusCode ||
                                      'unknown') +
                                    '</i>)'
                                  : '') +
                                '<br />' +
                                interceptText(criteria.statusText) +
                                '</p>'
                              );
                            })
                            .join('\n'))
                    );
                  })
                  .join('\n\n'))
          );
        }
        return '';
      } else {
        if (
          Array.isArray((findings || {}).granteeDetailsList) &&
          (findings || {}).granteeDetailsList.length
        ) {
          (findings || {}).granteeDetailsList.forEach(function(granteeDetails) {
            trimStructure(granteeDetails);
          });
        }
        let noData;
        // eslint-disable-next-line default-case
        switch (data.id) {
          case 'Home-based_services__Not-Applicable-Check':
            noData = false;
            if ((findings || {}).summaryBygrantees[0]) {
              let granteeData = (findings || {}).summaryBygrantees[0];
              Object.keys(granteeData).forEach(sectionDataKey => {
                if (sectionDataKey.indexOf('PAGrp_') !== 0) {
                  return;
                }
                let sectionDatas = granteeData[sectionDataKey];
                if (Array.isArray(sectionDatas)) {
                  sectionDatas.forEach(sectionData => {
                    if (Array.isArray(sectionData.paSections))
                      sectionData.paSections.forEach(item => {
                        if (
                          (item.paSectionName + '').match(
                            /Home.+?base.+?services/i
                          )
                        ) {
                          if (data.placeholder) {
                            if (
                              (item.summaryStatus.statusText + '').match(
                                /^\s*no\s*data\s*$/i
                              )
                            ) {
                              noData = true;
                            }
                          } else {
                            if (
                              (item.summaryStatus.statusCode + '').match(
                                /no\s*data/i
                              )
                            ) {
                              noData = true;
                            }
                          }
                        }
                      });
                  });
                }
              });
            }
            if (noData) {
              return '<strong>Not Applicable</strong>';
            } else {
              if (data.placeholder) {
                return data.placeholder;
              } else {
                return label('MET_REQ');
              }
            }
            break;
          case 'Services_for_pregnant_women__Not-Applicable-Check':
            noData = false;
            if ((findings || {}).summaryBygrantees[0]) {
              let granteeData = findings.summaryBygrantees[0];
              Object.keys(granteeData).forEach(sectionDataKey => {
                if (sectionDataKey.indexOf('PAGrp_') !== 0) {
                  return;
                }
                let sectionDatas = granteeData[sectionDataKey];
                if (Array.isArray(sectionDatas)) {
                  sectionDatas.forEach(sectionData => {
                    if (Array.isArray(sectionData.paSections))
                      sectionData.paSections.forEach(item => {
                        if (
                          (item.paSectionName + '').match(
                            /Services.+?for.+?pregnant.+?women/i
                          )
                        ) {
                          if (data.placeholder) {
                            if (
                              (item.summaryStatus.statusText + '').match(
                                /^\s*no\s*data\s*$/i
                              )
                            ) {
                              noData = true;
                            }
                          } else {
                            if (
                              (item.summaryStatus.statusCode + '').match(
                                /no\s*data/i
                              )
                            ) {
                              noData = true;
                            }
                          }
                        }
                      });
                  });
                }
              });
            }
            if (noData) {
              return '<strong>Not Applicable</strong>';
            } else {
              if (data.placeholder) {
                return data.placeholder;
              } else {
                return label('MET_REQ');
              }
            }
            break;
          case 'Delegate_agency_oversight__Not-Applicable-Check':
            noData = false;
            if ((findings || {}).summaryBygrantees[0]) {
              let granteeData = findings.summaryBygrantees[0];
              Object.keys(granteeData).forEach(sectionDataKey => {
                if (sectionDataKey.indexOf('PAGrp_') !== 0) {
                  return;
                }
                let sectionDatas = granteeData[sectionDataKey];
                if (Array.isArray(sectionDatas)) {
                  sectionDatas.forEach(sectionData => {
                    if (Array.isArray(sectionData.paSections))
                      sectionData.paSections.forEach(item => {
                        if (
                          (item.paSectionName + '').match(
                            /Delegate.+?agency.+?oversight/i
                          )
                        ) {
                          if (data.placeholder) {
                            if (
                              (item.summaryStatus.statusText + '').match(
                                /^\s*no\s*data\s*$/i
                              )
                            ) {
                              noData = true;
                            }
                          } else {
                            if (
                              (item.summaryStatus.statusCode + '').match(
                                /no\s*data/i
                              )
                            ) {
                              noData = true;
                            }
                          }
                        }
                      });
                  });
                }
              });
            }

            if (noData) {
              return '<strong>Not Applicable</strong>';
            } else {
              if (data.placeholder) {
                return data.placeholder;
              } else {
                return label('MET_REQ');
              }
            }
            break;
          case 'GRANTEE_DETAILS__STYLE_SINGLE':
            return isMulti ? 'display: none;' : '';
            break;
          case 'GRANTEE_DETAILS__STYLE_MULTI':
            return isMulti ? '' : 'display: none;';
            break;
          case 'GRANTEE_DIRECTOR_NAME':
            if (
              Array.isArray((findings || {}).granteeDetailsList) &&
              (findings || {}).granteeDetailsList.length
            ) {
              return (
                (findings || {}).granteeDetailsList[0].directorNamePrefix +
                ' ' +
                (findings || {}).granteeDetailsList[0].directorName
              );
            }
            break;
          case 'GRANTEE_NAME':
            if (
              Array.isArray((findings || {}).granteeDetailsList) &&
              (findings || {}).granteeDetailsList.length
            ) {
              return (findings || {}).granteeDetailsList[0].granteeName;
            }
            break;
          case 'PROGRAM_INFRASTRUCTURE_OVERSIGHT':
            return (
              '<table class="summary" cellspacing="0">\n\
<tr>\n\
<td colspan="' +
              (isMulti ? 1 + findings.summaryBygrantees.length : 2) +
              '">Program Performance: Program Infrastructure, Oversight, and Improvement</td>\n\
</tr>\n\
<tr>\n\
<td align="center">Performance Area</td>\n\
' +
              (isMulti
                ? (findings || {}).summaryBygrantees
                    .map(granteeDetails => {
                      return (
                        '<td align="center" class="header-cell">Performance Status<br />' +
                        granteeDetails.granteeId +
                        '</td>'
                      );
                    })
                    .join('')
                : '<td align="center" class="header-cell">Performance Status</td>') +
              '\n\
</tr>' +
              (isMulti
                ? (grantees => {
                    var items = {};
                    var granteeIds = [];
                    grantees.forEach(grantee => {
                      granteeIds.push(grantee.granteeId);
                      (
                        (grantee.OverAllPerfSummary || {})[
                          'PAGrp_ProgInfraOversightAndImprovementSummary'
                        ] || []
                      ).forEach(function(item) {
                        if (!(item.paName in items)) {
                          items[item.paName] = {};
                        }

                        items[item.paName][grantee.granteeId] = {
                          paStatus: item.paStatus,
                          paStatusHtml: label(item.paStatus),
                        };
                      });
                    });

                    return Object.keys(items).map(function(paName) {
                      return (
                        '<tr>' +
                        '<td>' +
                        paName +
                        '</td>' +
                        granteeIds
                          .map(granteeId => {
                            return (
                              '<td align="center">' +
                              ((items[paName][granteeId] || {}).paStatusHtml ||
                                '') +
                              '</td>'
                            );
                          })
                          .join('') +
                        '</tr>'
                      );
                    });
                  })((findings || {}).summaryBygrantees)
                : (
                    ((findings || {}).OverAllPerfSummary || {})[
                      'PAGrp_ProgInfraOversightAndImprovementSummary'
                    ] || []
                  )
                    .map(item => {
                      return (
                        '<tr>\n\
                  <td>' +
                        item.paName +
                        '</td>\n\
                  <td align="center">' +
                        label(item.paStatus) +
                        '</td>\n\
                </tr>'
                      );
                    })
                    .join('') + '</table>')
            );
            break;
          case 'GRANTEE_ADDRESS':
            if (
              Array.isArray((findings || {}).granteeDetailsList) &&
              (findings || {}).granteeDetailsList.length
            ) {
              return (
                (findings || {}).granteeDetailsList[0].address1 +
                ' ' +
                (findings || {}).granteeDetailsList[0].city +
                '<br>' +
                (findings || {}).granteeDetailsList[0].state +
                ' ' +
                ((findings || {}).granteeDetailsList[0].address2
                  ? ', ' + (findings || {}).granteeDetailsList[0].address2 + ' '
                  : '') +
                ((findings || {}).granteeDetailsList[0].zip5
                  ? ', ' + (findings || {}).granteeDetailsList[0].zip5 + ''
                  : '') +
                ((findings || {}).granteeDetailsList[0].zip4
                  ? '-' + (findings || {}).granteeDetailsList[0].zip4 + ' '
                  : '')
              );
            }
          case 'PROGRAM_INFRASTRUCTURE_DELIVERY':
            return (
              '<table class="summary" cellspacing="0">\n\
<tr>\n\
<td colspan="' +
              (isMulti ? 1 + findings.summaryBygrantees.length : 2) +
              '">Program Performance: Service Delivery</td>\n\
</tr>\n\
<tr>\n\
<td align="center">Performance Area</td>\n\
' +
              (isMulti
                ? (findings || {}).summaryBygrantees
                    .map(granteeDetails => {
                      return (
                        '<td align="center" class="header-cell">Performance Status<br />' +
                        granteeDetails.granteeId +
                        '</td>'
                      );
                    })
                    .join('')
                : '<td align="center" class="header-cell">Performance Status</td>') +
              '\n\
</tr>' +
              (isMulti
                ? (grantees => {
                    var items = {};
                    var granteeIds = [];
                    grantees.forEach(grantee => {
                      granteeIds.push(grantee.granteeId);
                      (
                        (grantee.OverAllPerfSummary || {})[
                          'PAGrp_serviceDeliverySummary'
                        ] || []
                      ).forEach(function(item) {
                        if (!(item.paName in items)) {
                          items[item.paName] = {};
                        }

                        items[item.paName][grantee.granteeId] = {
                          paStatus: item.paStatus,
                          paStatusHtml: label(item.paStatus),
                        };
                      });
                    });

                    return Object.keys(items)
                      .map(function(paName) {
                        return (
                          '<tr>' +
                          '<td>' +
                          paName +
                          '</td>' +
                          granteeIds
                            .map(granteeId => {
                              return (
                                '<td align="center">' +
                                ((items[paName][granteeId] || {})
                                  .paStatusHtml || '') +
                                '</td>'
                              );
                            })
                            .join('') +
                          '</tr>'
                        );
                      })
                      .join('');
                  })((findings || {}).summaryBygrantees)
                : (
                    ((findings || {}).OverAllPerfSummary || {})[
                      'PAGrp_serviceDeliverySummary'
                    ] || []
                  )
                    .map(item => {
                      return (
                        '<tr>\n\
                  <td>' +
                        item.paName +
                        '</td>\n\
                  <td align="center">' +
                        label(item.paStatus) +
                        '</td>\n\
                </tr>'
                      );
                    })
                    .join('') + '</table>')
            );
            break;

          case 'GRANTEES_SUMMARY_OF_FINDINGS':
            if (isMulti) {
              let items = {};

              (findings || {}).summaryBygrantees.forEach(grantee => {
                grantee.findings.forEach(item => {
                  let key =
                    item.HSPPSNum +
                    '»' +
                    item.paSectionName +
                    '»' +
                    item.statusCode;
                  items[key] = items[key] || {
                    ___item: item,
                  };
                  items[key][item.statusCode] =
                    items[key][item.statusCode] || {};
                  items[key][item.statusCode][grantee.granteeId] = {
                    grantee: grantee,
                    item: item,
                  };
                });
              });

              if (Object.keys(items).length) {
                return [
                  '<h2 class="summary">Summary of Findings</h2>\n\
                  <table class="summary" cellspacing="0"><tbody><tr>' +
                    '<td>Applicable Standards</td>' +
                    '<td>Grant Number(s)</td>' +
                    '<td>Timeframe</td>' +
                    '<td>Compliance Level</td>' +
                    '<td>Service Area</td>' +
                    '</tr>',
                  Object.keys(items)
                    .map(rowKey => {
                      let row = items[rowKey];
                      return (
                        '<tr>\n\
                      <td>' +
                        row.___item.HSPPSNum +
                        '</td>\n\
                      <td>' +
                        Object.keys(row[row.___item.statusCode]).join(', ') +
                        '</td>\n\
                      <td>120 Days</td>\n\
                      <td align="center">' +
                        ({
                          AON: 'Area of Noncompliance',
                          ANC: 'Area of Noncompliance',
                          AOC: 'Area of Concern',
                          NOT_MET_REQ:
                            '<img class="label" src="/report-preview/deny.png" align="absmiddle" />',
                          DEF: '',
                          'AOC OR ANC':
                            'Area of Concern OR Area of Noncompliance',
                          MET_REQ:
                            '<img class="label" src="/report-preview/checked.png" align="absmiddle" />',
                        }[row.___item.statusCode] || '') +
                        '</td>\n\
                    <td>' +
                        row.___item.paSectionName +
                        '</td>\n\
                    </tr>'
                      );
                    })
                    .join(''),
                ].join('');
              }
              return '';
            }
            if ((findings || {}).findings) {
              if ((findings || {}).findings.length) {
                return (
                  '<h2 class="summary">Summary of Findings</h2>\n\
                <table class="summary" cellspacing="0"><tbody><tr>' +
                  '<td>Applicable Standards</td>' +
                  '<td>Grant Number(s)</td>' +
                  '<td>Timeframe</td>' +
                  '<td>Compliance Level</td>' +
                  '<td>Service Area</td>' +
                  '</tr>' +
                  findings.findings
                    .map(item => {
                      return (
                        '<tr>\n\
                        <td>' +
                        item.HSPPSNum +
                        '</td>\n\
                        <td>' +
                        ((findings.granteeDetailsList[0] || {}).granteeId ||
                          '') +
                        '</td>\n\
                        <td>120 Days</td>\n\
                        <td align="center">' +
                        ({
                          AON: 'Area of Noncompliance',
                          ANC: 'Area of Noncompliance',
                          AOC: 'Area of Concern',
                          NOT_MET_REQ:
                            '<img class="label" src="/report-preview/deny.png" align="absmiddle" />',
                          DEF: '',
                          'AOC OR ANC':
                            'Area of Concern OR Area of Noncompliance',
                          MET_REQ:
                            '<img class="label" src="/report-preview/checked.png" align="absmiddle" />',
                        }[item.statusCode] || '') +
                        '</td>\n\
                        <td>' +
                        item.paSectionName +
                        '</td>\n\
                        </tr>'
                      );
                    })
                    .join('') +
                  '</tbody></table>'
                );
              }
            }

            return '';
            break;

          case 'FINDINGS_ANC_AOC':
            return ''; // disable findings mapping
            if (isMulti) {
              let items = {};

              (findings || {}).summaryBygrantees.forEach(grantee => {
                grantee.findings.forEach(item => {
                  let key = item.HSPPSNum + '»' + item.HSPPSDetail;
                  items[key] = items[key] || {
                    ___item: item,
                  };
                  items[key].grantees = items[key].grantees || {};
                  items[key].grantees[grantee.granteeId] = {
                    grantee: grantee,
                    item: item,
                  };
                });
              });

              if (Object.keys(items).length) {
                return [
                  Object.keys(items)
                    .map(rowKey => {
                      let row = items[rowKey];
                      let item = row.___item;
                      return (
                        '<p><strong>' +
                        ({
                          AON: 'Area of Noncompliance',
                          ANC: 'Area of Noncompliance',
                          AOC: 'Area of Concern',
                          NOT_MET_REQ:
                            '<img class="label" src="/report-preview/deny.png" align="absmiddle" />',
                          DEF: '',
                          'AOC OR ANC':
                            'Area of Concern OR Area of Noncompliance',
                          MET_REQ:
                            '<img class="label" src="/report-preview/checked.png" align="absmiddle" />',
                        }[item.statusCode] || '') +
                        ' ' +
                        item.HSPPSNum +
                        '&nbsp;&nbsp;&nbsp;&nbsp;' +
                        'Timeframe for Correction: 120 days ;</strong> <sup>Grantees: <i>' +
                        Object.keys(row.grantees).join(', ') +
                        '</i></sup></p>\
                      <p>' +
                        item.HSPPSDetail +
                        '</p>'
                      );
                    })
                    .join(''),
                ].join('');
              }
              return '';
            }
            if ((findings || {}).findings) {
              if ((findings || {}).findings.length) {
                return (findings || {}).findings
                  .map(item => {
                    return (
                      '<p><strong>' +
                      ({
                        AON: 'Area of Noncompliance',
                        ANC: 'Area of Noncompliance',
                        AOC: 'Area of Concern',
                        NOT_MET_REQ:
                          '<img class="label" src="/report-preview/deny.png" align="absmiddle" />',
                        DEF: '',
                        'AOC OR ANC':
                          'Area of Concern OR Area of Noncompliance',
                        MET_REQ:
                          '<img class="label" src="/report-preview/checked.png" align="absmiddle" />',
                      }[item.statusCode] || '') +
                      ' ' +
                      item.HSPPSNum +
                      '&nbsp;&nbsp;&nbsp;&nbsp;' +
                      'Timeframe for Correction: 120 days</strong></p>\
                        <p>' +
                      item.HSPPSDetail +
                      '</p>'
                    );
                  })
                  .join('');
              }
            }
            return '';
            break;

          case 'GRANTEE_DETAILS__HEAD START ENROLLMENT_NUMBER':
            if (isMulti) {
              return (findings || {}).summaryBygrantees
                .map(granteeDetails => {
                  return (
                    '<i>' +
                    granteeDetails.granteeId +
                    ' ' +
                    granteeDetails.headStartEnrollment +
                    '</i>'
                  );
                })
                .join('<br />');
            } else {
              if (
                Array.isArray((findings || {}).granteeDetailsList) &&
                (findings || {}).granteeDetailsList.length
              ) {
                return (findings || {}).granteeDetailsList[0]
                  .headStartEnrollment;
              }
            }
            break;
          case 'GRANTEE_DETAILS__HEAD START ENROLLMENT':
            if (isMulti) {
              return (findings || {}).summaryBygrantees
                .map(granteeDetails => {
                  return '<i>' + granteeDetails.granteeId + ' ...</i>';
                })
                .join('<br />');
            } else {
              if (
                Array.isArray((findings || {}).granteeDetailsList) &&
                (findings || {}).granteeDetailsList.length
              ) {
                return (findings || {}).granteeDetailsList[0].granteeName;
              }
            }
            break;
          case 'GRANTEE_DETAILS__EARLY HEAD START ENROLLMENT':
            if (isMulti) {
              return (findings || {}).summaryBygrantees
                .map(granteeDetails => {
                  return (
                    '<i>' +
                    granteeDetails.granteeId +
                    ' ' +
                    granteeDetails.earlyHeadStartEnrollment +
                    '</i>'
                  );
                })
                .join('<br />');
            } else {
              if (
                Array.isArray((findings || {}).granteeDetailsList) &&
                (findings || {}).granteeDetailsList.length
              ) {
                return (findings || {}).granteeDetailsList[0]
                  .earlyHeadStartEnrollment;
              }
            }
            break;
          case 'GRANTEE_DETAILS__NUMBER OF GRANTS':
            if (
              Array.isArray((findings || {}).granteeDetailsList) &&
              (findings || {}).granteeDetailsList.length
            ) {
              return (findings || {}).numberOfGrants;
            } else {
              return (findings || {}).numberOfGrants;
            }
            break;
          case 'GRANTEE_DETAILS__HEAD START DIRECTOR':
            if (
              Array.isArray((findings || {}).granteeDetailsList) &&
              (findings || {}).granteeDetailsList.length
            ) {
              return ((findings || {}).granteeDetailsList[0] || {})
                .directorName;
            }
            break;
          case 'GRANTEE_DETAILS__BOARD CHAIR':
            if ((findings || {}).distributionList) {
              return (
                (findings || {}).distributionList.filter(item => {
                  return item.title === 'Board Chairperson';
                })[0] || {}
              ).name;
            }
            break;
          case 'GRANTEE_DETAILS__POLICY COUNCIL CHAIR':
            if (isMulti) {
              return (findings || {}).summaryBygrantees
                .map(granteeDetails => {
                  return (
                    '<i>' +
                    granteeDetails.granteeId +
                    ' ' +
                    granteeDetails.policyCouncilChairPersonName +
                    (granteeDetails.policyCouncilChairPersonEmail
                      ? ' <' +
                        granteeDetails.policyCouncilChairPersonEmail +
                        '>'
                      : '') +
                    '</i>'
                  );
                })
                .join('<br />');
            } else {
              if (
                Array.isArray((findings || {}).granteeDetailsList) &&
                (findings || {}).granteeDetailsList.length
              ) {
                return (
                  (findings || {}).granteeDetailsList[0]
                    .policyCouncilChairPersonName +
                  ((findings || {}).granteeDetailsList[0]
                    .policyCouncilChairPersonEmail
                    ? ' <' +
                      (findings || {}).granteeDetailsList[0]
                        .policyCouncilChairPersonEmail +
                      '>'
                    : '')
                );
              }
            }
            break;
          case 'GRANTEE_DETAILS__PROGRAM SPECIALIST':
            if (isMulti) {
              return (findings || {}).summaryBygrantees
                .map(granteeDetails => {
                  return (
                    '<i>' +
                    granteeDetails.granteeId +
                    ' ' +
                    granteeDetails.programSpecialistName +
                    (granteeDetails.programSpecialistEmail
                      ? ' <' + granteeDetails.programSpecialistEmail + '>'
                      : '') +
                    '</i>'
                  );
                })
                .join('<br />');
            } else {
              if (
                Array.isArray((findings || {}).granteeDetailsList) &&
                (findings || {}).granteeDetailsList.length
              ) {
                return (
                  (findings || {}).granteeDetailsList[0].programSpecialistName +
                  ((findings || {}).granteeDetailsList[0].programSpecialistEmail
                    ? ' <' +
                      (findings || {}).granteeDetailsList[0]
                        .programSpecialistEmail +
                      '>'
                    : '')
                );
              }
            }
            break;
          case 'GRANTEE_DETAILS__FISCAL SPECIALIST':
            if (
              Array.isArray((findings || {}).granteeDetailsList) &&
              (findings || {}).granteeDetailsList.length
            ) {
              return '...';
            }
            break;
          case 'REVIEW_START_DATE':
            return (findings || {}).reviewStartDate
              ? ' ' +
                  moment((findings || {}).reviewStartDate).format(
                    'MM/DD/YYYY'
                  ) +
                  ' '
              : 'start date';
            break;
          case 'REVIEW_END_DATE':
            return (findings || {}).reviewEndDate
              ? ' ' +
                  moment((findings || {}).reviewEndDate).format('MM/DD/YYYY') +
                  ' '
              : 'end date';
            break;
          case 'DISTRIBUTION_LIST':
            if (((findings || {}).distributionList || []).length) {
              return (
                '<h2>DISTRIBUTION OF THE REPORT</h2>\n\n<p>Copies of this report will be distributed to the following recipients:</p>\n<p>' +
                (findings || {}).distributionList
                  .map(function(user) {
                    return user.title + ': ' + user.name + ' ' + user.email;
                  })
                  .join('<br>\n') +
                '</p>'
              );
            }
            break;
        }
      }
    }
    // return '[' + param0 + ']';

    return '';
  };

  template = template.replace(rule, replaceLogic);
  template = template.replace(rule, replaceLogic);
  template = template
    .replace(/^[\n\s]*/, '')
    .replace(/[\n\s]*$/, '')
    .replace(
      /^\<div[^\>]+class\s*=\s*[\"\'][\"\'\>]*report\-item\-\-content[^\>]*?\>([\s\S]*)\<\/div[^\>]*\>$/,
      '$1'
    )
    .replace(
      /\<div[^\>]*\>[^\>\<]*\<b[^\>]*\>[\s\n]*---[\s\n]*End\s+of\s+Report[\s\n]*---[\s\n]*\<\/b[^\>]*\>[^\>\<]*\<\/div[^\>]*\>/g,
      ''
    );
  return template;
}

export function applyTemplateFindings(template, findings, cb) {
  return dispatch => {
    cb(applyFindingsForReviewTemplate(template, findings));
  };
}

class ReportPreview extends Component {
  constructor() {
    super();

    this.applyFindingsForReviewTemplate = this.applyFindingsForReviewTemplate.bind(
      this
    );
    this.showPreviewTemplate = this.showPreviewTemplate.bind(this);
    this.previewReportTemplate = this.previewReportTemplate.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.menuItem = React.createRef();
  }

  componentDidMount() {
    const { reviewType, reviewId, newFAFlowFlag } = (
      this.props.review || {}
    ).selectedReview;

    const windowId = this.props.params.reviewId || this.props.params.id;

    if (this.props.router.location.pathname.indexOf('/reports/') === 0)
      return null;

    if (reviewId && reviewType && !newFAFlowFlag && reviewId === windowId) {
      this.props.reportTemplateAction({
        reviewType,
        reviewId,
        templateType: 'NCMP',
      });

      this.props.fetchReportDraftTemplate({ reviewId });
    }
  }

  applyFindingsForReviewTemplate(html, findings) {
    return applyFindingsForReviewTemplate(html, findings);
  }

  showPreviewTemplate() {
    let granteesIds = '';
    let granteesName = '';
    let chGrantees = (
      ((this.props.review || {}).selectedReview || {}).grantInfo || []
    ).filter(grantee => grantee.granteeId.includes('CH'));
    // console.log(chGrantees);
    let otherGrantees = (
      ((this.props.review || {}).selectedReview || {}).grantInfo || []
    ).filter(grantee => !grantee.granteeId.includes('CH'));
    // console.log(otherGrantees);
    if (!chGrantees.length) {
      // console.log('no CH grantees');
      // eslint-disable-next-line array-callback-return
      otherGrantees.map((grantee, index) => {
        const sortedPreceedingGrantee =
          grantee.precedingGrantee &&
          sortBy(grantee.precedingGrantee, ['granteeReplacementDate']);
        if (index === 0) {
          granteesName = grantee.granteeName;
          granteesIds = grantee.granteeId;
          if (sortedPreceedingGrantee && sortedPreceedingGrantee.length > 0) {
            granteesIds =
              sortedPreceedingGrantee
                .map(sortedGrantee => sortedGrantee.granteeId)
                .join('/') +
              '/' +
              grantee.granteeId;
          } else granteesIds = grantee.granteeId;
        } else {
          if (sortedPreceedingGrantee && sortedPreceedingGrantee.length > 0) {
            granteesIds = granteesIds +=
              ', ' +
              sortedPreceedingGrantee
                .map(sortedGrantee => sortedGrantee.granteeId)
                .join('/') +
              '/' +
              grantee.granteeId;
          } else granteesIds += ', ' + grantee.granteeId;
        }
      });
    } else {
      // console.log(granteesIds);
      // eslint-disable-next-line array-callback-return
      chGrantees.map((grantee, index) => {
        const sortedPreceedingGrantee =
          grantee.precedingGrantee &&
          sortBy(grantee.precedingGrantee, ['granteeReplacementDate']);
        if (index === 0) {
          granteesName = grantee.granteeName;
          if (sortedPreceedingGrantee && sortedPreceedingGrantee.length > 0) {
            granteesIds =
              sortedPreceedingGrantee
                .map(sortedGrantee => sortedGrantee.granteeId)
                .join('/') +
              '/' +
              grantee.granteeId;
          } else granteesIds = grantee.granteeId;
        } else {
          if (sortedPreceedingGrantee && sortedPreceedingGrantee.length > 0) {
            granteesIds = granteesIds +=
              ', ' +
              sortedPreceedingGrantee
                .map(sortedGrantee => sortedGrantee.granteeId)
                .join('/') +
              '/' +
              grantee.granteeId;
          } else granteesIds += ', ' + grantee.granteeId;
        }
      });

      if (otherGrantees.length) {
        // eslint-disable-next-line array-callback-return
        otherGrantees.map(otherGrantee => {
          granteesIds += ', ' + otherGrantee.granteeId;
        });
      }
    }

    let granteeIdText = '';
    this.props.review.selectedReview &&
      this.props.review.selectedReview.grantInfo &&
      this.props.review.selectedReview.grantInfo.forEach((e, i) => {
        if (
          e.inactivationReason !== 'Relinquished' &&
          e.inactivationReason !== 'Terminated'
        ) {
          granteeIdText === ''
            ? (granteeIdText = e.granteeId)
            : (granteeIdText += ', ' + e.granteeId);
        }
      });

    //if the status is signed or shipped, show all grantees
    if (
      (this.props.review || {}).selectedReview.reviewStatus &&
      (this.props.review.selectedReview.reviewStatus === 'Report Signed' ||
        this.props.review.selectedReview.reviewStatus === 'Shipped')
    ) {
      granteeIdText = granteesIds;
    }

    let grantees = `${granteesName} ${
      granteeIdText ? `(${granteeIdText})` : ''
    }`;

    let template = this.props.reportTemplate;
    let findings = this.props.reportDraftTemplate;
    let previewTemplate =
      '<div class="review-preview-template">\
        <div class="review-preview-template--bg animated fadeIn"></div>\
        <div class="review-preview-template--container animated slideInRight">\
           <div class="review-preview-template--content" grantee-id="' +
      grantees +
      '">\
             <button class="btn btn-sm btn-primary review-preview-template--close" onclick="window.close()">Close</button>\
             <span role="img" aria-label="Administration for Children & Families. Office of Head Start. 4th Floor - Switzer Memorial Building, 330 C Street SW, Washington DC 20024. e c l k c.o h s.a c f.h h s.gov"> </span><div class="report-item--content"></div>\
           </div>\
        </div>\
      </div>';

    let node = document.createElement('div');
    node.innerHTML = previewTemplate;
    previewTemplate = node.getElementsByTagName('div')[0];
    previewTemplate.parentNode.removeChild(previewTemplate);

    if (
      (this.props.review || {}).selectedReview.newFAFlowFlag &&
      (this.props.review.selectedReview || {}).reviewType !== 'Follow-up'
    ) {
      previewTemplate.getElementsByTagName('div')[3].innerHTML =
        template.content;
    } else {
      let code = this.applyFindingsForReviewTemplate(
        template.content,
        findings
      );

      previewTemplate.getElementsByTagName('div')[3].innerHTML = code;
    }

    node.innerHTML = '';
    node.appendChild(previewTemplate);

    let htmlPage =
      '<!DOCTYPE html>\n\
<head>\n\
  <meta charset="utf-8">\n\
  <base href="' +
      window.location.href +
      '">\n\
  <title>Report Preview</title>\n\
  ' +
      (() => {
        return Array.prototype.slice
          .call(document.getElementsByTagName('style'))
          .map(style => {
            return '<style type="text/css">' + style.textContent + '</style>';
          })
          .join('\n');
      })() +
      '\n\
  ' +
      (() => {
        return Array.prototype.slice
          .call(document.getElementsByTagName('link'))
          .filter(link => {
            return link.getAttribute('rel') === 'stylesheet';
          })
          .map(link => {
            return link.getAttribute('href');
          })
          .filter(url => {
            return !!url;
          })
          .map(url => {
            if (!url.match(/^http(s|)\:\/\//))
              return window.location.origin + url;
            return url;
          })
          .map(url => {
            return '<link type="text/css" rel="stylesheet" href="' + url + '">';
          })
          .join('\n');
      })() +
      '\n\
</head>\n\
<body>' +
      node.innerHTML +
      '\n\
</body>\n\
</html>';

    return htmlPage;
  }

  previewReportTemplate() {
    const { reviewType, reviewId } = (this.props.review || {}).selectedReview;
    const { reportTemplate, reportDraftTemplate } = this.props;

    if (reportTemplate === 'Error') {
      return (
        <h3 className="text-center error-msg">
          System is unable to retrieve template
        </h3>
      );
    }
    const windowId = this.props.params.reviewId || this.props.params.id;

    if (reviewId && reviewId !== windowId) {
      return <div>Loading report preview...</div>;
    }

    if (reviewType && reviewId && (reportTemplate || reportDraftTemplate)) {
      if (
        this.props.routes.findIndex(function(route) {
          return route.path === 'fy19';
        }) === -1
      ) {
        return (
          <div
            ref={this.menuItem}
            style={{ width: '100%', height: '100%' }}
            onClick={(ev => {
              ev.nativeEvent.stopPropagation();
              ev.nativeEvent.preventDefault();
              let htmlPage = this.showPreviewTemplate();
              let blob = new Blob([htmlPage], { type: 'text/html' });
              let url = URL.createObjectURL(blob);
              window.open(
                url,
                'preview-template',
                'width=' +
                  Math.ceil(window.screen.width * 0.8) +
                  ',height=' +
                  Math.ceil(window.screen.height * 0.8)
              );
              // eslint-disable-next-line no-extra-bind
            }).bind(this)}
          >
            Report Preview
          </div>
        );
      } else {
        return (
          <div
            ref={this.menuItem}
            style={{ width: '100%', height: '100%' }}
            onClick={(ev => {
              ev.nativeEvent.stopPropagation();
              ev.nativeEvent.preventDefault();
              let htmlPage = this.showPreviewTemplate();
              let blob = new Blob([htmlPage], { type: 'text/html' });
              let url = URL.createObjectURL(blob);
              window.open(
                url,
                'preview-template',
                'width=' +
                  Math.ceil(window.screen.width * 0.8) +
                  ',height=' +
                  Math.ceil(window.screen.height * 0.8)
              );
              // eslint-disable-next-line no-extra-bind
            }).bind(this)}
          >
            Report Preview
          </div>
        );
      }
    }
    if (
      ((this.props.review || {}).selectedReview || {}).reviewType === 'Special'
    ) {
      return (
        <span
          className="text-center"
          style={{ cursor: 'not-allowed', color: '#a2a2a2' }}
        >
          Report Preview
        </span>
      );
    }
    return <div>Loading report preview...</div>;
  }

  componentDidUpdate() {
    const windowId = this.props.params.reviewId || this.props.params.id;
    if (
      ((this.props.review || {}).selectedReview || {}).reviewId !== windowId
    ) {
      return;
    }
    if (
      !this.reportDraftTemplateRequested &&
      ((this.props.review || {}).selectedReview || {}).reviewType === 'FA-2' &&
      ((this.props.review || {}).selectedReview || {}).reviewId
    ) {
      this.reportDraftTemplateRequested = true;
      this.props.reportNewFaTwoAction({
        reviewId: ((this.props.review || {}).selectedReview || {}).reviewId,
      });
    }
    if (
      !this.reportDraftTemplateRequested &&
      ((this.props.review || {}).selectedReview || {}).reviewType ===
        'FA2-CR' &&
      ((this.props.review || {}).selectedReview || {}).reviewId
    ) {
      this.reportDraftTemplateRequested = true;
      this.props.newDraftReportAction({
        reviewId: ((this.props.review || {}).selectedReview || {}).reviewId,
      });
    }
    if (
      !this.reportDraftTemplateRequested &&
      ((this.props.review || {}).selectedReview || {}).reviewType === 'FA-1' &&
      ((this.props.review || {}).selectedReview || {}).reviewId
    ) {
      this.reportDraftTemplateRequested = true;
      this.props.reportNewFaOneAction({
        reviewId: ((this.props.review || {}).selectedReview || {}).reviewId,
      });
    }
    if (
      !this.reportDraftTemplateRequested &&
      ((this.props.review || {}).selectedReview || {}).reviewType ===
        'Follow-up' &&
      ((this.props.review || {}).selectedReview || {}).reviewId
    ) {
      this.reportDraftTemplateRequested = true;
      this.props.fetchReportDraftTemplate(
        { reviewId: ((this.props.review || {}).selectedReview || {}).reviewId },
        'Follow-up'
      );
    }
    if (
      !this.reportDraftTemplateRequested &&
      ((this.props.review || {}).selectedReview || {}).reviewType === 'RAN' &&
      ((this.props.review || {}).selectedReview || {}).reviewId
    ) {
      this.reportDraftTemplateRequested = true;
      if (((this.props.review || {}).selectedReview || {}).isNewForms) {
        this.props.newDraftReportAction({
          reviewId: ((this.props.review || {}).selectedReview || {}).reviewId,
        });
      } else {
        this.props.reportRanAction({
          reviewId: ((this.props.review || {}).selectedReview || {}).reviewId,
        });
      }
    }
    if (
      !this.reportDraftTemplateRequested &&
      ((this.props.review || {}).selectedReview || {}).reviewType ===
        'Special' &&
      ((this.props.review || {}).selectedReview || {}).reviewId
    ) {
      this.reportDraftTemplateRequested = true;
      if (((this.props.review || {}).selectedReview || {}).isNewForms) {
        this.props.newDraftReportAction({
          reviewId: ((this.props.review || {}).selectedReview || {}).reviewId,
        });
      } else {
        this.props.reportSpecialAction({
          reviewId: ((this.props.review || {}).selectedReview || {}).reviewId,
        });
      }
    }
    if (
      !this.reportDraftTemplateRequested &&
      ((this.props.review || {}).selectedReview || {}).reviewType ===
        'AIAN-DEF' &&
      ((this.props.review || {}).selectedReview || {}).reviewId
    ) {
      this.reportDraftTemplateRequested = true;
      this.props.reportAIANDEFAction({
        reviewId: ((this.props.review || {}).selectedReview || {}).reviewId,
      });
    }
    if (
      !this.reportDraftTemplateRequested &&
      ((this.props.review || {}).selectedReview || {}).reviewType === 'ERSEA' &&
      ((this.props.review || {}).selectedReview || {}).reviewId
    ) {
      this.reportDraftTemplateRequested = true;
      this.props.reportERSEAAction({
        reviewId: ((this.props.review || {}).selectedReview || {}).reviewId,
      });
    }
    if (
      !this.reportDraftTemplateRequested &&
      ((this.props.review || {}).selectedReview || {}).reviewType ===
        'FA1-FR' &&
      ((this.props.review || {}).selectedReview || {}).reviewId
    ) {
      this.reportDraftTemplateRequested = true;
      this.props.newDraftReportAction({
        reviewId: ((this.props.review || {}).selectedReview || {}).reviewId,
      });
    }
  }

  render() {
    if (this.props.router.location.pathname.indexOf('/reports/') === 0) {
      return null;
    }

    const { reviewType } = (this.props.review || {}).selectedReview;
    return reviewType === 'CLASS' ||
      reviewType === 'AIAN-CLASS' ||
      reviewType === 'PR-CLASS' ||
      reviewType === 'VP-CLASS' ||
      reviewType === 'AIAN CLASS Self-Observations' ||
      reviewType === 'CLASS AIAN Onsite' ||
      reviewType === 'CLASS AIAN Video' ||
      reviewType === 'CLASS-Video' ? null : (
      <Menu.Item
        to="#"
        onClick={(e, data) => {
          e.preventDefault();
        }}
        link
        as={Link}
        key="ReportPreview"
        onKeyDown={(e, data) => {
          if (e.keyCode === 13) {
            this.menuItem.current.click();
          }
        }}
      >
        {this.previewReportTemplate() || null}
      </Menu.Item>
    );
  }
}

ReportPreview.propTypes = {
  review: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    reportTemplate: state.report.reportTemplate,
    reportDraftTemplate: state.report.reportDraftTemplate,
    report: state.report,
    review: state.review,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    reportTemplateAction,
    fetchReportDraftTemplate,
    reportNewFaTwoAction,
    newDraftReportAction,
    reportNewFaOneAction,
    reportRanAction,
    reportSpecialAction,
    reportAIANDEFAction,
    reportERSEAAction,
  })(ReportPreview)
);
