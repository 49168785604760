import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Menu, Label } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router';
import { isEmpty } from 'lodash';

import { fetchReviewFollowUps } from '../../../../actions/reviewActions';

const ReviewFollowUps = props => {
  const { selectedReview } = props;
  const [madeRequest, setMadeRequest] = useState(false);
  const dispatch = useDispatch();
  const { reviewFollowUpsList } = useSelector(state => state.review);

  const fiscalYear =
    selectedReview.fiscalYear && selectedReview.fiscalYear.toString().slice(-2);

  useEffect(() => {
    const loadData = reviewId => {
      const urlId = props.params.reviewId || props.params.id;
      let idToUse = reviewId === urlId ? reviewId : urlId;
      let input = {
        filters: {
          parentReviewId: idToUse,
        },
      };
      if (idToUse)
        dispatch(fetchReviewFollowUps(input)).then(() => setMadeRequest(true));
    };

    if (selectedReview.reviewType) {
      const { reviewType, reviewId } = selectedReview;
      if (!isEmpty(reviewId) && reviewType && !madeRequest) {
        setMadeRequest(false);
        loadData(reviewId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReview]);

  const renderButton = () => {
    return reviewFollowUpsList && reviewFollowUpsList.total > 0 ? (
      <Menu.Item
        link
        key="ReviewFollowUps"
        as={Link}
        to={`/review/fy${fiscalYear}/${selectedReview.reviewId}/follow-ups`}
      >
        <Label>{reviewFollowUpsList.total}</Label>
        Follow-up Reviews
      </Menu.Item>
    ) : null;
  };

  if (!selectedReview) return null;

  return selectedReview &&
    (selectedReview.reviewType !== 'CLASS' ||
      selectedReview.reviewType !== 'AIAN-CLASS' ||
      selectedReview.reviewType !== 'PR-CLASS' ||
      selectedReview.reviewType !== 'VP-CLASS' ||
      selectedReview.reviewType !== 'CLASS-Video' ||
      selectedReview.reviewType !== 'AIAN CLASS Self-Observations' ||
      selectedReview.reviewType !== 'CLASS AIAN Onsite' ||
      selectedReview.reviewType !== 'CLASS AIAN Video')
    ? renderButton()
    : null;
};

ReviewFollowUps.propTypes = {
  selectedReview: PropTypes.object,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
});

export default withRouter(connect(mapStateToProps)(ReviewFollowUps));
