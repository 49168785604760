import React, { useEffect, useState } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

function GrantsList(props) {
  var grants =
    props &&
    props.formContext &&
    props.formContext.grantInfo &&
    props.formContext.grantInfo.map(item => ({
      key: item?.granteeId,
      text: item?.granteeId,
      value: item?.granteeId,
    }));

  var showLabel = false;
  if (grants && grants.length == 1) {
    showLabel = true;
    if (!props.value) {
      props.onChange(grants[0].value);
    }
  }

  return (
    <Form.Field>
      <label>
        <span>
          {props.label}
          {props.required ? <span className="required">*</span> : <></>}
        </span>
      </label>
      {showLabel ? (
        <div style={{ paddingLeft: '15px' }}>{props.value}</div>
      ) : (
        <Dropdown
          placeholder="Select Grant ID"
          value={props.value}
          name="grants"
          id={props.id}
          options={grants || []}
          onChange={(e, data) => {
            props.onChange(data.value);
          }}
          fluid
          search
          selection
          required={props.required}
          disabled={props.readonly || props.disabled}
          aria-labelledby="filterGrants"
          searchInput={{
            id: 'filterGrants',
            title: 'Select a Grant ID',
            'aria-labelledby': 'filterGrants',
          }}
        />
      )}
    </Form.Field>
  );
}

GrantsList.defaultProps = {
  autofocus: false,
};

export default GrantsList;
