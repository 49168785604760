import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { isNumber } from 'lodash';

// Import Actions
import {
  fetchFUTaskDetail,
  fetchFUSubmission,
} from '../../../actions/submissions';
import { fetchNotes } from '../../../actions/noteActions';
import {
  fetchCitations,
  fetchReviewDetail,
} from '../../../actions/reviewActions';

//Import Helper functions
import { formatUserNotes } from '../../Notes/util.js';

// Import components
import SlidingContainer from '../../../utils/layout/SlidingContainer';
import NotesContainer from '../../Notes';
import FollowUpGranteeInfo from './FollowUpGranteeInfo';
import FollowUpFormContainer from './FollowUpFormContainer';

const FollowUpTabsPage = ({ props }) => {
  const [formProcessing, setFormProcessing] = useState(false);
  const [isReportFinalized, setIsReportFinalized] = useState(false);
  const { followUpTaskDetail } = useSelector(state => state.submissions);
  const { notes } = useSelector(state => state.note);

  const dispatch = useDispatch();

  const { params } = props.router;

  useEffect(() => {
    const reviewId = params.reviewId;
    const fetchFollowUpTaskDetails = async reviewId => {
      if (!reviewId) return;
      let input = { reviewId };

      try {
        const data = await dispatch(fetchFUTaskDetail(input));
        if (data) {
          setFormProcessing(false);
        }
        setIsReportFinalized(data.followUpTaskDetail.isReportFinalized);
      } catch (err) {
        setFormProcessing(false);
        console.log(err);
      }
    };

    const fetchSurveyNotes = () => {
      const body = {
        filters: {
          tags: {
            reviewId: props.router.params.reviewId,
          },
        },
      };
      dispatch(fetchNotes(body));
    };

    const fetchCitationsDetails = () => {
      dispatch(fetchCitations({}));
    };

    fetchFollowUpTaskDetails(reviewId);
    fetchSurveyNotes();
    fetchCitationsDetails();

    dispatch(fetchReviewDetail({ reviewId: reviewId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (amsSubmissionId, version) => {
    if (!amsSubmissionId) return;
    let input = {};
    if (isNumber(version))
      input = {
        filters: {
          amsSubmissionId,
          version,
        },
      };
    else
      input = {
        filters: {
          amsSubmissionId,
        },
      };

    try {
      const data = dispatch(fetchFUSubmission(input));
      if (data) {
        setFormProcessing(false);
      }
    } catch (error) {
      setFormProcessing(false);

      console.log(error);
    }
  };

  const render = () => {
    const tags = {
      type: 'survey_collection',
      reviewId: params.reviewId,
    };

    const surveyNotes = notes && formatUserNotes(notes);

    return (
      <SlidingContainer
        calendar
        granteeStatusBanner={true}
        granteeStatusData={{
          id: params.reviewId,
          type: 'review',
        }}
        title={`Follow-up Survey for Review ID ${params.reviewId}`}
        actionButtons={
          <NotesContainer
            tags={tags}
            list={surveyNotes && surveyNotes}
            enabled={!isReportFinalized && surveyNotes}
            type={'Notes'}
            title={'Follow-Up Survey'}
            reloadData={() => {
              const body = {
                filters: {
                  tags: {
                    reviewId: props.router.params.reviewId,
                  },
                },
              };
              dispatch(fetchNotes(body));
            }}
          />
        }
      >
        <Grid stackable>
          <Grid.Row>
            <Grid.Column>
              <FollowUpGranteeInfo />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <FollowUpFormContainer
                fetchData={fetchData}
                formLoading={formProcessing}
                followUpTaskDetail={followUpTaskDetail}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SlidingContainer>
    );
  };

  return <div>{render()}</div>;
};

export default FollowUpTabsPage;
