import PropTypes from 'prop-types';
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';

const ReviewLegacyInfo = ({ selectedReview }) => {
  if (selectedReview.systemName !== 'OHSMS') return null;
  let team = [];
  if (selectedReview && selectedReview.Team && selectedReview.Team.length > 0) {
    team = selectedReview.Team.map(e => {
      return {
        ...e,
        name: `${e.firstName} ${e.lastName}`,
      };
    });
  }

  const columns = [
    {
      dataField: 'name',
      isKey: true,
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'roleName',
      text: 'Role Name',
      sort: true,
    },
    {
      dataField: 'effectiveDate',
      text: 'Effective Date',
      sort: true,
    },
    {
      dataField: 'expirationDate',
      text: 'Expiration Date',
      sort: true,
    },
  ];

  const renderTeamTable = () => {
    return (
      <BootstrapTable
        keyField="name"
        trClassName="clickable"
        data={team}
        pagination
        remote
        tableContainerClass="table-container"
        headerWrapperClasses="table-header"
        hover
        condensed
        bordered={false}
        columns={columns}
      />
    );
  };

  return (
    <div>
      {selectedReview.reportShippedDate && (
        <div>
          <span className="field-title">Shipped Date: </span>{' '}
          {selectedReview.reportShippedDate}
        </div>
      )}

      {selectedReview.systemName && (
        <div>
          <span className="field-title">System Field: </span>{' '}
          {selectedReview.systemName}
        </div>
      )}
      {selectedReview.reviewOutcome && (
        <div>
          <span className="field-title">Review Outcome: </span>{' '}
          {selectedReview.reviewOutcome}
        </div>
      )}
      {team && team.length > 0 && (
        <div>
          <h3>Team </h3> {renderTeamTable()}
        </div>
      )}
    </div>
  );
};

ReviewLegacyInfo.propTypes = {
  selectedReview: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
});

export default connect(mapStateToProps)(ReviewLegacyInfo);
