import _, { isEmpty } from 'lodash';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import {
  Form,
  Segment,
  Button,
  Icon,
  Grid,
  Loader,
  Dimmer,
  Progress,
  Header,
  List,
  Accordion,
  Dropdown,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import ReviewLayout from '../../../utils/layout/ReviewLayout';
import EASForm from '../../Shared/SurveyForms/EASForm';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import { customWidgets } from '../../Shared/SurveyForms/customWidgets';
import AttachmentField from '../../Shared/SurveyForms/FileUpload/AttachmentField';
import WysiwygField from '../../Shared/Wysiwyg/WsywigField';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsAlert from '../../../utils/AmsAlert';
import AmsTable from '../../../utils/AmsTable';
import { browserHistory } from 'react-router';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

import {
  fetchConsolidatedQuestions,
  saveQuestionFormData,
  fetchConsolidatedAnswers,
  fetchDataCollectionDetails,
  fetchMultiSurveyForm,
  fetchAdditionalCitationForm,
  clearConsolidatedAnswers,
  fetchSurveyCentersAndClassrooms,
} from '../../../actions/surveyActions';
import {
  fetchFollowupSurveyCitations,
  fetchSurveyGuides,
} from '../../../actions/fy19/reviewActions';
import {
  fetchReviewDetail,
  fetchFindingDetails,
} from '../../../actions/reviewActions';

//import utils
import {
  addFindingId,
  removeEmptyObjects,
  transformErrors,
  addSubmittedByAndDate,
} from '../../Shared/FormHelpers/helpers/utils';
import EASArrayFieldTemplate from '../../Shared/SurveyForms/EASArrayFieldTemplate';
import * as Constants from '../../Shared/SurveyForms/Shared/SurveyFormConstants';
import OriginalNarrative from './OriginalNarrative';
import { reviewTypeFormat } from '../../../utils/TextFormatters';
import ShowRemoveCitationDialog from './ShowRemoveCitationDialog';

function EASSurveyForm(props) {
  const SOCKET_URL = `https://api.dev.ams20.com:8443/amsweb/ws-message`;
  const dispatch = useDispatch();
  const [responseErrors, setResponseErrors] = useState([]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [formData, setFormData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [dcSessionId, setDcSessionId] = useState(null);
  const [wsError, setWsError] = useState(null);
  const [filters, setFilters] = useState({ reviewType: null });
  const [attributes, setAttributes] = useState({
    questionStatus: '',
  });
  const [showAlert, setShowAlert] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [dataCollectionDtls, setDataCollectionDtls] = useState(false);
  //const [filtertedQStatusData, setFiltertedQStatusData] = useState(data);

  const { selectedReview } = useSelector(state => state.review);

  const viewForm = useRef(null);
  const amsTableRef = useRef(null);
  const amsTaskRef = useRef(null);

  const { consolidatedQuestions, surveyCentersClassrooms } = useSelector(
    state => state.survey
  );
  const { multiSurveyForm } = useSelector(state => state.survey);
  const { consolidatedAnswers } = useSelector(state => state.survey);
  const { additionalCitationForm } = useSelector(state => state.survey);
  const currentUser = useSelector(state => state.auth.user);
  const [showSideBar, setShowSideBar] = useState(true);
  const [activeIndexes, setActiveIndexes] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [centersClassrooms, setCentersClassrooms] = useState([]);
  const [isVersionHistoryClicked, setIsVersionHistoryClicked] = useState(false);

  const [connectTime, setConnectTime] = useState(null);
  const [message, setMessage] = useState('Listening to Server....');
  const [changedQuestionIds, setChangedQuestionIds] = useState([]);
  const [showQuestionAlert, setShowQuestionAlert] = useState(false);
  const [showRefreshAlert, setShowRefreshAlert] = useState(false);
  const [showCitationRemoveAlert, setShowCitationRemoveAlert] = useState(false);
  const [showCitationDeletedAlert, setShowCitationDeletedAlert] = useState(
    false
  );
  const [isCitationActive, setIsCitationActive] = useState(true);
  const [answerId, setAnswerId] = useState(null);
  const [centerLoading, setCenterLoading] = useState(false);
  const [guideOptions, setGuideOptions] = useState([]);
  const [citationOptions, setCitationOptions] = useState([]);
  const [skipInitialLoad, setSkipInitialLoad] = useState(false);
  const [contentArea, setContentArea] = useState(null);
  const [performanceArea, setPerformanceArea] = useState(null);
  const [fotpFindings, setFotpFindings] = useState([]);
  const [formGuideName, setFormGuideName] = useState(null);
  const [accordionsData, setAccordionsData] = useState(null);
  const [accordionToDelete, setAccordionToDelete] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [originalMultiSurveyForm, setOriginalMultiSurveyForm] = useState({});
  const [
    originalConsolidatedAnswers,
    setOriginalConsolidatedAnswers,
  ] = useState({});
  const [guideDetails, setGuideDetails] = useState({});

  const INCIDENT_DATA = 'Incident Data';
  const ADDITIONAL_CITATION = 'Additional Citation';
  const FINDINGS_OUTSIDE_THE_PROTOCOL = 'Findings Outside the Protocol';
  const CLASS_ROOM_EXPLORATION = 'Classroom Exploration';
  const CENTER_EXPLORATION = 'Center Exploration';
  const ELIGIBILITY_FILE_REVIEW_INDIVIDUAL_DATA =
    'Eligibility File Review - Individual Data';

  const id = props.router.params.reviewId || props.router.params.id;

  const stompClient = useRef(null);
  const subscriptionRef = useRef(null);
  const newFormReviewTypes = ['Special'];
  //const clientRef = useRef();
  const connectToWs = () => {
    if (dcSessionId) {
      if (!stompClient.current) {
        const socket = new SockJS(SOCKET_URL);
        stompClient.current = Stomp.over(socket);
      }

      if (
        !(
          wsError &&
          wsError.headers &&
          wsError.headers.message ===
            '"Failed to send message to ExecutorSubscribableChannel[clientInboundChannel]; nested exception is java.lang.RuntimeExceptionc Unauthorized Client Connect"'
        )
      ) {
        stompClient.current.connect(
          { dcSessionId: dcSessionId },
          frame => {
            onConnected();
          },
          error => {
            console.log('error', error);
            setWsError(error);
          }
        );
      }
    }
  };

  useEffect(() => {
    // connectToWs();

    return () => {
      if (stompClient.current && stompClient.current.connected) {
        stompClient.current.disconnect();
      }
    };
  }, [dcSessionId]);

  const scrollToResults = () => {
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const onConnected = () => {
    subscribeToTopic();
    const topic = `/topic/${id}/${attributes.evidenceSource}`;

    const time = Date.now();
    setConnectTime(time);

    const message = JSON.stringify({
      reviewId: id,
      evidenceSource: attributes.evidenceSource,
      oid: currentUser.oid,
      userName: currentUser.userName,
      timeStamp: time,
      type: 'connected',
      dcSessionId: dcSessionId,
    });
  };

  const subscribeToTopic = () => {
    // Make sure to unsubscribe from the previous subscription if it exists
    if (subscriptionRef.current) {
      //subscriptionRef.current.unsubscribe();
    }

    const topic = `/topic/${id}/${attributes.evidenceSource}`;
    subscriptionRef.current = stompClient.current.subscribe(
      topic,
      message => {
        onMessageReceived(JSON.parse(message.body));
      },
      {}
    );
  };

  const onMessageReceived = msg => {
    if (msg.type === 'form change' && dcSessionId !== msg.dcSessionId) {
      const newChangedQuestions = [...changedQuestionIds, msg.questionId];
      setChangedQuestionIds([...changedQuestionIds, msg.questionId]);
      if (newChangedQuestions.length >= 4) {
        setShowRefreshAlert(true);
        return;
      }
      toast.warn(
        'There is currently more than one person on this page and they may be making changes. As a result, you may see the page periodically refresh with updated content.',
        {
          position: 'top-right',
          closeOnClick: true,
          theme: 'light',
        }
      );

      let answersInputObj = {};
      if (
        selectedReview.reviewType === 'Follow-up' ||
        !isEmpty(props.location.query.citation)
      ) {
        answersInputObj = {
          filters: {
            reviewId: id,
            questionIds: data.questionIds,
            citation: props.location.query.citation,
          },
        };
      } else {
        answersInputObj = {
          filters: {
            reviewId: id,
            questionIds: data.questionIds,
            evidenceSource:
              [data?.filters?.attributes?.evidenceSource] || [
                attributes?.evidenceSource,
              ] ||
              null,
          },
        };
      }
      fetchConsolidatAnswersDataAndSet(answersInputObj, data, 'SUBMIT');
    }
  };

  useEffect(() => {
    dispatch(fetchReviewDetail({ reviewId: id })).then(e => {
      setFilters({ ...filters, reviewType: e.review.reviewType });
      if (['FA2-CR', 'Special'].includes(e.review.reviewType)) {
        dispatch(fetchSurveyCentersAndClassrooms({ reviewId: id }));
      }
    });
  }, []);

  useEffect(() => {
    if (
      selectedReview.reviewId &&
      selectedReview.reviewType &&
      selectedReview.reviewType === 'Follow-up'
    ) {
      const { reviewId } = selectedReview;
      let followupRequestInput = { reviewId };
      dispatch(fetchFollowupSurveyCitations(followupRequestInput)).then(
        data => {
          const citationOptionsUpdate =
            !_.isEmpty(data) && !_.isEmpty(data.followupCitations)
              ? data.followupCitations.map(citation => ({
                  key: citation.name,
                  text:
                    citation.name +
                    (!citation.isPreliminaryFinding && citation.isActive
                      ? ' - New'
                      : !citation.isPreliminaryFinding && !citation.isActive
                      ? ' - Removed'
                      : ''),
                  value: citation.name,
                }))
              : [];

          setCitationOptions(citationOptionsUpdate);
          const queryParams = new URLSearchParams(props.location.query);
          const hasCitation = queryParams.has('citation');

          if (
            props.location.query &&
            hasCitation &&
            props.location.query.citation === ''
          ) {
            let newCitation = citationOptionsUpdate[0].value;
            setAttributes({ ...attributes, citation: newCitation });
            browserHistory.push(
              `/review/fy${selectedReview?.fiscalYear?.toString().slice(-2)}/${
                selectedReview?.reviewId
              }/survey/${selectedReview &&
                reviewTypeFormat(
                  selectedReview?.reviewType
                )}?citation=${encodeURIComponent(newCitation)}`
            );
            return;
          }
        }
      );
    } else {
      if (selectedReview.guides && selectedReview.guides.length > 0) {
        const guideOptionsUpdate = selectedReview.guides
          .filter(guide => guide.questionIds.length > 0)
          .map(guide => ({
            key: guide.name,
            text: guide.name,
            value: guide.name,
          }));
        setGuideOptions(guideOptionsUpdate);
      } else if (
        selectedReview.reviewId &&
        selectedReview.reviewType &&
        selectedReview.fiscalYear
      ) {
        const { reviewId, reviewType, fiscalYear } = selectedReview;
        let requestInput = { reviewId, reviewType, fiscalYear };
        dispatch(fetchSurveyGuides(requestInput)).then(data => {
          const guideOptionsUpdate =
            !_.isEmpty(data) && !_.isEmpty(data.guides)
              ? data.guides
                  .filter(guide => guide.questionIds.length > 0)
                  .map(guide => ({
                    key: guide.name,
                    text: guide.name,
                    value: guide.name,
                  }))
              : [];
          setGuideOptions(guideOptionsUpdate);
        });
      }
    }
  }, [selectedReview]);

  useEffect(() => {
    if (skipInitialLoad && citationOptions.length > 0) {
      setSkipInitialLoad(false);

      let citationExists = citationOptions.some(
        citation => citation.key === attributes.citation
      );

      if (!citationExists) {
        let newCitation = citationOptions[0].value;
        setAttributes({ ...attributes, citation: newCitation });
        browserHistory.push(
          `/review/fy${selectedReview?.fiscalYear?.toString().slice(-2)}/${
            selectedReview?.reviewId
          }/survey/${selectedReview &&
            reviewTypeFormat(
              selectedReview?.reviewType
            )}?citation=${encodeURIComponent(newCitation)}`
        );
        return;
      }
      getQuestions(attributes.citation, 'LOAD');
    }
  }, [citationOptions]);

  // useEffect to run preselect on pm and guide
  useEffect(() => {
    const queryParams = new URLSearchParams(props.location.query);
    const hasCitation = queryParams.has('citation');
    setActiveIndexes([0]);

    if (
      selectedReview.reviewType === 'Follow-up' ||
      !isEmpty(props.location.query.citation)
    ) {
      const citation = !isEmpty(props.location.query.citation)
        ? decodeURIComponent(props.location.query.citation)
        : '';
      //setFormGuideName(guideName);
      setAttributes({
        ...attributes,
        citation: citation,
      });

      if (!isEmpty(citation)) {
        getQuestions(citation, 'LOAD');
      }
    } else if (!hasCitation) {
      const guideName = !isEmpty(props.location.query.g)
        ? decodeURIComponent(props.location.query.g)
        : '';
      setFormGuideName(guideName);
      setAttributes({
        ...attributes,
        questionStatus: isMultiForm(guideName)
          ? 'All Incidents'
          : 'All questions',
        evidenceSource: guideName,
      });

      if (
        !isEmpty(guideName) &&
        guideName !== CENTER_EXPLORATION &&
        guideName !== CLASS_ROOM_EXPLORATION
      ) {
        getQuestions(guideName, 'LOAD');
      }
    }
  }, [JSON.stringify(props.location.query || '')]);

  const setFilterDataByIStatus = (data, accordions, action) => {
    if ('All Incidents' === attributes['questionStatus']) {
      if ('SUBMIT' === action) {
        setData(data);
      }
    } else {
      const allAccordionTitles = accordions.map(x => x.title);
      const accordionsToReturn = accordions
        .filter(
          x =>
            x.isValidated ===
            (attributes['questionStatus'] === 'Completed Incident')
        )
        .map(x => x.title);
      const filteredPAs = data?.performanceAreas?.filter(area =>
        accordionsToReturn.includes(area.accordionTitle)
      );
      var noAnswers = [];
      if (attributes['questionStatus'] === 'Uncompleted Incident') {
        noAnswers = data?.performanceAreas?.filter(
          area => !allAccordionTitles.includes(area.accordionTitle)
        );
      }
      const newPAs = filteredPAs.concat(noAnswers);
      setData({
        ...data,
        performanceAreas: newPAs,
      });
    }
  };

  const setFilterDataByQStatus = (data, dataCollectionDtls, action) => {
    if ('All questions' === attributes['questionStatus']) {
      if ('SUBMIT' === action) {
        setData(data);
      }
    } else {
      setData(
        getQStatusfilteredData(
          attributes['questionStatus'],
          data,
          dataCollectionDtls
        )
      );
    }
  };

  const isReportStages = ['finalized', 'signed', 'shipped'].includes(
    selectedReview?.reportStage
  );
  const getQStatusfilteredData = (questionStatus, data, dataCollectionDtls) => {
    const keysToRemove = dataCollectionDtls?.validQuestionIds;
    return {
      ...data,

      performanceAreas: data?.performanceAreas
        ?.map(area => {
          const filteredKeys = Object.keys(
            area['formSchema']?.properties
          ).filter(propKey =>
            'Answered Questions' === questionStatus
              ? keysToRemove?.includes(propKey)
              : !keysToRemove?.includes(propKey)
          );
          const filteredProperties = filteredKeys.reduce((obj, propKey) => {
            obj[propKey] = area['formSchema']?.properties[propKey];
            return obj;
          }, {});

          return !isEmpty(filteredProperties)
            ? {
                formSchema: {
                  ...area['formSchema'],
                  properties: filteredProperties,
                },
                uiSchema: area['uiSchema'],
                performanceArea: area['performanceArea'],
                accordionTitle: area['accordionTitle'],
              }
            : null;
        })
        .filter(obj => !isEmpty(obj)),
    };
  };

  function checkCitationStatus(citation) {
    for (let i = 0; i < citationOptions.length; i++) {
      if (citationOptions[i].key === citation) {
        if (
          citationOptions[i].text.includes(' - New') ||
          citationOptions[i].text.includes(' - Removed')
        ) {
          return 1; // The citation is found and contains '(New)'
        } else {
          return 0; // The citation is found but does not contain '(New)'
        }
      }
    }
    return -1; // The citation is not found at all
  }

  // Data
  const getQuestions = async (guideName, action) => {
    setLoading(true);
    let hasCitationInput = false;
    if (isMultiForm(guideName)) {
      getMultiForms(true, guideName, null, action);
    } else {
      let requestInput = {};
      if (
        selectedReview.reviewType === 'Follow-up' ||
        !isEmpty(props.location.query.citation)
      ) {
        hasCitationInput = true;
        requestInput = {
          filters: {
            reviewId: id,
            // citation: '1302.13' || guideName,
            citation: guideName || attributes.citation,
          },
        };
      } else {
        requestInput = {
          filters: {
            reviewType: filters.reviewType || selectedReview.reviewType,
            reviewId: id,
            questionStatus: null,
            attributes: {
              evidenceSource: guideName || attributes.evidenceSource || null,
            },
          },
        };
      }
      if (
        CENTER_EXPLORATION === attributes?.evidenceSource &&
        attributes &&
        attributes.center
      ) {
        requestInput.filters.centerName = attributes.center.centerName;
        requestInput.filters.centerId = attributes.center.centerId;
      }
      if (
        CLASS_ROOM_EXPLORATION === attributes?.evidenceSource &&
        attributes &&
        attributes.center &&
        attributes.classroom
      ) {
        requestInput.filters.centerName = attributes.center.centerName;
        requestInput.filters.classRoomName = attributes.classroom.classRoomName;
        requestInput.filters.classSampleId = attributes.classroom.classSampleId;
      }
      if (_.isEmpty(requestInput?.filters?.attributes?.contentArea)) {
        delete requestInput?.filters?.attributes?.contentArea;
      }

      if (hasCitationInput && citationOptions.length > 0) {
        let citation = requestInput.filters.citation;
        const citationType = checkCitationStatus(citation);
        if (citationType === 1) {
          let requestInput = {
            filters: {
              reviewId: id,
              reviewType: selectedReview.reviewType,
              attributes: {
                evidenceSource: 'Additional Citation',
              },
            },
          };
          dispatch(fetchAdditionalCitationForm(requestInput))
            .then(data => {
              setDcSessionId(data.additionalCitationForm.dcSessionId);
              setData(data.additionalCitationForm);

              const answersInputObj = {
                filters: {
                  reviewId: id,
                  questionIds: data.additionalCitationForm.questionIds,
                  citation: guideName || attributes.citation,
                  //evidenceSource: ['Additional Citation'],
                  parentReviewId: data?.filters?.parentReviewId,
                },
              };
              fetchConsolidatAnswersDataAndSet(answersInputObj, data, action);
            })
            .catch(error => {
              setResponseErrors([...responseErrors, error.response]);
            });
          return;
        }
      } else if (hasCitationInput && citationOptions.length === 0) {
        setSkipInitialLoad(true);
        return;
      }
      dispatch(fetchConsolidatedQuestions(requestInput))
        .then(e => {
          const data = e.consolidatedQuestions ?? e.multiSurveyForm;
          setLoading(false);
          setData(data);
          setDcSessionId(data.dcSessionId);
          setGuideDetails(data?.performanceAreas[0]?.guideDetail);
          let answersInputObj = {};
          if (
            selectedReview.reviewType === 'Follow-up' ||
            !isEmpty(props.location.query.citation)
          ) {
            answersInputObj = {
              filters: {
                reviewId: id,
                questionIds: data.questionIds,
                citation: guideName || attributes.citation,
                parentReviewId: data?.filters?.parentReviewId,
              },
            };
          } else {
            answersInputObj = {
              filters: {
                reviewId: id,
                questionIds: data.questionIds,
                evidenceSource:
                  [data?.filters?.attributes?.evidenceSource] || [
                    attributes?.evidenceSource,
                  ] ||
                  null,
              },
            };
          }
          if (
            (requestInput.filters && requestInput.filters.centerName) ||
            (attributes && !attributes.center)
          ) {
            answersInputObj.filters.centerName =
              requestInput.filters.centerName;
            answersInputObj.filters.centerId = requestInput.filters.centerId;
          }
          if (
            (requestInput.filters && requestInput.filters.classRoomName) ||
            (attributes && !attributes.classroom)
          ) {
            answersInputObj.filters.classRoomName =
              requestInput.filters.classRoomName;
            answersInputObj.filters.classSampleId =
              requestInput.filters.classSampleId;
          }
          fetchConsolidatAnswersDataAndSet(answersInputObj, data, action);
        })
        .catch(error => {
          setResponseErrors([...responseErrors, error.response]);
        });
    }
  };

  const fetchConsolidatAnswersDataAndSet = async (
    answersInputObj,
    data,
    action
  ) => {
    setLoading(true);
    if (attributes && attributes.center) {
      answersInputObj.filters.centerName = attributes.center.centerName;
      answersInputObj.filters.centerId = attributes.center.centerId;
    }
    if (attributes && attributes.classroom) {
      answersInputObj.filters.classRoomName =
        attributes.classroom.classRoomName;
      answersInputObj.filters.classSampleId =
        attributes.classroom.classSampleId;
    }
    if (
      (attributes.evidenceSource === CENTER_EXPLORATION ||
        attributes.evidenceSource === CLASS_ROOM_EXPLORATION) &&
      action === 'SUBMIT' &&
      !isEmpty(consolidatedQuestions?.performanceAreas)
    ) {
      let requestInput = {
        filters: {
          reviewType: filters.reviewType || selectedReview.reviewType,
          reviewId: id,
          questionStatus: null,
          attributes: {
            evidenceSource: attributes.evidenceSource,
          },
        },
      };
      if (attributes && attributes.center) {
        requestInput.filters.centerName = attributes.center.centerName;
        requestInput.filters.centerId = attributes.center.centerId;
      }
      if (attributes.center && attributes.classroom) {
        requestInput.filters.centerName = attributes.center.centerName;
        requestInput.filters.classRoomName = attributes.classroom.classRoomName;
        requestInput.filters.classSampleId = attributes.classroom.classSampleId;
      }
      await dispatch(fetchConsolidatedQuestions(requestInput)).then(e => {
        setData(e.consolidatedQuestions);
        setDcSessionId(e.consolidatedQuestions.dcSessionId);
      });
    }
    await dispatch(fetchConsolidatedAnswers(answersInputObj)).then(e => {
      setIsCitationActive(e.consolidatedAnswers.isActive);
      if (action !== 'VERSION_HISTORY') {
        setOriginalConsolidatedAnswers(e);
      }
      setConsolidatedAnswersData(e);
    });
    if ('VERSION_HISTORY' !== action) {
      await dispatch(fetchDataCollectionDetails(answersInputObj)).then(e => {
        setDataCollectionDtls(e);
        if (
          !isEmpty(attributes['questionStatus']) &&
          'VERSION_HISTORY' !== action
        ) {
          if (isMultiForm(formGuideName)) {
            setFilterDataByIStatus(data, accordionsData, action);
          } else if (
            attributes.evidenceSource === 'Center Exploration' ||
            attributes.evidenceSource === 'Classroom Exploration'
          ) {
            // Do nothing
          } else {
            setFilterDataByQStatus(data, e, action);
          }
        }
      });
    }
    setLoading(false);
  };

  const asyncFetchConsolidatAnswersDataAndSet = async (
    answersInputObj,
    data,
    action
  ) => {
    try {
      const response = await dispatch(
        fetchConsolidatedAnswers(answersInputObj)
      );
      setConsolidatedAnswersData(response);
      if ('VERSION_HISTORY' !== action) {
        const detailsResponse = await dispatch(
          fetchDataCollectionDetails(answersInputObj)
        );
        setDataCollectionDtls(detailsResponse);
        if (
          !isEmpty(attributes['questionStatus']) &&
          'VERSION_HISTORY' !== action
        ) {
          setFilterDataByQStatus(data, detailsResponse, action);
        }
      }
    } catch (error) {
      // Handle error here
    } finally {
    }
  };

  const setConsolidatedAnswersData = e => {
    var guideNames = e.consolidatedAnswers?.filterObj?.evidenceSource;
    if (guideNames?.length > 0 && isMultiForm(guideNames[0])) {
      var surveyData = Object.values(e.consolidatedAnswers?.surveyData);
      if (surveyData) {
        var accordions = surveyData[0]?.accordions;
        var data = buildMultiFormData(accordions);
        setAccordionsData(accordions);
        setOriginalData(data);
        setFormData(data);

        return { accordions, data };
      }
    } else {
      setOriginalData(e.consolidatedAnswers.surveyData);
      setFormData(e.consolidatedAnswers.surveyData);

      return { data };
    }
  };

  const buildMultiFormData = accordions => {
    var data = [];
    if (accordions) {
      for (var id in accordions) {
        data = { ...data, ...accordions[id]?.data };
      }
    }
    return data;
  };

  // Filters
  const filtersOnSubmit = e => {
    e.preventDefault();
    setActiveIndexes([0]);

    if (selectedReview?.reviewType === 'Follow-up') {
      browserHistory.push(
        `/review/fy${selectedReview?.fiscalYear?.toString().slice(-2)}/${
          selectedReview?.reviewId
        }/survey/${selectedReview &&
          reviewTypeFormat(
            selectedReview?.reviewType
          )}?citation=${encodeURIComponent(attributes.citation)}`
      );
    } else {
      if (attributes.evidenceSource) {
        browserHistory.push(
          `/review/fy${selectedReview?.fiscalYear?.toString().slice(-2)}/${
            selectedReview?.reviewId
          }/survey/${selectedReview &&
            reviewTypeFormat(
              selectedReview?.reviewType
            )}?g=${encodeURIComponent(attributes.evidenceSource)}`
        );
      }
    }

    if (
      (attributes['questionStatus'] &&
        !isEmpty(attributes['questionStatus'])) ||
      (attributes['evidenceSource'] &&
        !isEmpty(attributes['evidenceSource']) &&
        attributes['evidenceSource'] === CENTER_EXPLORATION &&
        !isEmpty(attributes['center'])) ||
      (attributes['evidenceSource'] &&
        !isEmpty(attributes['evidenceSource']) &&
        attributes['evidenceSource'] === CLASS_ROOM_EXPLORATION &&
        !isEmpty(attributes['classroom']))
    ) {
      if (isVersionHistoryClicked) {
        if (isMultiForm(formGuideName)) {
          resetMultiForm();
        } else {
          fetchConsolidatedAnswersAndSetFilteredData();
        }
      } else {
        if (isMultiForm(formGuideName)) {
          if (
            isEmpty(multiSurveyForm?.performanceAreas) ||
            multiSurveyForm?.filters?.attributes?.evidenceSource !==
              attributes?.evidenceSource
          ) {
            getQuestions(formGuideName, 'SUBMIT');
          } else {
            resetMultiForm();
          }
        } else {
          if (
            (isEmpty(consolidatedQuestions?.performanceAreas) ||
              consolidatedQuestions?.filters?.attributes?.evidenceSource !==
                attributes?.evidenceSource) &&
            selectedReview?.reviewType !== 'Follow-up'
          ) {
            getQuestions(null, 'SUBMIT');
          } else {
            fetchConsolidatedAnswersAndSetFilteredData();
          }
        }
        return;
      }
    } else {
      if (isVersionHistoryClicked) {
        getQuestions(null, 'SUBMIT');
      }
    }
  };

  const resetMultiForm = () => {
    setDcSessionId(originalMultiSurveyForm.dcSessionId);
    var obj = setConsolidatedAnswersData(originalConsolidatedAnswers);
    setChangedQuestionIds([]);
    setFilterDataByIStatus(originalMultiSurveyForm, obj.accordions, 'SUBMIT');
    setIsVersionHistoryClicked(false);
  };

  const handleAttributesValueChange = (e, { name, value }) => {
    if (name === 'evidenceSource') {
      if (value === CENTER_EXPLORATION || value === CLASS_ROOM_EXPLORATION) {
        //setFormGuideName(value);
        dispatch(fetchSurveyCentersAndClassrooms({ reviewId: id }));
      }
      if (value === CENTER_EXPLORATION) {
        setAttributes({
          ...attributes,
          center: null,
          evidenceSource: value,
          questionStatus: null,
        });
      } else if (value === CLASS_ROOM_EXPLORATION) {
        setAttributes({
          ...attributes,
          center: null,
          evidenceSource: value,
          questionStatus: null,
          classroom: null,
        });
      } else {
        setAttributes({
          ...attributes,
          [name]: value,
          questionStatus: '',
          center: null,
          classroom: null,
        });
      }
      setIsVersionHistoryClicked(false);
    } else {
      if (name === 'center') {
        let centerObj = surveyCentersClassrooms?.data?.filter(item => {
          if (item.centerName && item.centerName == value) {
            return item;
          }
        });
        setClassrooms(centerObj[0].classRoom);
        setAttributes({ ...attributes, center: centerObj[0], classroom: null });
      } else if (name === 'classroom') {
        let classRoomObj = classrooms.filter(item => {
          if (item.classRoomName && item.classRoomName == value) {
            return item;
          }
        });
        setAttributes({ ...attributes, classroom: classRoomObj[0] });
      } else {
        setAttributes({ ...attributes, [name]: value });
      }
    }
  };

  // Renders
  const showResponseErrors = () => {
    if (responseErrors && responseErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {responseErrors.map(
              (errorObject, index) =>
                errorObject && (
                  <li key={index}>
                    {errorObject.message} code:(
                    {errorObject.code})
                  </li>
                )
            )}
          </ul>
        </Alert>
      );
  };

  const fetchConsolidatedAnswersAndSetFilteredData = () => {
    const answersInputObj = {
      filters: {
        reviewId: id,
        questionIds:
          consolidatedQuestions?.questionIds || multiSurveyForm?.questionIds,
        evidenceSource: [attributes?.evidenceSource] || [formGuideName] || null,
      },
    };
    fetchConsolidatAnswersDataAndSet(
      answersInputObj,
      consolidatedQuestions,
      'SUBMIT'
    );
    //setData(filtertedQStatusData);
    setChangedQuestionIds([]);
  };

  const prepareDropDowns = items => {
    if (attributes.evidenceSource === CLASS_ROOM_EXPLORATION) {
      items = _.filter(items, function(item) {
        return item.classRoom && item.classRoom.length > 0;
      });
    }
    return (
      items &&
      items.map(item => ({
        text:
          item.centerName +
          ' - ' +
          ((attributes.evidenceSource === CLASS_ROOM_EXPLORATION &&
            item.allClassRoomStatus === 'Completed') ||
          (attributes.evidenceSource !== CLASS_ROOM_EXPLORATION &&
            item.submissionStatus === 'Completed')
            ? 'Complete'
            : 'Incomplete'),
        key: item.centerName,
        value: item.centerName,
      }))
    );
  };

  const classroomPrepareDropDowns = items => {
    return (
      items &&
      items.map(item => ({
        text:
          item.classRoomName +
          ' - ' +
          (item.submissionStatus === 'Completed' ? 'Complete' : 'Incomplete'),
        key: item.classRoomName,
        value: item.classRoomName,
      }))
    );
  };

  const renderSurveyQuestionsFilters = () => {
    const { evidenceSource, questionStatus, center, classroom } = attributes;
    const boldLabelStyle = { fontFamily: 'PublicSans-Bold' };
    const showIncidentStatuses = evidenceSource?.trim() === INCIDENT_DATA;
    return (
      <Segment basic>
        <Form onSubmit={filtersOnSubmit} noValidate className="filter-form">
          <Grid stackable columns={2}>
            <Grid.Row stretched>
              <Grid.Column width={10}>
                <Segment
                  style={{ marginTop: '-0.6em', height: '500%' }}
                  //disabled={disableForm}
                >
                  <div>
                    <Header as="h4">
                      {filters.reviewType === 'FA1-FR'
                        ? 'Foundational'
                        : filters.reviewType === 'FA2-CR'
                        ? 'Comprehensive'
                        : filters.reviewType === 'Follow-up'
                        ? 'Follow-up'
                        : filters.reviewType}{' '}
                      Data Collection{' '}
                    </Header>
                    <span
                      role="heading"
                      aria-level="3"
                      className="field-title"
                      aria-label="Citation Number"
                    >
                      {filters.reviewType === 'Follow-up'
                        ? 'Select from the dropdown below to view citation details or add a new citation'
                        : 'Select from the dropdown choices to see data for this review.'}
                      {''}
                    </span>
                  </div>
                  <br />
                  <br />
                  {filters.reviewType === 'Follow-up' ? (
                    <>
                      <Form.Group>
                        <Form.Field width={8}>
                          <AmsFormLabel
                            name={<Header as="h4">Citation Number</Header>}
                            fieldLabel="filterCitation"
                          >
                            <Form.Dropdown
                              aria-labelledby="filterCitation"
                              name={'citation'}
                              placeholder={'Select Citation Number'}
                              selection
                              searchInput={{
                                id: 'filterCitation',
                                title: 'Filter by Citation Number',
                              }}
                              search
                              fluid
                              loading={false}
                              selectOnBlur={false}
                              value={attributes.citation}
                              options={citationOptions}
                              style={{ height: '50px' }}
                              onChange={handleAttributesValueChange}
                            />
                          </AmsFormLabel>
                        </Form.Field>
                        <Form.Button
                          className="filter-button"
                          style={{ height: '50px' }}
                          primary
                          content={'See Details'}
                          fluid
                        />
                      </Form.Group>
                      <br />
                      {!isReportStages && !selectedReview.readOnly ? (
                        <Form.Field style={{ float: 'left' }}>
                          <Button
                            style={{ float: 'left' }}
                            floated="left"
                            className="btn-outline"
                            height="50px"
                            content="Add New Citation"
                            onClick={handleAddCitation}
                          />
                        </Form.Field>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <Form.Field width={14}>
                        <AmsFormLabel
                          name={<Header as="h4">Guide</Header>}
                          fieldLabel="filterGuide"
                        >
                          <Form.Dropdown
                            aria-labelledby="filterGuide"
                            name={'evidenceSource'}
                            placeholder={'Select Guide'}
                            selection
                            searchInput={{
                              id: 'filterGuide',
                              title: 'Filter by Guide',
                            }}
                            search
                            fluid
                            loading={false}
                            selectOnBlur={false}
                            value={evidenceSource?.trim()}
                            options={guideOptions}
                            style={{
                              height: '50px',
                              width:
                                evidenceSource !=
                                  'Findings Outside the Protocol' &&
                                evidenceSource !=
                                  ELIGIBILITY_FILE_REVIEW_INDIVIDUAL_DATA
                                  ? '100%'
                                  : '600px',
                            }}
                            onChange={handleAttributesValueChange}
                          />
                        </AmsFormLabel>
                      </Form.Field>
                      {evidenceSource === CENTER_EXPLORATION ||
                      evidenceSource === CLASS_ROOM_EXPLORATION ? (
                        <Form.Field
                          disabled={isEmpty(evidenceSource)}
                          width={14}
                        >
                          <AmsFormLabel
                            name={<Header as="h4">Center</Header>}
                            fieldLabel="filterCenters"
                          >
                            <Dropdown
                              placeholder="Select Center"
                              value={center && center.centerName}
                              fluid
                              search
                              selection
                              name="center"
                              options={prepareDropDowns(
                                surveyCentersClassrooms?.data
                              )}
                              onChange={handleAttributesValueChange}
                              aria-labelledby="filterCenter"
                              searchInput={{
                                id: 'filterCenter',
                                title: 'Filter by Center',
                              }}
                              openOnFocus={false}
                              selectOnBlur={false}
                              style={{ height: '50px' }}
                            />
                          </AmsFormLabel>
                        </Form.Field>
                      ) : (
                        <Form.Field
                          disabled={isEmpty(evidenceSource)}
                          width={14}
                        >
                          <AmsFormLabel
                            name={
                              <Header
                                as="h4"
                                style={{
                                  display: showIncidentStatuses
                                    ? 'block'
                                    : 'none',
                                }}
                              >
                                Incident Status
                              </Header>
                            }
                            fieldLabel="filterQuestionStatus"
                            hidden={!showIncidentStatuses}
                          >
                            <AmsLookupDropdown
                              placeholder="Select Incident Status"
                              value={questionStatus}
                              display="none"
                              fluid
                              search
                              selection
                              name="questionStatus"
                              control={AmsLookupDropdown}
                              category={'easIncidentStatus'}
                              onChange={handleAttributesValueChange}
                              aria-labelledby="filterQuestionStatus"
                              searchInput={{
                                id: 'filterQuestionStatus',
                                title: 'Filter by Incident Status',
                              }}
                              style={{
                                height: '50px',
                                display: showIncidentStatuses
                                  ? 'block'
                                  : 'none',
                              }}
                            />
                          </AmsFormLabel>
                          {evidenceSource != 'Findings Outside the Protocol' &&
                          evidenceSource !=
                            ELIGIBILITY_FILE_REVIEW_INDIVIDUAL_DATA ? (
                            <AmsFormLabel
                              name={
                                <Header
                                  as="h4"
                                  style={{
                                    display: !showIncidentStatuses
                                      ? 'block'
                                      : 'none',
                                  }}
                                >
                                  Question Status
                                </Header>
                              }
                              fieldLabel="filterQuestionStatus"
                              hidden={showIncidentStatuses}
                            >
                              <AmsLookupDropdown
                                placeholder="Select Question Status"
                                value={questionStatus}
                                fluid
                                search
                                selection
                                name="questionStatus"
                                control={AmsLookupDropdown}
                                category={'easQuestionStatus'}
                                onChange={handleAttributesValueChange}
                                aria-labelledby="filterQuestionStatus"
                                searchInput={{
                                  id: 'filterQuestionStatus',
                                  title: 'Filter by Question Status',
                                }}
                                style={{
                                  height: '50px',
                                  display: !showIncidentStatuses
                                    ? 'block'
                                    : 'none',
                                }}
                              />
                            </AmsFormLabel>
                          ) : (
                            <></>
                          )}
                        </Form.Field>
                      )}
                      {evidenceSource === CLASS_ROOM_EXPLORATION && (
                        <Form.Field
                          disabled={isEmpty(evidenceSource)}
                          width={14}
                        >
                          <AmsFormLabel
                            name={<Header as="h4">Classroom</Header>}
                            fieldLabel="filterClassrooms"
                          >
                            <Dropdown
                              placeholder="Select Classroom"
                              value={classroom && classroom.classRoomName}
                              fluid
                              search
                              selection
                              name="classroom"
                              options={classroomPrepareDropDowns(classrooms)}
                              onChange={handleAttributesValueChange}
                              aria-labelledby="filterClassroom"
                              searchInput={{
                                id: 'filterClassroom',
                                title: 'Filter by Classroom',
                              }}
                              openOnFocus={false}
                              selectOnBlur={false}
                              style={{ height: '50px' }}
                            />
                          </AmsFormLabel>
                        </Form.Field>
                      )}
                      &nbsp;
                      <br />
                      <Form.Field style={{ float: 'right' }}>
                        <Button
                          style={{ float: 'right' }}
                          floated="right"
                          //className="filter-button"
                          disabled={
                            (attributes.evidenceSource === CENTER_EXPLORATION &&
                              !attributes.center) ||
                            (attributes.evidenceSource ===
                              CLASS_ROOM_EXPLORATION &&
                              !attributes.classroom)
                          }
                          primary
                          content="See Data"
                        />
                      </Form.Field>
                    </>
                  )}
                </Segment>
              </Grid.Column>
              {formGuideName == CENTER_EXPLORATION ||
              formGuideName == CLASS_ROOM_EXPLORATION ? (
                <>
                  {dataCollectionDtls &&
                    dataCollectionDtls &&
                    dataCollectionDtls.totalCenterAndClass >= 0 && (
                      <Grid.Column width={6}>
                        <Segment style={{ marginTop: '-0.6em' }}>
                          <Header as="h4">Guide Progress</Header>
                          <Progress
                            percent={
                              dataCollectionDtls &&
                              dataCollectionDtls.percentage
                            }
                            className={`survey-progress${
                              dataCollectionDtls &&
                              dataCollectionDtls.percentage > 0
                                ? '-progressing'
                                : ''
                            }`}
                          />
                          {dataCollectionDtls
                            ? `${dataCollectionDtls.centerAndClassCompleted ||
                                0} of ${dataCollectionDtls.totalCenterAndClass ||
                                0} ${
                                formGuideName === CLASS_ROOM_EXPLORATION
                                  ? 'classrooms'
                                  : 'centers'
                              }` + ' complete'
                            : 'No questions available'}
                        </Segment>
                        <Segment style={{ marginTop: '1em' }}>
                          <Header as="h4">History</Header>
                          {dataCollectionDtls &&
                          dataCollectionDtls.editedTime &&
                          dataCollectionDtls.totalCenterAndClass > 0 ? (
                            <List>
                              <List.Item>
                                <List.Content>
                                  <span style={boldLabelStyle}>
                                    Last autosave:{' '}
                                  </span>
                                  {AmsDateFormatters.formatDate(
                                    dataCollectionDtls &&
                                      dataCollectionDtls.editedTime
                                  )}{' '}
                                  at{' '}
                                  {AmsDateFormatters.formatTime(
                                    dataCollectionDtls &&
                                      dataCollectionDtls.editedTime
                                  )}
                                </List.Content>
                              </List.Item>
                              <List.Item>
                                <List.Content>
                                  <span style={boldLabelStyle}>
                                    Last edited by:{' '}
                                  </span>
                                  {dataCollectionDtls &&
                                    dataCollectionDtls.editedByFullName}
                                </List.Content>
                              </List.Item>
                              <List.Item>
                                <List.Content>
                                  <span style={boldLabelStyle}>Status: </span>
                                  {dataCollectionDtls &&
                                    dataCollectionDtls.status}
                                </List.Content>
                              </List.Item>
                            </List>
                          ) : (
                            <List.Item>
                              <List.Content>
                                No Question was answered for this filter
                              </List.Content>
                            </List.Item>
                          )}
                        </Segment>
                      </Grid.Column>
                    )}
                </>
              ) : (
                <>
                  {dataCollectionDtls &&
                    dataCollectionDtls &&
                    dataCollectionDtls.totalQuestions >= 0 && (
                      <Grid.Column width={6}>
                        <Segment
                          style={{ marginTop: '-0.6em' }}
                          //disabled={disableForm}
                        >
                          <Header as="h4">
                            {filters.reviewType === 'Follow-up'
                              ? 'Survey Progress'
                              : 'Guide Progress'}
                          </Header>
                          <Progress
                            percent={
                              dataCollectionDtls &&
                              dataCollectionDtls.percentage
                            }
                            className={`survey-progress${
                              dataCollectionDtls &&
                              dataCollectionDtls.percentage > 0
                                ? '-progressing'
                                : ''
                            }`}
                          />
                          {/* <span> */}
                          {dataCollectionDtls
                            ? `${dataCollectionDtls.questionsCompleted ||
                                0} of ${dataCollectionDtls.totalQuestions ||
                                0} ${
                                formGuideName === INCIDENT_DATA
                                  ? 'incidents'
                                  : formGuideName == CENTER_EXPLORATION
                                  ? 'centers'
                                  : formGuideName ===
                                      FINDINGS_OUTSIDE_THE_PROTOCOL &&
                                    dataCollectionDtls.fotpTqAnswer &&
                                    dataCollectionDtls.fotpTqAnswer.toLowerCase() ===
                                      'yes'
                                  ? 'findings'
                                  : formGuideName ===
                                      ELIGIBILITY_FILE_REVIEW_INDIVIDUAL_DATA &&
                                    dataCollectionDtls.childFileTqAnswer &&
                                    dataCollectionDtls.childFileTqAnswer.toLowerCase() ===
                                      'yes'
                                  ? 'eligibility files'
                                  : 'questions'
                              }` + ' complete'
                            : 'No questions available'}
                          {/* </span> */}
                        </Segment>
                        <Segment
                          style={{ marginTop: '1em' }}
                          //disabled={disableForm}
                        >
                          <Header as="h4">History</Header>
                          {dataCollectionDtls &&
                          dataCollectionDtls.editedTime &&
                          dataCollectionDtls.totalQuestions > 0 ? (
                            <List>
                              <List.Item>
                                <List.Content>
                                  <span style={boldLabelStyle}>
                                    Last autosave:{' '}
                                  </span>
                                  {AmsDateFormatters.formatDate(
                                    dataCollectionDtls &&
                                      dataCollectionDtls.editedTime
                                  )}{' '}
                                  at{' '}
                                  {AmsDateFormatters.formatTime(
                                    dataCollectionDtls &&
                                      dataCollectionDtls.editedTime
                                  )}
                                </List.Content>
                              </List.Item>
                              <List.Item>
                                <List.Content>
                                  <span style={boldLabelStyle}>
                                    Last edited by:{' '}
                                  </span>
                                  {dataCollectionDtls &&
                                    dataCollectionDtls.editedByFullName}
                                </List.Content>
                              </List.Item>
                              <List.Item>
                                <List.Content>
                                  <span style={boldLabelStyle}>Status: </span>
                                  {dataCollectionDtls &&
                                    dataCollectionDtls.status}
                                </List.Content>
                              </List.Item>
                            </List>
                          ) : (
                            <List.Item>
                              <List.Content>
                                No Question was answered for this filter
                              </List.Content>
                            </List.Item>
                          )}
                        </Segment>
                      </Grid.Column>
                    )}
                </>
              )}
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  };

  const instructions = () => {
    const requiredStyle = {
      display: 'inline-block',
      position: 'relative',
    };

    const asteriskStyle = {
      top: 0,
      left: '-1.2em',
      fontSize: '1.5em',
      lineHeight: 0,
    };

    return (
      <div>
        <span style={requiredStyle}>
          <span style={asteriskStyle}>* - </span>Indicated a Required
          Field&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Icon name="info circle" size="large" className="largeIcon" />-
          Displays Guidance Text on Click
        </span>
        <br />
        <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>
          {' '}
          <b style={{ color: 'rgba(0, 0, 0, 1)' }}>{'Note: '}</b>{' '}
          {'Links to citation definitions will open in a new tab'}
        </span>
      </div>
    );
  };

  const autosaveAction = (
    autosaveData,
    validated,
    invalidQuestiosns,
    newOriginalData
  ) => {
    return isMultiForm(formGuideName)
      ? autoSaveAllForm(
          originalMultiSurveyForm?.performanceAreas,
          invalidQuestiosns,
          newOriginalData
        )
      : autoSaveSingleQuestion(autosaveData, validated, newOriginalData, true);
  };

  const autoSaveAllForm = (
    performanceAreas,
    invalidQuestiosns,
    newOriginalData
  ) => {
    return new Promise((resolve, reject) => {
      var accordions = performanceAreas.map(pa => {
        var data = {};
        var valid = true;
        if (invalidQuestiosns.title === pa.accordionTitle) {
          for (var key in pa.uiSchema) {
            data[key] = newOriginalData.hasOwnProperty(key)
              ? newOriginalData[key]
              : {};
            if (valid && invalidQuestiosns.ids.includes(key)) {
              valid = false;
            }
          }
        } else {
          var accordion = accordionsData?.find(
            x => x.title === pa.accordionTitle
          );
          if (accordion) {
            return accordion;
          }
          // default values for not found in memory and no changes
          data = {};
          valid = false;
        }

        return {
          title: pa.accordionTitle,
          data: data,
          isValidated: valid,
          questionId: 'accordionKey',
        };
      });

      const input = {
        reviewId: id,
        amsFormId: '',
        dcSessionId: dcSessionId,
        answerKey: 'accordionKey',
        questionCode: 'accordionKey',
        questionId: 'accordionKey',
        evidenceSource: [formGuideName],
        version: 0,
        surveyData: {
          accordionKey: {
            contentArea: contentArea,
            performanceArea: performanceArea,
            accordions: accordions,
          },
        },
      };

      dispatch(saveQuestionFormData(input))
        .then(e => {
          const answersInputObj = {
            filters: {
              reviewId: id,
              questionIds:
                (consolidatedQuestions && consolidatedQuestions.questionIds) ||
                (multiSurveyForm && multiSurveyForm.questionIds) ||
                data.questionIds,
              evidenceSource: [formGuideName] || null,
            },
          };

          setAccordionsData(accordions);
          var e = originalConsolidatedAnswers;
          if (e?.consolidatedAnswers?.surveyData?.accordionKey) {
            e.consolidatedAnswers.surveyData.accordionKey.accordions = accordions;
            setOriginalConsolidatedAnswers(e);
          }
          dispatch(fetchDataCollectionDetails(answersInputObj)).then(e => {
            setDataCollectionDtls(e);
          });
          if (formGuideName != INCIDENT_DATA) {
            dispatch(
              fetchFindingDetails(
                {
                  reviewId: selectedReview.reviewId,
                },
                false,
                {
                  reviewType: (selectedReview || {}).reviewType,
                }
              )
            );
          }
        })
        .catch(error => {
          console.log(error);
          reject(error); // Reject the promise if there's an error
        });
    });
  };

  const autoSaveSingleQuestion = (
    autosaveData,
    validated,
    newOriginalData,
    isActive
  ) => {
    return new Promise((resolve, reject) => {
      const keys = Object.keys(autosaveData);
      const key = keys[0];
      // Validate the number of keys
      if (keys.length > 1) {
        console.log('Invalid number of keys');
        return;
      }

      // Early exit if no key found
      if (!key || keys.length > 1) return;

      // Find the relevant performance area from the data
      const performanceArea = data.performanceAreas.find(e => {
        const formSchema = e.formSchema;
        if (formSchema?.properties[key] != null) {
          return e;
        }
      });

      const questionObj = performanceArea?.formSchema?.properties[key];

      // Step 1: Handle Evidence Source & Citations
      const evidence_source =
        questionObj && questionObj.properties?.evidence_source?.value;
      const isFreeForm =
        evidence_source?.includes(ADDITIONAL_CITATION) &&
        'Additional' !== data?.filters?.collectDataType;

      let citations = isFreeForm
        ? [newOriginalData[key]?.CitationPicker]
        : questionObj?.properties?.citations?.value;

      // Step 2: Handle content area and performance area
      let content_area = isFreeForm
        ? newOriginalData[key]?.CAPA?.content_area
        : evidence_source?.includes(ADDITIONAL_CITATION) &&
          'Additional' === data?.filters?.collectDataType &&
          !isEmpty(guideDetails?.contentArea)
        ? guideDetails?.contentArea
        : questionObj && questionObj.properties?.content_area?.value;

      let performance_area = isFreeForm
        ? newOriginalData[key]?.CAPA?.performance_area
        : ('fotp' === data?.filters?.collectDataType &&
            'fotp' === guideDetails?.collectedDataType) ||
          (evidence_source?.includes(ADDITIONAL_CITATION) &&
            'Additional' === data?.filters?.collectDataType &&
            !isEmpty(guideDetails?.performanceArea))
        ? guideDetails?.performanceArea
        : questionObj && questionObj.properties?.performance_area?.value;

      const amsFormId = questionObj && questionObj.amsFormId;

      const finding_category =
        questionObj && questionObj.properties?.finding_category?.value;

      const questionId = isFreeForm
        ? data.questionIds[0]
        : questionObj && questionObj.questionId;

      // Step 3: Handle No and checklist logic
      if (isFreeForm) {
        if (!newOriginalData[key].no_checkbox_list)
          newOriginalData[key]['no_checkbox_list'] = [];
        if (!newOriginalData[key].tq_answer)
          newOriginalData[key]['tq_answer'] = 'No';
      }

      // Step 4: Show alert if the question has been changed already
      if (
        changedQuestionIds.length > 0 &&
        changedQuestionIds.includes(questionId)
      ) {
        setShowQuestionAlert(true);
        return;
      }

      // Ensure form data is accurate
      autosaveData[key] = { ...autosaveData[key], ...newOriginalData[key] };
      delete autosaveData[key]['editDateTime'];

      // Populate autosaveData with necessary fields
      if (citations) autosaveData[key].citations = citations;
      if (content_area) autosaveData[key].contentArea = content_area;
      if (performance_area)
        autosaveData[key].performanceArea = performance_area;
      if (amsFormId) autosaveData[key].amsFormId = amsFormId;
      if (evidence_source) autosaveData[key].evidence_source = evidence_source;
      if (key in autosaveData) {
        autosaveData[key].isValidated = validated;
      }
      if (questionId) autosaveData[key].questionId = questionId;

      if (finding_category) autosaveData[key].category = finding_category;

      // Step 5: Handling 'No' checkbox list
      if (
        questionObj?.properties?.fiscal_year?.value > 2024 &&
        questionObj?.properties?.hasOwnProperty('no_checkbox_list') &&
        questionObj?.properties?.no_checkbox_list?.readOnly &&
        'No' != autosaveData[key]['tq_answer']
      ) {
        delete autosaveData[key]['no_checkbox_list'];
      }

      // Step 6: Handling 'Not Applicable checkbox' checkbox list
      if (
        questionObj?.properties?.hasOwnProperty('na_checkbox') &&
        true == autosaveData[key]['na_checkbox']
      ) {
        autosaveData[key]['checklist'] = [];
      }

      // Step 7: Ensure empty objects are removed and Findings are linked if needed
      let modifiedData = removeEmptyObjects(autosaveData);
      if (
        formGuideName == 'Findings Outside the Protocol' &&
        fotpFindings &&
        fotpFindings.length > 0
      ) {
        modifiedData = addFindingId(fotpFindings, autosaveData, key);
      }
      console.log(formGuideName);
      if (formGuideName == 'Eligibility File Review - Individual Data') {
        modifiedData = addSubmittedByAndDate(autosaveData, currentUser);
      }

      const input = {
        reviewId: id,
        amsFormId: amsFormId,
        questionCode: key,
        questionId: questionId,
        dcSessionId: dcSessionId,
        isValidated: validated,
        evidenceSource: evidence_source,
        category: finding_category,
        surveyData: modifiedData,
      };

      // Step 8: If question exists, include its ID in the payload
      if (questionObj && questionObj._id) {
        input._id = questionObj._id;
      }

      let changeURL = false;
      if (isFreeForm) {
        input['isActive'] = isActive;

        input['citation'] =
          citations && citations.length > 0 ? citations[0] : '';
        input.citationType =
          (autosaveData[key] && autosaveData[key].submissionId) ||
          (questionObj && questionObj._id)
            ? 'modified'
            : 'create';

        if (
          input.citationType === 'create' ||
          (input.citationType === 'modified' &&
            originalData[key].CitationPicker !== input.citation)
        ) {
          let citationExists = citationOptions.some(
            citation => citation.key === input.citation
          );

          if (citationExists) {
            return;
          }
          changeURL = true;
        }
      }
      if (attributes && attributes.center) {
        input.centerName = attributes.center.centerName;
        input.centerId = attributes.center.centerId;
      }
      if (attributes && attributes.classroom) {
        input.classRoomName = attributes.classroom.classRoomName;
        input.classSampleId = attributes.classroom.classSampleId;
      }

      // Step 9: Save the form data via dispatch
      dispatch(saveQuestionFormData(input))
        .then(async e => {
          questionObj._id = e._id;
          setAnswerId(e._id);
          setIsCitationActive(e.isActive);
          const answersInputObj = {
            filters: {
              reviewId: id,
              questionIds: isFreeForm
                ? additionalCitationForm.questionIds
                : (consolidatedQuestions &&
                    consolidatedQuestions.questionIds) ||
                  (multiSurveyForm && multiSurveyForm.questionIds) ||
                  data.questionIds,
            },
          };
          if (
            selectedReview.reviewType === 'Follow-up' &&
            (citationOptions.length > 0 || isFreeForm)
          ) {
            answersInputObj.filters.citation =
              citations && citations.length > 0 ? citations[0] : '';
          } else {
            answersInputObj.filters.evidenceSource = [formGuideName] || null;
          }
          if (formGuideName == 'Findings Outside the Protocol') {
            setFotpFindings(e.surveyData[key].findings);
          }
          if (
            answersInputObj.filters.evidenceSource == CENTER_EXPLORATION ||
            answersInputObj.filters.evidenceSource == CLASS_ROOM_EXPLORATION
          ) {
            answersInputObj.filters.centerId = attributes.center.centerId;
            answersInputObj.filters.centerName = attributes.center.centerName;
          }
          if (
            answersInputObj.filters.evidenceSource == CLASS_ROOM_EXPLORATION
          ) {
            answersInputObj.filters.classRoomName =
              attributes.classroom.classRoomName;
            answersInputObj.filters.classSampleId =
              attributes.classroom.classSampleId;
          }
          if (
            answersInputObj.filters.evidenceSource == CENTER_EXPLORATION ||
            answersInputObj.filters.evidenceSource == CLASS_ROOM_EXPLORATION
          ) {
            dispatch(fetchSurveyCentersAndClassrooms({ reviewId: id }))
              .then(e => {
                let centerObj = e.surveyCentersClassrooms.data.filter(item => {
                  if (
                    item.centerName &&
                    item.centerName == attributes.center.centerName
                  ) {
                    return item;
                  }
                });
                setAttributes({ ...attributes, center: centerObj[0] });
                if (
                  answersInputObj.filters.evidenceSource ==
                  CLASS_ROOM_EXPLORATION
                ) {
                  setClassrooms(centerObj[0].classRoom);
                  let classRoomObj = centerObj[0].classRoom.filter(item => {
                    if (
                      item.classRoomName &&
                      item.classRoomName == attributes.classroom.classRoomName
                    ) {
                      return item;
                    }
                  });
                  setAttributes({ ...attributes, classroom: classRoomObj[0] });
                }
              })
              .catch(error => {
                setCenterLoading(false);
              });
          }
          dispatch(fetchDataCollectionDetails(answersInputObj)).then(
            async e => {
              setDataCollectionDtls(e);
              if (
                formGuideName == 'ERSEA Eligibility File Review' &&
                ((dataCollectionDtls.totalQuestions > 1 &&
                  e.totalQuestions == 1) ||
                  (dataCollectionDtls.totalQuestions == 1 &&
                    e.totalQuestions > 1) ||
                  (dataCollectionDtls.totalQuestions > 1 &&
                    e.totalQuestions < 1) ||
                  (dataCollectionDtls.totalQuestions < 1 &&
                    e.totalQuestions > 1))
              ) {
                await dispatch(
                  fetchConsolidatedQuestions({
                    filters: {
                      reviewId: id,
                      questionStatus: '',
                      displayAccordions: [],
                      attributes: {
                        evidenceSource:
                          attributes.evidenceSource || formGuideName || null,
                      },
                    },
                  })
                ).then(async e => {
                  setData(e.consolidatedQuestions);
                  const erseaAnswersInputObj = {
                    filters: {
                      reviewId: id,
                      questionIds: e.consolidatedQuestions.questionIds,
                      evidenceSource: [formGuideName],
                    },
                  };
                  await dispatch(
                    fetchConsolidatedAnswers(erseaAnswersInputObj)
                  ).then(e => {
                    setConsolidatedAnswersData(e);
                  });
                  await dispatch(
                    fetchDataCollectionDetails(erseaAnswersInputObj)
                  ).then(e => {
                    setDataCollectionDtls(e);
                  });
                });
              }
            }
          );
          if (
            answersInputObj.filters.evidenceSource == 'Center Exploration' ||
            answersInputObj.filters.evidenceSource == 'Classroom Exploration'
          ) {
            setCenterLoading(true);
            dispatch(fetchSurveyCentersAndClassrooms({ reviewId: id }))
              .then(e => {
                setCenterLoading(false);
                setCentersClassrooms(e.surveyCentersClassrooms.data);
                let centerObj = e.surveyCentersClassrooms.data.filter(item => {
                  if (
                    item.centerName &&
                    item.centerName == attributes.center.centerName
                  ) {
                    return item;
                  }
                });
                setAttributes({ ...attributes, center: centerObj[0] });
                if (
                  answersInputObj.filters.evidenceSource ==
                  'Classroom Exploration'
                ) {
                  let classRoomObj = centerObj.classRoom.filter(item => {
                    if (
                      item.classRoomName &&
                      item.classRoomName == attributes.classroom.classRoomName
                    ) {
                      return item;
                    }
                  });
                  setAttributes({ ...attributes, classroom: classRoomObj[0] });
                }
              })
              .catch(error => {
                setCenterLoading(false);
              });
          }
          if (isFreeForm) {
            let followupRequestInput = { reviewId: id };
            const citation =
              citations && citations.length > 0 ? citations[0] : '';
            dispatch(fetchFollowupSurveyCitations(followupRequestInput)).then(
              data => {
                const citationOptionsUpdate =
                  !_.isEmpty(data) && !_.isEmpty(data.followupCitations)
                    ? data.followupCitations.map(citation => ({
                        key: citation.name,
                        text:
                          citation.name +
                          (!citation.isPreliminaryFinding && citation.isActive
                            ? ' - New'
                            : !citation.isPreliminaryFinding &&
                              !citation.isActive
                            ? ' - Removed'
                            : ''),
                        value: citation.name,
                      }))
                    : [];
                setCitationOptions(citationOptionsUpdate);
                setAttributes({ ...attributes, citation: citation });
                if (changeURL)
                  browserHistory.push(
                    `/review/fy${selectedReview?.fiscalYear
                      ?.toString()
                      .slice(-2)}/${
                      selectedReview?.reviewId
                    }/survey/${selectedReview &&
                      reviewTypeFormat(
                        selectedReview?.reviewType
                      )}?citation=${encodeURIComponent(input.citation)}`
                  );
              }
            );
          }
          dispatch(
            fetchFindingDetails(
              {
                reviewId: selectedReview.reviewId,
              },
              false,
              {
                reviewType: (selectedReview || {}).reviewType,
              },
              { isNewForms: selectedReview.isNewForms }
            )
          );
        })
        .catch(error => {
          console.log(error);
          reject(error); // Reject the promise if there's an error
        });
    });
  };

  const getEditTime = (data, keyPattern) => {
    const targetKey = Object.keys(data).find(key => key.includes(keyPattern)); // find the key that contains the pattern
    return data[targetKey]?.editedTime;
  };

  const onChange = formData => {
    setFormData(formData);
    setOriginalData(formData);
  };

  const handleAllUploadsComplete = async extraInfo => {
    let answersInputObj = {};

    if (selectedReview.reviewType === 'Follow-up' && attributes.citation) {
      let citation;
      if (extraInfo && extraInfo.hasOwnProperty('citation'))
        citation = extraInfo.citation;

      answersInputObj = {
        filters: {
          reviewId: id,
          questionIds: data.questionIds,
          parentReviewId: data?.filters?.parentReviewId,
          citation: citation || props.location.query.citation,
        },
      };
    } else {
      answersInputObj = {
        filters: {
          reviewId: id,
          questionIds: data.questionIds,
          evidenceSource:
            [data?.filters?.attributes?.evidenceSource] || [
              attributes?.evidenceSource,
            ] ||
            null,
        },
      };
    }
    if (attributes && attributes.center) {
      answersInputObj.filters.centerName = attributes.center.centerName;
      answersInputObj.filters.centerId = attributes.center.centerId;
    }
    if (attributes && attributes.classroom) {
      answersInputObj.filters.classRoomName =
        attributes.classroom.classRoomName;
      answersInputObj.filters.classSampleId =
        attributes.classroom.classSampleId;
    }
    await asyncFetchConsolidatAnswersDataAndSet(
      answersInputObj,
      data,
      'SUBMIT'
    );
  };

  const adjustSchema = schema => {
    if (!schema) return schema;

    var CAPA = getItem(schema, 'CAPA');
    if (
      selectedReview.reviewType &&
      data?.filters?.attributes?.evidenceSource !== INCIDENT_DATA &&
      data?.filters?.attributes?.evidenceSource !== ADDITIONAL_CITATION &&
      'Content Area Performance Area' !== CAPA?.title
    ) {
      var evidence_source = getItem(schema, 'evidence_source');
      var performance_area = getItem(schema, 'performance_area');
      if (evidence_source && performance_area) {
        evidence_source['reviewType'] = selectedReview.reviewType;
        evidence_source['performanceArea'] = performance_area.value;
        performance_area['reviewType'] = selectedReview.reviewType;
        performance_area['evidenceSource'] = evidence_source.value;
      }
    }

    setDefaultDate(schema);

    var checkboxList = getItem(schema, 'no_checkbox_list');

    if (!checkboxList) checkboxList = getItem(schema, 'yes_checkbox_list');
    if (!checkboxList) checkboxList = getItem(schema, 'checklist');
    if (!checkboxList) return schema;

    if (
      checkboxList.showOther &&
      checkboxList.items &&
      checkboxList.items.enum &&
      checkboxList.items.enum.length === 0
    ) {
      checkboxList.items.enum.push(Constants.OTHER_KEY);
      if (checkboxList.items.enumNames)
        checkboxList.items.enumNames.push(Constants.OTHER_KEY);
    }
    return schema;
  };

  const adjustUISchema = (schema, uiSchema) => {
    if (!schema) return schema;
    if (!uiSchema) return uiSchema;
    if ('Follow-up' === selectedReview?.reviewType) {
      var tq_answer = getItem(schema, 'tq_answer');
      if (
        tq_answer &&
        '<div>Were there findings outside the protocol?</div>' ===
          tq_answer?.description
      ) {
        for (let key in uiSchema) {
          if (uiSchema[key].tq_answer) {
            uiSchema[key].tq_answer['ui:options'] = {
              label: false,
            };
          }
        }
      }
    }
    return uiSchema;
  };

  /* Helper function to get today's date in YYYY-MM-DD format. */
  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  /* Recursive function that traverses the schema 
       and sets the default value for any date properties.
    */
  const setDefaultDate = schema => {
    const formattedDate = getFormattedDate();

    const recurse = obj => {
      if (typeof obj !== 'object' || obj === null) {
        return;
      }

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (obj[key].type === 'string' && obj[key].format === 'date') {
            obj[key].default = formattedDate;
          }
          if (typeof obj[key] === 'object') {
            recurse(obj[key]);
          }
        }
      }
    };

    recurse(schema);
    return schema;
  };

  const getItem = (obj, prop) => {
    for (var k in obj) {
      if (k === prop) {
        return obj[k];
      } else if (typeof obj[k] === 'object' && obj[k] !== null) {
        var val = getItem(obj[k], prop);
        if (val) return val;
      }
    }
    return null;
  };

  const renderSurvey = (
    performanceArea,
    disabledForm,
    containsOriginalResponse = false
  ) => {
    if (performanceArea.accordionTitle == 'Original Narrative')
      return <OriginalNarrative performanceArea={performanceArea} />;
    const schema =
      performanceArea &&
      performanceArea.formSchema &&
      performanceArea.formSchema.properties;

    const tempschema = {
      dependencies: {},
      properties: {},
      required: [],
      title: '',
      type: 'object',
    };

    let keys = Object.keys(schema);
    keys.forEach(e => {
      tempschema.properties[e] = adjustSchema(schema[e]);
    });
    delete performanceArea.uiSchema['ui:order'];
    adjustUISchema(schema, performanceArea.uiSchema);
    const customFieldsWithProps = {
      attachmentField: props => (
        <AttachmentField
          onAllUploadsComplete={handleAllUploadsComplete}
          attributes={attributes}
          {...props}
        />
      ),
      wysiwyg: WysiwygField,
    };

    if (!_.isEmpty(schema))
      return (
        <div ref={amsTaskRef} tabIndex="-1">
          <fieldset disabled={disabledForm}>
            {loading && (
              <Dimmer active inverted>
                <Loader content="Loading..." />
              </Dimmer>
            )}
            <div id="eas-form" className={disabledForm ? 'eas-readonly' : ''}>
              <EASForm
                schema={tempschema}
                uiSchema={performanceArea.uiSchema}
                readOnly={disabledForm}
                containsOriginalResponse={containsOriginalResponse}
                widgets={customWidgets}
                children={true}
                ref={viewForm}
                showErrorList={false}
                formData={formData}
                onChangeCallback={onChange}
                originalData={originalData}
                onAutoSave={autosaveAction}
                ArrayFieldTemplate={EASArrayFieldTemplate}
                fields={customFieldsWithProps}
                transformErrors={transformErrors}
                title={performanceArea.accordionTitle}
                formContext={{
                  reviewId: id,
                  surveyCitations: citationOptions,
                  reviewType: selectedReview.reviewType,
                  fiscalYear: selectedReview.fiscalYear,
                  guideDetails: guideDetails,
                  attributes,
                  evidenceSource: data?.filters?.attributes?.evidenceSource,
                  fiscalYear: selectedReview?.fiscalYear,
                  grantees: selectedReview.grantees,
                  grantInfo: selectedReview.grantInfo,
                  questionIds: data?.filters?.activeQuestionIds,
                  formData: formData,
                  validateQuestion: validateQuestion,
                  handleFileDeleteComplete: handleAllUploadsComplete,
                  answerId:
                    answerId != null
                      ? answerId
                      : consolidatedAnswers?.answerIds != null
                      ? consolidatedAnswers?.answerIds[0]
                      : '',
                }}
                isMultiForm={isMultiForm(formGuideName)}
              ></EASForm>
            </div>
          </fieldset>
        </div>
      );
  };

  const validateQuestion = (questionId, formData, schema) => {
    var valid = viewForm.current?.validateQuestion(
      questionId,
      formData,
      schema
    );
    return valid;
  };

  const renderForm = () => {
    const versionFilter = consolidatedAnswers?.filterObj?.versionFilter;
    const titleDetermine =
      formGuideName != 'Findings Outside the Protocol' &&
      formGuideName != ELIGIBILITY_FILE_REVIEW_INDIVIDUAL_DATA
        ? false
        : true;
    const disabledForm =
      (currentUser.roles.length == 1 &&
        currentUser.roles.includes('FiscalSpecialist')) ||
      (isVersionHistoryClicked &&
        versionFilter &&
        versionFilter.version !==
          consolidatedAnswers.versionHistory.length - 1) ||
      (selectedReview && selectedReview.reportStage === 'finalized') ||
      selectedReview.readOnly ||
      !isCitationActive ||
      ((selectedReview || {}).reviewAccessLevel &&
        (selectedReview || {}).reviewAccessLevel.isReviewAccessible === false &&
        (selectedReview || {}).reviewStatus !== 'Report Signed' &&
        (selectedReview || {}).reviewStatus !== 'Shipped');

    if (data && !_.isEmpty(data)) {
      const performanceAreas = data?.performanceAreas;
      const accordionForm = performanceAreas?.map((e, idx) => {
        let paDisabled =
          e.accordionTitle === 'Original Data Collection Responses';
        return (
          <React.Fragment key={idx}>
            <fieldset
              disabled={
                'Original Data Collection Responses' === e.accordionTitle
              }
              className={
                'Original Data Collection Responses' === e.accordionTitle
                  ? 'eas-readonly'
                  : ''
              }
            >
              <div>
                {renderAccordions(
                  idx,
                  e,
                  disabledForm,
                  paDisabled,
                  titleDetermine
                )}
              </div>
            </fieldset>
          </React.Fragment>
        );
      });
      return (
        <div>
          {accordionForm}
          {isMultiForm(formGuideName) && !disabledForm ? (
            <div>
              <br />
              <Form.Field>
                <Button
                  onClick={() => {
                    getMultiForms(false);
                  }}
                  primary
                  content={`+ Add ${
                    attributes.evidenceSource == INCIDENT_DATA
                      ? 'Incident'
                      : 'Item'
                  }`}
                />
              </Form.Field>
              <br />
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    }
  };

  const isMultiForm = guideName => {
    return guideName === INCIDENT_DATA;
  };

  const deleteAccordion = pa => {
    const index = originalMultiSurveyForm.performanceAreas.indexOf(pa);
    setActiveIndexes(activeIndexes.map(x => (x > index ? x - 1 : x)));
    const originalAccordions = originalMultiSurveyForm.performanceAreas.filter(
      x => x.accordionTitle != pa.accordionTitle
    );
    originalMultiSurveyForm.performanceAreas = originalAccordions;
    setOriginalMultiSurveyForm(originalMultiSurveyForm);

    const accordions = data.performanceAreas.filter(
      x => x.accordionTitle != pa.accordionTitle
    );
    data.performanceAreas = accordions;
    setData(data);
    autoSaveAllForm(originalAccordions, originalData);
  };

  const getMultiForms = (initialLoad, guideName, rowVersion, action) => {
    setLoading(true);

    let requestInput = {
      filters: {
        reviewId: id,
        questionStatus: '',
        displayAccordions: [],
        attributes: {
          evidenceSource: attributes.evidenceSource || guideName || null,
        },
      },
    };

    if (action != 'LOAD' && (rowVersion?.dcSessionId || dcSessionId)) {
      requestInput.filters['dcSessionId'] =
        rowVersion?.dcSessionId || dcSessionId;
    }
    if (!initialLoad) {
      var x = 0;
      var indexes =
        originalMultiSurveyForm.performanceAreas?.map(pa => {
          let index = Number(pa.accordionTitle.split(/[, ]+/).pop());
          x = index;
          return index;
        }) || [];
      indexes.push(x + 1);
      if (originalMultiSurveyForm.performanceAreas) {
        activeIndexes.push(originalMultiSurveyForm.performanceAreas?.length);
      }
      requestInput.filters['displayAccordions'] = indexes;
    }

    dispatch(fetchMultiSurveyForm(requestInput))
      .then(e => {
        const data = e.multiSurveyForm;
        setLoading(false);
        if (!rowVersion) {
          setOriginalMultiSurveyForm(data);
        }
        setData(data);
        setDcSessionId(data.dcSessionId);

        if (data.performanceAreas?.length > 0) {
          var pa = data.performanceAreas[0];
          setPerformanceArea(pa?.formSchema?.performanceArea);
          var questionIds = Object.keys(pa?.formSchema?.properties);
          if (questionIds?.length > 0) {
            setContentArea(
              pa?.formSchema?.properties[questionIds[0]]?.properties
                ?.content_area?.value
            );
          }
        }
        const answersInputObj = {
          filters: {
            reviewId: id,
            questionIds: data.questionIds,
            evidenceSource:
              [data?.filters?.attributes?.evidenceSource] || [
                attributes?.evidenceSource,
              ] ||
              null,
          },
        };
        if (rowVersion) {
          answersInputObj.filters['versionFilter'] = {
            dcSessionId: rowVersion.dcSessionId,
            editedTime: rowVersion.editedTime,
            version: rowVersion.version,
          };
        }
        fetchConsolidatAnswersDataAndSet(
          answersInputObj,
          data,
          action || 'LOAD'
        );
      })
      .catch(error => {
        setResponseErrors([...responseErrors, error.response]);
      });
  };

  const handleAddCitation = () => {
    let requestInput = {
      filters: {
        reviewId: id,
        reviewType: selectedReview.reviewType,
        attributes: {
          evidenceSource: 'Additional Citation',
        },
      },
    };
    setIsCitationActive(true);
    setAttributes({ ...attributes, citation: '' });
    dispatch(fetchAdditionalCitationForm(requestInput))
      .then(data => {
        setDcSessionId(data.additionalCitationForm.dcSessionId);
        setData(data.additionalCitationForm);
        dispatch(clearConsolidatedAnswers());
        setDataCollectionDtls(false);
        setFormData({});
      })
      .catch(error => {
        setResponseErrors([...responseErrors, error.response]);
      });
  };

  const renderVersionHistoryTable = () => {
    const dataHistory = consolidatedAnswers.versionHistory;
    const renderVersionTable =
      (attributes.evidenceSource == CENTER_EXPLORATION &&
        attributes.center == null) ||
      (attributes.evidenceSource == CLASS_ROOM_EXPLORATION &&
        attributes.classroom == null)
        ? false
        : true;

    if (renderVersionTable) {
      const linkVersion = (cell, row) => {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href=""
            onClick={e => {
              e.preventDefault();
              setAttributes({ ...attributes, questionStatus: '' });
              setIsVersionHistoryClicked(true);
              if (
                consolidatedQuestions &&
                INCIDENT_DATA !== attributes.evidenceSource &&
                ADDITIONAL_CITATION !==
                  data?.filters?.attributes?.evidenceSource
              ) {
                setData(consolidatedQuestions);
                setActiveIndexes([
                  ...Array(
                    consolidatedQuestions?.performanceAreas.length
                  ).keys(),
                ]);
                let answersInputObj = {
                  filters: {
                    reviewId: id,
                    questionIds:
                      consolidatedQuestions?.questionIds ||
                      multiSurveyForm?.questionIds ||
                      [],
                    versionFilter: {
                      dcSessionId: row.dcSessionId,
                      editedTime: row.editedTime,
                      version: row.version,
                    },
                  },
                };

                if (
                  selectedReview.reviewType === 'Follow-up' &&
                  citationOptions.length > 0
                ) {
                  answersInputObj.filters.citation =
                    attributes.citation || null;
                  answersInputObj.filters.parentReviewId =
                    consolidatedQuestions?.filters?.parentReviewId;
                  answersInputObj.filters.reviewType = 'Follow-up';
                } else {
                  answersInputObj.filters.evidenceSource = attributes.evidenceSource
                    ? [attributes.evidenceSource]
                    : null;
                }

                fetchConsolidatAnswersDataAndSet(
                  answersInputObj,
                  consolidatedQuestions || multiSurveyForm,
                  'VERSION_HISTORY'
                );
              } else if (multiSurveyForm) {
                if (multiSurveyForm?.performanceAreas) {
                  setActiveIndexes([
                    ...Array(multiSurveyForm?.performanceAreas.length).keys(),
                  ]);
                }
                getMultiForms(true, formGuideName, row, 'VERSION_HISTORY');
              } else if (
                additionalCitationForm &&
                data?.filters?.attributes?.evidenceSource == ADDITIONAL_CITATION
              ) {
                if (additionalCitationForm?.performanceAreas) {
                  setActiveIndexes([
                    ...Array(
                      additionalCitationForm?.performanceAreas.length
                    ).keys(),
                  ]);
                }
                fetchConsolidatAnswersDataAndSet(
                  {
                    filters: {
                      reviewId: id,
                      citation: attributes.citation || null,
                      questionIds: additionalCitationForm?.questionIds || [],
                      reviewType: 'Follow-up',
                      versionFilter: {
                        dcSessionId: row.dcSessionId,
                        editedTime: row.editedTime,
                        version: row.version,
                      },
                    },
                  },
                  additionalCitationForm,
                  'VERSION_HISTORY'
                );
              }
            }}
          >
            {cell}
          </a>
        );
      };

      const formatDate = (cell, row) =>
        AmsDateFormatters.formatDateTime(row.editedTime);

      const columns = [
        {
          dataField: 'version',
          text: 'Version',
          sort: true,
          formatter: linkVersion,
          style: { whiteSpace: 'normal' },
        },
        {
          dataField: 'editedByFullName',
          text: 'Edited By',
          sort: true,
          style: { whiteSpace: 'normal' },
        },
        {
          dataField: 'editedTime',
          sort: false,
          formatter: formatDate,
          style: { whiteSpace: 'normal' },
          text: 'Edited Time',
        },
      ];

      return (
        <AmsTable
          remote={false}
          basic
          total={dataHistory && dataHistory.length}
          columns={columns}
          keyField="version"
          ref={amsTableRef}
          data={dataHistory}
        />
      );
    }
  };

  const handleDataCollectionLock = () => {
    setDisableForm(true);
  };

  const showConfirmationAlert = () => {
    return (
      <AmsAlert
        show={showAlert}
        title="Are you sure you want to lock data collection?"
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Lock Data Collection"
        cancelButtonText={'Cancel'}
        confirmButtonColor={'#DD6B55'}
        text={`Once you confirm this action, you will not be able to undo it. Only lock data collection when you are sure no other reviewers need to review the collection form.`}
        onConfirm={() => {
          handleDataCollectionLock();
          setShowAlert(false);
        }}
        onCancel={() => {
          setShowAlert(false);
          setDisableForm(false);
        }}
      />
    );
  };

  const showDeleteConfirmationAlert = () => {
    return (
      <AmsAlert
        show={showDeleteAlert}
        title={`Are you sure you want to delete '${accordionToDelete?.accordionTitle}'?`}
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Delete"
        cancelButtonText={'Cancel'}
        confirmButtonColor={'#DD6B55'}
        text={`Once you confirm this action, you will not be able to undo it.`}
        onConfirm={() => {
          deleteAccordion(accordionToDelete);
          setShowDeleteAlert(false);
        }}
        onCancel={() => {
          setShowDeleteAlert(false);
        }}
      />
    );
  };

  const showChangedQuestionAlert = () => {
    return (
      <AmsAlert
        show={showQuestionAlert}
        title="There is another person currently making changes"
        type="warning"
        showConfirm
        confirmButtonText="Ok"
        confirmButtonColor={'#DD6B55'}
        text={`Changes can’t be made to this page while another person is making changes. Please try again in a few moments.`}
        onConfirm={() => {
          setShowQuestionAlert(false);
          window.location.reload();
        }}
      />
    );
  };

  const showRefreshFormAlert = () => {
    return (
      <AmsAlert
        show={showRefreshAlert}
        title="An updated version of this page is available"
        type="warning"
        showConfirm
        confirmButtonText="Ok"
        confirmButtonColor={'#DD6B55'}
        text={`This page has been updated with new changes. Please refresh this page to see the latest version.`}
        onConfirm={() => {
          setShowRefreshAlert(false);
          window.location.reload();
        }}
      />
    );
  };

  const showCitationDeleteConfirmationAlert = () => {
    return (
      <AmsAlert
        show={showCitationDeletedAlert}
        title="This citation has been removed"
        type="info"
        showConfirm
        confirmButtonText="OK"
        confirmButtonColor={'#FF0000'}
        onConfirm={() => {
          setShowCitationDeletedAlert(false);
        }}
      />
    );
  };

  const scrollTopOrViewCompletedQuestions = () => {
    const boldLabelStyle = {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '16px',
      letterSpacing: '0em',
      marginRight: '24px',
    };
    return (
      <Grid
        stackable={true}
        columns={2}
        centered={true}
        textAlign="center"
        verticalAlign="left"
      >
        <Grid.Row stackable columns="equal">
          <Grid.Column textAlign="left">
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                scrollToResults();
              }}
              style={{ color: '#005EA2', fontSize: '14px', fontWeight: 600 }}
            >
              <span>^</span> Return to top
            </a>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <span style={boldLabelStyle}>
              {dataCollectionDtls
                ? `${dataCollectionDtls.questionsCompleted ||
                    0} of ${dataCollectionDtls.totalQuestions || 0} ${
                    formGuideName === INCIDENT_DATA
                      ? 'incidents'
                      : formGuideName === FINDINGS_OUTSIDE_THE_PROTOCOL &&
                        dataCollectionDtls.fotpTqAnswer &&
                        dataCollectionDtls.fotpTqAnswer.toLowerCase() === 'yes'
                      ? 'findings'
                      : formGuideName ===
                          ELIGIBILITY_FILE_REVIEW_INDIVIDUAL_DATA &&
                        dataCollectionDtls.childFileTqAnswer &&
                        dataCollectionDtls.childFileTqAnswer.toLowerCase() ===
                          'yes'
                      ? 'eligibility files'
                      : 'questions'
                  }` + ' complete'
                : 'No questions available'}
            </span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const handleShowSideBar = showSideBarInd => {
    setShowSideBar(showSideBarInd);
  };

  const handleAccordionClick = index => {
    setActiveIndexes(prevIndexes => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter(item => item !== index);
      } else {
        return [...prevIndexes, index];
      }
    });
  };

  const renderAccordions = (
    idx,
    e,
    disabledForm,
    containsOriginal = false,
    titleDetermine
  ) => {
    const isAdditionalCitation =
      data?.filters?.attributes?.evidenceSource == ADDITIONAL_CITATION;
    const selectedCitation = isAdditionalCitation
      ? Object.values(formData)[0]?.CitationPicker
      : null;
    var formStyle = isAdditionalCitation
      ? {
          width: showSideBar ? '98%' : '125%',
          marginLeft: '-1px',
          marginTop: 0,
        }
      : {
          width: showSideBar ? '98%' : '125%',
          marginLeft: '-1px',
          marginTop: 0,
          borderWidth: '0px 1px 1px 1px',
          borderStyle: 'solid',
          borderColor: '#D9D9D9',
        };
    return (
      <div
        key={idx}
        style={formStyle}
        id={
          isAdditionalCitation
            ? 'eas-form-hide-accordion'
            : 'eas-form-accordion'
        }
      >
        <Accordion fluid>
          {!titleDetermine ? (
            <Accordion.Title
              active={activeIndexes.includes(idx)}
              index={idx}
              style={{ backgroundColor: '#DCDCDC', marginTop: 20 }}
              onClick={() => handleAccordionClick(idx)}
            >
              <h3 style={{ marginLeft: '10px' }}>
                {e.accordionTitle}
                {isMultiForm(formGuideName) && !disabledForm ? (
                  <Button
                    onClick={() => {
                      setAccordionToDelete(e);
                      setShowDeleteAlert(true);
                    }}
                    negative
                    floated="right"
                    content={`Delete`}
                    style={{ marginTop: '-7px' }}
                  />
                ) : null}
                <Icon
                  style={{ float: 'right' }}
                  name={activeIndexes.includes(idx) ? 'minus' : 'plus'}
                />
              </h3>
            </Accordion.Title>
          ) : (
            <Accordion.Title
              style={{ backgroundColor: '#DCDCDC', marginTop: 20 }}
            >
              <h3 style={{ marginLeft: '10px' }}>{formGuideName}</h3>
            </Accordion.Title>
          )}
          <Accordion.Content active={activeIndexes.includes(idx)}>
            {isAdditionalCitation ? (
              <h2>Add New Citation - {selectedCitation}</h2>
            ) : (
              <></>
            )}
            {renderSurvey(e, disabledForm, containsOriginal)}
            {isAdditionalCitation &&
            formData &&
            selectedCitation &&
            !isReportStages &&
            !selectedReview.readOnly &&
            isCitationActive ? (
              <Button
                onClick={() => {
                  setShowCitationRemoveAlert(true);
                }}
                negative
                content={`Remove Citation`}
              />
            ) : (
              <></>
            )}
          </Accordion.Content>
        </Accordion>
      </div>
    );
  };

  return (
    <ReviewLayout
      type={
        filters.reviewType === 'FA1-FR'
          ? 'Foundational Data Collection'
          : filters.reviewType === 'FA2-CR'
          ? 'Comprehensive Data Collection'
          : filters.reviewType === 'Follow-up'
          ? 'Follow-up Data Collection'
          : newFormReviewTypes.includes(filters.reviewType) &&
            selectedReview?.isNewForms
          ? 'Special Data Collection'
          : 'RAN Data Collection'
      }
      actionButtons={<></>}
      enableSideBar={true}
      handleShowSideBar={handleShowSideBar}
    >
      <fieldset disabled={disableForm}>
        <div className="survey-questions">
          {showResponseErrors()}
          {renderSurveyQuestionsFilters()}
          {renderForm()}
          {showConfirmationAlert()}
          {showChangedQuestionAlert()}
          {showDeleteConfirmationAlert()}
          {showRefreshFormAlert()}
          <ShowRemoveCitationDialog
            close={() => setShowCitationRemoveAlert(false)}
            save={() => {
              {
                autoSaveSingleQuestion(formData, true, formData, false);
              }
              {
                setShowCitationRemoveAlert(false);
              }
              {
                setShowCitationDeletedAlert(true);
              }
            }}
            showCitationRemoveAlert={showCitationRemoveAlert}
          />
          {showCitationDeleteConfirmationAlert()}
          <br />
          {dataCollectionDtls &&
            dataCollectionDtls.totalQuestions > 0 &&
            scrollTopOrViewCompletedQuestions()}
          {consolidatedAnswers &&
            consolidatedAnswers.versionHistory &&
            consolidatedAnswers.versionHistory.length > 0 &&
            renderVersionHistoryTable()}
        </div>
      </fieldset>
    </ReviewLayout>
  );
}

export default EASSurveyForm;
