import React from 'react';
import { Header } from 'semantic-ui-react';
import ReviewLayout from '../../utils/layout/ReviewLayout';

// Import style
import './assets/style.css';

import ReviewCenterClassroomManagement from './ReviewCenterClassroomManagement';

export default function ReviewsCenterClassroomManagement(props) {
  return (
    <ReviewLayout type={'Manage Centers/Classrooms'}>
      <Header as="h2" content="Manage Centers/Classrooms" />
      <ReviewCenterClassroomManagement
        props={props}
        key={`center-${Math.random().toString()}`}
      />
    </ReviewLayout>
  );
}
