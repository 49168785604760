import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import DOMPurify from 'dompurify';
import { formatCheckBoxString } from '../../Review/Findings/FindingsUtils';

export default function OriginalNarrative({ performanceArea }) {
  return (
    <div>
      <Grid stackable columns={2}>
        <Grid.Row
          style={{ marginLeft: '0px', marginTop: '15px', marginBottom: '10px' }}
        >
          <Grid.Column width={6}>
            <div
              style={{
                fontWeight: 400,
                fontSize: '16px',
              }}
            >
              <span
                role="heading"
                aria-level="3"
                className="field-title"
                aria-label="Citation Number"
              >
                <span
                  style={{
                    fontFamily: 'PublicSans-Bold',
                    fontWeight: 700,
                    fontSize: '16px',
                  }}
                >
                  Citation Number:{' '}
                </span>
              </span>

              {performanceArea.citation || ''}
            </div>
          </Grid.Column>
          <Grid.Column width={6}>
            <div
              style={{
                fontWeight: 400,
                fontSize: '16px',
              }}
            >
              <span
                role="heading"
                aria-level="4"
                className="field-title"
                aria-label="Finding Type"
              >
                <span
                  style={{
                    fontFamily: 'PublicSans-Bold',
                    fontWeight: 700,
                    fontSize: '16px',
                  }}
                >
                  Finding Type:{' '}
                </span>
              </span>
              {performanceArea.findingStatus || ''}
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ marginLeft: '0px' }}>
          <Grid.Column width={13}>
            <div
              role="heading"
              aria-level="4"
              className="field-title"
              aria-label="Citation Narrative"
              style={{ marginBottom: '10px', marginTop: '-20px' }}
            >
              <span style={{ fontFamily: 'PublicSans-Bold' }}>
                Citation Narrative{' '}
              </span>
            </div>
            <ul style={{ margin: '0px', paddingLeft: '16px' }}>
              {performanceArea?.checkBoxes?.map(x => (
                <li
                  className={
                    /^[0-9]_/.test(x) ? 'sub-checkbox' : 'custom-label'
                  }
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(formatCheckBoxString(x)),
                    }}
                  ></span>
                </li>
              ))}
            </ul>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ marginLeft: '0px' }}>
          <Grid.Column width={13}>
            <div
              role="heading"
              aria-level="4"
              className="field-title"
              aria-label="Citation Narrative"
              style={{ marginBottom: '10px', marginTop: '-5px' }}
            >
              <span style={{ fontFamily: 'PublicSans-Bold' }}>
                Additional Details From This Review Event{' '}
              </span>
            </div>
            <div
              style={{
                wordWrap: 'break-word',
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(performanceArea.analysisNarrative),
                }}
              ></span>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
