import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Header, List, Loader } from 'semantic-ui-react';
// import Avatar from 'react-avatar';
import { includes, isEmpty, some } from 'lodash';

// Import Components.
import TaskDetailModal from './TaskDetailModal';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';

//Import Actions
import { finalizeReview } from '../../actions/reviewActions';
import { fetchTaskList } from '../../actions/taskActions';

class TaskList extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      selectedTask: {},
      page: 1,
      limit: 8,
      sortName: 'dueDate',
      sortOrder: 'desc',
      loading: true,
    };

    this.handleTaskDetail = this.handleTaskDetail.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { fetchTaskList } = this.props;
    const { page, limit, sortName, sortOrder } = this.state;

    let input = {
      page,
      limit,
      sortName,
      sortOrder,
      filters: {},
    };

    this.setState({ loading: true });

    try {
      fetchTaskList(input);
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
    // fetchTaskList(input)
    //   .then(() => this.setState({ loading: false }))
    //   .catch(error => {
    //     this.setState({ loading: false });
    //     console.log(error);
    //     // this.props.onError(error);
    //   });
  };

  buildTaskList() {
    const { tasks } = this.props;

    if (!this.state.loading && isEmpty(tasks))
      return (
        <List.Item>
          <List.Content>
            <List.Description>No Tasks Found</List.Description>
          </List.Content>
        </List.Item>
      );

    if (isEmpty(tasks)) return null;

    const taskList = tasks.map((task, index) => {
      const grantee = {
        granteeName: !isEmpty(task.reviewGrantInfo[0].granteeName)
          ? task.reviewGrantInfo[0].granteeName
          : '',
        granteeId: !isEmpty(task.reviewGrantInfo[0].granteeId)
          ? task.reviewGrantInfo[0].granteeId
          : '',
      };
      const reviewStatus = task.taskStatus;
      const reviewerInfo = {
        submitterName: task.taskAssigneeName,
        submitterOid: task.taskAssigneeOid,
      };

      const roleFlag = some(
        [
          'DualCoder',
          'ReviewLead',
          'FieldOperationsManager',
          'AmsAdmi',
          'Analyst',
          'TechnicalWriter',
          'Editor',
          'HelpDesk',
        ],
        role => includes(this.props.currentUser.roles, role)
      );

      let params = {
        pathname: `/task/${task.fiscalYear}/${task.reviewId}/survey/class`,
        query: { ...reviewerInfo, ...grantee, roleFlag, reviewStatus },
      };

      const reviewId =
        task.taskDefKey === 'performDataCollection' &&
        (task.reviewType === 'CLASS' ||
          task.reviewType === 'AIAN-CLASS' ||
          task.reviewType === 'PR-CLASS' ||
          task.reviewType === 'VP-CLASS' ||
          task.reviewType === 'CLASS-Video' ||
          task.reviewType === 'AIAN CLASS Self-Observations' ||
          task.reviewType === 'CLASS AIAN Onsite' ||
          task.reviewType === 'CLASS AIAN Video') ? (
          <Link to={params}>{task.reviewId}</Link>
        ) : (
          <Link
            href={this.handleTaskDetail(task)}
            aria-label={`Go to ${task.reviewId} ${task.taskNameShort}`}
          >
            {task.reviewId}
          </Link>
        );

      const reviewStartDate = AmsDateFormatters.getMoment(
        reviewStartDate
      ).format();

      return (
        <List.Item key={task._id}>
          <List.Content>
            <List.Description
              content={`${task.reviewType} (${task.taskNameShort})` || ''}
            />
            <List.Header>
              {reviewId} &nbsp;
              {task.reviewGrantInfo ? task.reviewGrantInfo[0].granteeName : ''}
            </List.Header>
            <br />
          </List.Content>
        </List.Item>
      );
    });

    return <List>{taskList}</List>;
  }

  showMoreButton() {
    if (isEmpty(this.props.tasks)) return null;
    return (
      <div className="more">
        <Link className="more-button" to="/task">
          MORE
        </Link>
      </div>
    );
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleTaskDetail = (selectedTask, event) => {
    let path = '';
    const { reviewId, taskDefKey, hashContent } = selectedTask;

    if (!reviewId && !taskDefKey) return path;

    const defaultPath = `/review/${selectedTask.reviewId}`;

    path = defaultPath;

    switch (taskDefKey) {
      case 'performCenterClassroomEligibilityFilesDataCollection': {
        switch (selectedTask.reviewType) {
          case 'FA1-FR':
            path = `/task/${selectedTask.reviewId}/survey/fa1fr`;
            break;
          case 'FA2-CR':
            path = `/task/${selectedTask.reviewId}/survey/fa2cr`;
            break;
          case 'Special':
            path = `/task/${selectedTask.reviewId}/survey/special`;
            break;
          default:
            break;
        }
        break;
      }
      case 'performEvidenceSourceGuideDataCollection': {
        switch (selectedTask.reviewType) {
          case 'FA1-FR':
            path = `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
              selectedTask.reviewId
            }/survey/fa1fr`;
            break;
          case 'FA2-CR':
            path = `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
              selectedTask.reviewId
            }/survey/fa2cr`;
            break;
          case 'Special':
            path = `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
              selectedTask.reviewId
            }/survey/special`;
            break;
          default:
            break;
        }
        break;
      }
      case 'performDataCollection': {
        switch (selectedTask.reviewType) {
          case 'CLASS':
          case 'AIAN-CLASS':
          case 'PR-CLASS':
          case 'VP-CLASS':
          case 'CLASS-Video':
          case 'AIAN CLASS Self-Observations':
          case 'CLASS AIAN Onsite':
          case 'CLASS AIAN Video':
            path = `/task/${selectedTask.fiscalYear}/${selectedTask.reviewId}/survey/class`;
            break;
          case 'FA-1': {
            if (selectedTask.newFAFlowFlag) {
              path = `/task/${selectedTask.reviewId}/survey/fa1`;
            } else {
              //
            }
            break;
          }
          case 'FA1-FR':
            path = `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
              selectedTask.reviewId
            }/survey/fa1fr`;
            break;
          case 'FA-2': {
            if (selectedTask.newFAFlowFlag) {
              path = `/task/${selectedTask.reviewId}/survey/fa2`;
            } else {
              /*this.setState({
                modalIsOpen: true,
                selectedTask,
              });*/
            }
            break;
          }
          case 'FA2-CR':
            path = `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
              selectedTask.reviewId
            }/survey/fa2cr`;
            break;
          case 'Follow-up':
            path = `/task/${selectedTask.reviewId}/survey/follow-up`;
            break;
          case 'RAN':
            path = `/task/${selectedTask.reviewId}/survey/ran`;
            break;
          case 'Special':
            path = `/task/${selectedTask.reviewId}/survey/special`;
            break;
          case 'AIAN-DEF':
            path = `/task/${selectedTask.reviewId}/survey/aian-def`;
            break;
          case 'ERSEA':
            path = `/task/${selectedTask.reviewId}/survey/ersea`;
            break;

          default:
            break;
        }
        break;
      }
      case 'approveDataCollection': {
        path = `/review/${selectedTask.reviewId}`;
        break;
      }
      case 'performReportCreation': {
        path = `/reviews/${selectedTask.reviewId}`;
        break;
      }
      case 'reviewReportCollection': {
        if (selectedTask.newFAFlowFlag) {
          let fiscalYear = selectedTask.fiscalYear
            ? `fy` + selectedTask.fiscalYear.toString().slice(-2)
            : 'fy19';
          path = `/review/${fiscalYear}/${selectedTask.reviewId}`;
        } else {
          path = `/review/${selectedTask.reviewId}`;
        }
        break;
      }
      case 'provideReportComments': {
        if (hashContent) path = `/add-comment?key=${hashContent}`;
        break;
      }
      default:
        break;
    }
    return path;
  };

  showDetailModal() {
    const { selectedTask, modalIsOpen } = this.state;

    if (selectedTask && selectedTask._id)
      return (
        <TaskDetailModal
          task={selectedTask}
          modalIsOpen={modalIsOpen}
          closeModal={this.closeModal}
        />
      );
  }

  render() {
    return (
      <>
        <Header as="h2">Tasks</Header>
        <Loader
          active={this.state.loading}
          inline="centered"
          content="Loading tasks"
        />
        {this.buildTaskList()}
        {/* <Card fluid>
          <Card.Content>
            <Card.Header>
              <Header as="h2">Tasks</Header>
            </Card.Header>
          </Card.Content>
          <Card.Content>
            <Loader active={this.state.loading} inline="centered" content="Loading tasks" />
            {this.buildTaskList()}
          </Card.Content>
        </Card> */}
      </>
    );
  }
}

TaskList.propTypes = {
  currentUser: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
  fetchTaskList: PropTypes.func.isRequired,
  finalizeReview: PropTypes.func.isRequired,
};

TaskList.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  tasks: state.tasks.taskList,
  total: state.tasks.total,
});

export default connect(mapStateToProps, { fetchTaskList, finalizeReview })(
  TaskList
);
