import { isEmpty, pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  Icon,
  Accordion,
  Divider,
  Form,
  Modal,
  Button,
  Comment,
  Grid,
  Dimmer,
  Loader,
  Table,
} from 'semantic-ui-react';
import { preReviewSummary } from '../../../../actions/reviewActions';
import ReviewLayout from '../../../../utils/layout/ReviewLayout';
import { Header } from 'semantic-ui-react';
import AmsModal from '../../../../utils/AmsModal';
import AmsDateFormatters from '../../../../utils/AmsDateFormatters';
import { addNote } from '../../../../actions/noteActions';
import { Link } from 'react-router';
import DOMPurify from 'dompurify';

const PreReviewSummaryReportPage = ({ props, router, selectedReview }) => {
  const { params } = router;
  const dispatch = useDispatch();
  const [activeIndexes, setActiveIndexes] = useState([]);
  const [showSideBar, setShowSideBar] = useState(true);
  const [tags, setTags] = useState({});
  const [showNotesAlert, setShowNotesAlert] = useState(false);
  const currentUser = useSelector(state => state.auth.user);
  const [alertNoteBoxText, setAlertNoteBoxText] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeSummaryNote, setActiveSummaryNote] = useState('');
  const [filteredSummaries, setFilteredSummaries] = useState([]);
  const [additionalDataPoints, setAdditionalDataPoints] = useState([]);
  const preReviewSummaryPage = useSelector(
    state => state.review.preReviewSummaryPage
  );

  const style = {
    tableHeader: {
      backgroundColor: '#DCDCDC',
      marginTop: 20,
    },
    tableData: {
      width: showSideBar ? '98%' : '125%',
      marginLeft: '-1px',
      marginTop: 20,
      borderWidth: '0px 1px 1px 1px',
      borderColor: '#D9D9D9',
    },
    tableWordWrapData: {
      wordWrap: 'break-word',
      width: showSideBar ? '98%' : '125%',
      marginLeft: '-1px',
      marginTop: 20,
      borderWidth: '0px 1px 1px 1px',
      borderColor: '#D9D9D9',
    },
  };

  useEffect(() => {
    setActiveIndexes([0]);
    setLoading(true);
    dispatch(preReviewSummary({ reviewId: params.id })).then(e => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    populateSummaries(preReviewSummaryPage && preReviewSummaryPage.summaries);
  }, [preReviewSummaryPage]);

  const handleAccordionClick = index => {
    setActiveIndexes(prevIndexes => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter(item => item !== index);
      } else {
        return [...prevIndexes, index];
      }
    });
  };

  const handleShowSideBar = showSideBarInd => {
    setShowSideBar(showSideBarInd);
  };

  const preReviewSummaryNotes = () => {
    setTags({
      type: 'prereview_summary_note',
      reviewId: params.id,
    });
  };

  const populateSummaries = summaries => {
    const finalSummaries = [];
    summaries &&
      summaries.map((e, idx) => {
        if (e.title == 'Additional Pre-Review Data Points') {
          setAdditionalDataPoints(e.preReviewAnswerSummaries);
        } else {
          finalSummaries.push(e);
        }
      });
    setFilteredSummaries(finalSummaries);
  };

  const populateSummaryHeaderTexts = () => {
    const reportHeaderTexts =
      preReviewSummaryPage && preReviewSummaryPage.reportHeaderTexts;
    return (
      <div style={style.tableData}>
        {reportHeaderTexts &&
          reportHeaderTexts.map(reportHeaderText => (
            <label key={reportHeaderText}>
              <span style={{ fontWeight: 400 }}>{reportHeaderText}</span>
              <br />
              <br />
            </label>
          ))}
        <br />
      </div>
    );
  };

  const renderGranteeTable = () => {
    return (
      <div style={style.tableData}>
        <Header as="h3">Grant Recipient Information</Header>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell scope="col" style={style.tableHeader}>
                Grant Recipient Name
              </Table.HeaderCell>
              <Table.HeaderCell scope="col" style={style.tableHeader}>
                Grant Number(s)
              </Table.HeaderCell>
              <Table.HeaderCell scope="col" style={style.tableHeader}>
                Program Option(s)
              </Table.HeaderCell>
              <Table.HeaderCell scope="col" style={style.tableHeader}>
                Program Type(s)
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                {preReviewSummaryPage &&
                  preReviewSummaryPage.granteeNames.map(granteeName => (
                    <div key={granteeName}>
                      <span style={{ fontWeight: 400 }}>
                        {granteeName}
                        <br />
                      </span>
                    </div>
                  ))}
              </Table.Cell>
              <Table.Cell>
                {preReviewSummaryPage &&
                  preReviewSummaryPage.granteeIds.map(granteeId => (
                    <div key={granteeId}>
                      <span style={{ fontWeight: 400 }}>{granteeId}</span>
                      <br />
                    </div>
                  ))}
              </Table.Cell>
              <Table.Cell>
                {preReviewSummaryPage &&
                  preReviewSummaryPage.programOptions.map(programOption => (
                    <div key={programOption}>
                      <span style={{ fontWeight: 400 }}>{programOption}</span>
                      <br />
                    </div>
                  ))}
              </Table.Cell>
              <Table.Cell>
                {preReviewSummaryPage &&
                  preReviewSummaryPage.programTypes.map(programType => (
                    <div key={programType}>
                      <span style={{ fontWeight: 400 }}>{programType}</span>
                      <br />
                    </div>
                  ))}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  };

  const renderPreReviewSummaryReport = () => {
    if (preReviewSummaryPage && !isEmpty(preReviewSummaryPage)) {
      const accordionForm =
        filteredSummaries &&
        filteredSummaries.map((e, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: showSideBar ? '98%' : '125%',
                marginLeft: '-1px',
                marginTop: 20,
                borderWidth: '0px 1px 1px 1px',
                borderStyle: 'solid',
                borderColor: '#D9D9D9',
              }}
            >
              <React.Fragment key={idx}>
                <Accordion fluid>
                  <Accordion.Title
                    active={activeIndexes.includes(idx)}
                    index={idx}
                    style={{ backgroundColor: '#DCDCDC', marginTop: 20 }}
                    onClick={() => handleAccordionClick(idx)}
                  >
                    <h3 style={{ marginLeft: '10px' }}>
                      {e.title}
                      <Icon
                        style={{ float: 'right' }}
                        name={activeIndexes.includes(idx) ? 'minus' : 'plus'}
                      />
                    </h3>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndexes.includes(idx)}>
                    {renderPreReviewSummaryDetails(e)}
                  </Accordion.Content>
                </Accordion>
              </React.Fragment>
            </div>
          );
        });
      return accordionForm;
    }
  };

  const renderPreReviewSummaryDetails = e => {
    return (
      <div style={{ wordWrap: 'break-word' }}>
        {e.preReviewAnswerSummaries?.map((preReviewAnswerSummary, index) => {
          const {
            tqNumber,
            tqLanguage,
            tqResponse,
            checkBoxes,
          } = preReviewAnswerSummary;
          return (
            <div key={index} style={{ margin: '10px', marginBottom: '-1px' }}>
              <Form>
                <Form.Field width={16}>
                  <label>
                    <span
                      style={{ fontWeight: 400, fontFamily: 'PublicSans-Bold' }}
                    >
                      {tqNumber}
                    </span>
                  </label>
                </Form.Field>
                <Form.Field width={16}>
                  <label>
                    <span
                      style={{ fontWeight: 400 }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(tqLanguage),
                      }}
                    />
                  </label>
                </Form.Field>
                <Form.Field width={16}>
                  <label>
                    <span style={{ fontFamily: 'PublicSans-Bold' }}>
                      {'Selected Answers: '}
                    </span>
                    <span style={{ fontWeight: 400 }}>{tqResponse}</span>
                  </label>
                </Form.Field>
                <Form.Field width={16}>
                  <label>
                    <span style={{ fontWeight: 400 }}>
                      <ul>
                        {checkBoxes.map((checkBox, idx) => (
                          <li key={idx}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(checkBox),
                              }}
                            />
                          </li>
                        ))}
                      </ul>
                    </span>
                  </label>
                </Form.Field>
                <Divider />
              </Form>
            </div>
          );
        })}
        <br />
      </div>
    );
  };

  const renderAdditionalDataPoints = () => {
    if (additionalDataPoints && !isEmpty(additionalDataPoints)) {
      return (
        <div style={style.tableWordWrapData}>
          <Header as="h4">Additional Pre-Review Data Points</Header>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell scope="col" style={style.tableHeader}>
                  Number
                </Table.HeaderCell>
                <Table.HeaderCell scope="col" style={style.tableHeader}>
                  Question
                </Table.HeaderCell>
                <Table.HeaderCell scope="col" style={style.tableHeader}>
                  Responses
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body className="pre-review-table-body">
              {additionalDataPoints.map((additionalDataPoint, index) => {
                return (
                  <Table.Row key={additionalDataPoint.tqNumber}>
                    <Table.Cell>
                      <label>
                        <span
                          style={{
                            paddingLeft: '10px',
                            fontWeight: 400,
                            textAlign: 'right',
                          }}
                        >
                          {additionalDataPoint.tqNumber}
                        </span>
                      </label>
                    </Table.Cell>
                    <Table.Cell>
                      <label>
                        <span
                          style={{
                            paddingLeft: '10px',
                            fontWeight: 400,
                            textAlign: 'right',
                          }}
                        >
                          {additionalDataPoint.tqLanguage}
                        </span>
                      </label>
                    </Table.Cell>
                    <Table.Cell>
                      {additionalDataPoint.checkBoxes &&
                      !isEmpty(additionalDataPoint.checkBoxes) ? (
                        <label>
                          <span style={{ fontWeight: 400 }}>
                            <ul>
                              {additionalDataPoint.checkBoxes.map(
                                (checkBox, idx) => (
                                  <li key={idx}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(checkBox),
                                      }}
                                    />
                                  </li>
                                )
                              )}
                            </ul>
                          </span>
                        </label>
                      ) : (
                        <label>
                          <span
                            style={{ fontWeight: 400, marginRight: '25px' }}
                          ></span>
                          {additionalDataPoint.tqResponse}
                        </label>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      );
    }
  };

  const handleNoteChanges = (event, setter) => setter(event.target.value);

  const renderAddNotes = () => {
    const inlineStyle = {
      modal: {
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
      },
    };
    return (
      <AmsModal
        centered
        size="tiny"
        style={inlineStyle.modal}
        open={showNotesAlert}
      >
        <Header content={alertNoteBoxText} />
        <Modal.Content>
          <Form style={{ width: '150%', margin: '0 auto' }}>
            <Form.Field
              required
              id={'notes'}
              value={activeSummaryNote}
              control="textarea"
              placeholder="Enter the notes"
              rows={6}
              onChange={e => {
                handleNoteChanges(e, setActiveSummaryNote);
                preReviewSummaryNotes();
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => {
              setShowNotesAlert(false);
            }}
          >
            Close
          </Button>
          <Button
            disabled={activeSummaryNote.trim().length === 0}
            color="green"
            onClick={async () => {
              const data = {
                note: activeSummaryNote,
                createdAt: AmsDateFormatters.getMoment().utc(),
                updateAt: AmsDateFormatters.getMoment().utc(),
                submittedBy: pick(currentUser, ['oid', 'userName', 'fullName']),
              };

              data.tags = tags;
              await dispatch(addNote(data));
              setShowNotesAlert(false);
              setLoading(true);
              dispatch(preReviewSummary({ reviewId: params.id })).then(e => {
                setLoading(false);
              });
            }}
          >
            Submit
          </Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  const renderSummaryNotes = () => {
    const summaryNotes =
      preReviewSummaryPage && preReviewSummaryPage.summaryNotes;
    const editNotesUsers =
      preReviewSummaryPage && preReviewSummaryPage.editNotesUsers;
    const addNotesUsers =
      preReviewSummaryPage && preReviewSummaryPage.addNotesUsers;

    const readOnly =
      (selectedReview || {}).reviewAccessLevel &&
      (selectedReview || {}).reviewAccessLevel.isReviewAccessible === false &&
      (selectedReview || {}).reviewStatus !== 'Report Signed' &&
      (selectedReview || {}).reviewStatus !== 'Shipped';

    return (
      <div style={style.tableWordWrapData}>
        {summaryNotes && !isEmpty(summaryNotes) && (
          <Form>
            <Form.Field width={12}>
              <div>
                <label>
                  <span style={{ fontFamily: 'PublicSans-Bold' }}>
                    {'RL/FOM Notes'}
                  </span>
                </label>
                <br />
                {summaryNotes &&
                  summaryNotes.map(summaryNote => (
                    <div key={summaryNote.noteId}>
                      <span>
                        <Comment.Content>
                          <>
                            <Comment.Metadata>
                              <div>
                                Added By:{' '}
                                {summaryNote.userOid === currentUser.oid
                                  ? 'You'
                                  : summaryNote.userName}
                              </div>
                            </Comment.Metadata>
                            <Comment.Metadata>
                              <div>
                                Added On:{' '}
                                {AmsDateFormatters.formatDateTime(
                                  summaryNote.addedOn
                                )}
                              </div>
                            </Comment.Metadata>
                          </>
                          <br />
                          <Comment.Text>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(summaryNote.note),
                              }}
                              style={{ whiteSpace: 'pre-line' }}
                            ></p>
                          </Comment.Text>
                          <br />
                          <Comment.Metadata>
                            {summaryNote.userOid === currentUser.oid && (
                              <Link>
                                <button
                                  type="button"
                                  className="add-option-link-button"
                                  onClick={() => {
                                    setShowNotesAlert(true);
                                    setActiveSummaryNote(summaryNote.note);
                                    setAlertNoteBoxText('Edit Notes *');
                                  }}
                                >
                                  Edit
                                </button>
                              </Link>
                            )}
                          </Comment.Metadata>
                        </Comment.Content>
                      </span>
                      <br />
                    </div>
                  ))}
              </div>
            </Form.Field>
          </Form>
        )}
        {((addNotesUsers && addNotesUsers.includes(currentUser.oid)) ||
          (currentUser &&
            currentUser.roles &&
            currentUser.roles.includes('AmsAdmi'))) &&
          editNotesUsers &&
          !editNotesUsers.includes(currentUser.oid) &&
          !readOnly && (
            <Form>
              <Form.Field width={16}>
                <div
                  style={{
                    marginLeft: '40px',
                    width: showSideBar ? '98%' : '125%',
                    padding: '10px',
                    marginLeft: '-1px',
                    marginTop: 20,
                  }}
                >
                  <button
                    type="button"
                    className="add-option-link-button"
                    onClick={() => {
                      setShowNotesAlert(true);
                      setAlertNoteBoxText('Add Notes *');
                      setActiveSummaryNote('');
                    }}
                  >
                    Add Notes
                  </button>
                </div>
              </Form.Field>
              <br />
            </Form>
          )}
      </div>
    );
  };

  return (
    <ReviewLayout
      type="Pre-Review Summary"
      enableSideBar={true}
      handleShowSideBar={handleShowSideBar}
    >
      <fieldset>
        <div
          style={{
            marginLeft: '18px',
          }}
        >
          {
            <>
              <Dimmer active={loading} inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h2" content="Pre-Review Summary" />
                  {populateSummaryHeaderTexts()}
                  {renderGranteeTable()}
                  {filteredSummaries && !isEmpty(filteredSummaries) ? (
                    <>
                      <Header as="h3">
                        Grant Recipient Risks Identified in Pre-Review
                      </Header>
                      {renderPreReviewSummaryReport()}
                      <br />
                      {renderAdditionalDataPoints()}
                    </>
                  ) : (
                    ''
                  )}
                  {renderSummaryNotes()}
                  {renderAddNotes()}
                </Grid.Column>
              </Grid.Row>
            </>
          }
        </div>
      </fieldset>
    </ReviewLayout>
  );
};
const mapStateToProps = state => {
  return {
    selectedReview: state.review.selectedReview,
  };
};

export default connect(mapStateToProps)(PreReviewSummaryReportPage);
