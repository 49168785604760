import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';
import validator from 'validator';

// Import components
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsHelpText from '../../../utils/AmsHelpText';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';
import AmsTable from '../../../utils/AmsTable';

// Import helper function.
import enforceRole from '../../../utils/EnforceRole';

// Import actions.
import {
  updateUserProfile,
  updateUserRoles,
} from '../../../actions/profileActions';

// Import settings.
import { acl } from '../../../config';
import AmsModal from '../../../utils/AmsModal';

class EducationForm extends Component {
  state = {
    emptyValue: '--',
    showSuccess: false,
    loading: false,
    editMode: false,
    showModal: false,
    certificateAdd: false,
    certificateEdit: false,
    certificateDelete: false,
    modalTitle: '',
    selectedCertification: {
      degreeName: '',
      issuer: '',
      dateReceived: '',
      degreeVerified: false,
    },
    updatedCertification: {
      degreeName: '',
      issuer: '',
      dateReceived: '',
      degreeVerified: false,
    },
    errors: {},
    certificateErrors: {},
    data: {
      oid: '',
      highestEducationLevel: '',
      highestEducationLevelverified: false,
      educationDegreesAndCerts: [],
    },
  };

  static getDerivedStateFromProps(props, state) {
    const {
      oid,
      highestEducationLevel,
      highestEducationLevelverified,
    } = props.profile;

    const educationDegreesAndCerts =
      props.profile.educationDegreesAndCerts &&
      props.profile.educationDegreesAndCerts.map((e, index) => {
        return {
          ...e,
          id: index,
        };
      });

    const data = {
      oid,
      highestEducationLevel,
      highestEducationLevelverified,
      educationDegreesAndCerts,
    };

    if (!_.isEqual(state.data, data)) {
      const { profileTab } = props;

      if (profileTab !== 'education') {
        this.cancelEdit();
      }

      if (state.localChange) {
        return {
          localChange: false,
        };
      }

      return {
        data: {
          oid,
          highestEducationLevel,
          highestEducationLevelverified,
          educationDegreesAndCerts,
        },
      };
    }
    return null;
  }

  cancelEdit = () => {
    this.setState({
      showSuccess: false,
      loading: false,
      editMode: false,
      errors: {},
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ showSuccess: false, loading: true });

      const { data } = this.state;

      // Only update if there's a valid userId.
      if (data.oid) {
        this.props
          .updateUserProfile(data)
          .then(() =>
            this.setState({
              showSuccess: true,
              loading: false,
              editMode: false,
            })
          )
          .catch(err => {
            this.setState({
              showSuccess: false,
              loading: false,
              errors: {
                ...this.state.errors,
                profile: err.response.data.message,
              },
            });
          });
      }
    }
  };

  validate = data => {
    const errors = {};
    if (!data.highestEducationLevel)
      errors.highestEducationLevel =
        'Highest level achieved is a required fields';
    return errors;
  };

  handleChange = (e, { name, value }) =>
    this.setState({
      ...this.state,
      localChange: true,
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });

  handleCertificateEditChange = event =>
    this.setState({
      ...this.state,
      updatedCertification: {
        ...this.state.updatedCertification,
        [event.target.name]: event.target.value,
      },
      certificateErrors: _.omit(
        this.state.certificateErrors,
        event.target.name
      ),
      localChange: true,
    });

  handleCertificateVerifiedChange = (e, { name, checked }) =>
    this.setState({
      ...this.state,
      updatedCertification: {
        ...this.state.updatedCertification,
        [name]: checked,
      },
      errors: _.omit(this.state.errors, name),
      localChange: true,
    });

  addCertificate = e => {
    e.preventDefault();

    this.setState({
      showModal: true,
      certificateAdd: true,
      certificateEdit: false,
      certificateDelete: false,
      modalTitle: 'Add Certification',
      selectedCertification: {},
      updatedCertification: {},
      localChange: true,
    });
  };

  handleCertificationEdit(selectedCertification, e) {
    e.preventDefault();
    this.setState({
      selectedCertification,
      updatedCertification: selectedCertification,
      showModal: true,
      modalTitle: 'Edit Certification',
      certificateEdit: true,
      certificateAdd: false,
      certificateDelete: false,
      localChange: true,
    });
  }

  handleCertificationRemove(selectedCertification, e) {
    e.preventDefault();
    this.setState({
      showDeleteConfirm: true,
      certificateDelete: true,
      selectedCertification,
      updatedCertification: selectedCertification,
      localChange: true,
    });
  }

  closeDeleteConfirmModal() {
    this.setState({ showDeleteConfirm: false, localChange: true });
  }

  closeEditModal = () => {
    this.setState({
      showModal: false,
      certificateAdd: false,
      certificateEdit: false,
      certificateDelete: false,
      certificateErrors: {},
      modalTitle: '',
      selectedCertification: {},
      updatedCertification: {},
      localChange: true,
    });
  };

  setCertificationsChanges = () => {
    const {
      selectedCertification,
      updatedCertification,
      data,
      certificateAdd,
      certificateDelete,
    } = this.state;

    // Validate certificate dates.
    if (!_.isEmpty(updatedCertification.dateReceived)) {
      if (!validator.toDate(updatedCertification.dateReceived)) {
        this.setState({
          certificateErrors: {
            ...this.certificateErrors,
            dateReceived: 'Invalid Date',
          },
        });
        return;
      }
    }

    let updatedCertifications = data.educationDegreesAndCerts.map(
      certificate => {
        if (updatedCertification.id === certificate.id) {
          certificate = updatedCertification;
        }
        return certificate;
      }
    );

    // During new language add
    if (certificateAdd) {
      updatedCertifications.push(updatedCertification);
    }

    // During language delete.
    if (certificateDelete) {
      updatedCertifications = _.pull(
        updatedCertifications,
        selectedCertification
      );
    }

    this.saveCertifications({
      oid: data.oid,
      educationDegreesAndCerts: updatedCertifications,
      highestEducationLevel: data.highestEducationLevel,
    });
  };

  saveCertifications = certificateData => {
    this.setState({ loading: true });
    this.props
      .updateUserProfile(certificateData)
      .then(() =>
        this.setState({
          showSuccess: true,
          loading: false,
          certificateAdd: false,
          certificateEdit: false,
          certificateDelete: false,
          showModal: false,
          showDeleteConfirm: false,
          modalTitle: '',
          editMode: false,
        })
      )
      .catch(err => {
        this.setState({
          showSuccess: false,
          loading: false,
          certificateAdd: false,
          certificateEdit: false,
          certificateDelete: false,
          showModal: false,
          showDeleteConfirm: false,
          modalTitle: '',
          errors: { ...this.state.errors, profile: err.response.data.message },
        });
      });

    this.closeEditModal();
  };

  showCertificationModal = () => {
    const { currentUser } = this.props;
    const {
      showModal,
      updatedCertification,
      loading,
      modalTitle,
      certificateErrors,
    } = this.state;

    return (
      <AmsModal
        open={showModal}
        onClose={this.closeEditModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="education-modal ams-semantic-modal-fix"
      >
        <Modal.Header>{modalTitle}</Modal.Header>
        <Modal.Content>
          {!_.isEmpty(certificateErrors) && (
            <Message
              negative
              icon="cancel"
              header="Something went wrong!"
              list={Object.keys(certificateErrors).map(errorKey => (
                <li key={errorKey}>{certificateErrors[errorKey]}</li>
              ))}
            />
          )}

          <Form loading={loading}>
            <Form.Group widths="2">
              <Form.Field
                name="degreeName"
                onChange={this.handleCertificateEditChange}
                value={updatedCertification.degreeName || ''}
                placeholder="Degree Name"
                control={Input}
                label={{ children: 'Degree Name', htmlFor: 'degreeName' }}
                id="degreeName"
                aria-labelledby="degreeName"
              />

              <Form.Field
                name="issuer"
                onChange={this.handleCertificateEditChange}
                value={updatedCertification.issuer || ''}
                placeholder="Issuer Institute"
                control={Input}
                label={{ children: 'Issuer Institute', htmlFor: 'issuer' }}
                id="issuer"
                aria-labelledby="issuer"
              />
            </Form.Group>

            <Form.Group widths="2">
              <Form.Field
                error={!!certificateErrors.dateReceived}
                name="dateReceived"
                onChange={e =>
                  this.setState({
                    ...this.state,
                    updatedCertification: {
                      ...this.state.updatedCertification,
                      dateReceived: e?.endOf('day').format(),
                    },
                    certificateErrors: _.omit(
                      this.state.certificateErrors,
                      'dateReceived'
                    ),
                    localChange: true,
                  })
                }
                selected={
                  updatedCertification.dateReceived
                    ? AmsDateFormatters.getMoment(
                        updatedCertification.dateReceived
                      )
                    : null
                }
                placeholderText={'MM/DD/YYYY'}
                control={DatePicker}
                label={{ children: 'Date Received', htmlFor: 'dateReceived' }}
                id="dateReceived"
                aria-labelledby="dateReceived"
              />

              {enforceRole(
                <Form.Field
                  name="degreeVerified"
                  onChange={this.handleCertificateVerifiedChange}
                  checked={
                    updatedCertification.degreeVerified === true ? true : false
                  }
                  toggle
                  control={Checkbox}
                  label={{ children: 'Verified', htmlFor: 'degreeVerified' }}
                  id="degreeVerified"
                  aria-labelledby="degreeVerified"
                />,
                acl.actions.profile.checkBoxValidation,
                currentUser.roles
              ) || (
                <Form.Field>
                  <label>Verified</label>
                  {updatedCertification.degreeVerified ? 'Yes' : 'No'}
                </Form.Field>
              )}
            </Form.Group>
            <input type="submit" value="submit" hidden />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={this.setCertificationsChanges}>
            Save
          </Button>
          <Button onClick={this.closeEditModal}>Close</Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  showDeleteConfirmModal = () => {
    const { showDeleteConfirm, selectedCertification } = this.state;
    return (
      <AmsModal
        open={showDeleteConfirm}
        onClose={this.closeEditModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="education-modal ams-semantic-modal-fix"
      >
        <Modal.Header>Remove Certification/Degree</Modal.Header>
        <Modal.Content>
          <p>
            You are about to remove {selectedCertification.degreeName} from the
            degree/certification list. Are you sure?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.setCertificationsChanges}>
            Remove
          </Button>
          <Button onClick={this.closeDeleteConfirmModal.bind(this)}>
            Close
          </Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  showEditButton = () => {
    const { currentUser, profile } = this.props;

    if (
      currentUser &&
      acl.actions.profile.userSettings.some(r => currentUser.roles.includes(r))
    ) {
      return (
        <Button
          size="large"
          floated="right"
          onClick={e => {
            e.preventDefault();
            this.setState({
              error: {},
              showSuccess: false,
              editMode: true,
            });
          }}
        >
          Edit
        </Button>
      );
    } else if (currentUser && profile) {
      if (currentUser.oid !== profile.oid) return;
      else
        return (
          <Button
            size="large"
            floated="right"
            onClick={e => {
              e.preventDefault();
              this.setState({
                error: {},
                showSuccess: false,
                editMode: true,
              });
            }}
          >
            Edit
          </Button>
        );
    } else return;
  };

  render() {
    const {
      editMode,
      loading,
      errors,
      data,
      emptyValue,
      showSuccess,
    } = this.state;

    const dateFormatter = (cell, row) => {
      return cell ? AmsDateFormatters.formatDate(cell) : '';
    };

    const certificateVerifiedFormatter = (cell, row) =>
      cell === true ? 'Yes' : 'No';

    const actionFormatter = (cell, row) => {
      return (
        <Button.Group icon>
          <Button
            onClick={this.handleCertificationEdit.bind(this, row)}
            className="clickable"
            style={{ background: 'none' }}
          >
            <i className="fa fa-pencil fa-md" />
          </Button>
          &nbsp; | &nbsp;
          <Button
            onClick={this.handleCertificationRemove.bind(this, row)}
            className="clickable"
            style={{ background: 'none' }}
          >
            <i className="fa fa-trash fa-md" />
          </Button>
        </Button.Group>
      );
    };

    const columns = [
      { hidden: true, dataField: 'id', text: 'Degree' },
      {
        headerStyle: { width: '30%' },
        dataField: 'degreeName',
        sort: true,
        text: 'Degree',
      },
      {
        dataField: 'issuer',
        headerStyle: { width: '20%' },
        sort: true,
        text: 'Issuer',
      },
      {
        headerStyle: { width: '20%' },
        formatter: dateFormatter,
        dataField: 'dateReceived',
        sort: true,
        text: 'Date Received',
      },
      {
        dataField: 'degreeVerified',
        formatter: certificateVerifiedFormatter,
        sort: true,
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        text: 'Verified',
      },
      {
        hidden: !editMode,
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: actionFormatter,
        text: 'Action',
      },
    ];

    const total = data.educationDegreesAndCerts
      ? data.educationDegreesAndCerts.length
      : 0;
    return (
      <div>
        {this.showCertificationModal()}
        {this.showDeleteConfirmModal()}

        <div className="profile-form-title-wrapper">
          <h2>Education</h2>
        </div>

        <Form onSubmit={this.onSubmit} loading={loading}>
          <div className="profile-form-fields-wrapper">
            {showSuccess && (
              <Message
                positive
                icon="check"
                header="Success!"
                content="Data saved successfully."
              />
            )}

            {!_.isEmpty(errors) && (
              <Message
                negative
                icon="cancel"
                header="Something went wrong!"
                list={Object.keys(errors).map(errorKey => (
                  <li key={errorKey}>{errors[errorKey]}</li>
                ))}
              />
            )}

            <Form.Group widths="2">
              {editMode ? (
                <Form.Field
                  selection
                  error={!!errors.highestEducationLevel}
                  name="highestEducationLevel"
                  onChange={this.handleChange}
                  value={data.highestEducationLevel}
                  category={'highestEducationLevels'}
                  placeholder="Select highest level achieved"
                  control={AmsLookupDropdown}
                  label={{
                    children: 'Highest Level Achieved',
                    htmlFor: 'highestEducationLevel',
                  }}
                  id="highestEducationLevel"
                  aria-labelledby="highestEducationLevel"
                />
              ) : (
                <Form.Field required>
                  <label>
                    Highest Level Achieved
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="demographicHighestEducation"
                    />
                  </label>
                  <div className="default-value">
                    {data.highestEducationLevel || emptyValue}
                  </div>
                </Form.Field>
              )}
            </Form.Group>

            <br />

            <Form.Group widths="equal">
              <Form.Field>
                <div className="row">
                  <div className="col-sm-9">
                    <h5>
                      Degree Certifications
                      <AmsHelpText
                        category="helpText"
                        fieldLabel="demographicDegreeCert"
                      />
                    </h5>
                  </div>
                  {editMode && (
                    <div className="col-sm-1">
                      <Button basic size="tiny" onClick={this.addCertificate}>
                        {' '}
                        Add
                      </Button>
                    </div>
                  )}
                  <br />
                  <br />
                </div>
                <Col className="row language-table" xs={12} sm={12}>
                  <AmsTable
                    data={
                      data.educationDegreesAndCerts &&
                      data.educationDegreesAndCerts.map(
                        (certificates, index) => ({
                          id: index,
                          ...certificates,
                        })
                      )
                    }
                    total={total}
                    basic
                    columns={columns}
                    sizePerPageOption={false}
                    pagination={data.languages && data.languages.length > 10}
                    remote={false}
                    keyField="id"
                  ></AmsTable>
                </Col>
              </Form.Field>
            </Form.Group>

            <Segment basic floated="right">
              {editMode ? (
                <div>
                  <Button size="large" primary>
                    Save
                  </Button>
                  <Button
                    size="large"
                    onClick={e => {
                      e.preventDefault();
                      this.cancelEdit();
                    }}
                    basic
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                this.showEditButton()
              )}
            </Segment>
          </div>
        </Form>
      </div>
    );
  }
}

EducationForm.propTypes = {
  currentUser: PropTypes.object,
  profile: PropTypes.object,
  profileTab: PropTypes.string.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserRoles: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  profile: state.profile,
  profileTab: state.profileTab,
});

export default connect(mapStateToProps, { updateUserProfile, updateUserRoles })(
  EducationForm
);
