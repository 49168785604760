import {
  SURVEYS_FETCHED,
  SURVEY_SUBMISSION_FETCHED,
  SURVEY_OUTCOMES_FETCHED,
  DATA_COLLECTION_FETCHED,
  CLASS_ROOM_OUTCOMES_FETCHED,
  CENTER_OUTCOMES_FETCHED,
  SURVEY_ALL_SUBMISSION_FETCHED,
  SURVEY_SUBMISSION_UPDATED,
  RAS_URL,
  SURVEY_CHILD_FILES_SUMMARY_FETCHED,
  SPECIAL_ASSIGNMENTS_FETCHED,
  SPECIAL_ASSIGNMENTS_ADDED,
  SURVEY_COMMENTS_FETCHED,
  CHILD_FILES_ERSEA_SUMMARY_FETCHED,
  CONSOLIDATED_QUESTIONS_FETCHED,
  MULTI_SURVEY_FORM_FETCHED,
  CONSOLIDATED_ANSWERS_FETCHED,
  FOCUS_COMPONENT,
  DATA_COLLECTION_LOCKED,
  DATA_COLLECTION_DETAILS_FETCHED,
  ADDITIONAL_CITATION_FORM_FETCHED,
  CONSOLIDATED_ANSWERS_CLEARED,
  SPECIAL_SURVEY_ASSIGNMENTS_FETCHED,
  CONTENT_AREA_LOOKUPS_FETCHED,
  PERFORMANCE_AREA_LOOKUPS_FETCHED,
  PERFORMANCE_MEASURE_LOOKUPS_FETCHED,
  SURVEY_CENTERS_CLASSROOMS_FETCHED,
  SURVEY_FOTP_FINDINGS_FETCHED,
  ELIGIBILITY_FILES_SUMMARY_FETCHED,
} from '../redux/types';

const initialState = {
  surveys: [],
  selectedSubmission: {},
  allSubmission: [],
  requestOutcomes: {},
  outComes: [],
  dataCollectionOutcomes: [],
  classroomOutcomes: [],
  centerOutcomes: [],
  formBuilderFocus: null,
  consolidatedAnswers: null,
};

export default function survey(state = initialState, action) {
  switch (action.type) {
    case SURVEYS_FETCHED:
      return { ...state, surveys: action.surveys };

    case SURVEY_COMMENTS_FETCHED:
      return { ...state, comments: action.comments };

    case SURVEY_SUBMISSION_FETCHED:
      return { ...state, selectedSubmission: action.submission };

    case SURVEY_ALL_SUBMISSION_FETCHED:
      return {
        ...state,
        allSubmission:
          action.submission &&
          action.submission.map((e, index) => {
            e.index = index;
            return e;
          }),
      };

    case SURVEY_SUBMISSION_UPDATED:
      return { ...state };

    case SURVEY_OUTCOMES_FETCHED:
      return { ...state, outComes: action.outComes };

    case DATA_COLLECTION_FETCHED:
      return {
        ...state,
        dataCollectionOutcomes: action.dataCollectionOutcomes,
      };

    case CLASS_ROOM_OUTCOMES_FETCHED:
      return { ...state, classroomOutcomes: action.classroomOutcomes };

    case CENTER_OUTCOMES_FETCHED:
      return { ...state, centerOutcomes: action.centerOutcomes };

    case RAS_URL:
      return { ...state, rasUrl: action.rasUrl };

    case SURVEY_CHILD_FILES_SUMMARY_FETCHED:
      return { ...state, childFilesSummary: action.childFilesSummary };

    case CHILD_FILES_ERSEA_SUMMARY_FETCHED:
      return {
        ...state,
        childFilesERSEASummary: action.childFilesERSEASummary,
      };

    case ELIGIBILITY_FILES_SUMMARY_FETCHED:
      return {
        ...state,
        eligibilityFilesSummary: action.eligibilityFilesSummary,
      };

    case SPECIAL_ASSIGNMENTS_FETCHED:
      return { ...action.assignments };

    case SPECIAL_ASSIGNMENTS_ADDED:
      return { ...action.assignments };

    case CONSOLIDATED_QUESTIONS_FETCHED:
      return { ...state, consolidatedQuestions: action.consolidatedQuestions };
    case MULTI_SURVEY_FORM_FETCHED:
      return { ...state, multiSurveyForm: action.multiSurveyForm };
    case CONSOLIDATED_ANSWERS_FETCHED:
      return { ...state, consolidatedAnswers: action.consolidatedAnswers };
    case ADDITIONAL_CITATION_FORM_FETCHED:
      return {
        ...state,
        additionalCitationForm: action.additionalCitationForm,
      };

    case CONSOLIDATED_ANSWERS_CLEARED:
      return {
        ...state,
        consolidatedAnswers: {},
      };

    case FOCUS_COMPONENT:
      return { ...state, formBuilderFocus: action.componentName };
    case DATA_COLLECTION_LOCKED:
      return { ...state, consolidatedAnswers: action.consolidatedAnswers };

    case DATA_COLLECTION_DETAILS_FETCHED:
      return { ...state, consolidatedAnswers: action.consolidatedAnswers };

    case SPECIAL_SURVEY_ASSIGNMENTS_FETCHED:
      return { ...action.assignments };

    case CONTENT_AREA_LOOKUPS_FETCHED:
      return { ...state, contentAreaLookups: action.contentAreaLookups };

    case PERFORMANCE_AREA_LOOKUPS_FETCHED:
      return {
        ...state,
        performanceAreaLookups: action.performanceAreaLookups,
      };

    case PERFORMANCE_MEASURE_LOOKUPS_FETCHED:
      return {
        ...state,
        performanceMeasureLookups: action.performanceMeasureLookups,
      };

    case SURVEY_CENTERS_CLASSROOMS_FETCHED:
      return {
        ...state,
        surveyCentersClassrooms: action.surveyCentersClassrooms,
      };

    case SURVEY_FOTP_FINDINGS_FETCHED:
      return {
        ...state,
        fotpFindings: action.fotpFindings,
      };

    default:
      return state;
  }
}
