import React from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router';
import { isEmpty } from 'lodash';

import { acl } from '../../../../config';
import enforceRole from '../../../../utils/EnforceRole';

const ReviewJournal = () => {
  const { user: currentUser } = useSelector(state => state.auth);
  const { selectedReview } = useSelector(state => state.review);
  if (
    isEmpty(selectedReview.reviewId) ||
    selectedReview.reviewType === 'CLASS' ||
    selectedReview.reviewType === 'AIAN-CLASS' ||
    selectedReview.reviewType === 'PR-CLASS' ||
    selectedReview.reviewType === 'VP-CLASS' ||
    selectedReview.reviewType === 'CLASS-Video' ||
    selectedReview.reviewType === 'AIAN CLASS Self-Observations' ||
    selectedReview.reviewType === 'CLASS AIAN Onsite' ||
    selectedReview.reviewType === 'CLASS AIAN Video'
  )
    return null;

  return enforceRole(
    <Menu.Item
      as={Link}
      to={`/review/${selectedReview.reviewId}/review-journal`}
    >
      Journal
    </Menu.Item>,
    acl.actions.review['journal'],
    currentUser.roles
  );
};

export default ReviewJournal;
