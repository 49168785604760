import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Segment, Form, Button, Message, Input } from 'semantic-ui-react';
import _ from 'lodash';
import validator from 'validator';

//Import Components
import AmsHelpText from '../../../utils/AmsHelpText';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';

// Import actions.
import {
  updateUserProfile,
  updateUserRoles,
  updateUserEmailOnAzure
} from '../../../actions/profileActions';

// Import settings.
import { acl } from '../../../config';
import AmsFormLabel from '../../../utils/AmsFormLabel';

class ContactInformationForm extends Component {
  state = {
    emptyValue: '--',
    showSuccess: false,
    loading: false,
    editMode: false,
    errors: {},
    data: {
      oid: '',
      title: '',
      homePhone: '',
      cellPhone: '',
      homeFax: '',
      firstName: '',
      lastName: '',
      middleInitial: '',
      email: '',
      alternateEmail: '',
      homeAddrLine1: '',
      homeAddrLine2: '',
      homeAddrCity: '',
      homeAddrState: '',
      homeAddrZip: '',
      fedExAddrLine1: '',
      fedExAddrLine2: '',
      fedExAddrCity: '',
      fedExAddrState: '',
      fedExAddrZip: '',
      primaryContactName: '',
      primaryContactPhone: '',
      primaryContactRelation: '',
      secondaryContactName: '',
      secondaryContactPhone: '',
      secondaryContactRelation: '',
    },
  };

  static getDerivedStateFromProps(props, state) {
    if (props.profile.oid !== state.data.oid) {
      const { profileTab } = props;

      const {
        oid,
        title,
        homePhone,
        cellPhone,
        homeFax,
        firstName,
        lastName,
        middleInitial,
        email,
        alternateEmail,
        homeAddrLine1,
        homeAddrLine2,
        homeAddrCity,
        homeAddrState,
        homeAddrZip,
        fedExAddrLine1,
        fedExAddrLine2,
        fedExAddrCity,
        fedExAddrState,
        fedExAddrZip,
        primaryContactName,
        primaryContactPhone,
        primaryContactRelation,
        secondaryContactName,
        secondaryContactPhone,
        secondaryContactRelation,
      } = props.profile;

      if (profileTab !== 'contactInformation') {
        this.cancelEdit();
      }

      return {
        data: {
          oid,
          title,
          homePhone,
          cellPhone,
          homeFax,
          firstName,
          lastName,
          middleInitial,
          email,
          alternateEmail,
          homeAddrLine1,
          homeAddrLine2,
          homeAddrCity,
          homeAddrState,
          homeAddrZip,
          fedExAddrLine1,
          fedExAddrLine2,
          fedExAddrCity,
          fedExAddrState,
          fedExAddrZip,
          primaryContactName,
          primaryContactPhone,
          primaryContactRelation,
          secondaryContactName,
          secondaryContactPhone,
          secondaryContactRelation,
        },
      };
    }
    return null;
  }

  cancelEdit = () => {
    const {
      oid,
      title,
      homePhone,
      cellPhone,
      homeFax,
      firstName,
      lastName,
      middleInitial,
      email,
      alternateEmail,
      homeAddrLine1,
      homeAddrLine2,
      homeAddrCity,
      homeAddrState,
      homeAddrZip,
      fedExAddrLine1,
      fedExAddrLine2,
      fedExAddrCity,
      fedExAddrState,
      fedExAddrZip,
      primaryContactName,
      primaryContactPhone,
      primaryContactRelation,
      secondaryContactName,
      secondaryContactPhone,
      secondaryContactRelation,
    } = this.props.profile;
    this.setState({
      showSuccess: false,
      loading: false,
      editMode: false,
      errors: {},
      data: {
        oid,
        title,
        homePhone,
        cellPhone,
        homeFax,
        firstName,
        lastName,
        middleInitial,
        email,
        alternateEmail,
        homeAddrLine1,
        homeAddrLine2,
        homeAddrCity,
        homeAddrState,
        homeAddrZip,
        fedExAddrLine1,
        fedExAddrLine2,
        fedExAddrCity,
        fedExAddrState,
        fedExAddrZip,
        primaryContactName,
        primaryContactPhone,
        primaryContactRelation,
        secondaryContactName,
        secondaryContactPhone,
        secondaryContactRelation,
      },
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ showSuccess: false, loading: true });

      const { data } = this.state;

      // Only update if there's a valid userId.
      if (data.oid) {
        const azureData = {
          mail: data.email,
          oid: data.oid
        };
        this.props
        .updateUserEmailOnAzure(azureData, data)
        .then(() => 
          this.setState({
            showSuccess: true,
            loading: false,
            editMode: false,
          })
        )
        .catch(err => {
          this.setState({
            showSuccess: false,
            loading: false,
            errors: {
              ...this.state.errors,
              profile: err.response.data.message,
            },
          });
        })
      }
    }
  };

  validate = data => {
    const errors = {};
    // if (!data.userType) errors.userType = "User Type is a required fields";
    // if (!roles || !roles.length) errors.roles = "User Roles is a required fields";
    if (!data.firstName) errors.firstName = 'First Name is a required field';
    if (!data.lastName) errors.lastName = 'Last Name is a required field';
    if (!data.homePhone) errors.homePhone = 'Home Phone is a required field';
    else if (!validator.isMobilePhone(data.homePhone, 'en-US', false))
      errors.homePhone =
        'Please specify a valid Home Phone in one of the following formats: (xxx) xxx-xxxx, (xxx)xxx-xxxx, xxx-xxx-xxxx, or xxxxxxxxxx.';
    if (!data.cellPhone) errors.cellPhone = 'Cell Phone is a required field';
    else if (!validator.isMobilePhone(data.cellPhone, 'en-US', false))
      errors.cellPhone =
        'Please specify a valid Cell Phone in one of the following formats: (xxx) xxx-xxxx, (xxx)xxx-xxxx, xxx-xxx-xxxx, or xxxxxxxxxx.';
    if (!data.email) errors.email = 'Email is a required field';
    else if (!validator.isEmail(data.email))
      errors.email = 'Please enter a valid Email.';
    if (!validator.isEmail(data.alternateEmail) && data.alternateEmail)
      errors.alternateEmail = 'Please enter a valid Alternate Email.';
    if (!data.homeAddrLine1)
      errors.homeAddrLine1 = 'Home Address Line1 is a required field';
    if (!data.homeAddrCity)
      errors.homeAddrCity = 'Home Address City is a required field';
    if (!data.homeAddrState)
      errors.homeAddrState = 'Home Address State is a required field';
    if (!data.homeAddrZip)
      errors.homeAddrZip = 'Home Address Zip is a required field';
    else if (!validator.isPostalCode(data.homeAddrZip, 'US'))
      errors.homeAddrZip = 'Please specify a valid Home Address Zip code.';
    if (!validator.isPostalCode(data.fedExAddrZip, 'US') && data.fedExAddrZip)
      errors.fedExAddrZip = 'Please specify a valid FedEx Address Zip code.';
    if (!data.primaryContactName)
      errors.primaryContactName =
        'Primary Emergency Contact Name is a required field';
    if (!data.primaryContactPhone)
      errors.primaryContactPhone =
        'Primary Emergency Contact Phone is a required field';
    else if (!validator.isMobilePhone(data.primaryContactPhone, 'en-US', false))
      errors.primaryContactPhone =
        'Please specify a valid Primary Emergency Contact Phone in one of the following formats: (xxx) xxx-xxxx, (xxx)xxx-xxxx, xxx-xxx-xxxx, or xxxxxxxxxx';
    if (
      !validator.isMobilePhone(data.secondaryContactPhone, 'en-US', false) &&
      data.secondaryContactPhone
    )
      errors.secondaryContactPhone =
        'Please specify a valid Secondary Emergency Contact Phone in one of the following formats: (xxx) xxx-xxxx, (xxx)xxx-xxxx, xxx-xxx-xxxx, or xxxxxxxxxx';
    if (!data.primaryContactRelation)
      errors.primaryContactRelation =
        'Primary Emergency Contact Relationship is a required field';
    return errors;
  };

  handleChange = (e, { name, value }) =>
    this.setState({
      ...this.state,
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });

  handleToggle = (e, { name, checked }) =>
    this.setState({
      ...this.state,
      data: { ...this.state.data, [name]: checked },
      errors: _.omit(this.state.errors, name),
    });

  handleRoleChange = (e, { value }) =>
    this.setState({ roles: value, errors: _.omit(this.state.errors, 'roles') });

  showEditButton = () => {
    const { currentUser, profile } = this.props;

    if (
      currentUser &&
      acl.actions.profile.userSettings.some(r => currentUser.roles.includes(r))
    ) {
      return (
        <Button
          size="large"
          floated="right"
          onClick={e => {
            e.preventDefault();
            this.setState({
              error: {},
              showSuccess: false,
              editMode: true,
            });
          }}
        >
          Edit
        </Button>
      );
    } else if (currentUser && profile) {
      if (currentUser.oid !== profile.oid) return;
      else
        return (
          <Button
            size="large"
            floated="right"
            onClick={e => {
              e.preventDefault();
              this.setState({
                error: {},
                showSuccess: false,
                editMode: true,
              });
            }}
          >
            Edit
          </Button>
        );
    } else return;
  };

  render() {
    const {
      editMode,
      loading,
      errors,
      data,
      emptyValue,
      showSuccess,
    } = this.state;

    return (
      <Form onSubmit={this.onSubmit} loading={loading} error>
        <div className="profile-form-title-wrapper">
          <h2>Contact Information</h2>
        </div>

        <div className="profile-form-fields-wrapper">
          {showSuccess && (
            <Message
              positive
              icon="check"
              header="Success!"
              content="Data saved successfully."
            />
          )}

          {!_.isEmpty(errors) && (
            <Message
              negative
              icon="cancel"
              header="Something went wrong!"
              list={Object.keys(errors).map(errorKey => (
                <li key={errorKey}>{errors[errorKey]}</li>
              ))}
            />
          )}

          <h3>Contact Info</h3>
          <Form.Group widths="equal">
            {editMode ? (
              <Form.Field
                selection
                error={!!errors.title}
                name="title"
                onChange={this.handleChange}
                value={data.title}
                category={'salutations'}
                placeholder="Select title"
                control={AmsLookupDropdown}
                id="userTitle"
                aria-labelledby="userTitle"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userTitle"
                      label="Title"
                    />
                  ),
                  htmlFor: 'userTitle',
                }}
              />
            ) : (
              <Form.Field>
                <label>
                  Title
                  <AmsHelpText category="helpText" fieldLabel="userTitle" />
                </label>
                <div className="default-value">{data.title || emptyValue}</div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                required
                error={!!errors.firstName}
                name="firstName"
                onChange={this.handleChange}
                value={data.firstName}
                placeholder="First Name"
                control={Input}
                id="userFirstName"
                aria-labelledby="userFirstName"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userFirstName"
                      label="First Name"
                    />
                  ),
                  htmlFor: 'userFirstName',
                }}
              />
            ) : (
              <Form.Field required>
                <label>
                  First Name
                  <AmsHelpText category="helpText" fieldLabel="userFirstName" />
                </label>
                <div className="default-value">
                  {data.firstName || emptyValue}
                </div>
              </Form.Field>
            )}
          </Form.Group>

          <Form.Group widths="equal">
            {editMode ? (
              <Form.Field
                name="middleInitial"
                onChange={this.handleChange}
                value={data.middleInitial}
                placeholder="Middle Initial"
                control={Input}
                id="userMiddleName"
                aria-labelledby="userMiddleName"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userMiddleName"
                      label="Middle Initial"
                    />
                  ),
                  htmlFor: 'userMiddleName',
                }}
              />
            ) : (
              <Form.Field>
                <label>
                  Middle Initial
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userMiddleName"
                  />
                </label>
                <div className="default-value">
                  {data.middleInitial || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                required
                error={!!errors.lastName}
                name="lastName"
                onChange={this.handleChange}
                value={data.lastName}
                placeholder="Last Name"
                control={Input}
                id="userLastName"
                aria-labelledby="userLastName"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userLastName"
                      label="Last Name"
                    />
                  ),
                  htmlFor: 'userLastName',
                }}
              />
            ) : (
              <Form.Field required>
                <label>
                  Last Name
                  <AmsHelpText category="helpText" fieldLabel="userLastName" />
                </label>
                <div className="default-value">
                  {data.lastName || emptyValue}
                </div>
              </Form.Field>
            )}
          </Form.Group>

          <Form.Group widths="equal">
            {editMode ? (
              <Form.Field
                error={!!errors.homePhone}
                name="homePhone"
                onChange={this.handleChange}
                value={data.homePhone}
                placeholder="Home Phone"
                control={Input}
                required
                id="userHomePhone"
                aria-labelledby="userHomePhone"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userHomePhone"
                      label="Home Phone"
                    />
                  ),
                  htmlFor: 'userHomePhone',
                }}
              />
            ) : (
              <Form.Field required>
                <label>
                  Home Phone
                  <AmsHelpText category="helpText" fieldLabel="userHomePhone" />
                </label>
                <div className="default-value">
                  {data.homePhone || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                error={!!errors.cellPhone}
                name="cellPhone"
                onChange={this.handleChange}
                value={data.cellPhone}
                placeholder="Cell Phone"
                required
                control={Input}
                id="userCellPhone"
                aria-labelledby="userCellPhone"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userCellPhone"
                      label="Cell Phone"
                    />
                  ),
                  htmlFor: 'userCellPhone',
                }}
              />
            ) : (
              <Form.Field required>
                <label>
                  Cell Phone
                  <AmsHelpText category="helpText" fieldLabel="userCellPhone" />
                </label>
                <div className="default-value">
                  {data.cellPhone || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                name="homeFax"
                onChange={this.handleChange}
                value={data.homeFax}
                placeholder="Home Fax"
                control={Input}
                id="userFaxPhone"
                aria-labelledby="userFaxPhone"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userFaxPhone"
                      label="Home Fax"
                    />
                  ),
                  htmlFor: 'userFaxPhone',
                }}
              />
            ) : (
              <Form.Field>
                <label>
                  Home Fax
                  <AmsHelpText category="helpText" fieldLabel="userFaxPhone" />
                </label>
                <div className="default-value">
                  {data.homeFax || emptyValue}
                </div>
              </Form.Field>
            )}
          </Form.Group>

          <Form.Group widths="equal">
            {editMode ? (
              <Form.Input
                error={!!errors.email}
                name="email"
                onChange={this.handleChange}
                value={data.email}
                placeholder="Email"
                required
                control={Input}
                id="userPrimaryEmail"
                aria-labelledby="userPrimaryEmail"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userPrimaryEmail"
                      label="Email"
                    />
                  ),
                  htmlFor: 'userPrimaryEmail',
                }}
              />
            ) : (
              <Form.Field required>
                <label>
                  Email
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userPrimaryEmail"
                  />
                </label>
                <div className="default-value">{data.email || emptyValue}</div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Input
                error={!!errors.alternateEmail}
                name="alternateEmail"
                onChange={this.handleChange}
                value={data.alternateEmail}
                placeholder="Alternate Email"
                control={Input}
                id="userSecondaryEmail"
                aria-labelledby="userSecondaryEmail"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userSecondaryEmail"
                      label="Alternate Email"
                    />
                  ),
                  htmlFor: 'userSecondaryEmail',
                }}
              />
            ) : (
              <Form.Field>
                <label>
                  Alternate Email
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userSecondaryEmail"
                  />
                </label>
                <div className="default-value">
                  {data.alternateEmail || emptyValue}
                </div>
              </Form.Field>
            )}
          </Form.Group>

          <h3>Home Address</h3>
          <Form.Group widths="equal">
            {editMode ? (
              <Form.Field
                error={!!errors.homeAddrLine1}
                name="homeAddrLine1"
                onChange={this.handleChange}
                value={data.homeAddrLine1}
                placeholder="Home Address1"
                required
                control={Input}
                id="userAddress"
                aria-labelledby="userAddress"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userAddress"
                      label="Address Line1"
                    />
                  ),
                  htmlFor: 'userAddress',
                }}
              />
            ) : (
              <Form.Field required>
                <label>
                  Address Line1
                  <AmsHelpText category="helpText" fieldLabel="userAddress" />
                </label>
                <div className="default-value">
                  {data.homeAddrLine1 || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                name="homeAddrLine2"
                onChange={this.handleChange}
                value={data.homeAddrLine2}
                placeholder="Home Address2"
                control={Input}
                id="userAdditionalAddress"
                aria-labelledby="userAdditionalAddress"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userAdditionalAddress"
                      label="Address Line2"
                    />
                  ),
                  htmlFor: 'userAdditionalAddress',
                }}
              />
            ) : (
              <Form.Field>
                <label>
                  Address Line2
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userAdditionalAddress"
                  />
                </label>
                <div className="default-value">
                  {data.homeAddrLine2 || emptyValue}
                </div>
              </Form.Field>
            )}
          </Form.Group>

          <Form.Group widths="equal">
            {editMode ? (
              <Form.Input
                error={!!errors.homeAddrCity}
                name="homeAddrCity"
                onChange={this.handleChange}
                value={data.homeAddrCity}
                placeholder="City"
                required
                control={Input}
                id="userCity"
                aria-labelledby="userCity"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userCity"
                      label="City"
                    />
                  ),
                  htmlFor: 'userCity',
                }}
              />
            ) : (
              <Form.Field required>
                <label>
                  City
                  <AmsHelpText category="helpText" fieldLabel="userCity" />
                </label>
                <div className="default-value">
                  {data.homeAddrCity || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field required>
                <AmsFormLabel
                  helpText={true}
                  fieldLabel="userState"
                  name="State"
                >
                  <AmsLookupDropdown
                    error={!!errors.homeAddrState}
                    name="homeAddrState"
                    search
                    selection
                    onChange={this.handleChange}
                    value={data.homeAddrState}
                    category={'stateNames'}
                    placeholder="State"
                    required
                    id="userState"
                    aria-labelledby="userState"
                    searchInput={{
                      id: 'userStateSearch',
                      title: 'User settings roles search',
                      'aria-labelledby': 'userState',
                    }}
                  />
                </AmsFormLabel>
              </Form.Field>
            ) : (
              <Form.Field required>
                <label>
                  State
                  <AmsHelpText category="helpText" fieldLabel="userState" />
                </label>
                <div className="default-value">
                  {data.homeAddrState || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                error={!!errors.homeAddrZip}
                name="homeAddrZip"
                onChange={this.handleChange}
                value={data.homeAddrZip}
                placeholder="Zip"
                required
                control={Input}
                id="userZipcode"
                aria-labelledby="userZipcode"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userZipcode"
                      label="Zip"
                    />
                  ),
                  htmlFor: 'userZipcode',
                }}
              />
            ) : (
              <Form.Field required>
                <label>
                  Zip
                  <AmsHelpText category="helpText" fieldLabel="userZipcode" />
                </label>
                <div className="default-value">
                  {data.homeAddrZip || emptyValue}
                </div>
              </Form.Field>
            )}
          </Form.Group>

          <h3>Fedex Address (if home address is P. O. Box)</h3>
          <Form.Group widths="equal">
            {editMode ? (
              <Form.Field
                name="fedExAddrLine1"
                onChange={this.handleChange}
                value={data.fedExAddrLine1}
                placeholder="Fedex Address1"
                control={Input}
                id="fedExAddrLine1"
                aria-labelledby="fedExAddrLine1"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userAddress"
                      label="Address Line1"
                    />
                  ),
                  htmlFor: 'fedExAddrLine1',
                }}
              />
            ) : (
              <Form.Field>
                <label>
                  Address Line1
                  <AmsHelpText category="helpText" fieldLabel="userAddress" />
                </label>
                <div className="default-value">
                  {data.fedExAddrLine1 || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                name="fedExAddrLine2"
                onChange={this.handleChange}
                value={data.fedExAddrLine2}
                placeholder="Fedex Address2"
                control={Input}
                id="fedExAddrLine2"
                aria-labelledby="fedExAddrLine2"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userAdditionalAddress"
                      label="Address Line2"
                    />
                  ),
                  htmlFor: 'fedExAddrLine2',
                }}
              />
            ) : (
              <Form.Field>
                <label>
                  Address Line2
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userAdditionalAddress"
                  />
                </label>
                <div className="default-value">
                  {data.fedExAddrLine2 || emptyValue}
                </div>
              </Form.Field>
            )}
          </Form.Group>

          <Form.Group widths="equal">
            {editMode ? (
              <Form.Field
                name="fedExAddrCity"
                onChange={this.handleChange}
                value={data.fedExAddrCity}
                placeholder="City"
                control={Input}
                id="fedExAddrCity"
                aria-labelledby="fedExAddrCity"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userCity"
                      label="City"
                    />
                  ),
                  htmlFor: 'fedExAddrCity',
                }}
              />
            ) : (
              <Form.Field>
                <label>
                  City
                  <AmsHelpText category="helpText" fieldLabel="userCity" />
                </label>
                <div className="default-value">
                  {data.fedExAddrCity || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field required>
                <AmsFormLabel
                  helpText={true}
                  fieldLabel="fedExAddrState"
                  name="State"
                >
                  <AmsLookupDropdown
                    name="fedExAddrState"
                    search
                    selection
                    onChange={this.handleChange}
                    value={data.fedExAddrState}
                    category={'stateNames'}
                    placeholder="State"
                    id="fedExAddrState"
                    aria-labelledby="fedExAddrState"
                    searchInput={{
                      id: 'fedExAddrStateSearch',
                      title: 'User settings roles search',
                      'aria-labelledby': 'fedExAddrState',
                    }}
                  />
                </AmsFormLabel>
              </Form.Field>
            ) : (
              <Form.Field>
                <label>
                  State
                  <AmsHelpText category="helpText" fieldLabel="userState" />
                </label>
                <div className="default-value">
                  {data.fedExAddrState || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                error={!!errors.fedExAddrZip}
                name="fedExAddrZip"
                onChange={this.handleChange}
                value={data.fedExAddrZip}
                placeholder="Zip"
                control={Input}
                id="fedExAddrZip"
                aria-labelledby="fedExAddrZip"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userZipcode"
                      label="Zip"
                    />
                  ),
                  htmlFor: 'fedExAddrZip',
                }}
              />
            ) : (
              <Form.Field>
                <label>
                  Zip
                  <AmsHelpText category="helpText" fieldLabel="userZipcode" />
                </label>
                <div className="default-value">
                  {data.fedExAddrZip || emptyValue}
                </div>
              </Form.Field>
            )}
          </Form.Group>

          <h3>Emergency Contact (Primary)</h3>
          <Form.Group widths="equal">
            {editMode ? (
              <Form.Input
                error={!!errors.primaryContactName}
                name="primaryContactName"
                onChange={this.handleChange}
                value={data.primaryContactName}
                placeholder="Name"
                required
                control={Input}
                id="primaryContactName"
                aria-labelledby="primaryContactName"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userEmergencyFullName"
                      label="Name"
                    />
                  ),
                  htmlFor: 'primaryContactName',
                }}
              />
            ) : (
              <Form.Field required>
                <label>
                  Name
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userEmergencyFullName"
                  />
                </label>
                <div className="default-value">
                  {data.primaryContactName || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                error={!!errors.primaryContactPhone}
                name="primaryContactPhone"
                onChange={this.handleChange}
                value={data.primaryContactPhone}
                placeholder="Phone"
                required
                control={Input}
                id="primaryContactPhone"
                aria-labelledby="primaryContactPhone"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userEmergencyPhone"
                      label="Phone"
                    />
                  ),
                  htmlFor: 'primaryContactPhone',
                }}
              />
            ) : (
              <Form.Field required>
                <label>
                  Phone
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userEmergencyPhone"
                  />
                </label>
                <div className="default-value">
                  {data.primaryContactPhone || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                error={!!errors.primaryContactRelation}
                name="primaryContactRelation"
                onChange={this.handleChange}
                value={data.primaryContactRelation}
                placeholder="Relationship"
                required
                control={Input}
                id="primaryContactRelation"
                aria-labelledby="primaryContactRelation"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userEmergencyRelationship"
                      label="Relationship"
                    />
                  ),
                  htmlFor: 'primaryContactRelation',
                }}
              />
            ) : (
              <Form.Field required>
                <label>
                  Relationship
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userEmergencyRelationship"
                  />
                </label>
                <div className="default-value">
                  {data.primaryContactRelation || emptyValue}
                </div>
              </Form.Field>
            )}
          </Form.Group>

          <h3>Emergency Contact (Secondary)</h3>
          <Form.Group widths="equal">
            {editMode ? (
              <Form.Field
                name="secondaryContactName"
                onChange={this.handleChange}
                value={data.secondaryContactName}
                placeholder="Name"
                control={Input}
                id="secondaryContactName"
                aria-labelledby="secondaryContactName"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userEmergencySecondFull"
                      label="Name"
                    />
                  ),
                  htmlFor: 'secondaryContactName',
                }}
              />
            ) : (
              <Form.Field>
                <label>
                  Name
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userEmergencySecondFull"
                  />
                </label>
                <div className="default-value">
                  {data.secondaryContactName || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                error={!!errors.secondaryContactPhone}
                name="secondaryContactPhone"
                onChange={this.handleChange}
                value={data.secondaryContactPhone}
                placeholder="Phone"
                control={Input}
                id="secondaryContactPhone"
                aria-labelledby="secondaryContactPhone"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userEmergencySecondPhone"
                      label="Phone"
                    />
                  ),
                  htmlFor: 'secondaryContactPhone',
                }}
              />
            ) : (
              <Form.Field>
                <label>
                  Phone
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userEmergencySecondPhone"
                  />
                </label>
                <div className="default-value">
                  {data.secondaryContactPhone || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Input
                name="secondaryContactRelation"
                onChange={this.handleChange}
                value={data.secondaryContactRelation}
                placeholder="Relationship"
                control={Input}
                id="secondaryContactRelation"
                aria-labelledby="secondaryContactRelation"
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userEmergencySecondRelationship"
                      label="Relationship"
                    />
                  ),
                  htmlFor: 'secondaryContactRelation',
                }}
              />
            ) : (
              <Form.Field>
                <label>
                  Relationship
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userEmergencySecondRelationship"
                  />
                </label>
                <div className="default-value">
                  {data.secondaryContactRelation || emptyValue}
                </div>
              </Form.Field>
            )}
          </Form.Group>
        </div>

        <Segment basic floated="right">
          {editMode ? (
            <div>
              <Button size="large" primary onClick={e => this.onSubmit(e)}>
                Save
              </Button>
              <Button
                size="large"
                onClick={e => {
                  e.preventDefault();
                  this.cancelEdit();
                }}
                basic
              >
                Cancel
              </Button>
            </div>
          ) : (
            this.showEditButton()
          )}
        </Segment>
      </Form>
    );
  }
}

ContactInformationForm.propTypes = {
  profile: PropTypes.object,
  rolesList: PropTypes.array,
  lookups: PropTypes.object,
  profileTab: PropTypes.string.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserEmailOnAzure: PropTypes.func.isRequired,
  updateUserRoles: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  lookups: state.lookups,
  profile: state.profile,
  profileTab: state.profileTab,
  rolesList: state.admin.userRoles,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, { updateUserProfile, updateUserRoles, updateUserEmailOnAzure })(
  ContactInformationForm
);
