import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

// Import actions
import { fetchReviewFollowUps } from '../../../actions/reviewActions';

import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsTable from '../../../utils/AmsTable';

class FollowUpsListItems extends Component {
  state = {
    loading: false,
    selectedReview: {},
    reviewSubmissions: [],
    filters: {},
    followUps: {
      data: [],
      page: 1,
      limit: 25,
      total: 0,
    },
  };

  componentDidMount = () => {
    if (
      this.props.reviewFollowUpsList &&
      this.props.reviewFollowUpsList.length > 0
    )
      this.setState({ followUps: this.props.reviewFollowUpsList });
    else this.loadData();
  };

  componentWillReceiveProps = nextProps => {
    this.setState({ followUps: nextProps.reviewFollowUpsList });
  };

  loadData = () => {
    const { page, limit, sortName, sortOrder } = this.state;
    const reviewId = this.context.router.params.id;

    if (isEmpty(reviewId)) return null;

    this.setState({ loading: true });

    let input = {
      filters: {
        parentReviewId: reviewId,
      },
      page: page ? page : 1,
      limit: limit ? limit : 25,
      sortName: sortName ? sortName : 'dueDate',
      sortOrder: sortOrder ? sortOrder : 'desc',
    };

    this.props
      .fetchReviewFollowUps(input)
      .then(() => this.setState({ loading: false }))
      .catch(error => {
        this.setState({
          loading: false,
          errors: {
            ...this.state.errors,
            fetchReviewSubmissions:
              (error.response && error.response.data.message) || '',
          },
        });
      });
  };

  renderFollowUpReviews = () => {
    const {
      followUps: { data, page, total },
    } = this.state;

    const dateFormatter = (cell, row) => AmsDateFormatters.formatDate(cell);

    const linkId = (cell, row) => {
      if (
        (row && row.newFAFlowFlag) ||
        (row && row.reviewType === 'RAN') ||
        (row && row.fiscalYear && parseInt(row.fiscalYear) > 2018)
      ) {
        let easReview = row.reviewType === 'FA1-R' ? '/new' : '';
        let fiscalYear = row.fiscalYear
          ? `fy` + row.fiscalYear.toString().slice(-2)
          : 'fy19';
        return (
          <Link
            to={{
              pathname: `/review${easReview}/${fiscalYear}/${cell}`,
              state: { reviewType: `${row.reviewType}` },
            }}
          >
            {cell}
          </Link>
        );
      }
      return (
        <Link
          to={{
            pathname: `/review/${cell}`,
            state: { reviewType: `${row.reviewType}` },
          }}
        >
          {cell}
        </Link>
      );
    };

    const scrollToRef = () => {
      window.scrollTo(0, this.amsTableRef.current.offsetTop);
      this.amsTableRef.current.focus();
    };

    const onSizePerPageChange = (sizePerPage, page) =>
      this.setState(
        {
          page,
          limit: sizePerPage,
        },
        () => {
          this.loadData();

          // Go to the top of the summary table
          scrollToRef();
        }
      );

    const onPageChange = (page, sizePerPage) =>
      this.setState(
        {
          page,
          limit: sizePerPage,
        },
        () => {
          this.loadData();

          // Go to the top of the summary table
          scrollToRef();
        }
      );

    const onTableChange = (type, { sortField, sortOrder }) => {
      this.setState(
        {
          sortName: sortField,
          sortOrder: this.state.sortOrder === 'desc' ? 'asc' : 'desc',
        },
        () => this.loadData()
      );
    };

    const columns = [
      {
        dataField: 'reviewId',
        text: 'Review ID',
        formatter: linkId,
        sort: true,
      },
      {
        dataField: 'granteeId',
        text: 'Grantee ID',
        sort: true,
      },
      {
        dataField: 'granteeName',
        text: 'Grantee Name',
        sort: true,
      },
      {
        dataField: 'numberOfGrants',
        text: 'Number of Grants',
        sort: true,
      },
      {
        dataField: 'reviewType',
        text: 'Review Type',
        sort: true,
      },
      {
        dataField: 'dueDate',
        text: 'Due Date',
        formatter: dateFormatter,
        sort: true,
      },
      {
        dataField: 'outCome',
        text: 'Outcome',
        sort: true,
      },
      {
        dataField: 'workFlowStatus',
        text: 'Review Status',
        sort: true,
      },
    ];

    return (
      <div ref={this.amsTableRef} tabIndex="-1">
        <AmsTable
          remote
          data={data}
          page={page}
          total={total}
          limit={this.state.limit}
          loading={this.state.loading}
          columns={columns}
          keyField="_id"
          onTableChange={onTableChange}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
        />
      </div>
    );
  };

  render() {
    return <>{this.renderFollowUpReviews()}</>;
  }
}

FollowUpsListItems.contextTypes = {
  router: PropTypes.object.isRequired,
};

FollowUpsListItems.propTypes = {
  selectedReview: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
  reviewFollowUpsList: state.review.reviewFollowUpsList,
});

export default connect(mapStateToProps, { fetchReviewFollowUps })(
  FollowUpsListItems
);
