import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import _ from 'lodash';
import AmsTable from '../../utils/AmsTable';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AmsAlert from '../../utils/AmsAlert';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

import {
  Loader,
  Dimmer,
  Card,
  Form,
  Statistic,
  Dropdown,
} from 'semantic-ui-react';
import AmsLookupDropdown from '../../utils/AmsLookupDropdown';

import pdfIcon from '../../assets/images/pdf-icon.png';

import SurveyEditForm from '../Survey/SurveyEditForm';
import enforceRole from '../../utils/EnforceRole';
import ReportPreview from '../OldReports/ReportPreview';

// Imort UI config
import { acl } from '../../config';

// Import actions.
import {
  fetchSurvyeSubmission,
  surveySubmissionFetched,
  finalizeSurveys,
  fetchSurveyOutcomes,
  // surveyOutcomesSet,
} from '../../actions/surveyActions';
import {
  fetchReviewDetail,
  fetchReviewSurveySubmissions,
  fetchReviewSurveySubmitters,
  fetchFindingDetails,
} from '../../actions/reviewActions';

class SurveyItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finalizing: false,
      showAlert: false,
      modalIsOpen: false,
      selectedRow: {},
      selectedSurveys: [],
      filters: {
        submitter: [],
        surveyName: [],
        surveyType: [],
      },
    };

    this.closeModal = this.closeModal.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  componentDidMount = () => {
    this.getSubmissions();
    this.getSubmitters();
  };

  getSubmissions = () => {
    const { selectedReview } = this.props;
    const { filters } = this.state;

    if (selectedReview.reviewId)
      this.props.fetchReviewSurveySubmissions({
        reviewId: selectedReview.reviewId,
        filters,
      });
  };

  getSubmitters = () => {
    const { selectedReview } = this.props;
    if (selectedReview.reviewId)
      this.props.fetchReviewSurveySubmitters(selectedReview.reviewId);
  };

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  showModal() {
    const { modalIsOpen, selectedRow } = this.state;
    const { selectedSubmission, selectedReview } = this.props;
    return (
      <Modal
        className="modal-container"
        overlayClassName="modal-overlay-container"
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Task Detail"
      >
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-11">
              <h2 className="no-top-margin">
                <span className="modal-title">Review ID:</span>{' '}
                {selectedReview && selectedReview.reviewId}
              </h2>
            </div>

            <div className="col-sm-1">
              <span onClick={this.closeModal} className="clickable">
                <i className="fa fa-close fa-2x" />
              </span>
            </div>

            <div className="col-sm-12">
              <hr />
            </div>
            <br />

            <div className="col-sm-6 review-detail">
              {selectedRow && selectedRow.surveyName && (
                <div>
                  <span className="field-title"> Survey Name: </span>
                  {selectedRow.surveyName}
                </div>
              )}
            </div>

            <div className="col-sm-12 review-detail">
              {selectedReview && selectedReview.grantees && (
                <div>
                  <span className="field-title"> Grantee: </span>
                  {selectedReview.grantees.length
                    ? `${selectedReview.grantees[0].granteeName} (${selectedReview.grantees[0].granteeId})`
                    : null}
                </div>
              )}
            </div>

            <div className="col-sm-6 review-detail">
              {selectedReview.manifestLink && selectedRow.type === 'formal' && (
                <div>
                  <span className="field-title">Manifest File: </span>
                  <a href={selectedReview.manifestLink}>Download</a>{' '}
                  {
                    <img
                      alt="PDF icon"
                      className="manifest-pdf-icon"
                      src={pdfIcon}
                    />
                  }
                </div>
              )}
            </div>

            <div className="col-sm-12 review-detail">
              {selectedRow.classNo && selectedRow.type === 'formal' && (
                <div>
                  <span className="field-title">Class No.: </span>
                  {selectedRow.classNo}
                </div>
              )}
            </div>

            <div className="col-sm-12 review-detail">
              {selectedRow.reviewStatus && selectedRow.type === 'formal' && (
                <div>
                  <span className="field-title">Status No.: </span>
                  {selectedRow.reviewStatus}
                </div>
              )}
            </div>

            <div className="col-sm-12 review-detail">
              {selectedRow.reviewer && (
                <div>
                  <span className="field-title">Reviewer: </span>
                  {selectedRow.reviewer}
                </div>
              )}
            </div>

            <div className="col-sm-12 review-detail">
              {selectedRow.reviewLead && (
                <div>
                  <span className="field-title">Reviewer Lead: </span>
                  {selectedRow.reviewLead}
                </div>
              )}
            </div>

            <div className="col-sm-12">
              <hr />
            </div>

            <div className="col-sm-12">
              {selectedSubmission && (
                <SurveyEditForm
                  selectedReview={selectedReview}
                  finalized={selectedRow.submissionFinalized} // If undefined hide edit button.
                  type={selectedRow.type}
                  selectedSubmission={selectedSubmission}
                />
              )}
            </div>

            <div className="col-sm-12">
              <hr />
            </div>
            <br />

            <div className="col-sm-12 submission-version-table">
              {selectedSubmission && selectedSubmission.versionsQA
                ? this.showVersionTable(selectedSubmission.versionsQA)
                : 'Version information not found.'}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  handleVersionClick(version, event) {
    event.preventDefault();

    const { selectedSubmission, selectedReview } = this.props;

    const requestInput = {
      reviewId: selectedReview.reviewId,
      submissionId: selectedSubmission.submissionId,
      koboFormId: selectedSubmission.koboFormId,
      version,
    };

    if (
      requestInput.reviewId &&
      requestInput.submissionId &&
      requestInput.koboFormId &&
      requestInput.version
    ) {
      this.setState({ loading: true });
      this.props.fetchSurvyeSubmission(requestInput).catch(error => {
        const { message } = error.response.data;
        console.error(message);
      });
    }
  }

  showVersionTable(version) {
    const { selectedSubmission } = this.props;

    const formatVersion = (cell, row) => {
      if (selectedSubmission.version === cell)
        return `v${parseFloat(cell).toFixed(1)}`;

      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="" onClick={this.handleVersionClick.bind(this, cell)}>
          v{parseFloat(cell).toFixed(1)}
        </a>
      );
    };

    const formatDate = (cell, row) => {
      return AmsDateFormatters.formatDateTime(cell);
    };

    const columns = [
      {
        dataField: 'version',
        text: 'Version',
        sort: true,
        formatter: formatVersion,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editedByName',
        text: 'Edited By',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editedTime',
        sort: true,
        formatter: formatDate,
        style: { whiteSpace: 'normal' },
        text: 'Edited Date',
      },
    ];
    if (!_.isEmpty(version))
      return (
        <AmsTable
          remote={false}
          basic
          total={version.length}
          columns={columns}
          keyField="version"
          ref="table"
          data={
            version &&
            _.orderBy(
              version,
              v => {
                return parseFloat(v.version);
              },
              'desc'
            )
          }
        />
      );
  }

  handleRowClick(row) {
    const requestInput = {
      reviewId: this.props.selectedReview.reviewId,
      submissionId: row.submissionId,
      koboFormId: row.koboFormId,
    };

    this.props.surveySubmissionFetched({});
    this.props.fetchSurvyeSubmission(requestInput);
    this.setState({ selectedRow: row, modalIsOpen: true });
  }

  // Handle single select
  handleRowSelect(row, isSelected, e) {
    const { selectedSurveys } = this.state;
    let selections = [];

    if (isSelected) {
      selections = [...selectedSurveys, row];
    }
    if (!isSelected) {
      selections = _.pull(selectedSurveys, row);
    }

    this.setState({
      ...this.state,
      selectedSurveys: selections,
    });
  }

  // Handle multi select
  handleSelectAll(isSelected, rows) {
    const { selectedSurveys } = this.state;
    let selections = [];

    if (isSelected) {
      selections = rows;
    }
    if (!isSelected) {
      selections = _.pull(selectedSurveys, ...rows);
    }

    this.setState({
      ...this.state,
      selectedSurveys: selections,
    });
  }

  renderTable() {
    const { surveys } = this.props;
    const { selectedSurveys, finalizing } = this.state;

    const options = {
      onRowClick: row => {
        this.handleRowClick(row);
      },
    };

    // Ignore finalized submissions from being checked.
    const unselectables =
      (surveys &&
        surveys.length &&
        // eslint-disable-next-line array-callback-return
        surveys.map(survey => {
          if (survey.submissionFinalized === true) return survey.submissionId;
        })) ||
      [];

    const selectRow = {
      mode: 'checkbox', // multi select,
      selected: selectedSurveys.map(item => item.submissionId),
      unselectable: unselectables,
      columnWidth: '3px',
      onSelect: this.handleRowSelect.bind(this),
      onSelectAll: this.handleSelectAll.bind(this),
    };

    const formatDate = (cell, row) => AmsDateFormatters.formatDateTime(cell);

    const columns = [
      {
        hidden: true,
        dataField: 'submissionId',
        text: 'Submission ID',
        isKey: true,
        sort: true,
      },
      {
        dataField: 'surveyName',
        text: 'Survey Name',
        sort: true,
        headerStyle: { width: '40%' },
      },
      {
        dataField: 'reviewer',
        text: 'Submitter',
        sort: true,
        headerStyle: { width: '20%' },
      },
      {
        dataField: 'submissionTime',
        text: 'Date Submitted (Eastern Time)',
        formatter: formatDate,
        sort: true,
        headerStyle: { width: '30%' },
      },
      {
        dataField: 'surveyStatus',
        text: 'Status',
        headerStyle: { width: '10%' },
        sort: true,
      },
    ];

    return finalizing ? (
      <Dimmer active inverted>
        <Loader inverted>Finalizing Surveys...</Loader>
      </Dimmer>
    ) : (
      <div>
        <BootstrapTable
          keyField="submissionId"
          ref="table"
          trClassName="clickable"
          data={surveys}
          tableContainerClass="table-container"
          hover
          condensed
          bordered={false}
          options={options}
          selectRow={selectRow}
          columns={columns}
          pagination={surveys?.length > 10 ? paginationFactory() : undefined}
          headerWrapperClasses="table-header"
          bodyClasses="table-body"
        />
      </div>
    );
  }

  handleFinalizeSurveyClick(e) {
    e.preventDefault();

    this.setState({ showAlert: true });
  }

  renderFinalizeSurveyButton() {
    const { currentUser } = this.props;
    const { selectedSurveys } = this.state;

    return (
      <div className="text-right finalize-survey">
        {selectedSurveys.length !== 0 &&
          enforceRole(
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="" onClick={this.handleFinalizeSurveyClick.bind(this)}>
              {' '}
              Approve ({selectedSurveys.length}) Survey Data
            </a>,
            acl.actions.survey.finalize, // Allowed roles
            currentUser.roles // Current user roles
          )}
      </div>
    );
  }

  showConfirmationAlert() {
    const { selectedSurveys } = this.state;
    const { selectedReview } = this.props;

    return (
      <AmsAlert
        show={this.state.showAlert}
        title="Finalize Surveys"
        text={`You are about to finalize ${selectedSurveys.length} surveys. Are you sure?`}
        type={'warning'}
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes, finalize surveys'}
        cancelButtonText={'No, cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({ showAlert: false });
        }}
        onConfirm={() => {
          this.setState({ finalizing: true });

          const surveys = selectedSurveys.map(survey => ({
            submissionId: survey.submissionId,
            koboFormId: survey.koboFormId,
            reviewId: selectedReview.reviewId,
          }));

          this.props
            .finalizeSurveys(surveys, selectedReview)
            .then(() =>
              this.props
                .fetchReviewDetail({ reviewId: selectedReview.reviewId })
                .then(() => this.setState({ finalizing: false }))
                .catch(() => this.setState({ finalizing: false }))
            )
            .catch(() => this.setState({ finalizing: false }));

          this.setState({ selectedSurveys: [], showAlert: false });
        }}
      />
    );
  }

  renderCards() {
    const {
      reviewId,
      showFindingsButton,
      reviewStatus,
    } = this.props.selectedReview;
    const { observations, currentUser } = this.props;

    const cards = observations.map(observation => {
      const cardLink = `/review/${reviewId}/survey-outcome/${
        observation.koboFormId
      }/${observation.surveyName
        .trim()
        .split(' ')
        .join('-')
        .toLocaleLowerCase()}`;
      const surveyName = observation.surveyName.trim();
      const currentCount = observation.currentCount;
      const cardCount =
        surveyName === 'Data Collection' && (!currentCount || currentCount >= 0)
          ? ''
          : `${observation.currentCount}/${observation.targetCount}`;

      if (surveyName) {
        const aclKey = `${_.camelCase(surveyName)}Card`;
        return enforceRole(
          <Card as={Link} to={cardLink} target="_blank" key={surveyName}>
            <Card.Content textAlign="center">
              <Card.Header size="small">{surveyName}</Card.Header>
              <Statistic size="small">
                <Statistic.Value>{cardCount}</Statistic.Value>
              </Statistic>
            </Card.Content>
          </Card>,
          acl.actions.survey[aclKey],
          currentUser.roles
        );
      }
      return null;
    });

    // Build report template card.
    const reportsTemplateCard =
      this.props.currentUser.roles.indexOf('ReviewLead') !== -1
        ? null
        : enforceRole(
            <Card key="staticDebrief">
              <Card.Content>
                <Card.Header>&nbsp;</Card.Header>
                <Card.Description className="center aligned">
                  {!(
                    reviewStatus === 'Shipped' || reviewStatus === 'Completed'
                  ) && <ReportPreview />}
                  <button
                    disabled={!showFindingsButton}
                    onClick={ev => {
                      window.open(
                        `/review/${reviewId}/findings`,
                        'findings',
                        'resizable,scrollbars=yes,status=1'
                      );
                    }}
                    className="btn btn-sm btn-primary"
                  >
                    Findings
                    {(props => {
                      let count = (
                        (this.props.review || {}).fetchedFindingDetails || {}
                      ).count;
                      if (count) {
                        return ' (' + count + ')';
                      }
                      return ' (0)';
                    })(this.props)}
                  </button>
                </Card.Description>
              </Card.Content>
            </Card>,
            acl.actions.survey.reportTemplateCard, // list of roles that can access card.
            currentUser.roles // current user roles
          );

    // Add report template card and return cards.
    return [...cards, reportsTemplateCard];
  }

  renderSurveyOutcomes() {
    const { reviewType } = this.props.selectedReview;

    if (reviewType === 'FA-2') {
      return (
        <div className="summary-cards">
          <Card.Group stackable itemsPerRow={4}>
            {this.renderCards()}
          </Card.Group>
          <div>
            <hr />
          </div>
        </div>
      );
    }
  }

  handleChange = (e, { name, value }) =>
    this.setState({ filters: { ...this.state.filters, [name]: [value] } });

  renderFilters = () => {
    const { surveyType, surveyName, submitter } = this.state.filters;
    const { submitters } = this.props;

    return (
      <Form>
        <Form.Group>
          <Form.Field
            label={'Survey Type'}
            width={4}
            name="surveyType"
            placeholder="Select Survey Type"
            selection
            search
            control={AmsLookupDropdown}
            value={surveyType[0] || ''}
            category={'surveyTypes'}
            onChange={this.handleChange}
          />

          <Form.Field
            width={4}
            label={'Survey Name'}
            name="surveyName"
            placeholder="Select Survey Name"
            selection
            search
            control={AmsLookupDropdown}
            value={surveyName[0] || ''}
            category={'surveyNames'}
            onChange={this.handleChange}
          />

          <Form.Field
            width={4}
            label={'Submitter'}
            name="submitter"
            placeholder="Select Submitter"
            selection
            search
            control={Dropdown}
            value={submitter[0] || ''}
            onChange={this.handleChange}
            options={submitters.map(submitter => ({
              key: submitter.id,
              text: submitter.name,
              value: submitter.oid,
            }))}
          />

          <Form.Button
            label="&nbsp;"
            primary
            content="Filter"
            onClick={e => {
              e.preventDefault();
              this.getSubmissions();
            }}
          />

          <Form.Button
            label="&nbsp;"
            basic
            content="Reset"
            onClick={e => {
              e.preventDefault();
              this.setState(
                {
                  filters: {
                    submitter: [],
                    surveyName: [],
                    surveyType: [],
                  },
                },
                () => this.getSubmissions()
              ); // Clear filters then fetch data again.
            }}
          />
        </Form.Group>
      </Form>
    );
  };

  render() {
    let reviewId = (this.props.selectedReview || {}).reviewId || null;
    if (!_.isEmpty(reviewId) && this.findingsForReviewId !== reviewId) {
      this.findingsForReviewId = reviewId;
      this.props.fetchFindingDetails(
        {
          reviewId: reviewId,
        },
        !this.props.selectedReview.newFAFlowFlag
      );
    }

    return (
      <div>
        <div>
          <hr />
        </div>
        {this.showConfirmationAlert()}
        {this.showModal()}
        {this.renderSurveyOutcomes()}
        {this.renderFilters()}
        {this.renderFinalizeSurveyButton()}
        {this.renderTable()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedSubmission: state.survey.selectedSubmission,
    selectedReview: state.review.selectedReview,
    surveys: state.review.reviewSurveys.submissions,
    submitters: state.review.reviewSurveys.submitters,
    observations: state.review.selectedReview.observations || [],
    currentUser: state.auth.user,
    review: state.review,
  };
}

export default connect(mapStateToProps, {
  fetchReviewSurveySubmitters,
  fetchReviewSurveySubmissions,
  fetchSurvyeSubmission,
  fetchSurveyOutcomes,
  // surveyOutcomesSet,
  surveySubmissionFetched,
  finalizeSurveys,
  fetchReviewDetail,
  fetchFindingDetails,
})(SurveyItems);
