import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Loader, Dimmer } from 'semantic-ui-react';

import enforceRole from '../../utils/EnforceRole';

// Imort UI config
import { acl } from '../../config';

// Import actions.
import { fetchTaskDetail } from '../../actions/taskActions';

import './assets/style.css';

const linkSurveyName = (cell, row) => {
  return (
    <div>
      <a target="_blank" href={row.link} rel="noopener noreferrer">
        {cell}
      </a>{' '}
      {row.isOptional ? '(Optional)' : ''}
    </div>
  );
};
const columns = [
  {
    dataField: 'link',
    text: 'Link',
    hidden: true,
    isKey: true,
  },
  {
    dataField: 'SurveyName',
    text: 'Survey Name',
    formatter: linkSurveyName,
  },
  {
    dataField: 'submissionCount',
    text: 'Survey Submission Count',
    align: 'center',
    headerAlign: 'center',
    headerStyle: {
      width: '40%',
    },
  },
];

class TaskTabs extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    const { reviewId } = this.props;

    if (reviewId) this.getData(reviewId);
  }

  getData(reviewId) {
    this.setState({ loading: true });
    this.props
      .fetchTaskDetail(reviewId)
      .then(() => this.setState({ loading: false }))
      .catch(error => {
        this.setState({ loading: false });
        console.error(error);
      });
  }

  render() {
    const { loading } = this.state;
    const { taskDetail, currentUser } = this.props;

    if (_.isEmpty(taskDetail)) return null;

    const { presiteSurvey, onsiteSurvey, easSurvey } = taskDetail;

    return loading ? (
      <Dimmer active inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
    ) : (
      <Tabs defaultActiveKey={'onsite'} id="uncontrolled-tab-example">
        {onsiteSurvey && onsiteSurvey.length && (
          <Tab eventKey={'onsite'} title="Onsite">
            <div className="table-container">
              <BootstrapTable
                keyField="link"
                data={onsiteSurvey}
                columns={columns}
                bordered={false}
                pagination={
                  onsiteSurvey && onsiteSurvey.length > 10
                    ? paginationFactory()
                    : undefined
                }
                headerWrapperClasses="table-header"
                bodyClasses="table-body"
              />
            </div>
          </Tab>
        )}

        {presiteSurvey &&
          presiteSurvey.length &&
          enforceRole(
            <Tab eventKey={'presite'} title="Pre-Review">
              <div className="table-container">
                <BootstrapTable
                  keyField="link"
                  data={presiteSurvey}
                  columns={columns}
                  bordered={false}
                  pagination={
                    presiteSurvey && presiteSurvey.length > 10
                      ? paginationFactory()
                      : undefined
                  }
                  headerWrapperClasses="table-header"
                  bodyClasses="table-body"
                />
              </div>
            </Tab>,
            acl.actions.task.presiteTab, // Allowed roles
            currentUser.roles // Current user roles
          )}

        {easSurvey && easSurvey.length && (
          <Tab eventKey={'informal'} title="EAS Assessments">
            <div className="table-container">
              <BootstrapTable
                keyField="link"
                data={easSurvey}
                columns={columns}
                bordered={false}
                pagination={
                  easSurvey && easSurvey.length > 10
                    ? paginationFactory()
                    : undefined
                }
                headerWrapperClasses="table-header"
                bodyClasses="table-body"
              />
            </div>
          </Tab>
        )}
      </Tabs>
    );
  }
}
TaskTabs.propTypes = {
  currentUser: PropTypes.object.isRequired,
  reviewId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    taskDetail: state.taskDetail,
  };
}

export default connect(mapStateToProps, { fetchTaskDetail })(TaskTabs);
