import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Grid, Button } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { isObject } from 'lodash';

import AmsAlert from '../../../../utils/AmsAlert';

import { deleteEvidenceFileAttachment } from '../../../../actions/reviewActions';
import { fetchSurveyFotpFindings } from '../../../../actions/surveyActions';

const ArrayAttachmentTemplate = props => {
  useEffect(() => {
    if (adding) {
      if (lastChildRef.current) {
        let fileInput;
        setTimeout(() => {
          fileInput = lastChildRef.current.querySelector('input[type="file"]');
          if (fileInput) {
            fileInput.click();
            fileInput.addEventListener('cancel', () => {
              const lastIndex = props.items.length - 1;
              const lastItem = props.items[lastIndex];
              lastItem.onDropIndexClick(lastIndex)();
            });
          }
        }, 500);
      }
      // Reset adding state
      setAdding(false);
    }
  }, [adding, props.items]);

  const dispatch = useDispatch();
  const lastChildRef = React.useRef(null);
  const [state, setState] = useState({});
  const [adding, setAdding] = useState(false);
  const [
    showAddNewCitationFileAlert,
    setShowAddNewCitationFileAlert,
  ] = useState(false);
  const isMultiForm = useSelector(store =>
    store.survey.consolidatedQuestions ? false : true
  );
  const questionsData = useSelector(store =>
    store.survey.consolidatedQuestions
      ? store.survey.consolidatedQuestions
      : store.survey.multiSurveyForm
  );
  const { followUpCitations } = useSelector(
    state => state.review.followUpSurveyDetails
  );
  const answersData = useSelector(store => store.survey.consolidatedAnswers);

  const addCitationInfo = useSelector(
    store => store.survey.additionalCitationForm
  );

  const dcSessionId = questionsData?.dcSessionId;
  const questionFilters = questionsData?.filters;

  const renderDeleteConfirmation = () => {
    const {
      showDeleteConfirmationAlert,
      deleteEvent,
      deleteElement,
      deleteFileName,
    } = state;
    return (
      <AmsAlert
        show={showDeleteConfirmationAlert}
        title="Are you sure you want to delete this file"
        text={`You are about to delete this file. Are you sure you want to delete ${deleteFileName}?`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'Delete'}
        cancelButtonText={'Cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          setState({
            ...state,
            showDeleteConfirmationAlert: false,
          });
        }}
        onConfirm={async () => {
          if (deleteElement.children.props.formData) {
            let questionId = parseQuestionId(
              deleteElement.children.props.idSchema.$id
            );
            let questionCode;
            if (questionId) {
              questionCode = questionId;
              questionId =
                deleteElement.children.props.registry.rootSchema.properties[
                  questionId
                ].questionId;

              const input = {
                reviewId:
                  (questionFilters && questionFilters.reviewId) ||
                  props.formContext.reviewId,
                questionId: questionId,
                fileName: deleteElement.children.props.formData,
                dcSessionId: dcSessionId,
              };

              let citation = null;

              if (
                props.formContext &&
                props.formContext.attributes &&
                props.formContext.attributes.hasOwnProperty('citation') &&
                props.formContext.attributes.citation
              ) {
                let citationSessionId;
                citation =
                  answersData &&
                  answersData.surveyData &&
                  answersData.surveyData[questionCode] &&
                  answersData.surveyData[questionCode].CitationPicker;

                citationSessionId = addCitationInfo?.dcSessionId;

                if (!citation) {
                  citationSessionId = questionsData?.dcSessionId;
                  citation = questionsData.filters.citation;
                }
                input.dcSessionId = citationSessionId;
                input.citation = citation;
              }

              appendAccordionKey(input, questionCode);
              appendIsValidated(
                input,
                questionCode,
                deleteElement.children.props.formData
              );
              if (
                props.formContext.attributes.evidenceSource ==
                'Findings Outside the Protocol'
              ) {
                const keyss = props.idSchema.$id.split('_');
                const itemIndex = keyss[3];
                let findingInput = {
                  reviewId: props.formContext.reviewId,
                  findingIndex: parseInt(itemIndex),
                };
                const data = await dispatch(
                  fetchSurveyFotpFindings(findingInput)
                );
                const findingObj = data.fotpFindings.findings[0];
                if (findingObj) {
                  input.findingId = findingObj.findingId;
                  input.findingIndex = itemIndex;
                  input.citation = findingObj.CitationPicker;
                  input.contentArea = findingObj.CAPA.content_area;
                  input.performanceArea = findingObj.CAPA.performance_area;
                }
              }
              if (props.formContext.attributes.center) {
                input.centerId = props.formContext.attributes.center.centerId;

                input.centerName =
                  props.formContext.attributes.center.centerName;
              }
              if (props.formContext.attributes.classroom) {
                input.classSampleId =
                  props.formContext.attributes.classroom.classSampleId;

                input.classRoomName =
                  props.formContext.attributes.classroom.classRoomName;
              }

              dispatch(deleteEvidenceFileAttachment(input)).then(e => {
                setTimeout(() => {
                  if (props.formContext.handleFileDeleteComplete) {
                    props.formContext.handleFileDeleteComplete();
                  }
                }, 1500);
              });
              deleteElement.onDropIndexClick(deleteElement.index)(deleteEvent);
              setTimeout(
                () =>
                  setState({ ...state, showDeleteConfirmationAlert: false }),
                1
              );
            }
          }
        }}
      />
    );
  };

  const parseQuestionId = questionId => {
    let match;
    if (
      props.formContext &&
      props.formContext.attributes &&
      props.formContext.attributes.hasOwnProperty('citation')
    ) {
      match =
        questionId.match(/^root_([a-fA-F0-9-]+_\d+)/) === null
          ? questionId.match(/^root_([0-9a-fA-F-]+)_/)
          : questionId.match(/^root_([a-fA-F0-9-]+_\d+)/);
    } else {
      match =
        isMultiForm ||
        props.formContext.attributes.evidenceSource == 'Center Exploration' ||
        props.formContext.attributes.evidenceSource == 'Classroom Exploration'
          ? questionId.match(/^root_([a-fA-F0-9-]+_\d+)/)
          : questionId.match(/^root_([0-9a-fA-F-]+)_/);
    }

    return match ? match[1] : null;
  };

  const appendAccordionKey = (formData, questionCode) => {
    if (!isMultiForm) return;

    if (!questionsData) return;

    var accordion = questionsData.performanceAreas?.filter(area =>
      area.uiSchema.hasOwnProperty(questionCode)
    );
    if (accordion && accordion.length > 0 && accordion[0]) {
      formData['accordionKey'] = accordion[0].accordionTitle;
      formData['questionCode'] = questionCode;
    }
  };

  const appendIsValidated = (formData, questionCode, fileName) => {
    if (!props.formContext.validateQuestion) return;
    if (!props.required) return;

    // append file name to pass validation
    var data = _.cloneDeep(props.formContext?.formData);
    if (data && data[questionCode]) {
      // remove deleted file
      data[questionCode].evidence = data[questionCode].evidence.filter(
        x => x && x != fileName
      );
      if (data[questionCode].evidence.length == 0)
        delete data[questionCode].evidence;
    }

    var isValid = props.formContext.validateQuestion(
      questionCode,
      data,
      props.registry.rootSchema
    );
    formData['isValidated'] = isValid ? 'Yes' : 'No';
  };

  const getFileName = file => {
    let parsedData = file.split('|');
    let name = '';
    if (parsedData.length === 4) {
      name = parsedData[0];
    }

    return name;
  };

  const renderAddNewCitationCheckAlert = () => {
    return (
      <AmsAlert
        show={showAddNewCitationFileAlert}
        title="Unable to add evidence"
        text={
          props.formContext.evidenceSource == 'Findings Outside the Protocol'
            ? `Please select a Content Area and Performance Area before uploading evidence for Findings Outside the Protocol form.`
            : `Please select a Content Area and Performance Area before uploading evidence for Add Additional Citation forms.`
        }
        type="info"
        showConfirm
        confirmButtonText="OK"
        confirmButtonColor={'#FF0000'}
        onConfirm={() => {
          setShowAddNewCitationFileAlert(false);
        }}
      />
    );
  };
  return (
    <div
      id={props.idSchema['$id']}
      className={props.className + ' evidence-files'}
    >
      {renderDeleteConfirmation()}
      {renderAddNewCitationCheckAlert()}
      {props.canAdd && (
        <Grid style={{ margin: '0px 10px' }}>
          <Grid.Row style={{ padding: '0px' }}>
            {props.items &&
              props.items.map((element, index) => {
                const isLast = index === props.items.length - 1;
                return (
                  <React.Fragment key={index}>
                    <div
                      className="eas-file-link"
                      ref={isLast ? lastChildRef : null}
                    >
                      {element.children}

                      <Button
                        color="red"
                        className="eas-delete-button"
                        onClick={event => {
                          event.preventDefault();

                          if (
                            element.children.props.formData === undefined ||
                            element.children.props.formData === null ||
                            isObject(element.children.props.formData)
                          ) {
                            element.onDropIndexClick(element.index)(event);
                          } else {
                            setState({
                              deleteEvent: event,
                              deleteElement: element,
                              showDeleteConfirmationAlert: true,
                              deleteFileName: getFileName(
                                element.children.props.formData
                              ),
                            });
                          }
                        }}
                      >
                        Delete Evidence
                      </Button>
                    </div>
                  </React.Fragment>
                );
              })}
            <Button
              primary
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                if (
                  props.formContext.evidenceSource ==
                  'Findings Outside the Protocol'
                ) {
                  const keyss = props.idSchema.$id.split('_');
                  const itemIndex = keyss[3];
                  let input = {
                    reviewId: props.formContext.reviewId,
                    findingIndex: parseInt(itemIndex),
                  };
                  dispatch(fetchSurveyFotpFindings(input)).then(data => {
                    const findingObj = data.fotpFindings.findings[0];
                    if (
                      findingObj === undefined ||
                      findingObj === null ||
                      findingObj.CAPA === undefined ||
                      findingObj.CAPA === null ||
                      findingObj.CAPA.content_area === undefined ||
                      findingObj.CAPA.content_area === null ||
                      findingObj.CAPA.performance_area === undefined ||
                      findingObj.CAPA.performance_area === null
                    ) {
                      setShowAddNewCitationFileAlert(true);
                      return;
                    }
                  });
                } else if (
                  props.formContext &&
                  props.formContext.attributes &&
                  props.formContext.attributes.hasOwnProperty('citation') &&
                  props.formContext.attributes.citation
                ) {
                  let questionCode = parseQuestionId(props.idSchema.$id);
                  let citation =
                    answersData &&
                    answersData.surveyData &&
                    answersData.surveyData[questionCode] &&
                    answersData.surveyData[questionCode].CitationPicker;
                  let citationToCheck = followUpCitations.find(
                    e => e.name === citation
                  );

                  if (
                    citationToCheck &&
                    citationToCheck.citationEditable === true
                  ) {
                    setShowAddNewCitationFileAlert(true);
                    return;
                  }
                }
                props.onAddClick();

                setAdding(true);
              }}
              floated="right"
              style={{ marginRight: '10px', marginTop: '15px' }}
            >
              Upload Evidence
            </Button>
            <span
              style={{
                display: 'block',
                width: '100%',
                marginTop: '10px',
                marginLeft: '3px',
              }}
            >
              Files cannot exceed 150MB total per upload
            </span>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
};

export default ArrayAttachmentTemplate;
