import axios from 'axios';
import fileDownload from 'js-file-download';

import { downloadBlob } from '../components/Shared/FormHelpers/helpers/utils';

import {
  SURVEY_SUBMISSION_FETCHED,
  SURVEY_ALL_SUBMISSION_FETCHED,
  SURVEY_SUBMISSION_UPDATED,
  SURVEY_SUBMISSION_SAVED,
  SURVEY_OUTCOMES_FETCHED,
  DATA_COLLECTION_FETCHED,
  CLASS_ROOM_OUTCOMES_FETCHED,
  CENTER_OUTCOMES_FETCHED,
  SURVEYS_FINALIZED,
  RAS_URL,
  SURVEY_CHILD_FILES_SUMMARY_FETCHED,
  SURVEY_SUBMISSION_DELETED,
  FA2_GUIDES_FETCHED,
  FA2_GUIDE_SELECTED,
  FA2_PERFORMANCE_MEASURE_SELECTED,
  SURVEY_ATTACHMENT_DOWNLOADING,
  SURVEY_ATTACHMENT_DOWNLOADED,
  SURVEY_COMMENTS_FETCHED,
  CHILD_FILES_ERSEA_SUMMARY_FETCHED,
  CONSOLIDATED_QUESTIONS_FETCHED,
  MULTI_SURVEY_FORM_FETCHED,
  CONSOLIDATED_ANSWERS_FETCHED,
  FOCUS_COMPONENT,
  DATA_COLLECTION_LOCKED,
  DATA_COLLECTION_DETAILS_FETCHED,
  ADDITIONAL_CITATION_FORM_FETCHED,
  CONSOLIDATED_ANSWERS_CLEARED,
  SURVEY_LOOKUPS_FETCHED,
  CONTENT_AREA_LOOKUPS_FETCHED,
  PERFORMANCE_AREA_LOOKUPS_FETCHED,
  PERFORMANCE_MEASURE_LOOKUPS_FETCHED,
  SURVEY_CENTERS_CLASSROOMS_FETCHED,
  SURVEY_FOTP_FINDINGS_FETCHED,
  ELIGIBILITY_FILES_SUMMARY_FETCHED,
} from '../redux/types';

export const surveySubmissionFetched = submission => ({
  type: SURVEY_SUBMISSION_FETCHED,
  submission,
});

export const surveySubmissionSaved = submission => ({
  type: SURVEY_SUBMISSION_SAVED,
});

export const getSurveyAllSubmission = submission => ({
  type: SURVEY_ALL_SUBMISSION_FETCHED,
  submission,
});

export const submissionUpdated = response => ({
  type: SURVEY_SUBMISSION_UPDATED,
  response,
});

export const surveysFinalized = surveys => ({
  type: SURVEYS_FINALIZED,
  surveys,
});

export const outcomesFetched = outComes => ({
  type: SURVEY_OUTCOMES_FETCHED,
  outComes,
});

export const dataCollectionOutcomesFetched = dataCollectionOutcomes => ({
  type: DATA_COLLECTION_FETCHED,
  dataCollectionOutcomes,
});

export const classroomOutcomesFetched = classroomOutcomes => ({
  type: CLASS_ROOM_OUTCOMES_FETCHED,
  classroomOutcomes,
});

export const centerOutcomesFetched = centerOutcomes => ({
  type: CENTER_OUTCOMES_FETCHED,
  centerOutcomes,
});

export const rasUrlFetched = rasUrl => ({
  type: RAS_URL,
  rasUrl,
});

export const childFilesSummaryFetched = childFilesSummary => ({
  type: SURVEY_CHILD_FILES_SUMMARY_FETCHED,
  childFilesSummary,
});

export const surveySubmissionDeleted = () => ({
  type: SURVEY_SUBMISSION_DELETED,
});

export const fa2GuidesFetched = (reviewId, data) => ({
  type: FA2_GUIDES_FETCHED,
  reviewId,
  guides: data.guides,
  granteeInfo: data.granteeInfo,
});

export const fa2GuideSelected = (guide, performanceMeasure) => ({
  type: FA2_GUIDE_SELECTED,
  guide,
  performanceMeasure,
});

export const performanceMeasureSelected = selected => ({
  type: FA2_PERFORMANCE_MEASURE_SELECTED,
  selected,
});

export const surveyAttachmentDownloading = () => ({
  type: SURVEY_ATTACHMENT_DOWNLOADING,
});

export const surveyAttachmentDownloaded = () => ({
  type: SURVEY_ATTACHMENT_DOWNLOADED,
});

export const surveyCommentsFetched = comments => ({
  type: SURVEY_COMMENTS_FETCHED,
  comments,
});

export const childFilesERSEASummaryFetched = childFilesERSEASummary => ({
  type: CHILD_FILES_ERSEA_SUMMARY_FETCHED,
  childFilesERSEASummary,
});

export const eligibilityFilesSummaryFetched = eligibilityFilesSummary => ({
  type: ELIGIBILITY_FILES_SUMMARY_FETCHED,
  eligibilityFilesSummary,
});

export const consolidatedQuestionsFetched = consolidatedQuestions => ({
  type: CONSOLIDATED_QUESTIONS_FETCHED,
  consolidatedQuestions,
});

export const multiSurveyFormFetched = multiSurveyForm => ({
  type: MULTI_SURVEY_FORM_FETCHED,
  multiSurveyForm,
});

export const consolidatedAnswersFetched = consolidatedAnswers => ({
  type: CONSOLIDATED_ANSWERS_FETCHED,
  consolidatedAnswers,
});

export const formBuilderFocusedComponent = componentName => ({
  type: FOCUS_COMPONENT,
  componentName,
});

export const dataCollectionDetailsFetched = dataCollectionDetails => ({
  type: DATA_COLLECTION_DETAILS_FETCHED,
  dataCollectionDetails,
});

export const dataCollectionLocked = dataCollectionLockDetails => ({
  type: DATA_COLLECTION_LOCKED,
  dataCollectionLockDetails,
});

export const additionalCitationFormFetched = additionalCitationForm => ({
  type: ADDITIONAL_CITATION_FORM_FETCHED,
  additionalCitationForm,
});

export const surveyLookupsFetched = surveyLookups => ({
  type: SURVEY_LOOKUPS_FETCHED,
  surveyLookups,
});

export const contentAreaLookupsFetched = contentAreaLookups => ({
  type: CONTENT_AREA_LOOKUPS_FETCHED,
  contentAreaLookups,
});

export const performanceAreaLookupsFetched = performanceAreaLookups => ({
  type: PERFORMANCE_AREA_LOOKUPS_FETCHED,
  performanceAreaLookups,
});

export const performanceMeasureLookupsFetched = performanceMeasureLookups => ({
  type: PERFORMANCE_MEASURE_LOOKUPS_FETCHED,
  performanceMeasureLookups,
});

export const surveyFotpFindingsFetched = fotpFindings => ({
  type: SURVEY_FOTP_FINDINGS_FETCHED,
  fotpFindings,
});

export const consolidatedAnswersCleared = () => ({
  type: CONSOLIDATED_ANSWERS_CLEARED,
});

export const surveyCentersClassroomsFetched = surveyCentersClassrooms => ({
  type: SURVEY_CENTERS_CLASSROOMS_FETCHED,
  surveyCentersClassrooms,
});

export const fetchSurvyeSubmission = requestInput => dispatch =>
  axios.post(`/survey/submission`, requestInput).then(response => {
    dispatch(surveySubmissionFetched(response.data));
    return;
  });

export const fetchAllSubmissions = requestInput => dispatch =>
  axios
    .post(`/survey/submission/all`, requestInput)
    .then(response => dispatch(getSurveyAllSubmission(response.data)));

export const updateSumbission = updateObject => dispatch =>
  axios.post(`/survey/submission/update`, updateObject).then(response => {
    dispatch(submissionUpdated(response.data));

    // After update, refetch the data.
    const requestInput = {
      reviewId: updateObject.reviewId,
      submissionId: updateObject.submissionId,
      koboFormId: updateObject.koboFormId,
    };
    dispatch(fetchSurvyeSubmission(requestInput));
  });

export const finalizeSurveys = surveys => dispatch =>
  axios
    .post(`/survey/finalize`, surveys)
    .then(response => dispatch(surveysFinalized(response.data)));

//store reviewId and koboFormId
// export const setSurveyOutcomes = (obj) => dispatch =>
//  dispatch(surveyOutcomesSet(obj))

export const fetchSurveyOutcomes = obj => dispatch =>
  axios
    .post(`/survey/submissionCount`, obj)
    .then(response => dispatch(outcomesFetched(response.data.outComes)));

export const fetchDataCollectionOutcomes = obj => dispatch =>
  axios
    .post(`/survey/dataCollectionOutcomes`, obj)
    .then(response => dispatch(dataCollectionOutcomesFetched(response.data)));

export const fetchClassroomOutcomes = obj => dispatch =>
  axios
    .post(`/survey/classroomOutcomes`, obj)
    .then(response => dispatch(classroomOutcomesFetched(response.data)));

export const fetchCenterOutcomes = obj => dispatch =>
  axios
    .post(`/survey/centerOutcomes`, obj)
    .then(response => dispatch(centerOutcomesFetched(response.data)));

export const fetchRasUrl = obj => dispatch =>
  axios
    .post(`/survey/rasUrl`, obj)
    .then(response => dispatch(rasUrlFetched(response.data)));

export const fetchChildFilesSummary = obj => dispatch =>
  axios
    .post(`/survey/childFilesSummary`, obj)
    .then(response => dispatch(childFilesSummaryFetched(response.data)));

export const deleteSurveySubmission = submission => dispatch =>
  axios
    .post(`/survey/deleteSubmission`, submission)
    .then(response => dispatch(surveySubmissionDeleted(response.data)));

export const fetchFa2Guides = reviewId => dispatch =>
  axios
    .post(`/survey/fa2`, { reviewId })
    .then(response => dispatch(fa2GuidesFetched(reviewId, response.data)));

export const fetchFY19ChildFilesSummary = obj => dispatch =>
  axios
    .post(`/survey/fy19ChildFilesSummary`, obj)
    .then(response => dispatch(childFilesSummaryFetched(response.data)));

export const downloadSurveyAttachment = (fileId, name) => dispatch => {
  dispatch(surveyAttachmentDownloading());
  const payload = { fileId };

  return axios.post(`/survey/download-attachment`, payload).then(response => {
    const { data } = response;

    if (!data) return;

    const contentType = data.match(
      /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/
    )[1];

    const dataUrl = data.split(';base64,').pop();
    const base64 = Buffer.from(dataUrl, 'base64');
    if (base64 && name && contentType) {
      fileDownload(base64, name, contentType);
      return dispatch(surveyAttachmentDownloaded());
    }
  });
};

export const downloadFeedbackFile = (fileId, name) => dispatch => {
  dispatch(surveyAttachmentDownloading());
  const payload = { fileId };

  return axios.post(`/survey/download-attachment`, payload).then(response => {
    const { data } = response;

    if (!data) return;

    const bData = data.split(';base64,').pop();
    const buf = Buffer.from(bData, 'base64');

    const blob = new Blob([buf], {
      type: 'application/octet-stream',
    });

    downloadBlob(blob, name);

    return response;
  });
};

export const downloadEvidenceBinderFile = (fileId, name, fy) => dispatch => {
  const payload = { fileId, fiscalYear: fy };

  return axios
    .post(`/survey/download-evidence-binder-file`, payload)
    .then(response => {
      const { data } = response;

      if (!data) return;

      const contentType = data.match(
        /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/
      )[1];

      const dataUrl = data.split(';base64,').pop();
      const base64 = Buffer.from(dataUrl, 'base64');
      if (base64 && name && contentType) {
        fileDownload(base64, name, contentType);
        return dispatch(surveyAttachmentDownloaded());
      }
    });
};

export const postFormSubmission = request => dispatch =>
  axios.post(`/survey/form/save-submission`, request).then(response => {
    return response.data;
  });

export const fetchERSEAChildFilesSummary = obj => dispatch =>
  axios.post(`/survey/ERSEAChildFilesSummary`, obj).then(response => {
    dispatch(childFilesERSEASummaryFetched(response.data));
  });

export const fetchEligibilityFilesSummary = obj => dispatch =>
  axios.post(`/survey/EligibilityFilesSummary`, obj).then(response => {
    dispatch(eligibilityFilesSummaryFetched(response.data));
  });

export const postQuestionSchemaJSONs = request => dispatch =>
  axios.post(`/survey/eas-question`, request).then(response => {
    return response.data;
  });

export const fetchQuestionSchemaJSONs = request => dispatch =>
  axios.post(`/survey/eas-questions`, request).then(response => {
    return response.data;
  });

export const fetchQuestionSchemaJsonById = id => dispatch =>
  axios.get(`/survey/eas-question/${id}`).then(response => {
    return response.data;
  });

export const deleteQuestionSchemaJsonById = id => dispatch =>
  axios.delete(`/survey/eas-question/${id}`).then(response => {
    return response.data;
  });

export const patchQuestionSchemaJsonById = request => dispatch =>
  axios.patch(`/survey/eas-question`, request).then(response => {
    return response.data;
  });

export const updateQuestion = request => dispatch =>
  axios.post(`/survey/survey-questions/update`, request).then(response => {
    return response.data;
  });

export const fetchQuestion = request => dispatch =>
  axios.post(`/survey/survey-questions/fetch`, request).then(response => {
    return response.data;
  });

export const addQuestion = request => dispatch =>
  axios.post(`/survey/survey-questions`, request).then(response => {
    return response.data;
  });

export const deleteQuestion = id => dispatch =>
  axios.delete(`/survey/survey-questions/${id}`).then(response => {
    return response.data;
  });

export const fetchConsolidatedQuestions = input => dispatch =>
  axios.post(`/survey/easQuestionConsolidated`, input).then(response => {
    return dispatch(consolidatedQuestionsFetched(response.data));
  });

export const fetchMultiSurveyForm = input => dispatch =>
  axios.post(`/survey/fetchMultiSurveyForm`, input).then(response => {
    return dispatch(multiSurveyFormFetched(response.data));
  });

export const fetchConsolidatedAnswers = input => dispatch =>
  axios
    .post(`/survey/easAnswerConsolidated`, input)
    .then(response => dispatch(consolidatedAnswersFetched(response.data)));

export const saveQuestionFormData = input => dispatch =>
  axios.post(`/survey/easAnswer`, input).then(response => {
    return response.data;
  });

export const fetchSurveyLookups = input => dispatch =>
  axios
    .post(`/survey/survey-lookups`, input)
    .then(response => dispatch(surveyLookupsFetched(response.data)));

export const fetchContentAreaLookups = input => dispatch =>
  axios
    .post(`/survey/contentArea-lookups`, input)
    .then(response => dispatch(contentAreaLookupsFetched(response.data)));

export const fetchPerformanceAreaLookups = input => dispatch =>
  axios
    .post(`/survey/performanceArea-lookups`, input)
    .then(response => dispatch(performanceAreaLookupsFetched(response.data)));

export const fetchPerformanceMeasureLookups = input => dispatch =>
  axios
    .post(`/survey/performanceMeasure-lookups`, input)
    .then(response =>
      dispatch(performanceMeasureLookupsFetched(response.data))
    );

export const fetchDataCollectionDetails = input => dispatch =>
  axios.post(`/survey/dataCollectionDetails`, input).then(response => {
    return response.data;
  });

export const postDataCollectionLock = input => dispatch =>
  axios
    .post(`/survey/surveySnapShot`, input)
    .then(response => dispatch(dataCollectionLocked(response.data)));

export const fetchAdditionalCitationForm = input => dispatch =>
  axios.post(`/survey/fetchAdditionalCitationForm`, input).then(response => {
    return dispatch(additionalCitationFormFetched(response.data));
  });

export const fetchSurveyFotpFindings = input => dispatch =>
  axios
    .post(`/survey/fetch-survey_fotp_findings`, input)
    .then(response => dispatch(surveyFotpFindingsFetched(response.data)));

export const clearConsolidatedAnswers = () => dispatch =>
  dispatch(consolidatedAnswersCleared());

export const fetchSurveyCentersAndClassrooms = input => dispatch =>
  axios
    .post(`/survey/survey-centers-classrooms`, input)
    .then(response => dispatch(surveyCentersClassroomsFetched(response.data)));
