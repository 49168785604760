import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Link, withRouter, browserHistory } from 'react-router';

import {
  Grid,
  Comment,
  Message,
  Header,
  Segment,
  Popup,
  Label,
  Icon,
  Accordion,
  Button,
} from 'semantic-ui-react';

//Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import ReportTrackerFindingsModal from './ReportTrackerFindingsModal';
import ReportTrackerFindingsModalFY24 from './ReportTrackerFindingsModalFY24';

import { exportDelegatedTasks } from '../../actions/taskActions';
import { fetchReportFindingsDetail } from '../../actions/taskActions';

import {
  approvedBox,
  needClarificationBox,
  inProgressBox,
  rejectedBox,
  redirectToTasksSummary,
  generateReviewDetails,
  renderFindingsAndPracticesCount,
  hasFindings,
  getContentAreaFindings,
  hideStrongPractices,
  disableStrongPracticesComment
} from './util'

import './assets/style.css';
import _, { orderBy } from 'lodash';
import DOMPurify from 'dompurify';

const SurveyAllReportTrackerFindingsPage = props => {
  const dispatch = useDispatch();

  //fetched data for task details, current user, and external user email list
  const tasks = useSelector(state => {
    return state.tasks;
  });
  const { user } = useSelector(state => state.auth);
  //task data
  const [reviewFindings, setReviewFindings] = useState([]);
  const [activeComments, setActiveComments] = useState([]);
  const [activePanelsObj, setActivePanelsObj] = useState({});
  const [delegatedTasks, setDelegatedTasks] = useState(false);
  const [activeIndex, setActiveIndex] = useState([]);
  const newReviewTypes = ['FA1-FR', 'FA2-CR'];
  const newFormReviewTypes = ['RAN', 'Special', 'Follow-up'];
  const STRONG_PRACTICE = 'strongPractice';
  const FINDING = 'finding';

  //set data upon fetch
  useEffect(() => {
    if (tasks && tasks.taskList) {
      const isDelegated = tasks.tasksList.delegated;
      const payload = isDelegated ? tasks.delegatedTasksList.data : tasks.taskList;

      if (isDelegated === true) {
        setDelegatedTasks(true);
      }

      if (payload.length > 0) {
        setReviewFindings(payload);
      } else {
        redirectToTasksSummary(props);
      }
    } else {
      redirectToTasksSummary(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  const onAccordionDropdownClick = (e, data, index) => {
    if (!data.findings && !data.taskFindings) {
      props.fetchReportFindingsDetail(data)
      .then(response => {        
        let updatedReviewFindings = [...reviewFindings];
        Object.keys(response[0]).forEach(key => {
          if (!updatedReviewFindings[index].hasOwnProperty(key)) 
            updatedReviewFindings[index][key] = response[0][key];
        });
        setReviewFindings(updatedReviewFindings)
      });
    }
    
    if (e) e.preventDefault();

    const found = activePanelsObj.hasOwnProperty(
      data.conversationId
    );

    const newActiveObj = { ...activePanelsObj };

    if (found) {
      newActiveObj[data.conversationId] = !newActiveObj[
        data.conversationId
      ];
    } else {
      newActiveObj[data.conversationId] = true;
    }

    setActivePanelsObj(newActiveObj);
  }

  const onAllFeedbackSubmitted = (data) => {
    let updatedReviewFindings = [...reviewFindings];
    updatedReviewFindings = updatedReviewFindings
      .filter(x => data.filter(d => d._id == x._id).length > 0)

    if (updatedReviewFindings.length == 0)
      browserHistory.push('/task');
    setReviewFindings(updatedReviewFindings)
  }
  const removeReview = (id) => {
    let updatedReviewFindings = [...reviewFindings];
    updatedReviewFindings = updatedReviewFindings.filter(x => x._id != id);
    if (updatedReviewFindings.length == 0)
      browserHistory.push('/task');
    setReviewFindings(updatedReviewFindings)
  }
  const onFeedbackSubmitted = (id, data) => { 
    let updatedReviewFindings = [...reviewFindings];
    var reviews = updatedReviewFindings.filter(x => x._id == id);
    if (reviews && reviews[0] && data && data[0]) {
      var review = reviews[0];
      if (data[0].isNewTask && 
          data[0].remainingFindingsCount == 0 &&
          data[0].remainingStrongPracticeCount == 0)
        removeReview(id);
      else if (!data[0].isNewTask && data[0].remainingFindingsCount == -1)
        removeReview(id);
      else {
        Object.keys(data[0]).forEach(key => {
          if (review.hasOwnProperty(key)) 
            review[key] = data[0][key];
        });
        setReviewFindings(updatedReviewFindings)
      }
    }
    
  }

  const handleAccordion = (value) => {
    let copy = activeIndex.slice(0);

    if (copy.includes(value))
      copy  = copy.filter(function(item) { return item !== value });
    else
      copy.push(value);
  
    setActiveIndex(copy);
  };

  const generateFindingsDetails = (data, idx) => {
    if (!data || !data.reviewType) {
      return null;
    }
    return (<>
      {data.taskFindings?.map((item, index) => {
        var accordionId = data.reviewId + '-' + idx + '-' + index;
        if (hideStrongPractices(user) && !hasFindings(item))
          return (<></>);
        let findings = getContentAreaFindings(item);
      return (
        <Accordion key={index} fluid style={{marginBottom: '15px', boxShadow: 'none'}}>
          <Accordion.Title
            active={activeIndex.includes(accordionId)}
            index={0}
            onClick={x => { handleAccordion(accordionId)}}
            style={{ backgroundColor: '#DCDCDC', padding: '15px', borderRadius: "5px", color: '#000000de' }}
          >
            <h3 style={{ margin: '0' }}>
              {item.contentArea}
              <Icon
                style={{ float: 'right' }}
                name={activeIndex.includes(accordionId) ? 'minus' : 'plus'}
              />
            </h3>
          </Accordion.Title>
          <Accordion.Content active={activeIndex.includes(accordionId)}>
            <Segment style={{border: "none", padding: "10px 0px 0px", boxShadow: "none"}}>
              {findings?.map((e, index) => {              
                const headerStyle = { marginBottom: '10px', fontSize: '16px' };
                const isStrongPractice = e.collectDataType == STRONG_PRACTICE;
                const isFinding = e.collectDataType == FINDING;
                if (isStrongPractice && hideStrongPractices(user))
                  return (<></>);
                return (
                  <Segment.Group key={index} style={{ border: '1px solid #ccc', boxShadow: 'none', paddingBottom: '10px'}}>
                    <Segment style={{padding: '0px 10px 12px 5px', 
                      margin: '15px 15px 0px 10px', 
                      borderBottom: '1px solid #ccc'}}>
                      <Header as="h5">
                        {isStrongPractice ? e.findingDisplayName : e.findingDisplayName + ": " + e.citation}
                        <span
                          style={{ color: 'black', float: 'right', marginRight: '-10px' }}
                        >
                          {e.feedbackStatus !== null
                            ? e.feedbackStatus === 'Approved'
                              ? approvedBox
                              : e.feedbackStatus === 'Rejected'
                              ? rejectedBox
                              : e.feedbackStatus === 'Need Clarification'
                              ? needClarificationBox
                              : e.feedbackStatus === 'In Progress'
                              ? inProgressBox
                              : null
                            : null}
                          
                          {e.provideFeedback && (!isStrongPractice || !disableStrongPracticesComment(user)) && (
                            <ReportTrackerFindingsModalFY24
                              type="feedback"
                              level="finding"
                              data={data}
                              index={index}
                              item={e}
                              onFeedbackSubmitted={onFeedbackSubmitted}
                              allFindings={
                                (reviewFindings && reviewFindings.length > 1) ||
                                (props.params.reviewId === undefined ? true : false)
                              }
                            />
                          )}
                        </span>
                      </Header>
                    </Segment>
                    <Grid style={{ margin: '0px', clear: 'both' }}>
                      <Grid.Row columns={isFinding ? 3 : 1} style={{ margin: 'auto', paddingBottom: '10px'}}>
                        {isFinding ? 
                        <Grid.Column>
                          <Header style={headerStyle} as="h5">
                            Citation
                          </Header>
                          <Link
                            to={{
                              pathname: `/review/citations/definition`,
                              query: { citation: e.citation },
                            }}
                            style={{fontSize: '14px'}}
                            target="_blank"
                          >
                            <Popup
                              trigger={
                                <Label
                                  style={{
                                    color: 'blue',
                                    background: 'white',
                                    padding: '0px',
                                    fontSize: '14px',
                                    textDecorationLine: 'underline',
                                  }}
                                >
                                  {' '}
                                  {e ? e.citation : ''}
                                </Label>
                              }
                              content="Select to see Citation Definition"
                            />
                          </Link>
                        </Grid.Column> : null}
                        <Grid.Column>
                          <Header style={headerStyle} as="h5">
                            Performance Area
                          </Header>
                          {e.performanceAreas?.join(', ')}
                        </Grid.Column>
                        {isFinding ? 
                        <Grid.Column>
                          <Header style={headerStyle} as="h5">
                            Finding Type
                            {e.findingTypeStatus !== null && ` (${e.findingTypeStatus})`}
                          </Header>
                          <div>{e.findingType}</div>
                        </Grid.Column> : null}
                      </Grid.Row>
                      <Grid.Row style={{ margin: 'auto', paddingBottom: '10px'}}>
                        <Grid.Column>
                          <Header style={headerStyle} as="h5">
                            {isFinding ? 'Collected Data and Narrative' : 'Collected Data'}
                            {e.narrativeStatus !== null && ` (${e.narrativeStatus})`}
                            <span
                              style={{
                                color: 'black',
                                float: 'right',
                                marginRight: '-15px',
                              }}
                            ></span>
                          </Header>
                          <Message style={{borderRadius: '2px', backgroundColor: 'transparent'}}>
                            <div
                              style={{
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              {(
                                <ul style={{ margin: '0px', paddingLeft: '10px' }}>
                                  {e.collectedData?.map((x, index) => (
                                    <li key={index} style={DOMPurify.sanitize(x)?.includes("_") ? {marginLeft: '10px'} : {}}>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(x?.replace(/\d+_/, '')),
                                        }}
                                      ></span>
                                    </li>
                                  ))}
                                </ul>
                              )}
                              <br />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(e.narrative),
                                }}
                              ></div>
                            </div>
                          </Message>
                        </Grid.Column>
                      </Grid.Row>
                      {e.comments && e.comments.length > 0 && (
                        <Grid.Row style={{ margin: 'auto', padding: '5px 0px 0px'}}>
                          <Grid.Column>
                            {generateCommentsView('finding', e)}
                          </Grid.Column>
                        </Grid.Row>
                      )}
                    </Grid>
                  </Segment.Group>
                );
              })}
            </Segment>
          </Accordion.Content>
        </Accordion>
      )})}
    </>)
  };

  const generateDelegatedFindingsView = (data, index) => {
    const title = `${data.reviewType} Review ID - ${data.reviewId} | ${data.grantType}: `;
    const granteeLink = `${data.reviewGrantInfo[0].granteeName} (${data.reviewGrantInfo[0].granteeId})`;
    const isActive =
      activePanelsObj.hasOwnProperty(data.conversationId) &&
      activePanelsObj[data.conversationId] === true;

    return (
      <React.Fragment key={index}>
        <Accordion.Title
          index={index}
          active={isActive}
          style={{ backgroundColor: '#f9fcff' }}
        >
          <Header as="h5">
            {title}{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/grantee/${data ? data.granteeId : ''}`}
            >
              {granteeLink}
            </a>
            <div style={{ float: 'right' }} className="actionButtons">
              {data && data.provideFeedback && (
                <ReportTrackerFindingsModalFY24
                  type="feedback"
                  context="taskSummaryPage"
                  level="review"
                  onFeedbackSubmitted={removeReview}
                  index={data}
                  allFindings={true}
                  delegated={true}
                />
              )}

              <Link
                to="#"
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  float: 'right',
                }}
                onClick={e => onAccordionDropdownClick(e, data, index)}
              >
                <Icon style={{ float: 'right' }} name="dropdown" />
                <span
                  style={{ transition: 'none 0s ease 0s' }}
                  className="visually-hidden"
                >
                  Select to expand or collapse review
                </span>
              </Link>
            </div>
            {data && data.findingsFeedbackStatus !== null
              ? data.findingsFeedbackStatus === 'Approved'
                ? approvedBox
                : data.findingsFeedbackStatus === 'Rejected'
                ? rejectedBox
                : data.findingsFeedbackStatus === 'Need Clarification'
                ? needClarificationBox
                : data.findingsFeedbackStatus === 'In Progress'
                ? inProgressBox
                : null
              : null}
          </Header>
        </Accordion.Title>
        {!data.isNewTask ? 
          <Accordion.Content active={isActive}>
            <Grid stackable style={{ width: '100' }}>
              <Grid.Row>
                <Grid.Column>
                  <Segment>{generateReviewDetailsOldUi(data)}</Segment>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column> {generateDelegatedTaskDetailsOldUi(data)}</Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content> : 
          <Accordion.Content active={isActive}>
            {renderFindingsAndPracticesCount(data, hideStrongPractices(user))}
            <div
             style={{
              backgroundColor: '#f9fcff',
              marginTop: '5px',
              marginLeft: '-14px',
              paddingLeft: '14px',
              marginRight: '-14px',
              paddingRight: '14px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
            >
              {generateReviewDetails(data)}
            </div>
            {generateDelegatedTaskDetails(data)}
          </Accordion.Content>
        }
      </React.Fragment>
    );
  };

  const generateCommentsView = (type, data) => {
    const expanded = data.findingId ? activeComments.includes(data.findingId) : activeComments.includes(type);
    
    const handleExpandComments = () => {
      const value = data.findingId  ? data.findingId : type;
      let copyComments = activeComments.slice(0);

      if (copyComments.includes(value))
        copyComments  = copyComments.filter(function(item) { return item !== value });
      else
        copyComments.push(value);
    
      setActiveComments(copyComments);
    };

    return (
      <Accordion
        className={type === 'all' ? '' : 'finding-level-comments'}
        style={{ boxShadow: 'none', margin: '0px' }}
      >
        <Accordion.Title
          active={expanded === true}
          index={0}
        >
          <Link
            to="#"
            style={{ textDecoration: 'none', color: 'rgba(0,0,0,.87)' }}
            onClick={e => {
              e.preventDefault();
              handleExpandComments(e);
            }}
          >
            <Icon name="angle right" />
            View All Comments
          </Link>
        </Accordion.Title>
        <Accordion.Content active={expanded}>
          <Comment.Group style={{ marginLeft: '15px' }}>
            {data.comments.map((e, index) => {
              return (
                <Comment key={index}>
                  <Comment.Content>
                    <Comment.Author as="span">{e.commentBy}</Comment.Author>
                    <Comment.Metadata>
                      <div>
                        {AmsDateFormatters.formatDateTime(e.updatedTime)}
                      </div>
                    </Comment.Metadata>
                    <Comment.Text>{e.comment}</Comment.Text>
                  </Comment.Content>
                  {e.responses && e.responses.length > 0 && (
                    <Comment.Group>
                      {orderBy(e.responses, ['updatedTime'], ['desc']).map(
                        (reply, index) => {
                          return (
                            <Comment key={index}>
                              <Comment.Content>
                                <Comment.Author as="span">
                                  {reply.responseBy}
                                </Comment.Author>
                                <Comment.Metadata>
                                  <div>
                                    {' '}
                                    {AmsDateFormatters.formatDateTime(
                                      reply.updatedTime
                                    )}
                                  </div>
                                </Comment.Metadata>
                                <Comment.Text>{reply.response}</Comment.Text>
                              </Comment.Content>
                            </Comment>
                          );
                        }
                      )}
                    </Comment.Group>
                  )}
                </Comment>
              );
            })}
          </Comment.Group>
        </Accordion.Content>
      </Accordion>
    );
  };

  const generateReviewFindingsView = (data, index) => {
    const title = `${data.reviewType} Review ID - ${data.reviewId} | ${data.grantType}: `;
    const granteeLink = `${data.reviewGrantInfo[0].granteeName} (${data.reviewGrantInfo[0].granteeId})`;

    const isActive =
      activePanelsObj.hasOwnProperty(data.conversationId) &&
      activePanelsObj[data.conversationId] === true;

    const hasComments = data.comments && data.comments.length > 0;

    return (
      <React.Fragment key={index}>
        <Accordion.Title
          index={index}
          active={isActive}
          style={{ backgroundColor: '#f9fcff' }}
        >
          <Header as="h5">
            {title}{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/grantee/${data ? data.reviewGrantInfo[0].granteeId : ''}`}
            >
              {granteeLink}
            </a>
            <div style={{ float: 'right' }} className="actionButtons">
              {data && data.forwardFindings && (
                <ReportTrackerFindingsModalFY24
                  type="forward"
                  data={data}
                  allFindings={reviewFindings && reviewFindings.length > 1}
                />
              )}
              <Link
                to="#"
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  float: 'right',
                }}
                onClick={e => onAccordionDropdownClick(e, data, index)}
              >
                <Icon style={{ float: 'right' }} name="dropdown" />
                <span
                  style={{ transition: 'none 0s ease 0s' }}
                  className="visually-hidden"
                >
                  Select to expand or collapse review
                </span>
              </Link>
            </div>
            {data && data.findingsFeedbackStatus !== null
              ? data.findingsFeedbackStatus === 'Approved'
                ? approvedBox
                : data.findingsFeedbackStatus === 'Rejected'
                ? rejectedBox
                : data.findingsFeedbackStatus === 'Need Clarification'
                ? needClarificationBox
                : data.findingsFeedbackStatus === 'In Progress'
                ? inProgressBox
                : null
              : null}
          </Header>
        </Accordion.Title>
        {!data.isNewTask ?
        <Accordion.Content
          active={isActive}
          className="view-all-findings-accordion"
        >
          <Grid stackable style={{ width: '100' }}>
            <Grid.Row>
              <Grid.Column>
                <Segment>{generateReviewDetailsOldUi(data)}</Segment>
              </Grid.Column>
            </Grid.Row>
            {hasComments && (
              <Grid.Row>
                <Grid.Column>
                  <Segment>{generateCommentsViewOldUi('all', data)}</Segment>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column>{generateFindingsDetailsOldUi(data)}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Accordion.Content>
        
        : <Accordion.Content
          active={isActive}
          className="view-all-findings-accordion"
        >
          {renderFindingsAndPracticesCount(data, hideStrongPractices(user))}
          <div
            style={{
              backgroundColor: '#f9fcff',
              marginTop: '5px',
              marginLeft: '-14px',
              paddingLeft: '14px',
              marginRight: '-14px',
              paddingRight: '14px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            {generateReviewDetails(data)}
          </div>
          {hasComments && (
            <Segment
              className="all-findings-comment-view"
              style={{ padding: '10px', paddingLeft: '0px' }}
            >
              {generateCommentsView('all', data)}
            </Segment>
          )}
          {generateFindingsDetails(data, index)}
        </Accordion.Content>}
      </React.Fragment>
    );
  };

  const generateReviewList = () => {
    return (
      <Accordion
        exclusive={false}
        style={{ width: '100%' }}
        styled
      >
        {reviewFindings.map((e, index) => {
          if (delegatedTasks === true) {
            return generateDelegatedFindingsView(e, index);
          }
          return generateReviewFindingsView(e, index);
        })}
      </Accordion>
    );
  };

  const renderDelegatedActionButtons = () => {
    return (
      <>
        <ReportTrackerFindingsModalFY24
          type="feedback"
          context="taskSummaryPage"
          level="review"
          allDelegated={true}
          delegatedFindingsView={true}
          onFeedbackSubmitted={onAllFeedbackSubmitted}
          index={
            (reviewFindings &&
              reviewFindings.filter(e => {
                return e.provideFeedback !== false;
              })) ||
            []
          }
          allFindings={false}
          delegated={true}
        />
        <Button
          style={{ backgroundColor: 'rgb(18,64,141)', color: 'white' }}
          onClick={() => {
            dispatch(exportDelegatedTasks());
          }}
        >
          Export
        </Button>
      </>
    );
  };

  const generateReviewDetailsOldUi = data => {
    const divStyle = { marginRight: '3em' };
    const boldStyle = { fontFamily: 'PublicSans-Bold' };
    if (!data || !data.reviewType) {
      return null;
    }

    const addComment = `/add-comment?key=${data.hashContent}`;
    const reportLink = (
      <a
        href={addComment}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`Report Link for ${data.reviewId}`}
      >
        Report Link
      </a>
    );

    return (
      <>
        <span style={divStyle}>
          <span style={boldStyle}>Review Type: </span>
          {data.reviewType}
        </span>
        <span style={divStyle}>
          <span style={boldStyle}>Review Status: </span>
          {data.reviewStatus}
        </span>
        <span style={divStyle}>
          <span style={boldStyle}>Review Outcome: </span>
          {data.reviewOutcome}
        </span>
        <span style={divStyle}>
          <span style={boldStyle}>State: </span>
          {data.state}
        </span>
        <span style={divStyle}>
          <span style={boldStyle}>Agency ID: </span>
          {data.agencyId}
        </span>
        <span style={divStyle}>
          <span style={boldStyle}>Start Date: </span>
          {AmsDateFormatters.formatDate(data.reviewStartDate)}
        </span>

        <span style={divStyle}>
          <span style={boldStyle}>Due Date: </span>
          {AmsDateFormatters.formatDate(data.reviewEndDate)}
        </span>
        <span style={divStyle}>
          <span style={boldStyle}> {reportLink} </span>
        </span>
      </>
    );
  };

  const generateCommentsViewOldUi = (type, data) => {
    const expanded = data.findingId ? activeComments.includes(data.findingId) : activeComments.includes(type);
    
    const handleExpandComments = () => {
      const value = data.findingId  ? data.findingId : type;
      let copyComments = activeComments.slice(0);

      if (copyComments.includes(value))
        copyComments  = copyComments.filter(function(item) { return item !== value });
      else
        copyComments.push(value);
    
      setActiveComments(copyComments);
    };

    return (
      <Accordion
        className={type === 'all' ? '' : 'finding-level-comments'}
        style={{ boxShadow: 'none' }}
      >
        <Accordion.Title
          active={expanded === true}
          index={0}
        >
          <Link
            to="#"
            style={{ textDecoration: 'none' }}
            onClick={e => {
              e.preventDefault();
              handleExpandComments(e);
            }}
          >
            <Icon name="dropdown" />
            View All Comments
          </Link>
        </Accordion.Title>
        <Accordion.Content active={expanded}>
          <Comment.Group style={{ marginLeft: '15px' }}>
            {data.comments.map(e => {
              return (
                <Comment>
                  <Comment.Content>
                    <Comment.Author as="span">{e.commentBy}</Comment.Author>
                    <Comment.Metadata>
                      <div>
                        {AmsDateFormatters.formatDateTime(e.updatedTime)}
                      </div>
                    </Comment.Metadata>
                    <Comment.Text>{e.comment}</Comment.Text>
                  </Comment.Content>
                  {e.responses && e.responses.length > 0 && (
                    <Comment.Group>
                      {_.orderBy(e.responses, ['updatedTime'], ['desc']).map(
                        reply => {
                          return (
                            <Comment>
                              <Comment.Content>
                                <Comment.Author as="span">
                                  {reply.responseBy}
                                </Comment.Author>
                                <Comment.Metadata>
                                  <div>
                                    {' '}
                                    {AmsDateFormatters.formatDateTime(
                                      reply.updatedTime
                                    )}
                                  </div>
                                </Comment.Metadata>
                                <Comment.Text>{reply.response}</Comment.Text>
                              </Comment.Content>
                            </Comment>
                          );
                        }
                      )}
                    </Comment.Group>
                  )}
                </Comment>
              );
            })}
          </Comment.Group>
        </Accordion.Content>
      </Accordion>
    );
  };

  const generateFindingsDetailsOldUi = (data, idx) => {
    if (!data || !data.reviewType) {
      return null;
    }

    const newView =
      newReviewTypes.includes(data.reviewType) ||
      (data.isNewForms === true &&
        newFormReviewTypes.includes(data.reviewType));

    return data.findings?.map((e, index) => {
      const headerStyle = { marginBottom: '10px', fontSize: '12px' };
      return (
        <Segment.Group key={index}>
          <Segment>
            <Header as="h4" color="blue">
              Finding {index + 1}
              <span
                style={{ color: 'black', float: 'right', marginRight: '-10px' }}
              >
                {e.feedbackStatus !== null
                  ? e.feedbackStatus === 'Approved'
                    ? approvedBox
                    : e.feedbackStatus === 'Rejected'
                    ? rejectedBox
                    : e.feedbackStatus === 'Need Clarification'
                    ? needClarificationBox
                    : e.feedbackStatus === 'In Progress'
                    ? inProgressBox
                    : null
                  : null}
                {e.provideFeedback && (
                  <ReportTrackerFindingsModal
                    type="feedback"
                    level="finding"
                    data={data}
                    index={index}
                    onFeedbackSubmitted={onFeedbackSubmitted}
                    allFindings={
                      (reviewFindings && reviewFindings.length > 1) ||
                      (props.params.reviewId === undefined ? true : false)
                    }
                  />
                )}
              </span>
            </Header>
          </Segment>

          <Grid as={Segment} style={{ margin: '5px' }}>
            <Grid.Row
              columns="4"
              style={{ marginLeft: '5px', marginRight: '5px' }}
            >
              <Grid.Column>
                <Header
                  style={{ marginBottom: '7px', fontSize: '12px' }}
                  as="h5"
                >
                  Citation
                </Header>
                <Link
                  to={{
                    pathname: `/review/citations/definition`,
                    query: { citation: e.citation },
                  }}
                  target="_blank"
                >
                  <Popup
                    trigger={
                      <Label
                        style={{
                          color: 'blue',
                          background: 'white',
                          marginLeft: '-10px',
                          textDecorationLine: 'underline',
                        }}
                      >
                        {' '}
                        {e ? e.citation : ''}
                      </Label>
                    }
                    content="Select to see Citation Definition"
                  />
                </Link>
              </Grid.Column>
              <Grid.Column>
                <Header style={headerStyle} as="h5">
                  {newView ? 'Content Area' : 'Guide Name'}
                </Header>
                {newView ? e.contentAreas?.join(', ') : e.guideName}
              </Grid.Column>
              <Grid.Column>
                <Header style={headerStyle} as="h5">
                  {newView ? 'Performance Area' : 'Performance Measure'}
                </Header>
                {newView
                  ? e.performanceAreas?.join(', ')
                  : e.performanceMeasure}
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Header
                  style={{
                    marginBottom: '10px',
                    display: 'inline-block',
                    marginRight: '5px',
                    fontSize: '12px',
                  }}
                  as="h5"
                >
                  Finding Type
                  {e.findingTypeStatus !== null && ` (${e.findingTypeStatus})`}
                </Header>
                <div style={{ marginRight: '16px' }}>{e.findingType}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginLeft: '5px', marginRight: '5px' }}>
              <Grid.Column>
                <Header as="h5" style={{ fontSize: '12px' }}>
                  {newView ? 'Collected Data and Narrative' : 'Narrative'}
                  {e.narrativeStatus !== null && ` (${e.narrativeStatus})`}
                  <span
                    style={{
                      color: 'black',
                      float: 'right',
                      marginRight: '-15px',
                    }}
                  ></span>
                </Header>
                <Message>
                  <div
                    style={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {!newView ? (
                      <></>
                    ) : (
                      <ul style={{ margin: '0px', paddingLeft: '10px' }}>
                        {e.collectedData?.map(x => (
                          <li style={DOMPurify.sanitize(x)?.includes("_") ? {marginLeft: '10px'} : {}}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(x?.replace(/\d+_/, '')),
                              }}
                            ></span>
                          </li>
                        ))}
                      </ul>
                    )}
                    <br />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(e.narrative),
                      }}
                    ></div>
                  </div>
                </Message>
              </Grid.Column>
            </Grid.Row>
            {e.comments && e.comments.length > 0 && (
              <Grid.Row>
                <Grid.Column>
                  {generateCommentsView("finding", e)}
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Segment.Group>
      );
    });
  };

  const generateDelegatedTaskDetailsOldUi = data => {
    const newView =
      newReviewTypes.includes(data.reviewType) ||
      (data.isNewForms === true &&
        newFormReviewTypes.includes(data.reviewType));

    return data.findings?.map((e, index) => {
      // const headerStyle = { marginBottom: '10px', fontSize: '12px' };
      return (
        <Segment.Group key={index}>
          <Segment>
            <Header as="h4" color="blue">
              Finding {index + 1}
              <span
                style={{ color: 'black', float: 'right', marginRight: '-10px' }}
              >
                {e.feedbackStatus !== null
                  ? e.feedbackStatus === 'Approved'
                    ? approvedBox
                    : e.feedbackStatus === 'Rejected'
                    ? rejectedBox
                    : e.feedbackStatus === 'Need Clarification'
                    ? needClarificationBox
                    : e.feedbackStatus === 'In Progress'
                    ? inProgressBox
                    : null
                  : null}
              </span>
            </Header>
          </Segment>

          <Grid as={Segment} style={{ margin: '5px' }}>
            <Grid.Row
              columns="4"
              style={{ marginLeft: '5px', marginRight: '5px' }}
            >
              <Grid.Column>
                <span style={{ marginRight: '10px' }}>{e.findingType}</span>{' '}
                <Link
                  to={{
                    pathname: `/review/citations/definition`,
                    query: { citation: e.citation },
                  }}
                  target="_blank"
                >
                  <Popup
                    trigger={
                      <Label
                        style={{
                          color: '#245e9d',
                          background: 'white',
                          marginLeft: '-10px',
                          fontSize: '14px',
                          textDecorationLine: 'underline',
                        }}
                      >
                        {' '}
                        {e ? `${e.citation}` : ''}
                      </Label>
                    }
                    content="Select to see Citation Definition"
                  />
                </Link>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginLeft: '5px', marginRight: '5px' }}>
              <Grid.Column>
                <Header as="h5" style={{ fontSize: '12px' }}>
                  {newView ? 'Collected Data and Narrative' : 'Narrative'}
                  {e.narrativeStatus !== null && ` (${e.narrativeStatus})`}
                  <span
                    style={{
                      color: 'black',
                      float: 'right',
                      marginRight: '-15px',
                    }}
                  ></span>
                </Header>
                <Message>
                  {!newView ? (
                    <></>
                  ) : (
                    <ul style={{ margin: '0px', paddingLeft: '10px' }}>
                      {e.collectedData?.map(x => (
                        <li style={DOMPurify.sanitize(x)?.includes("_") ? {marginLeft: '10px'} : {}}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(x?.replace(/\d+_/, '')),
                            }}
                          ></span>
                        </li>
                      ))}
                    </ul>
                  )}
                  <br />
                  <span
                    style={{ whiteSpace: 'pre-wrap' }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(e.narrative),
                    }}
                  ></span>
                </Message>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment.Group>
      );
    });
  };

  const generateDelegatedTaskDetails = (data) => {
      return (<>
        {data.taskFindings?.map((item, index) => {
          var findings = getContentAreaFindings(item);
          return (<Accordion key={index} fluid style={{marginBottom: '15px', boxShadow: 'none'}}>
            <Accordion.Title
              active={activeIndex.includes(index)}
              index={0}
              onClick={x => { handleAccordion(index) }}
              style={{ backgroundColor: '#DCDCDC', padding: '15px', borderRadius: "5px", color: '#000000de' }}
            >
              <h3 style={{ margin: '0' }}>
                  {item.contentArea}
                <Icon
                  style={{ float: 'right' }}
                  name={activeIndex.includes(index) ? 'minus' : 'plus'}
                />
              </h3>
            </Accordion.Title>
            <Accordion.Content active={activeIndex.includes(index)}>
              <Segment style={{border: "none", padding: "10px 0px 0px", boxShadow: "none"}}>
                {findings?.map((e, index) => {
                  const headerStyle = { marginBottom: '10px', fontSize: '1rem' };
                  const isStrongPractice = e.collectDataType == STRONG_PRACTICE;
                  const isFinding = e.collectDataType == FINDING;
                  return (
                    <Segment.Group key={index} style={{ border: '1px solid #ccc', boxShadow: 'none', paddingBottom: '10px'}}>
                    <Segment style={{padding: '0px 10px 12px 5px', 
                      margin: '15px 15px 0px 10px', 
                      borderBottom: '1px solid #ccc'}}>
                      <Header as="h5">
                        {isStrongPractice ? e.findingDisplayName : e.findingDisplayName + ": " + e.citation}
                        <span
                          style={{ color: 'black', float: 'right', marginRight: '-10px' }}
                        >
                          {e.feedbackStatus !== null
                            ? e.feedbackStatus === 'Approved'
                              ? approvedBox
                              : e.feedbackStatus === 'Rejected'
                              ? rejectedBox
                              : e.feedbackStatus === 'Need Clarification'
                              ? needClarificationBox
                              : e.feedbackStatus === 'In Progress'
                              ? inProgressBox
                              : null
                            : null}
                        </span>
                      </Header>
                    </Segment>
                    <Grid as={Segment} style={{ margin: '5px', border: 'none' }}>
                      <Grid.Row
                        columns="4"
                        style={{ marginLeft: '5px', marginRight: '5px' }}
                      >
                      {isFinding ? 
                      <Grid.Column>
                        <Header style={headerStyle} as="h5">
                          Citation
                        </Header>
                        <Link
                          to={{
                            pathname: `/review/citations/definition`,
                            query: { citation: e.citation },
                          }}
                          style={{fontSize: '1rem'}}
                          target="_blank"
                        >
                          <Popup
                            trigger={
                              <Label
                                style={{
                                  color: 'blue',
                                  background: 'white',
                                  padding: '0px',
                                  fontSize: '14px',
                                  textDecorationLine: 'underline',
                                }}
                              >
                                {' '}
                                {e ? e.citation : ''}
                              </Label>
                            }
                            content="Select to see Citation Definition"
                          />
                        </Link>
                      </Grid.Column> : null}
                      {isStrongPractice ?
                      <Grid.Column>
                        <Header style={headerStyle} as="h5">
                          Performance Area
                        </Header>
                        {e.performanceAreas?.join(', ')}
                      </Grid.Column> : null}
                      {isFinding ? 
                      <Grid.Column>
                        <Header style={headerStyle} as="h5">
                          Finding Type
                          {e.findingTypeStatus !== null && ` (${e.findingTypeStatus})`}
                        </Header>
                        <div>{e.findingType}</div>
                      </Grid.Column> : null}
                      </Grid.Row>
                      <Grid.Row style={{ marginLeft: '5px', marginRight: '5px' }}>
                        <Grid.Column>
                          <Header style={headerStyle} as="h5">
                            {isFinding ? 'Collected Data and Narrative' : 'Collected Data'}
                            {e.narrativeStatus !== null && ` (${e.narrativeStatus})`}
                            <span
                              style={{
                                color: 'black',
                                float: 'right',
                                marginRight: '-15px',
                              }}
                            ></span>
                          </Header>
                          <Message style={{borderRadius: '2px', backgroundColor: 'transparent'}}>
                            <div
                              style={{
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              {(
                                <ul style={{ margin: '0px', paddingLeft: '10px' }}>
                                  {e.collectedData?.map((x, index) => (
                                    <li key={index} style={DOMPurify.sanitize(x)?.includes("_") ? {marginLeft: '10px'} : {}}>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(x?.replace(/\d+_/, '')),
                                        }}
                                      ></span>
                                    </li>
                                  ))}
                                </ul>
                              )}
                              <br />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(e.narrative),
                                }}
                              ></div>
                            </div>
                          </Message>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment.Group> 
                  )
                })}
              </Segment>
            </Accordion.Content>
          </Accordion>
          )}
        )}
      </>)
  }

  const headerMargin = delegatedTasks === true ? '60px' : '20px';

  const delegatedStyleCollapse = {
    fontSize: '12px',
    display: 'block',
    position: 'absolute',

    right: '25px',
    marginTop: '10px',
  };

  let findingsCount = 0;
  let strongPracticeCount = 0;
  if (reviewFindings.length > 0) {
    reviewFindings.forEach(e => {
      findingsCount += e.findingsCount;
      strongPracticeCount += e.strongPracticeCount;
    });
  }
  if (reviewFindings?.length == 1) {
    if (!Object.keys(activePanelsObj)?.includes(reviewFindings[0].conversationId)) {
      onAccordionDropdownClick(null, reviewFindings[0], 0);
    }
  }
  return (
    <SlidingContainer title={'Tasks'}>
      {reviewFindings.length > 0 &&
        <React.Fragment>
          <Header style={{ paddingBottom: headerMargin }} as="h3">
            All Findings ({findingsCount}){!hideStrongPractices(user) ? ` and Strong Practices (${strongPracticeCount}) ` : ''}
            {delegatedTasks === true && (
              <div
                style={{
                  float: 'right',
                  fontSize: '12px',
                }}
                className="delegatedActionButtons"
              >
                {renderDelegatedActionButtons()}
              </div>
            )}
            <div
              style={
                delegatedTasks === true
                  ? delegatedStyleCollapse
                  : { float: 'right', fontSize: '12px' }
              }
              className="actionButtons"
            >
            </div>
          </Header>
          {generateReviewList()}
        </React.Fragment>
      }
    </SlidingContainer>
  );
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  delegatedTasksList: state.tasks.delegatedTasksList,
  total: state.tasks.total,
});

export default connect(mapStateToProps, {
  fetchReportFindingsDetail,
})(withRouter(SurveyAllReportTrackerFindingsPage));