import React from 'react';
import { Button } from 'semantic-ui-react';
import { browserHistory } from 'react-router';

export const CreateQuestionButton = () => {
  return (
    <Button
      color="blue"
      size="large"
      style={{ float: 'right' }}
      onClick={() => {
        browserHistory.push('/admin/surveyquestions/create');
      }}
    >
      Create Question
    </Button>
  );
};

export default CreateQuestionButton;
