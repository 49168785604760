import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Constants from '../Shared/SurveyFormConstants';
import Wysiwyg from '../../Wysiwyg';
import { isEmpty } from 'lodash';

class SurveysRadioListWidget extends Component {
  constructor(props) {
    super(props);

    const { value } = props;
    this.state = {
      selectedValues: value || [],
      otherReasonValue: '',
    };
  }
  componentDidMount() {
    this.adjustTrixEditor();
    document.addEventListener('trix-initialize', this.handleTrixInitialize);
    document.addEventListener('trix-change', this.handleTrixChange);
  }

  componentWillUnmount() {
    document.removeEventListener('trix-initialize', this.handleTrixInitialize);
    document.removeEventListener('trix-change', this.handleTrixChange);
  }

  handleTrixInitialize = event => {
    const editor = event.target;
    this.updateContentEditable(editor);
  };

  handleTrixChange = event => {
    const editor = event.target;
    this.updateContentEditable(editor);
  };

  updateContentEditable = editor => {
    const { selectedValues } = this.state;
    const otherChecked = selectedValues.indexOf(Constants.OTHER_KEY) !== -1;

    if (editor && editor.title.match(/other_text$/)) {
      const match =
        editor.title.match(/^root_([a-fA-F0-9-]+_\d+)/) ||
        editor.title.match(/^root_([0-9a-fA-F-]+)_/);
      const idMatch =
        this.props.id.match(/^root_([a-fA-F0-9-]+_\d+)/) ||
        this.props.id.match(/^root_([0-9a-fA-F-]+)_/);

      if (match?.[1] === idMatch?.[1]) {
        editor.setAttribute('contenteditable', otherChecked ? 'true' : 'false');
      }
    }
  };

  adjustTrixEditor = () => {
    const trixEditors = document.querySelectorAll('trix-editor');
    trixEditors.forEach(this.updateContentEditable);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ selectedValues: this.props.value || [] });
    }
    this.adjustTrixEditor();
  }

  handleOptionChange = (event, option) => {
    var newValue = [option.value];
    this.setState({ selectedValues: newValue }, () => {
      this.triggerOnChange(newValue);
    });
  };

  handleOtherChange = event => {
    var newValue = [Constants.OTHER_KEY];
    this.setState({ selectedValues: newValue }, () => {
      this.triggerOnChange(newValue);
      this.adjustTrixEditor();
    });
  };

  handleOtherReasonChange = content => {
    if (!isEmpty(content)) {
      var newValue = [
        Constants.OTHER_KEY,
        Constants.OTHER_REASON_KEY + content,
      ];
      this.setState({ selectedValues: newValue }, () => {
        this.triggerOnChange(newValue);
        this.adjustTrixEditor();
      });
    }
  };

  triggerOnChange(value) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  }
  adjustTrixEditor = () => {
    const trixEditors = document.querySelectorAll('trix-editor');
    trixEditors.forEach(this.updateContentEditable);
  };

  render() {
    const { id, disabled, options, autofocus, readonly, schema } = this.props;
    let { enumOptions, enumDisabled, inline } = options;
    // Filter out OTHER Key from the list to display
    enumOptions = enumOptions?.filter(o => o.value != Constants.OTHER_KEY);

    const { selectedValues } = this.state;
    const otherIndex = enumOptions.length;
    const otherChecked = selectedValues.indexOf(Constants.OTHER_KEY) !== -1;
    const otherReasonValue = otherChecked
      ? selectedValues.find(el =>
          el?.toString().startsWith(Constants.OTHER_REASON_KEY)
        )
      : null;
    const otherReasonValueClean = isEmpty(otherReasonValue)
      ? ''
      : otherReasonValue.replace(Constants.OTHER_REASON_KEY, '');
    const otherDisabled =
      enumDisabled && enumDisabled.indexOf(Constants.OTHER_KEY) !== -1;
    const otherDisabledCls =
      disabled || otherDisabled || readonly ? 'disabled' : '';
    const otherTextEnabled =
      otherChecked &&
      schema?.hasOwnProperty('readOnly') &&
      'disabled' !== otherDisabledCls;
    const otherTextDisabled = schema?.hasOwnProperty('readOnly')
      ? false
      : disabled || otherDisabled || readonly;
    const otherRadio = (
      <span>
        <input
          type="radio"
          id={`${id}_${otherIndex}`}
          checked={
            (otherChecked && !schema?.hasOwnProperty('readOnly')) ||
            otherTextEnabled
          }
          disabled={disabled || otherDisabled || readonly}
          autoFocus={autofocus && otherIndex === 0}
          onChange={this.handleOtherChange}
        />
        <span>Other</span>
      </span>
    );
    let allOptions = [];
    enumOptions.forEach((option, index) => {
      allOptions.push(option.value);
      if (schema.items.contingentEnum) {
        schema.items.contingentEnum.forEach(contingentItem => {
          allOptions.push(`${index}_${contingentItem}`);
        });
      }
    });
    this.adjustTrixEditor();
    return (
      <div className="radio-list" id={id}>
        {enumOptions.map((option, index) => {
          const checked = selectedValues.indexOf(option.value) !== -1;
          const itemDisabled =
            enumDisabled && enumDisabled.indexOf(option.value) !== -1;
          const disabledCls =
            disabled || itemDisabled || readonly ? 'disabled' : '';
          const checkId = `${id}_${index}`;

          const radio = (
            <span>
              <input
                type="radio"
                id={checkId}
                checked={checked}
                disabled={disabled || itemDisabled || readonly}
                autoFocus={autofocus && index === 0}
                onChange={event => this.handleOptionChange(event, option)}
              />
              <span>{option.label}</span>
            </span>
          );

          return inline ? (
            <label key={index} className={`radio-inline ${disabledCls}`}>
              {radio}
            </label>
          ) : (
            <div key={index} className={`radio ${disabledCls}`}>
              <label>{radio}</label>
            </div>
          );
        })}
        {!schema.showOther ? null : (
          <>
            <div key={otherIndex} className={`radio ${otherDisabledCls}`}>
              <label style={{ width: '100%' }}>{otherRadio}</label>
            </div>
            {!otherChecked && !schema?.hasOwnProperty('readOnly') ? null : (
              <div id={`${id}_${otherIndex}_other`}>
                <span style={{ fontWeight: 'bold' }}>
                  If Other selected, Please specify*
                </span>
                <Wysiwyg
                  id={`${id}_${otherIndex}_other_text`}
                  style={{ width: '100%' }}
                  required
                  disabled={otherTextDisabled}
                  value={otherReasonValueClean}
                  focusOnInit={false}
                  onChange={this.handleOtherReasonChange}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

SurveysRadioListWidget.defaultProps = {
  autofocus: false,
  options: {
    inline: false,
  },
};

SurveysRadioListWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.shape({
    enumOptions: PropTypes.array,
    inline: PropTypes.bool,
  }).isRequired,
  value: PropTypes.any,
  required: PropTypes.bool,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SurveysRadioListWidget;
