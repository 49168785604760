import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dimmer, List, Loader } from 'semantic-ui-react';

import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import SlidingContainer from '../../../utils/layout/SlidingContainer';
import { granteeName } from '../../../utils/TextFormatters';
import GranteeDetailAvailabilityPage from './GranteeDetailAvailabilityPage';

import { fetchGranteeAvailability } from '../../../actions/granteeActions';
import GranteeSidebar from '../GranteeSidebar';

const GranteeDetailUnavailability = props => {
  const dispatch = useDispatch();
  const { fiscalYear, nextFiscalYear, hsesStatus } = useSelector(
    state => state.granteeDetails
  );
  const { granteeDetails } = useSelector(state => state);
  const [selectedGrantee, setSelectedGrantee] = useState({});
  const [lastEditedGrantee, setLastEditedGrantee] = useState({});
  const [actionButons, setActionButtons] = useState();
  const [editMode, setEditMode] = useState(false);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState(
    AmsDateFormatters.getMoment().year()
  );

  useEffect(() => {
    const getGranteeCalendarDetails = () => {
      let granteeId = props.params.id;
      dispatch(fetchGranteeAvailability({ granteeId }));
    };
    getGranteeCalendarDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateSelectedGrantee = () => {
      if (granteeDetails[fiscalYear] && granteeDetails[fiscalYear].granteeInfo)
        setSelectedGrantee(granteeDetails[fiscalYear].granteeInfo);
      setSelectedFiscalYear(fiscalYear);
    };
    updateSelectedGrantee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [granteeDetails]);

  useEffect(() => {
    const buildActionButons = () => {
      if (!granteeDetails[selectedFiscalYear].granteeInfo) return;
      return (
        <>
          <List.Item>
            <List.Content>
              {!editMode &&
                granteeDetails?.isEditableRole &&
                hsesStatus === 'Active' && (
                  <Button
                    color="green"
                    size="large"
                    onClick={() => setEditMode(true)}
                  >
                    Edit Grantee Unavailability
                  </Button>
                )}
            </List.Content>
          </List.Item>
        </>
      );
    };

    if (
      granteeDetails &&
      granteeDetails[fiscalYear] &&
      granteeDetails[fiscalYear].granteeInfo
    )
      setActionButtons(buildActionButons());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, selectedFiscalYear]);

  const savePreviouseGrantee = value => {
    if (!isEmpty(value))
      setLastEditedGrantee({ ...lastEditedGrantee, ...value });
    else setLastEditedGrantee({});
  };

  const resetEditMode = value => {
    setEditMode(value);
  };

  const changeSelectedFiscalYear = year => {
    setSelectedFiscalYear(year);
  };

  return (
    <SlidingContainer
      title={granteeName(granteeDetails)}
      actionButtons={actionButons}
      sidebarComponent={
        <GranteeSidebar hasEditableRoles={granteeDetails?.isEditableRole} />
      }
    >
      {isEmpty(selectedGrantee) ? (
        <Dimmer active inverted>
          <Loader inverted>Loading...</Loader>
        </Dimmer>
      ) : (
        <>
          {!isEmpty(selectedGrantee) && (
            <GranteeDetailAvailabilityPage
              selectedGrantee={selectedGrantee}
              fiscalYear={fiscalYear}
              nextFiscalYear={nextFiscalYear}
              editMode={editMode}
              resetEditMode={resetEditMode}
              savePreviouseGrantee={data => savePreviouseGrantee(data)}
              lastEditedGrantee={lastEditedGrantee}
              changeSelectedFiscalYear={changeSelectedFiscalYear}
            />
          )}
        </>
      )}
    </SlidingContainer>
  );
};

export default GranteeDetailUnavailability;
