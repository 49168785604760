import { xor, cloneDeep } from 'lodash';
import {
  CITATIONS_FETCHED,
  CITATIONS_FILTERED,
  CITATIONS_FILTER_CLEARED,
  CREATE_REVIEW_ADD_PRIMARY_USER,
  CREATE_REVIEW_REMOVE_PRIMARY_USER,
  CREATE_REVIEW_UPDATE_SHADOWREVIEWER_LOCATION,
  CREATE_REVIEW_UPDATE_TEAM,
  EVIDENCE_BINDER_FETCHED,
  FETCH_REGIONAL_REVIEWS,
  JOURNAL_LOGS_FETCHED,
  MANIFEST_FETCHED,
  MANIFEST_FILE_RECREATED,
  REVIEWER_POSITION_ADD,
  REVIEWER_POSITION_CLEARED,
  REVIEWER_POSITION_FETCHED,
  REVIEWER_POSITION_REVIEWER_ADD,
  REVIEWER_POSITION_REVIEWER_UPDATE,
  REVIEWER_POSITION_SELECTED,
  REVIEWER_TOTAL_INCREMENT,
  REVIEWS_FETCHED,
  REVIEW_CREATE_FOLLOWUP_REVIEW,
  REVIEW_DOCUMENTS_FETCHED,
  REVIEW_EMAIL_NOTIFY_FOM,
  REVIEW_EMAIL_NOTIFY_MPS,
  REVIEW_EMAIL_NOTIFY_QC,
  REVIEW_EMAIL_NOTIFY_RL,
  REVIEW_RPM_FEEDBACK_PROGRAM_SPECIALIST,
  REVIEW_RPM_FEEDBACK_FISCAL_SPECIALIST,
  REVIEW_RPM_FEEDBACK,
  REVIEW_CONTENT_AREAS_PERFORMANCE_AREAS_FETCHED,
  REVIEW_INFORMATION_FETCHED,
  REVIEW_EVIDENCE_FETCHED,
  REVIEW_FIDINGS_DETAILS,
  REVIEW_FINALIZED,
  REVIEW_FINALIZE_FUR,
  REVIEW_FOLLOWUPS_FETCHED,
  REVIEW_FOLLOWUP_RECOMMENDATIONS,
  REVIEW_FOLLOWUP_SUBMISSIONS,
  REVIEW_GUIDES_FETCHED,
  REVIEW_OCI_SUMMARY_FETCHED,
  REVIEW_PERFORMANCE_MEASURES_FETCHED,
  REVIEW_SELECTED,
  REVIEW_SET_RESPONSE_OBJ,
  REVIEW_SURVEY_SUBMISSIONS_FETCHED,
  REVIEW_SURVEY_SUBMITTERS_FETCHED,
  UPDATE_REVIEW_STATUS,
  UPDATE_TEAM_POSITIONS,
  REVIEW_FOLLOWUP_FINDING_DETAILS_FETCHED,
  REVIEW_FOLLOWUP_FUR_TEAM_FETCHED,
  REVIEW_FOLLOWUP_FUR_TEAM_UPDATED,
  EXPLORATION_SUMMARY_FETCHED,
  SURVEY_GUIDES_FETCHED,
  SURVEY_CITATIONS_FETCHED,
  MANAGEMENT_SYSTEM_ANALYSIS_REPORT_FETCHED,
  PRE_REVIEW_SUMMARY_PAGE_FETCHED,
  SURRVEY_GUIDE_DETAILS_FETCHED,
} from '../redux/types';

const initialState = {
  total: 0,
  reviews: [],
  reviewList: [],
  reviewsList: {
    data: [],
    limit: 0,
    page: 0,
    total: -1,
  },
  reviewSurveys: {
    submissions: [],
    submitters: [],
  },
  selectedReview: {
    reviewDocuments: {},
    guides: [],
    performanceMeasures: [],
    reviewBinder: [],
  },
  followUpSurveyDetails: {
    followUpCitations: [],
  },
  message: {},
  notificationStatus: {},
  reviewInfo: {
    positions: [],
    reviewersHistory: [],
    reviewers: [],
    primaryUsers: [],
  },
  finalizeFUR: false,
  fetchedFollowupRecommendations: null,
  citations: [],
  selectedPosition: {
    _id: '',
    languageSkills: [],
    contentAreaSkills: [],
    otherSkills: [],
  },
  runPresetSearch: false,
  furTeamInfo: {},
  explorationSummary: null,
};

export default function review(state = initialState, action) {
  switch (action.type) {
    case REVIEW_SURVEY_SUBMISSIONS_FETCHED: {
      return {
        ...state,
        reviewSurveys: {
          ...state.reviewSurveys,
          submissions: action.submissions,
        },
      };
    }

    case REVIEW_SURVEY_SUBMITTERS_FETCHED: {
      return {
        ...state,
        reviewSurveys: {
          ...state.reviewSurveys,
          submitters: action.submitters,
        },
      };
    }

    case REVIEWS_FETCHED: {
      return {
        ...state,
        reviews: action.reviews,
        reviewsList: action.reviewsList,
        total: action.reviewsList.total,
        message: {},
      };
    }

    case REVIEW_SELECTED: {
      if (
        (action.review || {}).reviewAccessLevel &&
        (action.review || {}).reviewAccessLevel.isReviewAccessible === false &&
        (action.review || {}).reviewStatus !== 'Report Signed' &&
        (action.review || {}).reviewStatus !== 'Shipped'
      ) {
        action.review.readOnly = true;
      }

      if (
        state &&
        state.selectedReview &&
        action &&
        action.review &&
        state.selectedReview.reviewId !== action.review.reviewId
      ) {
        return {
          ...state,
          selectedReview: { ...action.review },
          message: {},
        };
      }

      return {
        ...state,
        selectedReview: { ...state.selectedReview, ...action.review },
        message: {},
      };
    }

    case REVIEW_FINALIZED: {
      return { ...state, message: action.message };
    }

    case REVIEW_CREATE_FOLLOWUP_REVIEW:
      return { ...state, createFollowupReview: action.createFollowupReview };

    case MANIFEST_FETCHED: {
      return { ...state, manifest: action.manifest };
    }

    case MANIFEST_FILE_RECREATED: {
      return {
        ...state,
        selectedReview: { ...state.selectedReview, manifestLink: action.link },
      };
    }

    case REVIEW_DOCUMENTS_FETCHED: {
      return {
        ...state,
        reviewDocumentsFetchedList: action.reviewDocumentsFetchedList,
      };
    }

    case REVIEW_FIDINGS_DETAILS:
      let list = [];
      if (Array.isArray(action.fetchedFindingDetails.findings)) {
        let copyObject = item => JSON.parse(JSON.stringify(item));

        action.fetchedFindingDetails.findings.forEach(item => {
          if (Array.isArray(item.associatedCitations)) {
            item.associatedCitations.forEach(associatedCitation => {
              let listItem = copyObject(item);
              listItem.associatedCitations = [associatedCitation];
              list.push(copyObject(listItem));
            });
          } else {
            list.push(copyObject(item));
          }
        });
      }
      let payload = { ...action.fetchedFindingDetails };
      payload = { ...payload, findingsList: [...list] };
      return { ...state, fetchedFindingDetails: payload };

    case REVIEW_FOLLOWUP_RECOMMENDATIONS:
      return {
        ...state,
        fetchedFollowupRecommendations: action.fetchedFollowupRecommendations,
      };

    case REVIEW_EMAIL_NOTIFY_MPS:
      return { ...state, fetchedEmailNotifyMps: action.fetchedEmailNotifyMps };

    case REVIEW_SET_RESPONSE_OBJ:
      return { ...state, notificationStatus: action.returnedStatus.data };

    case REVIEW_EMAIL_NOTIFY_RL:
      return { ...state, fetchedEmailNotifyRL: action.fetchedEmailNotifyRL };

    case REVIEW_EMAIL_NOTIFY_QC:
      return { ...state, fetchedEmailNotifyQC: action.fetchedEmailNotifyQC };

    case REVIEW_EMAIL_NOTIFY_FOM:
      return { ...state, fetchedEmailNotifyFOM: action.fetchedEmailNotifyFOM };

    case FETCH_REGIONAL_REVIEWS: {
      return { ...state, regionalReviews: action.regionalReviews };
    }

    case REVIEW_RPM_FEEDBACK_PROGRAM_SPECIALIST:
      return {
        ...state,
        reviewRpmFeedbackProgramSpecialistFetchedList:
          action.reviewRpmFeedbackProgramSpecialistFetchedList,
      };

    case REVIEW_RPM_FEEDBACK_FISCAL_SPECIALIST:
      return {
        ...state,
        reviewRpmFeedbackFiscalSpecialistFetchedList:
          action.reviewRpmFeedbackFiscalSpecialistFetchedList,
      };

    case REVIEW_RPM_FEEDBACK:
      return {
        ...state,
        saveReviewRpmFeedbackFetchedData: action.reviewRpmFeedbackFetchedData,
      };

    case REVIEW_INFORMATION_FETCHED:
      return {
        ...state,
        reviewInfo: action.reviewInfo,
      };

    case REVIEW_EVIDENCE_FETCHED:
      return {
        ...state,
        selectedReview: {
          ...state.selectedReview,
          reviewDocuments: action.documents,
        },
      };
    case REVIEW_FINALIZE_FUR:
      return { ...state, finalizeFUR: action.finalizeFUR };

    case UPDATE_REVIEW_STATUS:
      return {
        ...state,
        selectedReview: {
          ...state.selectedReview,
          reviewStatus: action.status,
        },
      };

    case REVIEW_GUIDES_FETCHED:
      return {
        ...state,
        selectedReview: {
          ...state.selectedReview,
          guides: action.guides,
        },
      };

    case SURVEY_GUIDES_FETCHED:
      return {
        ...state,
        selectedReview: {
          ...state.selectedReview,
          guides: action.guides,
        },
      };

    case SURVEY_CITATIONS_FETCHED:
      return {
        ...state,
        followUpSurveyDetails: {
          followUpCitations: action.followupCitations,
        },
      };

    case SURRVEY_GUIDE_DETAILS_FETCHED:
      return {
        ...state,
        surveyGuideDetails: action.surveyGuideDetails,
      };

    case REVIEW_CONTENT_AREAS_PERFORMANCE_AREAS_FETCHED:
      return {
        ...state,
        capaForReview: {
          ...state.capaForReview,
          contentAreas: action.contentAreas,
        },
      };

    case REVIEW_PERFORMANCE_MEASURES_FETCHED:
      return {
        ...state,
        selectedReview: {
          ...state.selectedReview,
          performanceMeasures: action.performanceMeasures,
        },
      };

    case REVIEW_OCI_SUMMARY_FETCHED:
      return {
        ...state,
        selectedReview: {
          ...state.selectedReview,
          ociSummaryDetails: action.summaryDetails,
        },
      };

    case CITATIONS_FETCHED:
      return {
        ...state,
        citations: action.citations,
      };

    case CITATIONS_FILTERED:
      return {
        ...state,
        citations: action.citations,
      };

    case CITATIONS_FILTER_CLEARED:
      return {
        ...state,
        citations: [],
      };

    case REVIEW_FOLLOWUP_SUBMISSIONS:
      return {
        ...state,
        followUpSubmission: action.submission.submission,
      };

    case REVIEWER_POSITION_CLEARED:
      return {
        ...state,
        reviewInfo: {
          ...state.reviewInfo,
          positions: [],
        },
      };

    case REVIEWER_POSITION_ADD:
      return {
        ...state,
        reviewInfo: {
          ...state.reviewInfo,
          positions: action.positions,
        },
      };

    case REVIEWER_POSITION_REVIEWER_ADD:
      return {
        ...state,
        reviewInfo: {
          ...state.reviewInfo,
          reviewers: action.reviewers,
        },
      };

    case REVIEWER_POSITION_SELECTED:
      return {
        ...state,
        selectedPosition: action.selectedPosition,
        runPresetSearch: action.runPresetSearch,
      };

    case REVIEWER_POSITION_FETCHED:
      return {
        ...state,
        runPresetSearch: false,
      };

    case REVIEWER_POSITION_REVIEWER_UPDATE:
      const { reviewers } = state.reviewInfo;
      const {
        dates,
        status,
        invitationDate,
        willingToTravel,
        reviewerDatesUpdated,
      } = action.data;
      const updatedReviewers = reviewers.map(e => {
        if (e.oid === action.reviewer.oid) {
          if (!e.previousDates) {
            e.previousDates = e.dates;
            if (e.status !== 'withdrawn') e.previousStatus = e.status;
          }
          e.dates = dates;
          e.invitationDate = invitationDate;
          e.status = status || 'pending';
          if (reviewerDatesUpdated) {
            e.resendInvite = true;
            e.reviewerDatesUpdated = true;
          }
          if (willingToTravel) {
            e.willingToTravel = willingToTravel;
            if (e.resendInvite !== true || !e.resendInvite)
              e.resendInvite = false;
          }
        }

        return e;
      });

      return {
        ...state,
        reviewInfo: {
          ...state.reviewInfo,
          reviewers: updatedReviewers,
        },
      };

    case REVIEW_FOLLOWUPS_FETCHED: {
      return {
        ...state,
        reviewFollowUpsList: action.reviewFollowUpsList,
      };
    }

    case EVIDENCE_BINDER_FETCHED: {
      return {
        ...state,
        selectedReview: {
          ...state.selectedReview,
          reviewBinder: action.files,
        },
      };
    }

    case REVIEWER_TOTAL_INCREMENT:
      return {
        ...state,
        reviewInfo: {
          ...state.reviewInfo,
          numberOfReviewers: (state.reviewInfo.numberOfReviewers += 1),
        },
      };

    case CREATE_REVIEW_ADD_PRIMARY_USER: {
      const { primaryUser } = action;
      const { primaryUsers } = state.reviewInfo;
      // Return if primary user is not set.
      if (!primaryUser) return state;

      // togglePrimaryUsers(primaryUsers, primaryUser)
      const oldPrimaryUsers = primaryUsers.filter(
        p => p.type !== primaryUser.type
      );
      const newPrimaryUsers = [...oldPrimaryUsers, primaryUser];

      return {
        ...state,
        reviewInfo: {
          ...state.reviewInfo,
          primaryUsers: newPrimaryUsers, // togglePrimaryUsers(primaryUsers, primaryUser)
        },
      };
    }

    case CREATE_REVIEW_UPDATE_TEAM:
      return {
        ...state,
        reviewInfo: {
          ...state.reviewInfo,
          [action.name]: action.team,
        },
      };

    case CREATE_REVIEW_UPDATE_SHADOWREVIEWER_LOCATION:
      const { shadowReviewer } = action;
      const { reviewInfo } = state;

      const updatedShadowReviewers = reviewInfo[shadowReviewer.type].map(p => {
        if (p.oid === shadowReviewer.oid) p.onSite = shadowReviewer.onSite;
        return p;
      });

      return {
        ...state,
        reviewInfo: {
          ...state.reviewInfo,
          [shadowReviewer.type]: updatedShadowReviewers,
        },
      };

    case CREATE_REVIEW_REMOVE_PRIMARY_USER: {
      const { primaryUser } = action;
      const { primaryUsers } = state.reviewInfo;
      // Return if primary user is not set.
      if (!primaryUser) return state;

      // togglePrimaryUsers(primaryUsers, primaryUser)

      const oldPrimaryUsers = primaryUsers.filter(
        p => p.type !== primaryUser.type
      );
      const newPrimaryUsers = [...oldPrimaryUsers];

      return {
        ...state,
        reviewInfo: {
          ...state.reviewInfo,
          primaryUsers: newPrimaryUsers, // togglePrimaryUsers(primaryUsers, primaryUser)
        },
      };
    }

    case UPDATE_TEAM_POSITIONS: {
      const { updatedTeamPositions } = action;

      return {
        ...state,
        reviewInfo: {
          ...state.reviewInfo,
          teamPositions: updatedTeamPositions,
        },
      };
    }

    case JOURNAL_LOGS_FETCHED: {
      return { ...state, journalLogs: action.journalLogs };
    }

    case REVIEW_FOLLOWUP_FINDING_DETAILS_FETCHED: {
      return {
        ...state,
        followupFindingDetails: action.followupFindingDetails,
      };
    }

    case REVIEW_FOLLOWUP_FUR_TEAM_FETCHED: {
      return {
        ...state,
        furTeamInfo: action.furTeamInfo,
      };
    }

    case REVIEW_FOLLOWUP_FUR_TEAM_UPDATED:
      const FURs = cloneDeep(state.furTeamInfo.followUpReviews);
      FURs[action.index] = action.review;
      return {
        ...state,
        furTeamInfo: {
          ...state.furTeamInfo,
          followUpReviews: FURs,
        },
      };

    case EXPLORATION_SUMMARY_FETCHED: {
      return { ...state, explorationSummary: action.explorationSummary };
    }

    case MANAGEMENT_SYSTEM_ANALYSIS_REPORT_FETCHED: {
      return {
        ...state,
        managementSystemAnalysisSummary: action.managementSystemAnalysisSummary,
      };
    }

    case PRE_REVIEW_SUMMARY_PAGE_FETCHED: {
      return {
        ...state,
        preReviewSummaryPage: action.preReviewSummaryPage,
      };
    }

    default:
      return state;
  }
}
