import { groupBy, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dimmer, Grid, Header, Icon, Loader, Segment } from 'semantic-ui-react';

// Import utils.
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import SlidingContainer from '../../../utils/layout/SlidingContainer';
import { granteeName } from '../../../utils/TextFormatters';

import { fetchGranteeAvailability } from '../../../actions/granteeActions';

import GranteeSidebar from '../GranteeSidebar';

const DATE_FORMAT = 'M-YYYY';

const GranteeDetailScheduleView = props => {
  const dispatch = useDispatch();
  const { fiscalYear } = useSelector(state => state.granteeDetails);
  const { granteeDetails } = useSelector(state => state);
  const [allUnavailableDates, setAllUnavailableDates] = useState([]);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState('');

  useEffect(() => {
    const getGranteeCalendarDetails = () => {
      let granteeId = props.params.id;
      let year = props.params.fiscalYear;
      setSelectedFiscalYear(year ? year : fiscalYear);
      dispatch(fetchGranteeAvailability({ granteeId }));
    };
    getGranteeCalendarDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getUnavailableDates = () => {
      const grantee =
        granteeDetails &&
        granteeDetails[selectedFiscalYear] &&
        granteeDetails[selectedFiscalYear].granteeInfo;

      const date = d => ({
        fullDate: AmsDateFormatters.formatDate(d),
        month: AmsDateFormatters.getMoment(d).format('MMM'),
        year: AmsDateFormatters.getMoment(d).format('YYYY'),
        monthYear: AmsDateFormatters.getMoment(d).format(DATE_FORMAT),
      });

      if (grantee) {
        const allUnavailabilityDates = [];

        const notInSessionDates =
          grantee.notInSessionDates &&
          grantee.notInSessionDates.map(d => ({
            ...date(d),
            typeDescription: 'Not in Session',
            color: '#205493',
          }));

        !isEmpty(notInSessionDates) &&
          allUnavailabilityDates.push(notInSessionDates);

        const springBreakDates =
          grantee.springBreakDates &&
          grantee.springBreakDates.map(d => ({
            ...date(d),
            typeDescription: 'Spring Break',
            color: '#2e8540',
          }));

        !isEmpty(springBreakDates) &&
          allUnavailabilityDates.push(springBreakDates);

        const unavailabilityDates =
          grantee.unavailableDates &&
          grantee.unavailableDates.map(d => ({
            ...date(d),
            typeDescription: 'Unavailable',
            color: '#323a45',
          }));

        !isEmpty(unavailabilityDates) &&
          allUnavailabilityDates.push(unavailabilityDates);

        const groupedUnavailabilityDates = groupBy(
          [].concat(...Object.values(allUnavailabilityDates)),
          'monthYear'
        );
        let dates = formatUnavailablilityDates(groupedUnavailabilityDates);
        setAllUnavailableDates(dates);
      }
    };

    getUnavailableDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiscalYear, granteeDetails]);

  const formatUnavailablilityDates = allUnavailabilityDates => {
    // Review year start usually runs from October - September
    const startAt = AmsDateFormatters.getMoment()
      .year(selectedFiscalYear - 1)
      .set('month', 9);

    const unavailablilityDatesList = [];
    let dates = [];

    for (let i = 0; i < 12; i++) {
      const nextMonth = AmsDateFormatters.getMoment(startAt)
        .add(i, 'month')
        .format(DATE_FORMAT);

      dates = allUnavailabilityDates[nextMonth];

      if (!isEmpty(dates)) unavailablilityDatesList.push(dates);
    }

    return unavailablilityDatesList;
  };

  const formatDates = dates =>
    dates &&
    sortDate(dates).map((date, key) => (
      <Grid.Row key={key}>
        <Grid.Column width={8}>
          <Icon name="circle" style={{ color: date.color }} /> &nbsp;
          {AmsDateFormatters.getMoment(date.fullDate).format('MMMM D')}
        </Grid.Column>
        <Grid.Column width={8}>
          {String(date.typeDescription).trim()}
        </Grid.Column>
      </Grid.Row>
    ));

  const sortDate = d =>
    d.sort(
      (a, b) =>
        new AmsDateFormatters.getMoment(a.fullDate).format('YYYYMMDD') -
        new AmsDateFormatters.getMoment(b.fullDate).format('YYYYMMDD')
    );

  const unavailabilityDates = () => {
    return (
      allUnavailableDates &&
      allUnavailableDates.map((dates, key) => (
        <Grid.Row key={key}>
          <Grid.Column width={2}>
            <Segment basic>
              <Header
                as={'h3'}
                content={dates[0].month}
                style={{ textTransform: 'uppercase', marginBottom: 0 }}
              />
              <Header
                as={'h5'}
                content={dates[0].year}
                style={{
                  textTransform: 'uppercase',
                  marginTop: 0,
                  color: '#888',
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment basic>
              <Grid columns="equal">{formatDates(dates)}</Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column width={6} />
        </Grid.Row>
      ))
    );
  };

  return (
    <SlidingContainer
      title={granteeName(granteeDetails)}
      sidebarComponent={
        <GranteeSidebar hasEditableRoles={granteeDetails?.isEditableRole} />
      }
    >
      {isEmpty(allUnavailableDates) ? (
        <Dimmer active inverted>
          <Loader inverted>Loading...</Loader>
        </Dimmer>
      ) : (
        <Grid columns="equal" divided="vertically">
          {unavailabilityDates()}
        </Grid>
      )}
    </SlidingContainer>
  );
};

export default GranteeDetailScheduleView;
